import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import { getMonthName } from '../../../../utils/common';
import { nationalityToFlag, statusDiv } from '../../../../utils/helper';
const PeopleAndContactItem = ({ officer, isCardView = false }) => {
  return (
    <div
      className={` ${
        isCardView
          ? 'company-info-modal-person-list-is-card-view'
          : 'company-info-modal-person-list'
      }`}
    >
      <div className="company-info-modal-person-list-info">
        <div style={{ display: 'flex' }}>
          <PersonIcon fontSize="small" />
          <div style={{ width: '100%', marginLeft: '10px' }}>
            <div
              className="company-info-modal-overview-key"
              style={{ display: 'flex', justifyContent: 'space-between' ,marginBottom:"2px"}}
            >
              {officer.name}
              <div className="company-info-modal-person-list-info-link-buttons">
                <div>
                  {officer.resigned_on
                    ? statusDiv('resigned')
                    : statusDiv('active')}
                </div>
                <div>
                  <a
                    href={`https://www.linkedin.com/search/results/all/?keywords=${officer?.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon style={{ width: '20px' }} />
                  </a>
                </div>
                <div>
                  <a
                    href={`https://www.google.com/search?q=${officer?.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LanguageIcon style={{ width: '20px' }} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="company-info-modal-overview-value company-info-modal-overview-value-people-list company-info-modal-overview-value-with-flex">
          <div>
            {nationalityToFlag[officer?.nationality?.toUpperCase()] && (
              <img
                src={nationalityToFlag[officer?.nationality?.toUpperCase()]}
                alt={`${officer.nationality} flag`}
                style={{
                  width: '15px',
                  height: '10px',
                }}
              />
            )}
            {officer.nationality && (
              <span style={{ marginLeft: '2px' }}>
                {officer?.nationality?.toUpperCase()}
              </span>
            )}
          </div>

          <div style={{ display: 'flex', gap: '5px' }}>
            <div>
              {officer.officer_role && <span>{officer.officer_role}</span>}
            </div>
            <div>{officer.occupation && <span>{officer.occupation}</span>}</div>
          </div>
          <div>
            {officer?.date_of_birth?.month && officer?.date_of_birth?.year && (
              <span>
                Born in&nbsp;
                {getMonthName(officer?.date_of_birth?.month)}
                &nbsp;
                {officer.date_of_birth.year}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeopleAndContactItem;
