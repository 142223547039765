import React from "react";

const JudgmentReport = ({ pdfUrl }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div id="pdfViewer" style={{ width: "100%", height: "100%" }}>
        <iframe
          src={pdfUrl}
          title="Judgment Report"
          style={{ width: "100%", height: "100%" }}
        ></iframe>
      </div>
    </div>
  );
};

export default JudgmentReport;
