import './SearchSuggestions.css';
import { useState, useCallback } from 'react';
import {
  debounce,
  processRegisteredOfficeAddress,
  processRegisteredOfficeAddressObject,
} from '../../utils/helper';
import { explorerFilter } from '../../api/corporateExplorer/corporateExplorer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
const SearchSuggestions = ({ setSelectedCompany, setAccountsInfo }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Wrap the debounced function in useCallback to prevent it from being re-created on every render
  const debouncedSearchCompanyByName = useCallback(
    debounce(async companyHouseSearchName => {
      if (!companyHouseSearchName) return setSuggestions([]);
      setIsLoading(true);

      try {
        const entitiesWithOptions = await explorerFilter([
          { header: 'company_name', filter: ['+' + companyHouseSearchName] },
        ]);

        let accountsinfo = entitiesWithOptions?.accountsInfo;

        let mapResults = entitiesWithOptions?.result
          ?.map(company => ({
            id: company?._id,
            title: company?._source?.company_name,
            address: processRegisteredOfficeAddress(company?._source),
            addressObj: processRegisteredOfficeAddressObject(company?._source),
            date_of_creation: company?._source?.date_of_creation,
            company_status: company?._source?.company_status,
            company_number: company?._source?.company_number,
            confirmation_statement: company?._source?.confirmation_statement,
            accounts: company?._source?.accounts,
            sic_codes: company?._source?.sic_codes,
          }))
          .slice(0, 5);
        setAccountsInfo(accountsinfo);
        setSuggestions(mapResults || []);
      } catch (error) {
        console.error('Failed to fetch company suggestions:', error);
      } finally {
        setIsLoading(false);
      }
    }, 400),
    [] // Only create this function once
  );

  const handleChange = event => {
    const { value } = event.target;
    setInputValue(value);
    debouncedSearchCompanyByName(value);
  };

  const handleSuggestionClick = suggestion => {
    setInputValue(suggestion.title);
    setSuggestions([]);
    setSelectedCompany(suggestion);
  };

  return (
    <div style={{ width: '50%' }}>
      <div style={{ display: 'flex' }}>
        <input
          style={{
            borderTopLeftRadius: '20px',
            borderBottomLeftRadius: '20px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
          }}
          className="form-control"
          type="search"
          placeholder="Search"
          value={inputValue}
          onChange={handleChange}
        />
        <div
          style={{
            backgroundColor: '#ffdd8e',
            borderTopRightRadius: '20px',
            borderBottomRightRadius: '20px',
            width: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </div>
      </div>
      {/* {isLoading && <div>Loading...</div>} */}
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map(item => (
            <li key={item.id} onClick={() => handleSuggestionClick(item)}>
              {item.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchSuggestions;
