import './PricingPage.css';
import React from 'react';
import Header from '../../components/Header';
import ProbePricing from '../../components/ProbePricing/ProbePricing';
import { useAuth } from '../../context/AuthProvider';

function PricingPage() {
  const { openSignup } = useAuth();
  return (
    <div>
      <Header />
      <div className="pricing-page-main">
        <ProbePricing
          styles={{
            mainDivStyle: 'pricing-page-main-judgement-checker-main',
            headerDivStyle: 'pricing-page-main-judgement-checker-main-header',
            contentDivStyle: 'pricing-page-main-judgement-checker-main-content',
            priceTag: 'priceTag',
            originalPrice: 'originalPrice',
            salePrice: 'salePrice',
            marginTop: 'pricing-page-main-margin-top',
          }}
          data={{
            header: 'Judgment Checker Service',
            content:
              'Checks Official statutory Register of Judgments, Orders, and Fines County Court Judgments, High Court Judgments, Tribunal Awards, Administration Orders, Fine Defaults and Child Support Agency Liability Orders, Scotland Ordinary Causes, Small Claims Summary Cause and Simple Procedure Decrees kept by Registry Trust UK on behalf of the Ministry of Justice.',
          }}
          isDisabled={false}
        />
        <div className="pricing-page-main-judgement-basic-pro-enterprise">
          <ProbePricing
            styles={{
              mainDivStyle: 'pricing-page-main-judgement-checker-main',
              headerDivStyle: 'pricing-page-main-judgement-checker-main-header',
              contentDivStyle:
                'pricing-page-main-judgement-checker-main-content',
              featuresDivStyle:
                'pricing-page-main-judgement-checker-main-feature',
              marginTop: 'pricing-page-main-margin-top',
            }}
            data={{
              header: 'Basic',
              content: 'Corporate Links Inspection',
              subType: { header: 'Free', content: 'Per User/Per Month' },
              features: {
                header: 'Features',
                contentHeader: 'Corporate Links',
                innerContent: '300 tokens per 5 minutes',
              },
              buttonContent: 'Sign up',
            }}
            isHaveButton={true}
            onClick={openSignup}
            isDisabled={false}
          />
          <ProbePricing
            styles={{
              mainDivStyle: 'pricing-page-main-judgement-checker-main',
              headerDivStyle: 'pricing-page-main-judgement-checker-main-header',
              contentDivStyle:
                'pricing-page-main-judgement-checker-main-content',
              marginTop: 'pricing-page-main-margin-top',
            }}
            data={{
              header: 'Pro',
              content: 'Corporate Links Inspection Judgement Checker',
              subType: { header: '£12.99', content: 'Per User/Per Month' },
              features: {
                header: 'Features',
                contentHeader: 'Corporate Links',
                innerContent: '300 tokens per 5 minutes',
              },
              buttonContent: 'Coming Soon',
            }}
            isHaveButton={true}
            isDisabled={true}
          />
          <ProbePricing
            styles={{
              mainDivStyle: 'pricing-page-main-judgement-checker-main',
              headerDivStyle: 'pricing-page-main-judgement-checker-main-header',
              contentDivStyle:
                'pricing-page-main-judgement-checker-main-content',
              marginTop: 'pricing-page-main-margin-top',
            }}
            data={{
              header: 'Enterprise',
              content:
                'Cororate Links Inspection Judgement Checker Foreign UK Ownership',
              subType: { header: 'Coming Soon' },
              features: {
                header: 'Features Includes Pro +',
                contentHeader: 'Corporate Links',
                innerContent: 'Per agreement',
              },
              buttonContent: 'Coming Soon',
            }}
            isHaveButton={true}
            isDisabled={true}
          />
        </div>
      </div>
    </div>
  );
}

export default PricingPage;
