import React from "react";

function CopywriteLiine() {
  return (
    <div className="caption-text-bold light-text-color primary-background-color text-center p-4">
      © Copyright 2023. All Rights Reserved
    </div>
  );
}

export default CopywriteLiine;
