import React, { useEffect, useRef } from "react";
import "./StimulusGrid.css";
import CompanyIcon from "../../assets/images/icons/Organization.svg";
import locationIcon from "../../assets/images/icons/Address_icon.svg";
import statusIcon from "../../assets/images/icons/Settings.svg";
import dobicon from "../../assets/images/icons/Tear-Off Calendar.svg";
import YesIcon from "../../assets/images/icons/yes-tag.svg";
import RegTag from "../../assets/images/icons/registered-tag.svg";
import OpenTag from "../../assets/images/icons/open-tag.svg";
import NoIcon from "../../assets/images/icons/no-tag.svg";

const StimulusGrid = ({
  showCheckBox,
  hoverRowSelect,
  options,
  entitySelectedHandler,
  setSideBarInfoHandler,
}) => {
  const scrollRef = useRef(null);
  const extraRef = useRef(null);

  return (
    <div className="stimulus-info-section">
      {options.length === 0 ?? "No results to show"}

      {options.length >= 1 && (
        <div
          className="table-responsive"
          style={{ maxHeight: "210px", overflowY: "scroll" }}>
          <table className="table table-custom">
            {/* <thead >
              <tr >
                {showCheckBox && <td scope="col"></td>}
                <td scope="col">
                  <div className="thead-td-custom">
                    <img
                      className="img-thead"
                      src={CompanyIcon}
                      alt="icon here"
                    />
                    Company<span style={{ color: "transparent" }}>.</span>
                    Name
                  </div>
                </td>
                <td scope="col">
                  <div className="thead-td-custom">
                    <img
                      className="img-thead"
                      src={locationIcon}
                      alt="icon here"
                    />
                    Locality
                  </div>
                </td>
                <td scope="col">
                  <div className="thead-td-custom">
                    <img className="img-thead" src={dobicon} alt="icon here" />
                    Creation Date
                  </div>
                </td>
                <td scope="col">
                  <div className="thead-td-custom">
                    <img
                      className="img-thead"
                      src={statusIcon}
                      alt="icon here"
                    />
                    Status
                  </div>
                </td>

                <td scope="col">
                  <div className="thead-td-custom"> </div>
                </td>
              </tr>
            </thead> */}
            {/* <thead>
              <tr>
                {showCheckBox && <td scope="col"></td>}
                <td scope="col">
                  <div className="thead-td-custom">
                    <img
                      className="img-thead"
                      src={CompanyIcon}
                      alt="icon here"
                    />
                    Company<span style={{ color: "transparent" }}>.</span>Name
                  </div>
                </td>
                <td scope="col">
                  <div className="thead-td-custom">
                    <img
                      className="img-thead"
                      src={locationIcon}
                      alt="icon here"
                    />
                    Locality
                  </div>
                </td>
                <td scope="col">
                  <div className="thead-td-custom">
                    <img className="img-thead" src={dobicon} alt="icon here" />
                    Creation<span className="creation-date">Date</span>
                  </div>
                </td>
                <td scope="col">
                  <div className="thead-td-custom">
                    <img
                      className="img-thead"
                      src={statusIcon}
                      alt="icon here"
                    />
                    Status
                  </div>
                </td>
                <td scope="col">
                  <div className="thead-td-custom"> </div>
                </td>
              </tr>
            </thead> */}

            <thead>
              <tr>
                {showCheckBox && <th scope="col"></th>}
                <th scope="col">
                  <div className="thead-td-custom">
                    <img
                      className="img-thead"
                      src={CompanyIcon}
                      alt="icon here"
                    />
                    Company<span style={{ color: "transparent" }}>.</span>Name
                  </div>
                </th>
                <th scope="col">
                  <div className="thead-td-custom">
                    <img
                      className="img-thead"
                      src={locationIcon}
                      alt="icon here"
                    />
                    Locality
                  </div>
                </th>
                <th scope="col">
                  <div className="thead-td-custom">
                    <img className="img-thead" src={dobicon} alt="icon here" />
                    Creation<span className="creation-date">Date</span>
                  </div>
                </th>
                <th scope="col">
                  <div className="thead-td-custom">
                    <img
                      className="img-thead"
                      src={statusIcon}
                      alt="icon here"
                    />
                    Status
                  </div>
                </th>
                <th scope="col">
                  <div className="thead-td-custom"> </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {options.map((option, index) => {
                return (
                  <tr
                    className={hoverRowSelect ? "probe-stimulus-grid-row" : ""}
                    key={index}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      entitySelectedHandler(option);
                    }}
                    onMouseOver={
                      setSideBarInfoHandler &&
                      (() => {
                        setSideBarInfoHandler(option);
                      })
                    }
                    onFocus={
                      setSideBarInfoHandler &&
                      (() => {
                        setSideBarInfoHandler(option);
                      })
                    }>
                    {showCheckBox && (
                      <td>
                        <input
                          ref={option.selected ? scrollRef : extraRef}
                          type="checkbox"
                          checked={option.selected}
                          onChange={() => {
                            entitySelectedHandler(option);
                            setTimeout(() => {
                              let btn = document.getElementById("scrollButton");
                              btn?.click();
                            }, 300);
                          }}
                        />
                      </td>
                    )}
                    <td className="body-text-bold " style={{height:"30px"}}>
                      {`${option.title || option.company?.name}`}
                    </td>
                    <td className="body-text-bold">
                      {option?.address?.locality || ""}
                    </td>
                    <td>{option.date_of_creation || ""}</td>
                    <td>
                      <div className="mb-4">
                        <div className="label-div"></div>
                        <div className="text-div">
                          <div className="yes-tag">
                            <img
                              src={
                                option.company_status === "active"
                                  ? YesIcon
                                  : option.company_status === "registered"
                                  ? RegTag
                                  : option.company_status === "open"
                                  ? OpenTag
                                  : NoIcon
                              }
                              alt="icon here"
                            />
                            {option.company_status}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <button
        id="scrollButton"
        style={{ display: "none" }}
        onClick={() => {
          scrollRef.current?.scrollIntoView({
            behavior: "instant",
            block: "end",
            top: "15px",
          });
        }}></button>
    </div>
  );
};

export default StimulusGrid;
