import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import {
  faBars,
  faCog,
  faShareSquare,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import './GraphRightSideBar.css';
import GraphButton from '../GraphButton/GraphButton';

const RightSidebarMenu = ({ zoomIn, zoomOut, exportSelectedGraph }) => {
  const [isBurgerMenu, setIsBurgerMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsBurgerMenu(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);

    // Check initial window size
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="graph-Right-side-bar-menu-container">
      {isBurgerMenu ? (
        <>
          <button
            className="graph-Right-side-bar-burger-menu"
            onClick={toggleMenu}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className={`graph-right-sidebar ${isOpen ? 'open' : ''}`}>
            <button
              className="graph-Right-side-bar-menu-close-menu"
              onClick={toggleMenu}
            >
              &times;
            </button>
            <ul className="graph-Right-side-bar-menu-items">
              <li onClick={exportSelectedGraph}>
                <FontAwesomeIcon icon={faShareSquare} /> Share
              </li>
              <li>
                <FontAwesomeIcon icon={faCog} /> Settings
              </li>
            </ul>
          </div>
        </>
      ) : (
        <>
          <div className="graph-Right-side-bar-btn-group">
            <GraphButton
              icon={<FontAwesomeIcon icon={faCog} />}
              variant="primary"
              customStyles={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                borderRadius: '50%',
                cursor: 'pointer',
                width: '40px',
                height: '40px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            />
            <div>
              <div style={{ marginBottom: '10px' }}>
                <GraphButton
                  icon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
                  variant="primary"
                  customStyles={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '18px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    width: '40px',
                    height: '40px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                  onClick={exportSelectedGraph}
                />
              </div>
              <div className="graph-Right-side-bar-graph-btns-plus-minus">
                <GraphButton
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  variant="primary"
                  customStyles={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '16px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    width: '40px',
                    height: '40px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                  onClick={zoomIn}
                />
                <GraphButton
                  icon={<FontAwesomeIcon icon={faMinus} />}
                  variant="primary"
                  customStyles={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '16px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    width: '40px',
                    height: '40px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                  onClick={zoomOut}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RightSidebarMenu;
