import React, { useContext, useState } from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { Content } from '../../../styles/styles';
import { themeStyles } from '../../../styles/theme';
import { AppContext } from '../../../context/Context';
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { toast } from 'react-toastify';
import { addBlogToFirestore, uploadFile } from '../../../api/blogs/blogs';

function PostBlogs({ navigation }) {
  const navigate = useNavigate();

  const { appInfo } = useContext(AppContext);

  const [blog, setBlog] = useState({
    cover: undefined,
    coverUrl: undefined,
    title: undefined,
    intro: undefined,
    content: [],
  });

  const [storageProgress, setStorageProgress] = useState(0);
  const [uploadBtnText, setUploadBtnText] = useState('Upload Blog');
  const [selectedSubtitle, setSelectedSubtitle] = useState('');
  const [selectedPara, setSelectedPara] = useState('');
  const [para, setPara] = useState('');
  const [subtitle, setSubtitle] = useState('');

  const mainInfo = (key, value) => {
    blog[key] = value;
    setBlog({ ...blog });
  };

  const insert = (key, value) => {
    let object = {};
    object[key] = value;
    blog.content.push(object);
    setBlog({ ...blog });
  };

  const onChooseCover = e => {
    blog.cover = e.target.files[0];
    blog.coverUrl = URL.createObjectURL(e.target.files[0]);

    setBlog({ ...blog });
  };

  const onChoosePic = async e => {
    let object = {
      image: e.target.files[0],
      imageUrl: URL.createObjectURL(e.target.files[0]),
    };
    blog.content.push(object);
    setBlog({ ...blog });
  };

  const insertCoverClick = () => {
    document.getElementById('chooseCover').click();
  };

  const insertPicClick = () => {
    document.getElementById('choosePic').click();
  };

  const getRandomArbitrary = (min, max) => {
    return Math.trunc(Math.random() * (max - min) + min).toString();
  };

  const uploadAllImagesWithProgress = async (files, id) => {
    var promises = files.map(async (file, index) => {
      const result = uploadFile(
        file.role === 'cover'
          ? `blogs/${id}/cover`
          : `blogs/${id}/image_${file.index}`,
        file.object
      );

      if (file.role === 'section') {
        blog.content[file.index].remoteImage = result.downloadLink;
      }
      if (file.role === 'cover') {
        blog.coverDownloadUrl = result.downloadLink;
      }
      setStorageProgress(((index + 1) / files.length) * 100);
    });
    await Promise.all(promises);
  };

  const uploadBlog = async () => {
    setUploadBtnText('Uploading...');

    let random = getRandomArbitrary(1, 1000000000);

    var fileObjects = [];
    fileObjects.push({ role: 'cover', object: blog.cover, index: -1 });

    blog.content.forEach((item, index) => {
      if (item.image)
        fileObjects.push({ role: 'section', index: index, object: item.image });
    });

    await uploadAllImagesWithProgress(fileObjects, random);

    delete blog.cover;
    blog.content.forEach(item => {
      if (item.image) delete item.image;
    });
    setBlog({ ...blog });

    await addBlogToFirestore({
      blogId: random,
      ...blog,
    });

    setStorageProgress(100);
    toast('blog uploaded successfully');
  };

  const handleBlogs = () => {
    navigate('/blogs/view');
  };

  const handleCreate = () => {
    navigate('/blogs/post');
  };

  return (
    <Col lg={12}>
      <Row style={Styles.heading}>
        <Button style={Styles.button} onClick={handleCreate}>
          {' '}
          Create{' '}
        </Button>
        <Form.Text style={Styles.navbarText} onClick={handleBlogs}>
          Blogs
        </Form.Text>
      </Row>

      <Row style={Styles.sideBarContainer}>
        <Col
          lg={appInfo.sideBarExpanded ? 10 : 11}
          xs={12}
          style={Styles.column}
        >
          <Col lg={8} style={Styles.container}>
            <br />

            <p style={{ ...themeStyles.heading3, color: 'gray' }}>
              {' '}
              Choose a Title , Cover image, Description for your blog. Add more
              sections using toolbox.{' '}
            </p>

            <Form.Control
              className={'title'}
              type="text"
              placeholder="title"
              onChange={e => {
                mainInfo('title', e.target.value);
              }}
            />
            <br />
            <Form.Control
              type="text"
              placeholder="Intro"
              onChange={e => {
                mainInfo('intro', e.target.value);
              }}
            />
            <br />
            <Button onClick={insertCoverClick}>Insert Cover Image</Button>

            <br />
            <br />

            <Col lg={10}>
              <Row style={Styles.toolBox}>
                <Col lg={1}>
                  <Form.Text style={Styles.insertText}> Insert </Form.Text>
                </Col>

                <Col lg={2}>
                  <Row
                    style={{ width: '100%' }}
                    onClick={e => {
                      setSelectedSubtitle(true);
                    }}
                  >
                    <Form.Text style={Styles.toolboxText}>
                      {' '}
                      Subheading{' '}
                    </Form.Text>
                  </Row>
                </Col>

                <Col lg={2}>
                  <Row
                    style={{ width: '100%' }}
                    onClick={e => {
                      setSelectedPara(true);
                    }}
                  >
                    <Form.Text syle={Styles.toolboxText}> Para </Form.Text>
                  </Row>
                </Col>

                <Col lg={2}>
                  <Row style={{ width: '100%' }}>
                    <Form.Text
                      syle={Styles.toolboxText}
                      onClick={insertPicClick}
                    >
                      {' '}
                      Image{' '}
                    </Form.Text>
                  </Row>
                </Col>
              </Row>
            </Col>

            <br />

            {selectedSubtitle && (
              <Col>
                <Form.Control
                  style={{ height: '100px' }}
                  type="text"
                  placeholder="type your subheading here"
                  onChange={e => {
                    setSubtitle(e.target.value);
                  }}
                ></Form.Control>
                <br />
                <Button
                  onClick={() => {
                    setSelectedSubtitle(false);
                    insert('subtitle', subtitle);
                  }}
                >
                  {' '}
                  Submit{' '}
                </Button>
              </Col>
            )}

            {selectedPara && (
              <Col>
                <Form.Control
                  style={{ height: '100px' }}
                  type="text"
                  placeholder="type your paragraph here"
                  onChange={e => {
                    setPara(e.target.value);
                  }}
                ></Form.Control>
                <br />
                <Button
                  onClick={() => {
                    setSelectedPara(false);
                    insert('para', para);
                  }}
                >
                  {' '}
                  Submit{' '}
                </Button>
              </Col>
            )}

            <br />
            <br />
            <br />

            <Col lg={12}>
              <Form.Text style={themeStyles.heading4}> Preview </Form.Text>
            </Col>
            <br />
            <br />

            <Col lg={10}>
              <p style={themeStyles.heading4}> {blog.title} </p>
              <br />
              {blog.coverUrl && (
                <Image
                  style={{ width: '99%', height: '500px' }}
                  src={blog.coverUrl}
                />
              )}
              <br />
              <br />
              <p style={themeStyles.smallText2}> {blog.intro} </p>

              {blog.content.map(item => {
                return (
                  <Col lg={12} key={Math.random()}>
                    {item.image && (
                      <Image
                        style={{ width: '99%', height: '500px' }}
                        src={item.imageUrl}
                      />
                    )}
                    {item.subtitle && (
                      <Form.Text style={themeStyles.heading3}>
                        {' '}
                        {item.subtitle}{' '}
                      </Form.Text>
                    )}
                    {item.para && (
                      <Form.Text style={themeStyles.smallText2}>
                        {' '}
                        {item.para}{' '}
                      </Form.Text>
                    )}
                  </Col>
                );
              })}
            </Col>

            <br />

            <Button
              style={Styles.uploadButton}
              disabled={
                blog.coverUrl &&
                blog.title &&
                blog.intro &&
                uploadBtnText !== 'Uploading...'
                  ? false
                  : true
              }
              onClick={() => {
                uploadBlog();
              }}
            >
              {uploadBtnText}
            </Button>

            <br />
            <br />
            <ProgressBar striped variant="success" now={storageProgress} />
            <br />

            <input
              style={Styles.chooseCover}
              id={'chooseCover'}
              type={'file'}
              onChange={e => {
                onChooseCover(e);
              }}
            />
            <input
              style={Styles.choosePic}
              id={'choosePic'}
              type={'file'}
              onChange={e => {
                onChoosePic(e);
              }}
            />
          </Col>

          <br />
          <br />
        </Col>
      </Row>
    </Col>
  );
}

export default PostBlogs;

const Styles = {
  container: {
    padding: '15px',
    boxShadow: '0px 0px 8px 1px lightgray',
    marginLeft: '40px',
    marginTop: '40px',
  },
  toolBox: {
    width: '100%',
    height: '50px',
    marginLeft: '0%',
    border: 'none',
    borderRadius: '50px',
    outline: 'none',
    boxShadow: '0px 0px 8px 1px lightgray',
    ...Content.rowCentrify,
  },
  // insertText: {
  //   font: "bold italic 14px times new roman",
  // },
  toolboxText: {
    font: '12px ',
    cursor: 'pointer',
  },
  chooseCover: {
    display: 'none',
  },
  choosePic: {
    display: 'none',
  },
  uploadButton: {
    borderRadius: '14px',
    width: '200px',
    height: '50px',
    backgroundColor: 'blueviolet',
  },

  button: {
    width: '10%',
    height: '50px',
    borderRadius: '50px',
  },
  heading: {
    width: '101%',
    padding: '25px',
    height: 'auto',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0px 0px 8px 1px lightgray',
  },
  navbarText: {
    width: '100px',
    font: '18px',
    textDecoration: 'underline',
    cursor: 'text',
  },
  cardsWrapper: {
    padding: '25px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  card: {
    height: 'auto',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0px 0px 8px 1px lightgray',
    marginRight: '5px',
  },
  blogThumbnail: {
    width: '100%',
    height: '200px',
    padding: '0px',
  },
};
