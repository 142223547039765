import React from "react";
import "./ProbeInput.css"; // Import the CSS file

const ProbeInput = ({
  height,
  width,
  marginRight,
  placeholder,
  onChange,
  onError,
  setOnError,
}) => {
  // Inline styles for customizable dimensions
  const inputStyle = {
    height: height,
    width: width,
    marginRight: marginRight,
  };

  return (
    <div style={{ width: "100%" }}>
      <input
        type="text"
        className="probeInput" // Apply the class for base styles
        style={inputStyle} // Inline styles for height and width
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value);
          setOnError("");
        }}
      />

      <div style={{ minHeight: "30px", margin: "2px" }}>
        {onError ? (
          <div className="error" style={{ color: "red" }}>
            {onError}
          </div>
        ) : (
          <div style={{ height: "30px", visibility: "hidden" }}></div>
        )}
      </div>
    </div>
  );
};

export default ProbeInput;
