import React from 'react';
import { Link } from 'react-router-dom';
import './SearchResult.css';
import SearchResultTable from './SearchResultTable';
import SearchName from './SearchName';
import HeaderDropdown from './HeaderDropdownNative';
function SearchResult() {
  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className="header">
          <div className="header-heading dark-heading-color">Dashboard</div>
          <HeaderDropdown />
        </div>
        <div className="dashboard-content-div">
          <SearchName />
          <SearchResultTable />
          <Link to="/SummaryReport">
            <button className="summary-report-bottom-btn-div">Search</button>
          </Link>

          <Link to="/searchOnPerson">
            <button className="summary-report-back-btn-div">Back</button>
          </Link>

          <Link to="/searchOnPerson">
            <button className="summary-report-startover-btn-div">
              Start Over
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SearchResult;
