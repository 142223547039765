const NumberDisplay = ({ animatedValue }) => {
  // Function to format number with commas
  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Round the animated value
  const roundedValue = Math.round(animatedValue);

  // Format the rounded number with commas
  const formattedNumber = formatNumberWithCommas(roundedValue);

  return <div>{formattedNumber}</div>;
};

export default NumberDisplay;
