import React from "react";

const ProbeModal = ({
  showModal,
  onClose,
  title,
  children,
  maxWidth,
  maxHeight,
  className,
}) => {
  return (
    <div
      className={`modal fade ${showModal ? "show" : ""}`}
      style={{
        display: showModal ? "block" : "none",
        backgroundColor: showModal ? "rgba(0, 0, 0, 0.5)" : "none",
      }}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-hidden={!showModal}
    >
      <div
        className={`modal-dialog modal-dialog-centered modal-dialog-scrollable ${
          className ? className : ""
        }`}
        style={{ maxWidth, maxHeight }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalTitle">
              {title}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modal-address-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ProbeModal;
