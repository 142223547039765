import React, { useEffect } from 'react';
import './CEIPersonInsightsBio.css'; // Import the CSS file

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
const CEIPersonInsightsBio = ({ details }) => {
  useEffect(() => {
    console.log('details', details);
  }, [details]);
  return (
    <div className="corporate-explore-person-insights-bio">
      <div>
        <div className="corporate-explore-insights-person-icon ">
          <FontAwesomeIcon icon={faUser} />
        </div>
      </div>

      <h2 className="corporate-explore-insights-person-name">
        {details?.name}
      </h2>
      <p className="corporate-explore-insights-person-details">
        {details?.bio}
      </p>
    </div>
  );
};

export default CEIPersonInsightsBio;
