import React from "react";
import "./OneFinalCheckBox.css";
import { useState } from "react";

function OneFinalCheckBox({ name, setRadioValue, defaultValue }) {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setRadioValue(event.target.value === "yes" ? true : false);
  };
  return (
    <div>
      <div className="one_final_check_box_main_div">
        <div style={{ display: "flex" }}>
          <input
            type="radio"
            id="yes"
            name={name}
            value="yes"
            checked={selectedOption === "yes" || defaultValue === true}
            onChange={handleOptionChange}
          />
          <label htmlFor="yes" style={{ marginLeft: "5px" }}>
            Yes
          </label>
        </div>
        <div style={{ display: "flex" }}>
          <input
            type="radio"
            id="no"
            name={name}
            value="no"
            checked={selectedOption === "no"}
            onChange={handleOptionChange}
          />
          <label htmlFor="no" style={{ marginLeft: "5px" }}>
            No
          </label>
        </div>
      </div>
    </div>
  );
}

export default OneFinalCheckBox;
