import React, { useContext, useEffect, useState } from 'react';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import './CoporateLinksGraph.css';
import CorporateLinksGraphRightSidebar from '../../../components/CorporateLinks/CorporateLinksGraphRightSidebar/CorporateLinksGraphRightSidebar';
import CLGraph from './../../../components/CorporateLinks/CLGraph/CLGraph';
import { AppContext } from '../../../context/Context';
import CorporateLinksGraphLeftSidebar from './../../../components/CorporateLinks/CorporateLinksGraphLeftSidebar/CorporateLinksGraphSidebar';

import {
  getCompanyInfoByCompanyNumber,
  getOfficerInfoByCompanyNumber,
  getPersonInsights,
  getRelationShips,
} from '../../../api/corporateExplorer/corporateExplorer';
import CONSTANTS from '../../../utils/constants';
import { useAuth } from '../../../context/AuthProvider';
import LocalStorageService from '../../../utils/LocalStorageServices';

const CorporateLinksGraph = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const { clGraph, setClGraph, user } = useContext(AppContext);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedOfficer, setSelectedOfficer] = useState(null);
  const [accountInfo, setAccountsInfo] = useState([]);
  const [graphFromState, setGraphFromState] = useState(null);
  const [isInfoLoading, setIsInfoLoading] = useState(false);
  const { logout, openLogin, openProfile } = useAuth();

  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
  };

  const resetState = () => {
    setSelectedCompany(null);
    setSelectedOfficer(null);
    setRightSidebarOpen(false);
  };

  useEffect(() => {
    resetState();
    console.log('clGraph?.clickedNode', clGraph?.clickedNode);
    if (clGraph?.isOpenRightSidebar) {
      const getCompanyInfo = async () => {
        setRightSidebarOpen(clGraph?.isOpenRightSidebar);

        if (!clGraph?.clickedNode) return;

        try {
          if (clGraph?.clickedNode?.entityType === 'company') {
            setIsInfoLoading(true);

            const data = await getCompanyInfoByCompanyNumber(
              clGraph?.clickedNode?.id
            );
            setIsInfoLoading(false);
            setSelectedCompany(data);
          }

          if (clGraph?.clickedNode?.entityType === 'person') {
            setIsInfoLoading(true);

            let preparePayLoad = {
              combinationType: 'officer',
              ids: [
                {
                  type: 'officer',
                  id: clGraph?.clickedNode?.id,
                },
              ],
            };
            preparePayLoad['graphs'] = [clGraph?.selectedGraph];

            const data = await getPersonInsights(preparePayLoad);
            setIsInfoLoading(false);
            setSelectedOfficer(data);
          }
        } catch (error) {
          setIsInfoLoading(false);
        }
      };

      getCompanyInfo();
    }
  }, [clGraph?.clickedNode]);

  useEffect(() => {
    let graph = clGraph?.graph;
    console.log(graph);
    setGraphFromState(graph);
    resetState();
  }, []);

  useEffect(() => {
    // Return a cleanup function
    return () => {
      if (user?.guest) {
        const userResponse = window.confirm(
          'Save your results for future reference by logging in or signing up for an account.'
        );

        if (userResponse) {
          console.log('clGraph', clGraph);
          LocalStorageService.setItem('searchedGraph', clGraph?.combination);
          openLogin();
        }
      }
    };
  }, []);

  return (
    <div>
      <div className="dashboard-section">
        <div className="main-div">
          <div className="header">
            <div className="header-heading dark-heading-color">
              Corporate Links Graph
            </div>
            <HeaderDropdown />
          </div>

          <div className="corporate-links-graph-main-content">
            <div>
              <CLGraph
                graph={graphFromState}
                searchProbe={'Rooms that rock ltd'}
              />
            </div>

            {/* Right sidebar */}
            <div
              className={`corporate-links-graph-right-sidebar ${rightSidebarOpen ? 'open' : ''}`}
            >
              <CorporateLinksGraphRightSidebar
                rightSidebarOpen={rightSidebarOpen}
                toggleRightSidebar={toggleRightSidebar}
                selectedCompany={selectedCompany}
                selectedOfficer={selectedOfficer}
                isInfoLoading={isInfoLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateLinksGraph;
