import React from "react";
import LineHeader from "../assets/images/icons/header-line.svg";
import CompanyIcon from "../assets/images/icons/Organization.svg";
import userIcon from "../assets/images/icons/User.svg";
import surnameIcon from "../assets/images/icons/Badge.svg";
import ageIcon from "../assets/images/icons/Age.svg";
import dobicon from "../assets/images/icons/Tear-Off Calendar.svg";
import "./SearchResultTable.css";
function SearchResultTable() {
  return (
    <div className="searchresult-info-section">
      <div className="header-section">
        <div className="body-text-normal dark-text-color">
          <img src={LineHeader} alt="icon here" /> Search Result
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-custom">
          <thead>
            <tr>
              <td scope="col">
                {" "}
                <input type="checkbox" />
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  {" "}
                  <img
                    className="img-thead"
                    src={userIcon}
                    alt="icon here"
                  />{" "}
                  First<span style={{ color: "transparent" }}>.</span>Name
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="thead-td-custom">
                  {" "}
                  <img
                    className="img-thead"
                    src={surnameIcon}
                    alt="icon here"
                  />{" "}
                  Surname{" "}
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="thead-td-custom">
                  <img className="img-thead" src={dobicon} alt="icon here" />{" "}
                  D.O.B{" "}
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="thead-td-custom">
                  <img className="img-thead" src={ageIcon} alt="icon here" />{" "}
                  Age{" "}
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="thead-td-custom">
                  <img
                    className="img-thead"
                    src={CompanyIcon}
                    alt="icon here"
                  />{" "}
                  Current<span style={{ color: "transparent" }}>.</span>Company{" "}
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="thead-td-custom">
                  <img
                    className="img-thead"
                    src={CompanyIcon}
                    alt="icon here"
                  />{" "}
                  Previous<span style={{ color: "transparent" }}>.</span>
                  Company(ies){" "}
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {" "}
                <input type="checkbox" />
              </td>
              <td className="body-text-bold">James</td>
              <td className="body-text-bold">Smithson</td>
              <td className="body-text-bold">08/04/1973</td>
              <td className="body-text-bold">49</td>
              <td className="body-text-bold">Hubble Ltd.</td>
              <td className="body-text-bold">Motions Ltd.</td>
            </tr>
            <tr>
              <td>
                {" "}
                <input type="checkbox" />
              </td>
              <td className="body-text-bold">James</td>
              <td className="body-text-bold">Smithson</td>
              <td className="body-text-bold">08/04/1973</td>
              <td className="body-text-bold">49</td>
              <td className="body-text-bold">Hubble Ltd.</td>
              <td className="body-text-bold">Motions Ltd.</td>
            </tr>
            <tr>
              <td>
                {" "}
                <input type="checkbox" />
              </td>
              <td className="body-text-bold">James</td>
              <td className="body-text-bold">Smithson</td>
              <td className="body-text-bold">08/04/1973</td>
              <td className="body-text-bold">49</td>
              <td className="body-text-bold">Hubble Ltd.</td>
              <td className="body-text-bold">Motions Ltd.</td>
            </tr>
            <tr>
              <td>
                {" "}
                <input type="checkbox" />
              </td>
              <td className="body-text-bold">James</td>
              <td className="body-text-bold">Smithson</td>
              <td className="body-text-bold">08/04/1973</td>
              <td className="body-text-bold">49</td>
              <td className="body-text-bold">Hubble Ltd.</td>
              <td className="body-text-bold">Motions Ltd.</td>
            </tr>
            <tr>
              <td>
                {" "}
                <input type="checkbox" />
              </td>
              <td className="body-text-bold">James</td>
              <td className="body-text-bold">Smithson</td>
              <td className="body-text-bold">08/04/1973</td>
              <td className="body-text-bold">49</td>
              <td className="body-text-bold">Hubble Ltd.</td>
              <td className="body-text-bold">Motions Ltd.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SearchResultTable;
