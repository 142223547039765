import './CorporateExplorerFilter.css';
import React, { useEffect, useState } from 'react';
import FilterModal from './FilterModal';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ProbeTooltip from '../ProbeToolTip/ProbeToolTip';

function CorporateExplorerFilter({
  totalCompaniesDocument,
  applyFilters,
  toggleModal,
  showModal,
  setShowModal,
  locationSearchInput,
  locationSearchResults,
  setFilterReadyToApply,
  filterReadyToApply,
}) {
  return (
    <div>
      <ProbeTooltip
        tooltipContent={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
              Let's Get Started
            </div>
            <span style={{ fontSize: '12px' }}>Click here to add a filter</span>
          </div>
        }
        position="left"
        openAllTheTime
      >
        <div className="corporate_explorer_filter_button" onClick={toggleModal}>
          <div className="corporate_explorer_filter_button_text">
            <FilterListIcon />
            <div>Filters</div>
          </div>
        </div>
      </ProbeTooltip>

      {showModal && (
        <FilterModal
          showModal={showModal}
          setShowModal={setShowModal}
          filterReadyToApply={filterReadyToApply}
          setFilterReadyToApply={setFilterReadyToApply}
          totalCompaniesDocument={totalCompaniesDocument}
          applyFilters={applyFilters}
          locationSearchInput={locationSearchInput}
          locationSearchResults={locationSearchResults}
        />
      )}
    </div>
  );
}

export default CorporateExplorerFilter;
