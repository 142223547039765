import React, { useEffect, useState } from "react";
import LineHeader from "../assets/images/icons/header-line.svg";
import userIcon from "../assets/images/icons/User.svg";
import surnameIcon from "../assets/images/icons/Badge.svg";
import PhoneIcon from "../assets/images/icons/Phone.svg";
import EmailIcon from "../assets/images/icons/Email.svg";
import OrganizationIcon from "../assets/images/icons/Organization.svg";
import CountryIcon from "../assets/images/icons/Jurisdication.svg";
import TableLoadingScreen from "../components/LoadingScreen/TableLoadingScreen/TableLoadingScreen";
import "./CustomerTable.css";

function CustomerTable({
  customers,
  customersDetailClick,
  searchQuery,
  debouncedSearch,
  isSearchInProgress,
}) {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const onRowClick = (customer, index) => {
    customersDetailClick(customer);
    setSelectedRowIndex(index);
  };

  useEffect(() => {
    if (customers.length > 0) {
      customersDetailClick(customers[0]);
      setSelectedRowIndex(0);
    }
  }, [customers]);

  return (
    <div className="customerTable-info-section">
      <div className="header-section">
        <div className="body-text-normal dark-text-color">
          <img src={LineHeader} alt="icon here" /> Customers
        </div>
        <div>
          <input
            className="form-control"
            type="search"
            placeholder="Search here ..."
            onChange={(e) => {
              const value = e.target.value;
              debouncedSearch(value);
            }}
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-custom table-hover">
          <thead>
            <tr>
              <td scope="col">
                <div className="thead-td-custom">
                  <img
                    className="img-thead"
                    src={surnameIcon}
                    alt="icon here"
                  />{" "}
                  Customer ID{" "}
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  <img className="img-thead" src={userIcon} alt="icon here" />{" "}
                  Name{" "}
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  <img
                    className="img-thead"
                    src={OrganizationIcon}
                    alt="icon here"
                  />{" "}
                  Organization{" "}
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  <img className="img-thead" src={EmailIcon} alt="icon here" />{" "}
                  Email{" "}
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  <img className="img-thead" src={PhoneIcon} alt="icon here" />{" "}
                  Phone{" "}
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  <img
                    className="img-thead"
                    src={CountryIcon}
                    alt="icon here"
                  />{" "}
                  Country{" "}
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {isSearchInProgress && <TableLoadingScreen />}
            {customers.length > 0 && !isSearchInProgress ? (
              customers.map((customer, index) => (
                <tr
                  key={index}
                  style={{
                    "--bs-table-bg":
                      index === selectedRowIndex ? "#EEEDEB" : "",
                  }}
                  onClick={() => onRowClick(customer, index)}
                >
                  {customer.PrimaryContact && (
                    <>
                      <td className="body-text-bold">
                        {customer.PrimaryContact.Uid}
                      </td>
                      <td className="body-text-bold">
                        {customer.PrimaryContact.FullName}
                      </td>
                      <td className="body-text-bold">{customer.Name}</td>
                      <td className="body-text-bold">
                        {customer.PrimaryContact.Email}
                      </td>
                      <td className="body-text-bold">
                        {customer.PrimaryContact.PhoneMobile
                          ? customer.PrimaryContact.PhoneMobile
                          : ""}
                      </td>
                      <td className="body-text-bold">
                        {customer.PrimaryContact.Country
                          ? customer.PrimaryContact.Country
                          : ""}
                      </td>
                    </>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="body-text-bold">
                  {!isSearchInProgress && <>No data found</>}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CustomerTable;
