import React from "react";
import "./CEILatestActivity.css";

const CEILatestActivity = () => {
  const activities = [
    { type: "Accounts Submitted", time: "4 Months", date: "29 Mar 2024" },
    {
      type: "Confirmation Statement Submitted",
      time: "4 Months",
      date: "13 Mar 2024",
    },
    {
      type: "Confirmation Statement Submitted",
      time: "1 Year 5 Months",
      date: "2 Mar 2023",
    },
    {
      type: "Resigned",
      person: "Mr Graeme Ritchie",
      time: "1 Year 5 Months",
      date: "2 Mar 2023",
    },
    {
      type: "Appointed",
      person: "Mr Dominic John Blue",
      time: "2 Years",
      date: "9 Jun 2022",
    },
  ];

  return (
    <div className="cei-latest-activity">
      <h3>Latest Activity</h3>
      <div className="activity-list-container">
        <ul className="activity-list">
          {activities.map((activity, index) => (
            <li key={index} className="activity-item">
              <div className="activity-icon">
                <span>
                  {activity.type === "Resigned" || activity.type === "Appointed"
                    ? "👤"
                    : "📄"}
                </span>
              </div>
              <div className="activity-details">
                <p className="activity-type">
                  {activity.type === "Resigned" ||
                  activity.type === "Appointed" ? (
                    <span className="person-name">{activity.person}</span>
                  ) : (
                    activity.type
                  )}
                  {activity.type === "Resigned" || activity.type === "Appointed"
                    ? ` ${activity.type}`
                    : ""}
                </p>
                <p className="activity-time">
                  {activity.time}{" "}
                  <span className="activity-date">{activity.date}</span>
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <button className="add-to-watch-list-btn">ADD TO WATCH LIST</button>
    </div>
  );
};

export default CEILatestActivity;
