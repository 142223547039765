import React, { useEffect, useState } from "react";
import { Col, Image, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { themeStyles } from "../../../styles/theme";
import { Content } from "../../../styles/styles";

function SpecificBlog() {
  const location = useLocation();

  const [blog, setBlog] = useState();

  useEffect(() => {
    setBlog(location.state);
  }, []);

  if (blog) {
    return (
      <Col lg={11} xs={10} md={12} style={Styles.container}>
        <br />
        <br />

        <Col lg={10}>
          <p style={themeStyles.heading4}> {blog.title} </p>
          <br />
          {blog.coverDownloadUrl && (
            <Image
              style={{ width: "99%", height: "500px" }}
              src={blog.coverDownloadUrl}
            />
          )}
          <br />
          <br />
          <p style={themeStyles.smallText2}> {blog.intro} </p>

          {blog.content.map((item) => {
            return (
              <Col lg={12} key={Math.random()}>
                {item.remoteImage && (
                  <Image
                    style={{ width: "99%", height: "500px" }}
                    src={item.remoteImage}
                  />
                )}
                {item.subtitle && (
                  <Form.Text style={themeStyles.heading3}>
                    {" "}
                    {item.subtitle}{" "}
                  </Form.Text>
                )}
                {item.para && (
                  <Form.Text style={themeStyles.smallText2}>
                    {" "}
                    {item.para}{" "}
                  </Form.Text>
                )}
              </Col>
            );
          })}
        </Col>
      </Col>
    );
  } else {
    return <div> loading ... </div>;
  }
}

export default SpecificBlog;

const Styles = {
  container: {
    padding: "15px",
    boxShadow: "0px 0px 8px 1px lightgray",
    marginLeft: "25px",
    marginTop: "25px",
    overflow: "auto",
    height: "97vh",
  },
  toolBox: {
    width: "100%",
    height: "50px",
    marginLeft: "0%",
    border: "none",
    borderRadius: "50px",
    outline: "none",
    boxShadow: "0px 0px 8px 1px lightgray",
    ...Content.rowCentrify,
  },
  insertText: {
    font: "  14px ",
  },
  toolboxText: {
    font: "12px ",
    cursor: "pointer",
  },
  chooseCover: {
    display: "none",
  },
  choosePic: {
    display: "none",
  },
  uploadButton: {
    borderRadius: "14px",
    width: "200px",
    height: "50px",
    backgroundColor: "blueviolet",
  },

  button: {
    width: "10%",
    height: "50px",
    borderRadius: "50px",
  },
  heading: {
    width: "101%",
    padding: "25px",
    height: "auto",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    boxShadow: "0px 0px 8px 1px lightgray",
  },
  navbarText: {
    width: "100px",
    font: "18px",
    textDecoration: "underline",
    cursor: "text",
  },
  cardsWrapper: {
    padding: "25px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  card: {
    height: "auto",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "white",
    boxShadow: "0px 0px 8px 1px lightgray",
    marginRight: "5px",
  },
  blogThumbnail: {
    width: "100%",
    height: "200px",
    padding: "0px",
  },
};
