import React from 'react';
import './CEDomainName.css';
import BusinessIcon from '@material-ui/icons/Business';

const CEDomainName = ({ companyInfo }) => {
  const [domainNames, setDomainNames] = React.useState(null);
  // const domainNames = [
  //   // {
  //   //   domianName: ' kentstoneresidential.com',
  //   //   updated: '7 Jun 2019',
  //   // }
  // ];

  return (
    <div className="corporate-explorer-property-domain-card">
      <div className="corporate-explorer-property-domain-title">
        Domain Names
        <span className="corporate-explorer-property-domain-count">
          {domainNames?.length}
        </span>
      </div>
      {domainNames && (
        <div className="corporate-explorer-property-striped-table">
          <table>
            <thead>
              <tr>
                <th>Domain Names</th>
                <th>Updated</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(domainNames) &&
                domainNames?.map((domain, index) => (
                  <tr key={index}>
                    <td>
                      {/* <span className="corporate-explorer-property-land-building-building-icon"></span> */}
                      <BusinessIcon
                        fontSize="small"
                        className="corporate-explorer-property-domain-icon"
                      />
                      {domain?.domianName}
                    </td>
                    <td>{domain?.updated}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CEDomainName;
