import React from "react";
import headerLine from "../../../assets/images/icons/header-line.svg";

function Payment() {
  return (
    <div className="cards-div card-7">
      <div className="cards-div card-6">
        <div className="card-headers button-text">
          <img src={headerLine} /> Payment Method
        </div>

        <div className="content-div">
          <div className="form-check mb-3">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              disabled
            />
            <label
              class="form-check-label body-text-heading"
              for="flexRadioDefault1"
            >
              Balance (Coming Soon)
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked
            />
            <label
              class="form-check-label body-text-heading"
              for="flexRadioDefault2"
            >
              Credit/Debit card
            </label>
          </div>
        </div>
      </div>
      <div className="cards-div card-6">
        <div className="card-headers button-text">
          <img src={headerLine} /> Order Summary
        </div>

        <div className="content-div">
          <div className="d-flex justify-content-between">
            <div className="body-text">Sub-total</div>
            <div className="button-text-bold">£8.50</div>
          </div>
          <div className="d-flex justify-content-between mt-4 align-items-center">
            <input className="inputs" placeholder="VOUCHER CODE" />
            <button className="body-text-normal apply-btn">Apply</button>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="heading-2">Total</div>
            <div className="heading-2">£8.50</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
