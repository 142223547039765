import React from "react";

function JudgementCheckerSquare({
  color,
  width = "48px",
  height = "48px",
  marginTop = "10%",
}) {
  return (
    <div
      style={{
        width: width,
        height: height,
        backgroundColor: color,
        marginTop: marginTop,
      }}
    />
  );
}

export default JudgementCheckerSquare;
