export const staticCardData = [
  {
    data: [
      {
        title: "England and Wales",
        content: [
          "County Court Judgments, ",
          "High Court Judgments, ",
          "Tribunal Awards, ",
          "Administration Orders, Fine Defaults and Child Support, ",
          "Agency Liability Orders",
        ],
      },
    ],
  },
  {
    data: [
      {
        title: "Scotland",
        content: [
          "Scotland Ordinary Causes, Small Claims Summary Cause and Simple Procedure Decrees",
        ],
      },
    ],
  },
  {
    data: [
      { title: "Republic of Ireland", content: ["Registered Court Judgments"] },
      {
        title: "Northern Ireland",
        content: ["Judgments and High Court Judgments"],
      },
    ],
  },
  {
    data: [
      {
        title: "Isle of Man",
        content: "Judgments",
      },
      {
        title: "Jersey",
        content: "Judgments",
      },
    ],
  },
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const fontSizeForJudgmentCheckerInput = () => {
  return {
    fontSize: "15px", // Adjust the size as needed
    fontWeight: "bold",
    width: "100%",
    height: "40px",
  };
};

export const dummyDataForJudgementCheckerApi = () => {
  return {
    "Mashood Hassan": {
      data: {
        apiVersion: "1.0.0-ec3d5ac5",
        exactMatchCount: 1,
        lastJudgmentDate: "2019-0-03T",
        possibleMatchCount: 1,
        registrationCount: 2,
        results: [
          {
            address1: "",
            caseNumber: "E68yj357",
            companyName: "Test CARS LTD",
            isSatisfied: false,
            convictionDate: "1/10/2024",
            courtCode: "390",
            courtName: "CIVIL NATIONAL BUSINESS",
            judgmentDate: "2018-06-21T00:00:00",
            courtJusridiction: "England and Wales County Court Judgments",
            courtPostcode: "",
            type: "C",
          },
          {
            address1: "",
            caseNumber: "E68yj357",
            companyName: "Test CARS LTD",
            isSatisfied: false,
            convictionDate: "1/10/2024",
            courtCode: "390",
            courtName: "CIVIL NATIONAL BUSINESS",
            judgmentDate: "2018-06-21T00:00:00",
            courtJusridiction: "England and Wales County Court Judgments",
            courtPostcode: "",
            type: "C",
          },
        ],
        satisfactionCount: 1,
        status: "Completed",
      },
    },
    "Cafe Business": {
      data: {
        apiVersion: "1.0.0-ec3d5ac5",
        exactMatchCount: 1,
        lastJudgmentDate: "2019-0-03T",
        possibleMatchCount: 1,
        registrationCount: 2,
        results: [
          {
            address1: "",
            caseNumber: "E68yj357",
            companyName: "Test CARS LTD",
            isSatisfied: false,
            convictionDate: "1/10/2024",
            courtCode: "390",
            courtName: "CIVIL NATIONAL BUSINESS",
            judgmentDate: "2018-06-21T00:00:00",
            courtJusridiction: "England and Wales County Court Judgments",
            courtPostcode: "",
            type: "C",
          },
          {
            address1: "",
            caseNumber: "E68yj357",
            companyName: "Test CARS LTD",
            isSatisfied: true,
            convictionDate: "1/10/2024",
            courtCode: "390",
            courtName: "CIVIL NATIONAL BUSINESS",
            judgmentDate: "2018-06-21T00:00:00",
            courtJusridiction: "England and Wales County Court Judgments",
            courtPostcode: "",
            type: "C",
          },
        ],
        satisfactionCount: 1,
        status: "Completed",
      },
    },
    // ali hasan
    "Ali Hassan": {
      data: {
        apiVersion: "1.0.0-ec3d5ac5",
        exactMatchCount: 1,
        lastJudgmentDate: "2019-0-03T",
        possibleMatchCount: 1,
        registrationCount: 2,
        results: [
          {
            address1: "",
            caseNumber: "E68yj357",
            companyName: "Test CARS LTD",
            isSatisfied: true,
            convictionDate: "1/10/2024",
            courtCode: "390",
            courtName: "CIVIL NATIONAL BUSINESS",
            judgmentDate: "2018-06-21T00:00:00",
            courtJusridiction: "England and Wales County Court Judgments",
            courtPostcode: "",
            type: "C",
          },
          {
            address1: "",
            caseNumber: "E68yj357",
            companyName: "Test CARS LTD",
            isSatisfied: true,
            convictionDate: "1/10/2024",
            courtCode: "390",
            courtName: "CIVIL NATIONAL BUSINESS",
            judgmentDate: "2018-06-21T00:00:00",
            courtJusridiction: "England and Wales County Court Judgments",
            courtPostcode: "",
            type: "C",
          },
        ],
        satisfactionCount: 2,
        status: "Completed",
      },
    },
    // green simon nemon
    "Simon Nemon": {
      data: {
        apiVersion: "1.0.0-ec3d5ac5",
        exactMatchCount: 1,
        lastJudgmentDate: "2019-0-03T",
        possibleMatchCount: 1,
        registrationCount: 0,
        results: [],
        satisfactionCount: 0,
        status: "Completed",
      },
    },
  };
};

export const dummyAddresses = () => {
  return {
    addresses: [
      "1 Tonge Street, HEYWOOD, OL10 4HU",
      "3 Tonge Street, HEYWOOD, OL10 4HU",
      "5 Tonge Street, HEYWOOD, OL10 4HU",
      "7 Tonge Street, HEYWOOD, OL10 4HU",
      "8 Tonge Street, HEYWOOD, OL10 4HU",
      "9 Tonge Street, HEYWOOD, OL10 4HU",
      "10 Tonge Street, HEYWOOD, OL10 4HU",
      "11 Tonge Street, HEYWOOD, OL10 4HU",
      "12 Tonge Street, HEYWOOD, OL10 4HU",
      "13 Tonge Street, HEYWOOD, OL10 4HU",
      "14 Tonge Street, HEYWOOD, OL10 4HU",
      "15 Tonge Street, HEYWOOD, OL10 4HU",
      "16 Tonge Street, HEYWOOD, OL10 4HU",
      "17 Tonge Street, HEYWOOD, OL10 4HU",
      "18 Tonge Street, HEYWOOD, OL10 4HU",
      "19 Tonge Street, HEYWOOD, OL10 4HU",
      "20 Tonge Street, HEYWOOD, OL10 4HU",
      "21 Tonge Street, HEYWOOD, OL10 4HU",
      "22 Tonge Street, HEYWOOD, OL10 4HU",
      "23 Tonge Street, HEYWOOD, OL10 4HU",
      "24 Tonge Street, HEYWOOD, OL10 4HU",
      "25 Tonge Street, HEYWOOD, OL10 4HU",
      "26 Tonge Street, HEYWOOD, OL10 4HU",
      "27 Tonge Street, HEYWOOD, OL10 4HU",
      "28 Tonge Street, HEYWOOD, OL10 4HU",
      "29 Tonge Street, HEYWOOD, OL10 4HU",
      "30 Tonge Street, HEYWOOD, OL10 4HU",
      "31 Tonge Street, HEYWOOD, OL10 4HU",
      "32 Tonge Street, HEYWOOD, OL10 4HU",
      "33 Tonge Street, HEYWOOD, OL10 4HU",
      "34 Tonge Street, HEYWOOD, OL10 4HU",
      "36 Tonge Street, HEYWOOD, OL10 4HU",
      "38 Tonge Street, HEYWOOD, OL10 4HU",
      "40 Tonge Street, HEYWOOD, OL10 4HU",
      "42 Tonge Street, HEYWOOD, OL10 4HU",
    ],
    success: true,
  };
};

export const getMonthName = (monthNumber) => {
  if (monthNumber < 1 || monthNumber > 12) {
    return "Invalid month number";
  }
  return months[monthNumber - 1];
};

// postal code LE1 4NR, OL10 4HU
