import React from 'react';
import './CEIPersonInsightsDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBriefcase,
  faGlobe,
  faUser,
  faCalendar,
  faSuitcase,
} from '@fortawesome/free-solid-svg-icons';

const CEIPersonInsightsDetails = ({ details }) => {
  return (
    <div className="corporate-explore-details-container">
      <h3 className="corporate-explore-details-title">Details</h3>
      <div className="corporate-explore-details-item">
        <FontAwesomeIcon
          icon={faBriefcase}
          className="corporate-explore-details-icon"
        />
        <span className="corporate-explore-details-label">Role</span>
        <span className="corporate-explore-details-value">{details?.role}</span>
      </div>
      <div className="corporate-explore-details-item">
        <FontAwesomeIcon
          icon={faGlobe}
          className="corporate-explore-details-icon"
        />
        <span className="corporate-explore-details-label">
          Country Of Residence
        </span>
        <span className="corporate-explore-details-value">
          {details?.country_of_residence}
        </span>
      </div>
      <div className="corporate-explore-details-item">
        <FontAwesomeIcon
          icon={faUser}
          className="corporate-explore-details-icon"
        />
        <span className="corporate-explore-details-label">Nationality</span>
        <span className="corporate-explore-details-value">
          {details?.nationality}
        </span>
      </div>
      <div className="corporate-explore-details-item">
        <FontAwesomeIcon
          icon={faCalendar}
          className="corporate-explore-details-icon"
        />
        <span className="corporate-explore-details-label">Date of Birth</span>
        <span className="corporate-explore-details-value">
          {details?.date_of_birth}
        </span>
      </div>
      <div className="corporate-explore-details-item">
        <FontAwesomeIcon
          icon={faSuitcase}
          className="corporate-explore-details-icon"
        />
        <span className="corporate-explore-details-label">Occupation</span>
        <span className="corporate-explore-details-value">
          {details?.occupation}
        </span>
      </div>
    </div>
  );
};

export default CEIPersonInsightsDetails;
