import React from 'react';
import './CEIPersonInsightsAppointments.css';

const CEIPersonInsightsAppointments = ({ appointments }) => {
  return (
    <div className="corporate-explore-insight-appointments-container">
      <h4 className="corporate-explore-insight-appointments-title">
        Appointments
      </h4>
      <div className="corporate-explore-insight-appointments-stats">
        <div className="corporate-explore-insight-appointments-stat">
          <span className="corporate-explore-insight-appointments-dot active"></span>
          <span>Active</span>
          <h2>{appointments?.active}</h2>
        </div>
        <div className="corporate-explore-insight-appointments-stat">
          <span className="corporate-explore-insight-appointments-dot resigned"></span>
          <span>Resigned</span>
          <h2>{appointments?.resigned}</h2>
        </div>
        <div className="corporate-explore-insight-appointments-stat">
          <span className="corporate-explore-insight-appointments-dot dissolved"></span>
          <span>Dissolved</span>
          <h2>{appointments?.dissolved}</h2>
        </div>
        <div className="corporate-explore-insight-appointments-stat">
          <span className="corporate-explore-insight-appointments-dot other"></span>
          <span>Other</span>
          <h2>{appointments?.other}</h2>
        </div>
        <div className="corporate-explore-insight-appointments-stat">
          <span className="corporate-explore-insight-appointments-dot total"></span>
          <span>Total</span>
          <h2>{appointments?.total}</h2>
        </div>
      </div>
    </div>
  );
};

export default CEIPersonInsightsAppointments;
