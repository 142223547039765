import React from 'react';
import './ProbLabel.css'; // Import the CSS file

const ProbLabel = ({ text }) => {
  return (
    <div className="prob-label-container">
      <span className="prob-label-text">{text}</span>
    </div>
  );
};

export default ProbLabel;
