import React from 'react';
import './CEIPersonalInsightsCommon.css'; // Include your CSS file for styling

const CEIPersonalInsightsCommon = ({ personInsights }) => {
  return (
    <div>
      {personInsights.insights.map((section, index) => (
        <div className="corporate-explore-insight-common-info-card" key={index}>
          <h2 className="corporate-explore-insight-common-info-title">
            {section.header}
          </h2>
          <div className="corporate-explore-insight-common-info-grid">
            {section.items.map((item, idx) => (
              <div
                className="corporate-explore-insight-common-info-item"
                key={idx}
              >
                <h4>{item.key}</h4>
                <p style={{ fontWeight: 'bold' }}>{item.value}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CEIPersonalInsightsCommon;
