import React, { useContext, useEffect, useState } from 'react';

import './CorporateExplorer.css';
import {
  companiesRegisteredLocationAutoSearch,
  explorerFilter,
  getTotalDocumentsOfIndex,
} from '../../api/corporateExplorer/corporateExplorer';
import CorporateExplorerFilter from '../../components/CorporateExplorerFilter/CorporateExplorerFilter';
import { AppContext } from '../../context/Context';
import CorporateExplorerGrid from '../../components/CorporateExplorerFilter/CorporateExplorerGrid/CorporateExplorerGrid';
import Pagination from '../../components/Pagination/Pagination';
import HeaderDropdown from '../../components/HeaderDropdownNative';
import ProbeTooltip from '../../components/ProbeToolTip/ProbeToolTip';
import ProbeButton from '../../components/ProbeButton/ProbeButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useLoading } from '../../context/LoadingContext/LoadingContext';

export default function CorporateExplorer() {
  const [buildFilterResponseDefaultQuery, setBuildFilterResponseDefaultQuery] =
    useState([{ header: 'company_status', filter: ['active'] }]);
  const [locationSearchResults, setLocationSearchResults] = useState([]);
  const [showCompanyInfoModal, setShowCompanyInfoModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [filter, setFilter] = useState([]);
  const [totalCompaniesDocument, setTotalCompaniesDocument] = useState(0);
  const [countHolder, setCountHolder] = useState(0);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [filteredDataHolder, setFilteredDataHolder] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [accountsData, setAccountsData] = useState([]);

  const [buildFilterResponseHolder, setBuildFilterResponseHolder] =
    useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [paginationFrom, setpaginationFrom] = useState(0);
  const [showColumns, setShowColumns] = useState([
    { key: 'company_name', header: 'Name' },
    { key: 'company_status', header: 'Status' },
    { key: 'type', header: 'Type' },
    { key: 'sic_codes', header: 'Sic Codes' },
    { key: 'can_file', header: 'Can File' },
    { key: 'date_of_creation', header: 'Date of Creation' },
    { key: 'accounts.last_accounts.type', header: 'Account Type' },
    {
      key: 'registered_office_address',
      no_header: 'registered_office_address',
    },
    {
      key: 'company_number',
      no_header: 'company_number',
    },
    {
      key: 'company_number',
      no_header: 'company_number',
    },
  ]);

  const [filterReadyToApply, setFilterReadyToApply] = useState([]);

  const buildFilter = filters => {
    // Define header mappings
    const headerMappings = {
      Name: 'company_name',
      Status: 'company_status',
      Type: 'type',
      'Accounts Category': 'accounts.last_accounts.type',
      'SIC Code': 'sic_codes',
      'Registered Address': 'registered_address',
      Incorporation: 'date_of_creation',
      'Accounts Year End': 'accounts.last_accounts.period_end_on',
      'Accounts Due By': 'accounts.next_due',
      'Accounts Last Made': 'accounts.last_accounts.made_up_to',
    };

    // Iterate through each filter object and update the header
    const filteredFilters = filters.filter(filter => filter.filter.length > 0);

    const updatedFilters = filteredFilters.map(filter => {
      // Get the corresponding header mapping
      const mappedHeader = headerMappings[filter.header];

      // Return a new object with the updated header
      return {
        ...filter,
        header: mappedHeader,
      };
    });
    return updatedFilters;
  };

  const locationSearchInput = async searchInput => {
    let result = await companiesRegisteredLocationAutoSearch(searchInput);

    if (result?.success) {
      setLocationSearchResults(result?.data);
    }
  };

  const { setIsLoading } = useLoading();

  const applyFilters = () => {
    setFilteredData(filteredDataHolder);
    setShowModal(!showModal);
  };

  const resetFilter = async () => {
    setCurrentPage(0);
    setFilteredData([]);
    setFilterReadyToApply([]);
    setFilteredDataHolder([]);
    setBuildFilterResponseHolder(null);
    await fetchDefaultData();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleCompanyInfoModal = () => {
    setShowCompanyInfoModal(!showCompanyInfoModal);
  };

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const fetchDefaultData = async () => {
    try {
      setIsLoading(true);
      const response = await getTotalDocumentsOfIndex('companies-index');
      setTotalCompaniesDocument(response?.count);
      setCountHolder(response?.count);

      // if (currentPage + 1 >= 10) {
      //   return;
      // }

      const companiesList = await explorerFilter(
        buildFilterResponseDefaultQuery,
        1,
        20
      );

      setTotalPages(companiesList?.pagination?.totalPages);
      setpaginationFrom(companiesList?.pagination?.from);
      setFilteredData(companiesList?.result);
      setAccountsData(response?.accountsInfo);
      setIsLoading(false);
    } catch (error) {
      setTotalCompaniesDocument(0);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      // if (currentPage + 1 >= 10) {
      //   return;
      // }
      const response = await explorerFilter(
        buildFilterResponseHolder === null
          ? buildFilterResponseDefaultQuery
          : buildFilterResponseHolder,
        currentPage + 1,
        20
      );
      setTotalCompaniesDocument(response?.pagination?.total);
      setTotalPages(response?.pagination?.totalPages);
      setpaginationFrom(response?.pagination?.from);
      setFilteredData(response?.result);
      setAccountsData(response?.accountsInfo);
    };

    apiCall();
  }, [currentPage]);

  useEffect(() => {
    if (filter?.length > 0) {
      let buildFilterResponse = buildFilter(filter);

      if (buildFilterResponse?.length > 0) {
        const fetchData = async () => {
          try {
            setBuildFilterResponseHolder(buildFilterResponse);
            const response = await explorerFilter(buildFilterResponse);
            setTotalCompaniesDocument(response?.pagination?.total);
            setTotalPages(response?.pagination?.totalPages);
            setFilteredDataHolder(response?.result);
            setAccountsData(response?.accountsInfo);
            setpaginationFrom(response?.pagination?.from);
            // Handle the response as needed
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      } else {
        setTotalCompaniesDocument(countHolder);
      }
    } else {
      setTotalCompaniesDocument(countHolder);
    }
  }, [filter]);

  useEffect(() => {
    setFilter(filterReadyToApply);
  }, [filterReadyToApply]);

  useEffect(() => {
    fetchDefaultData();
  }, []);

  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className="header">
          <div className="header-heading dark-heading-color">
            Corporate Explorer
          </div>
          <HeaderDropdown />
        </div>
        <div className="dashboard-content-div">
          <section className="cp-explorer-section">
            <div className="cp-explorer-section-filter">
              <div>
                <CorporateExplorerFilter
                  totalCompaniesDocument={totalCompaniesDocument}
                  setTotalCompaniesDocument={setTotalCompaniesDocument}
                  applyFilters={applyFilters}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  toggleModal={toggleModal}
                  locationSearchInput={locationSearchInput}
                  locationSearchResults={locationSearchResults}
                  filterReadyToApply={filterReadyToApply}
                  setFilterReadyToApply={setFilterReadyToApply}
                />
              </div>
              <div
                className="cp-explorer-filterreset-button"
                onClick={resetFilter}
              >
                <div>
                  <RestartAltIcon />
                </div>
                <div className="cp-explorer-filterreset-button-text">Reset</div>
              </div>
            </div>
            <div className="cp-explorer-section-table">
              <CorporateExplorerGrid
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                setAccountsData={setAccountsData}
                accountsData={accountsData}
                hiddenColumns={hiddenColumns}
                showColumns={showColumns}
                paginationFrom={paginationFrom}
                showCompanyInfoModal={showCompanyInfoModal}
                setShowCompanyInfoModal={setShowCompanyInfoModal}
                toggleCompanyInfoModal={toggleCompanyInfoModal}
              />
            </div>
            <div style={{ marginTop: '20px' }}></div>
            {filteredData && (
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </section>
        </div>
      </div>
    </div>
  );
}
