import React, { useState } from "react";
import "./ProbeToolTip.css";

const ProbeTooltip = ({
  children,
  tooltipContent,
  position = "top",
  openAllTheTime = false,
}) => {
  const [visible, setVisible] = useState(openAllTheTime);

  const showTooltip = () => !openAllTheTime && setVisible(true);
  const hideTooltip = () => !openAllTheTime && setVisible(false);

  return (
    <div
      className="tooltip-wrapper"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && (
        <div
          className={`tooltip-box tooltip-${position} ${
            visible && "tooltip-visible"
          }`}
        >
          {tooltipContent}
        </div>
      )}
    </div>
  );
};
export default ProbeTooltip;
