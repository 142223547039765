import React from "react";
import "./CIEContact.css";
import locationAddress from "../../../assets/images/location-address.png";

import world from "../../../assets/images/world.png";
import telephone from "../../../assets/images/telephone.png";
import Email from "../../../assets/images/message.png";
import WEBSITE from "../../../assets/images/website.png";
import social from "../../../assets/images/social/social.png";

const CIEContact = ({ selectedCompany }) => {
  return (
    <div className="contact-card">
      <h2>Contact</h2>
      <div className="contact-item">
        <span
          className="fas fa-users"
          style={{ marginRight: "10px", fontSize: "14px" }}>
          <img
            src={locationAddress}
            alt="eye-icon"
            style={{
              width: "15px",
              height: "auto",

              borderRadius: "5px",
            }}
          />
        </span>
        <div className="contact-info">
          <strong>Registered Address</strong>
          <span>{selectedCompany?.address}</span>
        </div>
      </div>
      <div className="contact-item">
        <span
          className="fas fa-users"
          style={{ marginRight: "10px", fontSize: "14px" }}>
          <img
            src={world}
            alt="eye-icon"
            style={{
              width: "15px",
              height: "auto",
              borderRadius: "5px",
            }}
          />
        </span>{" "}
        <div className="contact-info">
          <strong>Country Origin</strong>
          <span>{selectedCompany?.addressObj?.country}</span>
        </div>
      </div>
      <div className="contact-item">
        <span
          className="fas fa-users"
          style={{ marginRight: "10px", fontSize: "14px" }}>
          <img
            src={telephone}
            alt="eye-icon"
            style={{
              width: "15px",
              height: "auto",
              borderRadius: "5px",
            }}
          />
        </span>{" "}
        <div className="contact-info">
          <strong>Telephone</strong>
          <span>Unreported</span>
        </div>
      </div>
      <div className="contact-item">
        <span
          className="fas fa-users"
          style={{ marginRight: "10px", fontSize: "14px" }}>
          <img
            src={Email}
            alt="eye-icon"
            style={{
              width: "15px",
              height: "auto",
              borderRadius: "5px",
            }}
          />
        </span>{" "}
        <div className="contact-info">
          <strong>Email Address</strong>
          <span>Unreported</span>
        </div>
      </div>
      <div className="contact-item">
        <span
          className="fas fa-users"
          style={{ marginRight: "10px", fontSize: "14px" }}>
          <img
            src={WEBSITE}
            alt="eye-icon"
            style={{
              width: "15px",
              height: "auto",

              borderRadius: "5px",
            }}
          />
        </span>{" "}
        <div className="contact-info">
          <strong>Website</strong>
          <span>Unreported</span>
        </div>
      </div>
      <div className="contact-item">
        <span
          className="fas fa-users"
          style={{ marginRight: "10px", fontSize: "14px" }}>
          <img
            src={social}
            alt="eye-icon"
            style={{
              width: "13px",
              height: "auto",

              borderRadius: "5px",
            }}
          />
        </span>{" "}
        <div className="contact-info">
          <strong>Social</strong>
          <span>Unreported</span>
        </div>
      </div>
      <button className="update-info-btn">UPDATE INFO</button>
      <a href="#" className="view-people-contacts">
        VIEW PEOPLE & CONTACTS
      </a>
    </div>
  );
};

export default CIEContact;
