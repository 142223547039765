import React from "react";
import "./CIEDocument.css";
import { getUseableDocumentUrl } from "../../../api/corporateExplorer/corporateExplorer";

const CIEDocRow = ({
  doc,
  setIsDocumentUrlGenerating,
  isDocumentUrlGenerating,
}) => {
  const viewFinancialDocument = async (doc) => {
    try {
      setIsDocumentUrlGenerating(true);
      let documenturl = await getUseableDocumentUrl(
        doc?.links?.document_metadata
      );
      window.open(documenturl, "_blank");
      setIsDocumentUrlGenerating(false);
    } catch (error) {
      setIsDocumentUrlGenerating(false);
    }
  };

  return (
    <tr style={{ borderBottom: "1px solid gray" }}>
      <td>
        <span className="file-icon"></span>
        <b className="coporate-Insights-Document-incorporation">
          {doc?.category
            ? doc.category.charAt(0).toUpperCase() + doc.category.slice(1)
            : ""}
        </b>
        <div className="desc">
          {doc?.date} • {doc?.pages} Pages
        </div>
      </td>
      <td>
        <div
          style={{
            float: "right",
            opacity: isDocumentUrlGenerating ? 0.5 : 1,
            pointerEvents: isDocumentUrlGenerating ? "none" : "auto",
            cursor: isDocumentUrlGenerating ? "not-allowed" : "pointer",
          }}
          className="coporate-Insights-Document-grey-button small"
          onClick={() => {
            if (!isDocumentUrlGenerating) {
              viewFinancialDocument(doc);
            }
          }}
        >
          VIEW PDF
        </div>
      </td>
    </tr>
  );
};

export default CIEDocRow;
