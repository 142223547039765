import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home.js';
import Sidebar from './components/Sidebar';
import SearchOnPerson from './pages/JudgementChecker/CheckReviewPerson/SearchOnPerson/SearchOnPerson.js';
import SummaryReport from './components/SummaryReport';
import SearchResult from './components/SearchResult';
import AccountsUsage from './pages/AccountsUsage/AccountsUsage.js';
import CustomerAdminScreen from './pages/CustomerAdminScreen/CustomerAdminScreen.js';
import { AppContext } from './context/Context.js';
import { ToastContainer } from 'react-toastify';
import FeedbackForm from './pages/FeedbackForm/FeedbackForm.js';
import PostBlogs from './pages/Blogs/Post/PostBlogs';
import ViewBlogs from './pages/Blogs/View/ViewBlogs';
import SpecificBlog from './pages/Blogs/Specific/SpecificBlog';
import {
  CorporateLinksContext,
  defaultEntities,
} from './context/corporateLinks/CorporateLinksContext.js';
import JudgementChecker from './pages/JudgementChecker/JudgementChecker.js';
import JudgementCheckerPerson from './pages/JudgementChecker/CheckReviewPerson/JudgementCheckerPerson/JudgementCheckerPerson';
import CheckReviewPerson from './pages/JudgementChecker/CheckReviewPerson/CheckReviewPerson';
import JudgementCheckerReports from './pages/JudgementChecker/JudgmentCheckerReports/JudgementCheckerReports';
import JudgementCheckerBusiness from './pages/JudgementChecker/CheckReviewBusiness/JudgementCheckerBusiness/JudgementCheckerBusiness';
import 'bootstrap/dist/css/bootstrap.min.css';
import CheckReviewBusiness from './pages/JudgementChecker/CheckReviewBusiness/CheckReviewBusiness';
import JudgmentCheckerReport from './pages/JudgementChecker/JudgmentCheckerReport/JudgmentCheckerReport';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import ProtectedRoute from './ProtectedRoute';
import BackgroundInvestigation from './pages/BackgroundInvestigation/BackgroundInvestigation';
import ForegnUKOwnership from './pages/ForeignUKOwnership/ForegnUKOwnership';
import { useLoading } from './context/LoadingContext/LoadingContext.js';
import LoadingScreen from './components/LoadingScreen/LoadingScreen.js';
import PaymentCancel from './pages/Payment/PaymentCancel.js';
import PricingPage from './pages/PricingPage/PricingPage.jsx';
import JudgementCheckerYourDetail from './pages/JudgementChecker/JudgementCheckerYourDetail/JudgementCheckerYourDetail.jsx';
import { useUserSetupContext } from './context/UserLoadingContext/UserLoadingContext.js';
import PrivacyPolicy from './pages/JudgementChecker/TermsAndPolicy/PrivacyPolicy/PrivacyPolicy.jsx';
import TermsAndConditions from './pages/JudgementChecker/TermsAndPolicy/TermsAndConditions/TermsAndConditions.jsx';
import { InitializeGoogleAnalytics } from './googleAnalytics/googleAnalytics.js';
import CorporateExplorer from './pages/CorporateExplorer/CorporateExplorer.jsx';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary.jsx';
import CorporateExploreInsight from './pages/CorporateExploreInsight/CorporateExploreInsight.jsx';
import CESearchEntities from './pages/CorporateLinks/CESearchEntities/CESearchEntities.jsx';
import CorporateLinksGraph from './pages/CorporateLinks/CoporateLinksGraph/CoporateLinksGraph.jsx';
import CEIPersonInsights from './pages/CorporateExploreInsight/CEIPersonInsights/CEIPersonInsights.jsx';
import PostSignup from './pages/Home/PostSignup.jsx';
import PostLogin from './pages/Home/PostLoginIn.jsx';
import { triggerGoogleAnalyticsEvent } from './utils/googleAnalyticsEvent.js';
import ToolTips from './components/ToolTips/ToolTips.js';
import AuthProvider from './context/AuthProvider.js';
import ReportsPage from './pages/ReportsPage/ReportsPage.jsx';

function App() {
  const UNPROTECTED_ROUTES = [
    '/Report',
    '/ccj-orders-fines',
    '/pricingPage',
    '/paymentCancel',
    '/JudgementCheckerYourDetail',
    '/PrivacyPolicy',
    '/TermsAndConditions',
    '/CorporateExplorer',
    '/SelectEntity',
    '/ForegnUKOwnership',
    '/CorporateExploreInsight',
    '/CorporateLinks',
    '/CorporateLinksGraph',
    '/CEIPersonInsights',
    '/LoginPost',
    '/SignUpPost',
  ];
  const { isLoading } = useLoading();
  const { isUserSetup } = useUserSetupContext();
  const [user, setUser] = useState(null);
  const [clGraph, setClGraph] = useState(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(null);
  const [searchedEntities, setSearchedEntites] = useState([]);
  const [sideBarInfo, setSideBarInfo] = useState({});
  const [
    corporateExplorerSelectedFilterItem,
    setCorporateExplorerSelectedFilterItem,
  ] = useState(null);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [appData, setAppData] = useState({
    userInfo: {},
    groups: [],
    selectedGroup: {},
    sideBarExpanded: true,
  });
  const [judgementChecker, setJudgementChecker] = useState({
    businessJudgementChecker: {},
    personJudgementChecker: {},
  });

  useEffect(() => {
    const googleAnalytics = async () => {
      InitializeGoogleAnalytics();
      await triggerGoogleAnalyticsEvent('page_visits', 'home');
    };
    googleAnalytics();
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      {isUserSetup && <LoadingScreen />}
      <AppContext.Provider
        value={{
          appInfo: appData,
          toggleSidebar: toggleSidebar,
          setToggleSidebar: setToggleSidebar,
          setAppInfo: setAppData,
          userLoggedIn: isUserLoggedIn,
          setIsUserLoggedIn: setIsUserLoggedIn,
          judgementChecker: judgementChecker,
          setJudgementChecker: setJudgementChecker,
          setUser: setUser,
          user: user,
          searchedEntities: searchedEntities,
          setSearchedEntites: setSearchedEntites,
          sideBarInfo: sideBarInfo,
          setSideBarInfo: setSideBarInfo,
          corporateExplorerSelectedFilterItem:
            corporateExplorerSelectedFilterItem,
          setCorporateExplorerSelectedFilterItem:
            setCorporateExplorerSelectedFilterItem,
          setClGraph,
          clGraph,
        }}
      >
        <BrowserRouter>
          <AuthProvider unprotectedRoutes={UNPROTECTED_ROUTES}>
            <ErrorBoundary>
              <Routes>
                <Route>
                  <Route element={<Home />} path="/" />
                  <Route element={<Home />} path="/home" />
                  <Route element={<PaymentCancel />} path="/paymentCancel" />
                  <Route element={<PricingPage />} path="/pricingPage" />
                  <Route element={<MainLayout />} path="/*" />
                </Route>
              </Routes>
            </ErrorBoundary>
          </AuthProvider>
        </BrowserRouter>
        <ToastContainer />
      </AppContext.Provider>
    </>
  );
}

function MainLayout() {
  const [graphInfo, setGraphInfo] = useState({
    entities: defaultEntities,
    step: 1,
    depth: 3,
    selectedApi: 'companiesHouse',
    cache: undefined,
    cri: undefined,
    selectedNode: undefined,
    searchesCount: 0,
    showFeedbackDialog: false,
    getFeedbackOnce: true,
    entityDetailsCache: [],
    networkAnalysis: {
      listsForEntity2: {},
      listsForEntity1: {},
    },
  });

  return (
    <div style={{ width: '100%' }}>
      <Sidebar>
        <CorporateLinksContext.Provider
          value={{ graphInfo: graphInfo, setGraphInfo: setGraphInfo }}
        >
          <Routes>
            <Route element={<PostSignup />} path="/SignUpPost" />
            <Route element={<PostLogin />} path="/LoginPost" />
            <Route element={<FeedbackForm />} path="/feedbackform" />
            <Route element={<SearchOnPerson />} path="/searchOnPerson" />
            <Route element={<SummaryReport />} path="/SummaryReport" />
            <Route element={<SearchResult />} path="/SearchResult" />
            <Route element={<AccountsUsage />} path="/AccountsUsage" />
            <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
              <Route element={<CustomerAdminScreen />} path="/CustomerAdmin" />
            </Route>
            <Route element={<PostBlogs />} path="/blogs/post" />
            <Route element={<ViewBlogs />} path="/blogs/view" />
            <Route element={<SpecificBlog />} path={'/blogs/:id'} />
            {/* <Route element={<SelectEntity />} path="/SelectEntity" /> */}
            <Route element={<CESearchEntities />} path="/CorporateLinks" />

            <Route element={<CorporateExplorer />} path="/CorporateExplorer" />
            <Route
              element={<CorporateExploreInsight />}
              path="/CorporateExploreInsight"
            />

            <Route element={<JudgementChecker />} path="/ccj-orders-fines" />
            <Route
              element={<JudgementCheckerPerson />}
              path="/JudgementCheckerPerson"
            />
            <Route
              element={<JudgementCheckerBusiness />}
              path="/JudgementCheckerBusiness"
            />
            <Route element={<CheckReviewPerson />} path="/CheckReviewPerson" />
            <Route
              element={<CheckReviewBusiness />}
              path="/CheckReviewBusiness"
            />
            <Route element={<ReportsPage />} path="/JudgementCheckerReports" />
            <Route
              element={<BackgroundInvestigation />}
              path="/BackgroundInvestigation"
            />
            <Route element={<JudgmentCheckerReport />} path="/Report" />
            <Route
              element={<JudgementCheckerYourDetail />}
              path="/JudgementCheckerYourDetail"
            />
            <Route
              element={<TermsAndConditions />}
              path="/TermsAndConditions"
            />
            <Route element={<PrivacyPolicy />} path="/PrivacyPolicy" />
            <Route element={<ForegnUKOwnership />} path="/ForegnUKOwnership" />
            <Route
              element={<CorporateLinksGraph />}
              path="/CorporateLinksGraph"
            />
            <Route element={<CEIPersonInsights />} path="/CEIPersonInsights" />
          </Routes>
        </CorporateLinksContext.Provider>
        <ToolTips />
      </Sidebar>
    </div>
  );
}

export default App;
