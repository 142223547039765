import React from 'react';
import './CLGrapgLeftSidebarItem.css';

const CLGrapgLeftSidebarItem = ({ icon, text, isActive, onClick }) => {
  return (
    <div
      className={`corporate-links-Graph-sidebar-item ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <div className="corporate-links-Graph-sidebar-item-icon">{icon}</div>
      <div className="corporate-links-Graph-sidebar-item-description">
        {text}
      </div>
    </div>
  );
};

export default CLGrapgLeftSidebarItem;
