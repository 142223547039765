import React from "react";

const FindAddressModal = ({
  show,
  setShowAddressModal,
  addresses,
  setSelectedAddressHandler,
}) => {
  const modalClass = show ? "modal fade show" : "modal fade";

  return (
    <div
      className={modalClass}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden={!show}
      style={{
        display: show ? "block" : "none",
        backgroundColor: show ? "rgba(0, 0, 0, 0.5)" : "none",
      }}
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Select Address
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setShowAddressModal(false)}
            ></button>
          </div>
          <div className="modal-body modal-address-body">
            {addresses &&
              addresses.map((address, index) => (
                <div
                  key={index}
                  style={{
                    padding: "10px",
                    margin: "1px 0",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    cursor: "pointer",
                    transition: "background-color 0.2s ease-in-out",
                    width: "100%",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#f8f9fa")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#fff")
                  }
                  onClick={() => setSelectedAddressHandler(address)}
                >
                  {address?.envelopeAddress}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindAddressModal;
