import React, { useEffect, useState } from 'react';
import ProbeButton from '../../ProbeButton/ProbeButton';
import './CETradeMark.css';
import { getCompanyTradeMarkByName } from '../../../api/corporateExplorer/corporateExplorer';

const CETradeMark = ({ companyInfo }) => {
  const [tradeMark, setTradeMark] = useState([]);

  const visiteOpenTradeMark = tradeMark => {
    if (tradeMark?.url) {
      window.open(tradeMark.url, '_blank');
    }
  };

  useEffect(() => {
    const fetchTradeMarks = async () => {
      if (!companyInfo) return;
      try {
        const tradeMarkResponse = await getCompanyTradeMarkByName(
          companyInfo?.company_name
        );
        setTradeMark(tradeMarkResponse);
      } catch (error) {}
    };
    fetchTradeMarks();
  }, [companyInfo]);
  return (
    <div className="corporate-explorer-property-trade-card">
      <div className="corporate-explorer-property-trade-title">
        Trade Marks
        <span className="corporate-explorer-property-land-building-count">
          {tradeMark?.length}
        </span>
      </div>
      {tradeMark && (
        <div className="corporate-explorer-property-trade-striped-table">
          <table>
            <thead>
              <tr>
                <th>Trade Mark</th>
                <th>Filing Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(tradeMark) &&
                tradeMark?.map((tmp, idx) => (
                  <tr
                    key={idx}
                    style={{
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    <td>
                      <span className="corporate-explorer-property-trade-website-icon">
                        🌐
                      </span>
                      {tmp?.tradeMark}
                    </td>
                    <td>{tmp?.fillingDate}</td>
                    <td>
                      <ProbeButton
                        onClick={() => visiteOpenTradeMark(tmp)}
                        name={'VIEW TRADE MARK'}
                        width={150}
                        fontSize={'12px'}
                        borderRadius={20}
                        position={'right'}
                        buttonDivDisplay={'flex'}
                        buttonDivJustifyContent={'flex-end'}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CETradeMark;
