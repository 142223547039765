import React from "react";
import "./LoadingScreen.css";
import loadingGif from "../../assets/images/loading.gif"; // Adjust the path to where your GIF is located

const LoadingScreen = () => (
  <div className="loading-container">
    <div className="loading-spinner">
      <img src={loadingGif} alt="Loading..." />
    </div>
  </div>
);

export default LoadingScreen;
