import LocalStorageService from './LocalStorageServices';
import { months } from './common';
import CONSTANTS from './constants';
import BritishFlag from '../assets/flags/GB.png';
import { width } from '@mui/system';

export const getCardsByIndex = (staticCardData, indexes) => {
  return indexes
    .map(index => staticCardData[index])
    .filter(card => card !== undefined);
};

export const reArrangetransformDateRange = dateRange => {
  const transformDate = date => {
    const [day, month, year] = date.split('-');
    return `${year}-${month}-${day}`;
  };
  const [startDate, endDate] = dateRange.split(' - ');
  return `${transformDate(startDate)} - ${transformDate(endDate)}`;
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getCurrentMonth = () => {
  const date = new Date();
  return months[date.getMonth()].toLowerCase();
};

export const getCurrentDateFormatted = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month.toUpperCase()} ${year}`;
};

export const shuffleString = str => {
  const arr = str.split('');
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr.join('');
};

export const generateUniqueReferenceNumber = () => {
  const now = new Date();

  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0');
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');

  const randomPart = String(Math.floor(Math.random() * 1000)).padStart(3, '0');

  return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}${randomPart}`;
};

export const formatDateFromString = inputString => {
  const year = inputString.substring(0, 4);
  const month = inputString.substring(4, 6);
  const day = inputString.substring(6, 8);

  return `${year}/${month}/${day}`;
};

export const determineCaseColor = jtd => {
  if (jtd) {
    if (jtd?.registrationCount === 0) {
      return '#34A91C';
    } else if (jtd?.satisfactionCount === jtd?.registrationCount) {
      return '#FDBE28';
    } else {
      return '#D1232A';
    }
  }
};

export const determineJudgmentCheckerType = judgementChecker => {
  return Object.keys(judgementChecker?.personJudgementChecker || {}).length !==
    0
    ? judgementChecker?.personJudgementChecker
    : judgementChecker?.businessJudgementChecker;
};

export const dynamicStyle = ({
  color,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  marginLeft,
  marginTop,
  marginBottom,
  display,
  justifyContent,
  textAlign,
}) => {
  return {
    color: color,
    fontFamily: fontFamily || 'Poppins',
    fontSize: fontSize,
    fontStyle: fontStyle,
    fontWeight: fontWeight,
    lineHeight: lineHeight,
    marginLeft: marginLeft,
    marginTop: marginTop,
    marginBottom: marginBottom,
    display: display,
    justifyContent: justifyContent,
    textAlign: textAlign,
  };
};

export const constructSearchData = judgementChecker => {
  // Determine if the input is for a person or a business based on the presence of certain properties
  if (judgementChecker.type === 'PersonSearch') {
    return {
      type: judgementChecker.type,
      names: judgementChecker.names, // Assuming names is an array of name objects
      addresses: judgementChecker.addresses, // Assuming addresses is an array of address objects
    };
  } else if (judgementChecker.type === 'BusinessSearch') {
    return {
      type: judgementChecker.type,
      names: [
        {
          businessName: judgementChecker.companyName,
        },
      ],
    };
  } else {
    return null;
  }
};

export const getBaseUrl = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

export const convertBlobUrlToBase64 = async blobUrl => {
  try {
    // Fetch the blob from the blob URL
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    // Use FileReader to convert the blob to base64
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve({ success: true, data: base64data }); // Contains the base64 string
      };
      reader.onerror = () => {
        resolve({
          success: false,
          message: 'Error reading the Blob data',
          status: 'error',
        });
      };
    });
  } catch (error) {
    console.error('Error fetching blob from URL:', error);
    return { success: false, message: error.message, status: 'error' };
  }
};

export const scrollToElement = elementId => {
  setTimeout(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, 0); // The delay can be adjusted if needed
};

export const getGuestUserInfo = () => {
  return {
    guest: true,
    FirebaseUser: {
      user_id: process.env.REACT_APP_DUMMY_USER_ID,
      api_key: process.env.REACT_APP_DUMMY_API_KEY,
    },
    Account: { Uid: process.env.REACT_APP_DUMMY_ACCOUNT_ID },
  };
};

// going to pay.
export const continueToCheckOut = async (
  setIsLoading,
  createCheckoutSession,
  judgementChecker,
  user
) => {
  try {
    setIsLoading(true);
    const successUrl = `${getBaseUrl()}/Report`;
    const cancelUrl = `${getBaseUrl()}/paymentCancel`; // all urls should be Capital

    const response = await createCheckoutSession(
      successUrl,
      cancelUrl,
      judgementChecker,
      user?.FirebaseUser?.user_id
    );

    if (response.success) {
      setIsLoading(false);
      LocalStorageService.setItem('payment_session_id', response.session?.id);
      window.location.href = response.url;
    } else {
      setIsLoading(false);
    }
  } catch (error) {
    setIsLoading(false);
  }
};

export const flattenStructure = node => {
  let result = [];

  function recurse(currentNode) {
    if (currentNode.parent) {
      result.push(currentNode.parent);
    }
    if (currentNode.child && currentNode.child.length > 0) {
      currentNode.child.forEach(childNode => recurse(childNode));
    }
  }

  node.forEach(rootNode => recurse(rootNode));
  return result;
};

const getDescriptionBySicCode = sicCode => {
  // Create a reverse lookup dictionary
  const reverseLookup = Object.keys(CONSTANTS.SIC_CODE_DESCRIPTION).reduce(
    (acc, key) => {
      acc[CONSTANTS.SIC_CODE_DESCRIPTION[key]] = key;
      return acc;
    },
    {}
  );

  // Return the description based on the SIC code
  return reverseLookup[sicCode];
};

const getDescriptionsByPrefix = prefix => {
  try {
    return Object.keys(CONSTANTS.SIC_CODE_DESCRIPTION).filter(key =>
      CONSTANTS.SIC_CODE_DESCRIPTION[key].startsWith(prefix)
    );
  } catch (error) {
    return [];
  }
};

export const searchList = (keyword, list) => {
  try {
    if (isNumericString(keyword)) {
      const matches = getDescriptionsByPrefix(keyword);
      // Remove duplicates by converting to a Set and back to an array
      return [...new Set(matches)];
    }

    if (!isNumericString(keyword)) {
      const matches = list.filter(item =>
        item.toLowerCase().includes(keyword?.toLowerCase())
      );

      // Remove duplicates by converting to a Set and back to an array
      return [...new Set(matches)];
    }
  } catch (error) {
    return [];
  }
};

export const formatDateRange = (startDateString, endDateString) => {
  const startDate = new Date(`${startDateString.split('-')}`);
  const endDate = new Date(`${endDateString.split('-')}`);
  const startDay = startDate.getDate();
  const startMonthIndex = startDate.getMonth();
  const startYear = startDate.getFullYear();

  const endDay = endDate.getDate();
  const endMonthIndex = endDate.getMonth();
  const endYear = endDate.getFullYear();

  const formattedStartDate = `${startDay} ${months[startMonthIndex]} ${startYear}`;
  const formattedEndDate = `${endDay} ${months[endMonthIndex]} ${endYear}`;

  return `${formattedStartDate} – ${formattedEndDate}`;
};

export function getDateRange(range) {
  const today = new Date();
  switch (range) {
    case 'today':
      return formatDate(today); // Return today's date only

    case 'yesterday':
      const yesterday = new Date(today.getTime() - 1000 * 60 * 60 * 24); // Subtract one day in milliseconds
      return formatDate(yesterday);

    case 'tomorrow':
      const tomorrow = new Date(today.getTime() + 1000 * 60 * 60 * 24); // Subtract one day in milliseconds
      return formatDate(tomorrow);

    case 'last 7 days': {
      const today = new Date();
      const endDate = new Date(today.getTime() - 1000 * 60 * 60 * 24); // Subtract 1 day from today
      const startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24 * 6); // Subtract 6 more days from endDate
      return `${formatDate(startDate)} - ${formatDate(endDate)}`; // Format and return date range
    }

    case 'next 7 days': {
      const today = new Date();
      const startDate = new Date(today.getTime() + 1000 * 60 * 60 * 24); // Add 1 day to today
      const endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 6); // Add 6 more days to startDate
      return `${formatDate(startDate)} - ${formatDate(endDate)}`; // Format and return date range
    }

    case 'last 14 days': {
      // Get the current date
      const today = new Date();

      const startDate = new Date(today.getTime() - 1000 * 60 * 60 * 24 * 14);

      // Calculate the end date (yesterday)
      const endDate = new Date(today);
      endDate.setDate(endDate.getDate() - 1);

      // Format and return the date range
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }

    case 'next 14 days': {
      // Get the current date
      const today = new Date();

      // Calculate the start date (tomorrow)
      const startDate = new Date(today.getTime() + 1000 * 60 * 60 * 24);

      // Calculate the end date (14 days from tomorrow)
      const endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 13);

      // Format and return the date range
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }

    case 'this week': {
      const today = new Date();
      const startDate = new Date(today);
      const dayOfWeek = today.getDay();

      // Calculate start of the week (Monday)
      const daysSinceMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Treat Sunday (0) as the end of the week
      startDate.setDate(today.getDate() - daysSinceMonday);

      // Format the dates
      const startDateFormatted = formatDate(startDate);
      const endDateFormatted = formatDate(today);

      return `${startDateFormatted} - ${endDateFormatted}`; // Format and return date range
    }

    case 'last week': {
      // Get the current date
      const today = new Date();

      // Calculate the start date of the previous week (Monday)
      const startDate = new Date(today);
      startDate.setDate(startDate.getDate() - today.getDay() - 6);

      // Calculate the end date of the previous week (Sunday)
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);

      // Return the formatted date range
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }

    case 'this month': {
      const startDate = new Date(today.getFullYear(), today.getMonth(), 1); // Set start date to 1st of current month
      const endDate = formatDate(today);
      return `${formatDate(startDate)} - ${endDate}`; // Format and return date range
    }

    case 'last 30 days': {
      const today = new Date();
      const endDate = new Date(today.getTime() - 1000 * 60 * 60 * 24); // Subtract 1 day to exclude today
      const startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24 * 29); // Subtract 29 days from the adjusted endDate
      return `${formatDate(startDate)} - ${formatDate(endDate)}`; // Format and return date range
    }

    case 'next 30 days': {
      const today = new Date();
      const startDate = new Date(today.getTime() + 1000 * 60 * 60 * 24); // Add 1 day to exclude today
      const endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 29); // Add 29 days to the adjusted startDate
      return `${formatDate(startDate)} - ${formatDate(endDate)}`; // Format and return date range
    }

    case 'last month': {
      const firstDayOfCurrentMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      const lastDayOfPreviousMonth = new Date(
        firstDayOfCurrentMonth.getTime() - 1000 * 60 * 60 * 24
      ); // Subtract 1 day from the first day of the current month to get the last day of the previous month

      const startDate = new Date(
        lastDayOfPreviousMonth.getFullYear(),
        lastDayOfPreviousMonth.getMonth(),
        1
      ); // Set start date to the first day of the previous month
      const endDate = lastDayOfPreviousMonth; // Set end date to the last day of the previous month

      return `${formatDate(startDate)} - ${formatDate(endDate)}`; // Format and return date range
    }
    default:
      return formatDate(today);
  }
}

function formatDate(date, format = 'D MMM YYYY') {
  // Customizable date format
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return format.replace('D', day).replace('MMM', month).replace('YYYY', year);
}

export const processCompanyName = input => {
  try {
    return input.replace(/[^a-zA-Z\s]/g, '').toUpperCase();
  } catch (error) {
    return '';
  }
};

function isNumericString(value) {
  try {
    if (typeof value === 'string' && /^[0-9]+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return null;
  }
}

const cleanCommas = inputString => {
  // Use regex to replace multiple consecutive commas with a single comma
  return inputString.replace(/,\s*,+/g, ', ').trim();
};

export const processRegisteredOfficeAddress = data => {
  if (!data) return null;
  const {
    registered_office_address: {
      address_line_1: addressOne = '',
      address_line_2: addressTwo = '',
      locality = '',
      postal_code: postalCode = '',
      country = '',
    } = {},
  } = data;

  // Create an array of the address components
  const addressComponents = [
    addressOne,
    addressTwo,
    locality,
    postalCode,
    country,
  ];

  // Filter out empty string values
  const filteredComponents = addressComponents.filter(
    component => component?.trim() !== ''
  );

  // Join the filtered components with ', ' separator
  return cleanCommas(filteredComponents?.join(', '));
};

export const processRegisteredOfficeAddressObject = data => {
  if (!data) return null;
  const {
    registered_office_address: {
      address_line_1: addressOne = '',
      address_line_2: addressTwo = '',
      locality = '',
      postal_code: postalCode = '',
      country = '',
    } = {},
  } = data;

  return { addressOne: addressOne, addressTwo, locality, country };
};

export const calculateYearsUntilNow = dateString => {
  try {
    const startDate = new Date(dateString);
    const currentDate = new Date();

    // Check if the date is valid
    if (isNaN(startDate)) {
      throw new Error('Invalid date');
    }

    let years = currentDate.getFullYear() - startDate.getFullYear();
    let months = currentDate.getMonth() - startDate.getMonth();
    let days = currentDate.getDate() - startDate.getDate();

    // Adjust the calculation if the current day is earlier in the month than the start day
    if (days < 0) {
      months--;
      days += new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      ).getDate(); // Get the total days of last month
    }

    // Adjust the calculation if the current month is earlier in the year than the start month
    if (months < 0) {
      years--;
      months += 12; // Add months from a full year
    }

    // Decide the largest unit of time to display
    if (years > 0) {
      return `${years} Year${years > 1 ? 's' : ''} Old`;
    } else if (months > 0) {
      return `${months} Month${months > 1 ? 's' : ''} Old`;
    } else {
      return `${days} Day${days > 1 ? 's' : ''} Old`;
    }
  } catch (error) {
    return '';
  }
};

export const formatNumber = num => {
  if (!num) return 0;
  if (num < 1000) {
    return num.toLocaleString(); // Format with commas for numbers less than 1000
  }

  // For numbers 1000 and above, format as 'k' with commas and two decimal places
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2, // Limit the number to two decimal places
    minimumFractionDigits: 2, // Ensure there are always two decimal places
  });
  return formatter.format(num / 1000) + 'k';
};

export const calculateNetAssets = (totalAssets, totalLiabilities) => {
  // Handle null, undefined, or non-number inputs for totalAssets and totalLiabilities
  totalAssets = Number(totalAssets) || 0;
  totalLiabilities = Number(totalLiabilities) || 0;

  // Calculate net assets
  const netAssets = totalAssets - totalLiabilities;

  // Use the existing formatNumber function to format the net assets
  return formatNumber(netAssets);
};

export const calculateDebtRatio = (totalAssets, totalLiabilities) => {
  // Handle null, undefined, or non-number inputs for totalAssets and totalLiabilities
  totalAssets = Number(totalAssets) || 0;
  totalLiabilities = Number(totalLiabilities) || 0;

  if (totalAssets === 0) {
    // Prevent division by zero
    return '0.00%';
  }

  // Calculate the debt ratio in percentage
  const debtRatio = (totalLiabilities / totalAssets) * 100;

  // Format the result to two decimal places and append a percent sign
  return formatNumberDepthRatio(debtRatio) + '%';
};

// Example formatting function, adjust as needed
const formatNumberDepthRatio = number => {
  return number.toFixed(2);
};

export const findAccountsDataByCompanyNumber = (companyNumber, dataList) => {
  // First, ensure both companyNumber and dataList are provided and valid
  if (!dataList || !companyNumber || !Array.isArray(dataList)) {
    console.error('Invalid inputs provided');
    return {};
  }

  // Find the data object for the specified company number
  const dataObject = dataList.find(
    data => data && data.hasOwnProperty(companyNumber)
  );

  // If the data object is found and it has the property, return the corresponding value
  if (dataObject && dataObject.hasOwnProperty(companyNumber)) {
    return dataObject[companyNumber];
  }

  return {};
};

export const capitalizeFirstLetter = string => {
  try {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  } catch (e) {
    return string;
  }
};

export const statusDiv = status => {
  const statusStyle = getStatusStyle(status);

  return (
    <>
      <div style={statusStyle}>
        {status?.toUpperCase()?.replace('IN', '')?.trim()}
      </div>
    </>
  );
};

export const getStatusStyle = status => {
  let style = {
    borderRadius: '4px',
    padding: '1px 5px',
    fontSize: '11px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'inline-block',
    color: '#fff',
    whiteSpace: 'nowrap',
    background: '',
  };

  if (status === 'active' || status?.includes('active')) {
    style.background = '#16a086'; // Green color for active status
  } else if (status === 'liquidation') {
    style.background = '#cc5834'; // Red color for reactive status
  } else if (status === 'dormant') {
    style.background = '#8c8c8c'; // #8c8c8c color for reactive status
  } else if (status === 'dissolved' || 'd') {
    style.background = '#8B0000'; // #8c8c8c color for reactive status
  } else if (status === 'in administration' || status === 'administration') {
    style.background = '#cc5834';
  } else if (status === 'resigned') {
    style.background = 'gray';
  }

  return style;
};

export const getStatusStringFromChar = status => {
  try {
    let lowerS = status?.toLowerCase();
    if (lowerS === 'd') {
      return 'Dissolved';
    }
    return status;
  } catch {
    return '';
  }
};

export const calculateAge = dateObj => {
  try {
    let { day, month, year } = dateObj;

    // Validate the year against the month
    if (year < month) {
      return '';
    }

    // Assign 1 to day or month if they are less than 1
    day = day < 1 ? 1 : day;
    month = month < 1 ? 1 : month;

    // Create the birthDate
    const birthDate = new Date(year, month - 1, day);
    if (isNaN(birthDate.getTime())) {
      throw new Error('Invalid date');
    }

    // Calculate age
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return `${age} years old`;
  } catch (error) {
    return '';
  }
};

export const isVowel = char => {
  return ['a', 'e', 'i', 'o', 'u'].includes(char.toLowerCase());
};

export const nationalityToFlag = {
  BRITISH: BritishFlag,
};

export const shortenStringByCharCount = (name, charCount) =>
  name.length > charCount ? name.slice(0, charCount) + '...' : name;

export const getOfficerLocationString = address => {
  const addressParts = [
    address?.premises?.trim() || '',
    address?.address_line_1?.trim() || '',
    address?.address_line_2?.trim() || '',
    address?.locality?.trim() || '',
    address?.region?.trim() || '',
    address?.postal_code?.trim() || '',
    address?.country?.trim() || '',
  ];

  return addressParts.filter(part => part.length > 0).join(', ');
};

export const calculateConfirmationStatementOverdueDays = next_due => {
  try {
    const nextDueDate = new Date(next_due);
    const currentDate = new Date();

    // Ensure that we only count full days overdue
    const timeDifference = currentDate - nextDueDate;
    const overdueDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Use Math.floor

    return overdueDays;
  } catch (error) {
    return 0;
  }
};

export const convertGraphToCSV = data => {
  const columns = [
    'officer_name',
    'company_name',
    'occupation',
    'officer_role',
  ];

  // Create CSV headers
  let csvContent = columns.join(',') + '\n';

  // Iterate over edges and match with company data
  data.edges.forEach(edge => {
    const officer = data.nodes.find(node => node.id === edge.source);
    const officerName = officer ? officer.entityName : '';
    const company = data.nodes.find(node => node.id === edge.target);
    const companyName = company ? company.entityName : '';

    // Separate occupation and officer_role combinations into distinct rows
    edge.edgeInfo.occupation.forEach((occupation, index) => {
      const officerRole =
        edge.edgeInfo.officer_role[index] || edge.edgeInfo.officer_role[0];
      // Add CSV row for each occupation-role pair
      csvContent += `${officerName},${companyName},${occupation},${officerRole}\n`;
    });
  });

  return csvContent;
};

export const getEntityNamesFromObjectGraph = dataObject => {
  // Extract the IDs from the combination in the object
  const ids = dataObject?.combination?.ids?.map(idObj => idObj.id) || [];

  // Extract graphs from the dataObject
  const graphs = dataObject?.graphs?.result?.graphs || [];
  // Find nodes matching the extracted IDs
  const entityNames = graphs.flatMap(graph =>
    graph.nodes
      .filter(
        node =>
          ids.includes(node.id) &&
          (node.entityType === 'company' || node.entityType === 'person')
      )
      .map(node => node.entityName)
  );

  let result = '';
  if (entityNames.length === 2) {
    result = entityNames.join(', ');
  } else {
    result = entityNames[0];
  }

  return result;
};

export const convertTimestampToDateTime = timestamp => {
  const milliseconds =
    timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
  const date = new Date(milliseconds);

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');

  // Extract hours, minutes, and seconds
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Return the formatted date and time
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const openGoogleMaps = address => {
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

  // Open new tab with the Google Maps URL
  window.open(googleMapsUrl, '_blank');
};
