import React from "react";
import "./CEIWatchList.css";

const WatchList = () => {
  return (
    <div className="watch-list-container">
      <span className="title">Watch List</span>
      <div className="watch-list-card">
        <div className="icon">
          <span role="img" aria-label="bell">
            🔔
          </span>
        </div>
        <div className="content">
          <p className="title">Stay updated with Koru Kids Ltd</p>
          <p className="description">
            Monitor changes to this company's activity with instant email
            notifications.
          </p>
          <button className="watch-list-button">ADD TO WATCH LIST</button>
        </div>
      </div>
    </div>
  );
};

export default WatchList;
