import React from 'react';
import './CEIPersonInsightsMutualPeople.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const CEIPersonInsightsMutualPeople = ({ mutual_people }) => {
  return (
    <div className="corporate-explore-insight-person-insights-container">
      <h2 className="corporate-explore-insight-person-insights-title">
        Mutual People
      </h2>
      {mutual_people &&
        mutual_people?.map((person, index) => (
          <div
            key={index}
            className="corporate-explore-insight-person-insights-main"
          >
            <div className="corporate-explore-insight-person-insights-icon">
              <FontAwesomeIcon icon={faUser} className="details-icon" />
            </div>
            <div className="corporate-explore-insight-person-insights-person-details">
              <div className="corporate-explore-insight-person-insights-person-detail-person-main-div">
                <span className="corporate-explore-insight-person-insights-person-detail-person-name">
                  {person.name}
                </span>

                <div style={{ display: 'flex', marginLeft: '60px' }}>
                  <div style={{ display: 'flex', marginLeft: '60px' }}>
                    <a
                      href={`https://www.google.com/search?q=${person?.name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LanguageIcon style={{ width: '20px' }} />
                    </a>
                  </div>
                  <div>
                    <a
                      href={`https://www.linkedin.com/search/results/all/?keywords=${person?.name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon style={{ width: '20px' }} />
                    </a>
                  </div>
                </div>
              </div>

              <div>
                {person.company_workes.map((company, idx) => (
                  <div
                    key={idx}
                    className="corporate-explore-insight-person-insights-detail-person-companies"
                  >
                    <div className="corporate-explore-insight-person-insights-person-detail-person-main-div">
                      <div>
                        <span className="cei-company-name">
                          {company.name},{' '}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <a
                            href={`https://www.google.com/search?q=${company?.name}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LanguageIcon style={{ width: '20px' }} />
                          </a>
                        </div>
                        <div>
                          <a
                            href={`https://www.linkedin.com/search/results/all/?keywords=${company?.name}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkedInIcon style={{ width: '20px' }} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CEIPersonInsightsMutualPeople;
