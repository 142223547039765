import React, { useContext, useState, useEffect } from "react";
import LineHeader from "../assets/images/icons/header-line.svg";
import surnameIcon from "../assets/images/icons/Badge.svg";
import StatusIcon from "../assets/images/icons/Settings.svg";
import APIicon from "../assets/images/icons/API.svg";
import CalenderIcon from "../assets/images/icons/Tear-Off Calendar.svg";
import YesIcon from "../assets/images/icons/yes-tag.svg";
import "./ServicesTable.css";

function ServicesTable({customerDetail, apiCallsEver, apiCallsCurrentMonth }) {

  return (
    <div className="servicesTable-info-section">
      <div className="header-section">
        <div className="body-text-normal dark-text-color">
          <img src={LineHeader} alt="icon here" />
          Services
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-custom">
          <thead>
            <tr>
              <td scope="col">
                {" "}
                <div className="thead-td-custom">
                  {" "}
                  <img
                    className="img-thead"
                    src={surnameIcon}
                    alt="icon here"
                  />{" "}
                  Services
                  <span style={{ color: "transparent" }}>.</span>
                  Name{" "}
                </div>
              </td>

              <td scope="col">
                <div className="thead-td-custom">
                  <img className="img-thead" src={APIicon} alt="icon here" />
                  API
                  <span style={{ color: "transparent" }}>.</span>KEY
                  <span className="small-sub-text">Api key assigned </span>
                </div>
              </td>

              <td scope="col">
                {" "}
                <div className="thead-td-custom">
                  {" "}
                  <img className="img-thead" src={APIicon} alt="icon here" /> No
                  <span style={{ color: "transparent" }}>.</span>of
                  <span style={{ color: "transparent" }}>.</span>API
                  <span style={{ color: "transparent" }}>.</span>Calls{" "}
                  <span className="small-sub-text">(in Current Month) </span>
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="thead-td-custom">
                  {" "}
                  <img className="img-thead" src={APIicon} alt="icon here" /> No
                  <span style={{ color: "transparent" }}>.</span>of
                  <span style={{ color: "transparent" }}>.</span>API
                  <span style={{ color: "transparent" }}>.</span>Calls{" "}
                  <span className="small-sub-text">(Year to Date) </span>
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  {" "}
                  <img
                    className="img-thead"
                    src={CalenderIcon}
                    alt="icon here"
                  />{" "}
                  Start<span style={{ color: "transparent" }}>.</span>Date
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  {" "}
                  <img
                    className="img-thead"
                    src={CalenderIcon}
                    alt="icon here"
                  />{" "}
                  Expiry<span style={{ color: "transparent" }}>.</span>Date
                </div>
              </td>

              <td scope="col">
                {" "}
                <div className="thead-td-custom">
                  <img className="img-thead" src={StatusIcon} alt="icon here" />
                  Status{" "}
                </div>
              </td>
            </tr>
          </thead>
          {customerDetail?.FirebaseUser && (
            <tbody>
              <tr>
                <td className="body-text-bold">Corporate Link</td>
                <td className="body-text-bold">
                  {" "}
                  {customerDetail?.FirebaseUser?.api_key}
                </td>
                <td className="body-text-bold">
                  {apiCallsCurrentMonth}
                </td>
                <td className="body-text-bold"> {apiCallsEver} </td>
                <td className="body-text-bold">21/12/2023</td>
                <td className="body-text-bold">2/11/2030</td>
                <td className="body-text-bold">
                  <div className="yes-tag">
                    <img src={YesIcon} alt="icon here" /> Active
                  </div>
                </td>
              </tr>
            </tbody>

          )}
        </table>
      </div>
    </div>
  );
}

export default ServicesTable;
