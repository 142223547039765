import React, { useState, useEffect, useContext } from 'react';
import './JudgementChecker.css';
import './CheckReviewPerson/SearchOnPerson/SearchOnPerson.css';
import { Link, useNavigate } from 'react-router-dom';
import HeaderDropdown from '../../components/HeaderDropdownNative';
import quoteImg from '../../assets/images/Law firm-bro 2.png';
import trustPower from '../../assets/images/icons/trustPower.svg';
import checkPerson from '../../assets/images/File searching-bro.svg';
import checkBusiness from '../../assets/images/Documents-bro.svg';
import headerLine from '../../assets/images/icons/header-line.svg';
import LocalStorageService from '../../utils/LocalStorageServices';
import { useLoading } from '../../context/LoadingContext/LoadingContext';
import { downloadReport } from '../../api/judgementChecker/judgementChecker';
import { JUDGMENT_CHECKER } from '../../utils/constants';
import { AppContext } from '../../context/Context';
import ProbeRegistryTrustLtd from '../../components/ProbeRegistryTrustLtd/ProbeRegistryTrustLtd';
import { triggerGoogleAnalyticsEvent } from '../../utils/googleAnalyticsEvent';
import { useAuth } from '../../context/AuthProvider';
import showToast from '../../utils/showToast';
import { getRelationShips } from '../../api/corporateExplorer/corporateExplorer';
function JudgementChecker() {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(null);
  const { user, status } = useContext(AppContext);
  const { openLogin } = useAuth();
  const { setIsLoading } = useLoading();

  const moveToJudgmentCheckerReportsPage = () => {
    if (!user || user?.guest) {
      LocalStorageService.setupPathForNavigation('/JudgementCheckerReports');
      openLogin();
      return;
    }
    navigate('/JudgementCheckerReports');
  };

  const downloadSampleReport = async () => {
    // Fetch the file
    await downloadReport(JUDGMENT_CHECKER.DUMMY_REPORT_URL);
  };

  const handleCardClick = cardType => {
    const genLink =
      cardType === 'Person'
        ? '/JudgementCheckerPerson'
        : '/JudgementCheckerBusiness';
    navigate(genLink);
  };

  useEffect(() => {
    LocalStorageService.removeItem('is-address-manual');
    triggerGoogleAnalyticsEvent('page_visits', 'judjement-checker');
  }, []);

  useEffect(() => {
    const handleGraphSearch = async () => {
      const searchedGraph = LocalStorageService.getItem('searchedGraph');

      if (searchedGraph) {
        debugger;
        setIsLoading(true);

        showToast('success', 'Graph saved!');

        try {
          if (user && user?.FirebaseUser && user?.FirebaseUser?.user_id) {
            searchedGraph['userId'] = user?.FirebaseUser?.user_id;
          }

          let nodeGraph = await getRelationShips(searchedGraph);

          if (nodeGraph?.status) {
            LocalStorageService.removeItem('searchedGraph');
            setIsLoading(false);
            navigate('/CorporateLinks');
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error fetching relationships:', error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    handleGraphSearch();
  }, []);

  return (
    <div className="dashboard-section dashboard-content-div-overflow">
      <div className="main-div">
        <div className="header">
          <div className="header-heading dark-heading-color probe-header-info">
            Judgments & Fines
          </div>
          <HeaderDropdown />
        </div>
        <div className="dashboard-content-div judgement-checker-main-bottom-div">
          <div className="quote-div">
            <div>
              <div className="main-quote-title light-text-color">
                "Your Trusted Source for Official <br /> UK Register
                Verification"
              </div>
              <div>
                <ProbeRegistryTrustLtd poweredByColor="white" width={120} />
              </div>
            </div>
            <div className="quote-img-div">
              <img src={quoteImg} />
            </div>
          </div>

          <div className="first-parallel-div">
            <div className="cards-div card-3 judgement-checker-select-service">
              <div className="card-headers heading-1">
                <img src={headerLine} /> Select Service?
              </div>
              <div className="body-text-normal mt-3 judgement-checker-select-the-service-text-margin-top">
                Select the service that suits you best. Whether you need to
                check an individual or a business against UK Official Registers,
                we've got you covered. Choose one of the two options below to
                start your check
              </div>
              <div className="select-service-cards judgement-checker-select-service-cards">
                <div
                  className={`cardPerson ${
                    selectedCard === 'Person' ? 'selected-card' : ''
                  }`}
                >
                  <div className="go-corner"></div>
                  <div
                    className="inner-card judgement-checker-inner-card"
                    onClick={() => handleCardClick('Person')}
                  >
                    <img src={checkPerson} alt="check-person" />
                    <div>
                      <div className="heading-2 mb-3">
                        {JUDGMENT_CHECKER.PERSON.TITLE}
                      </div>
                      <div className="body-text-normal">
                        {JUDGMENT_CHECKER.PERSON.DESCRIPTION}
                      </div>
                    </div>
                  </div>
                  <div className="bottom-rectangle"></div>
                </div>
                <div
                  className={`cardBusiness ${
                    selectedCard === 'Business' ? 'selected-card' : ''
                  }`}
                >
                  <div className="go-corner"></div>
                  <div
                    className="inner-card judgement-checker-inner-card"
                    onClick={() => handleCardClick('Business')}
                  >
                    <img src={checkBusiness} />
                    <div>
                      <div className="heading-2 mb-3">
                        {JUDGMENT_CHECKER.BUSINESS.TITLE}
                      </div>
                      <div className="body-text-normal">
                        {JUDGMENT_CHECKER.BUSINESS.DESCRIPTION}
                      </div>
                    </div>
                  </div>
                  <div className="bottom-rectangle"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="first-parallel-div">
            <div className="cards-div card-1 judgement-checker-cards-top-margin">
              <div className="card-headers button-text">
                <img src={headerLine} /> What is Judgments & Fines?
              </div>

              <div className="content-div">
                <div className="body-text-normal">
                  {' '}
                  Your Key to Official Records Across the UK and Beyond via
                  Judgments & Fines. We offer quick access to official records
                  for various regions, including England, Wales, Scotland,
                  Northern Ireland, Isle of Man, Jersey, and the Republic of
                  Ireland.
                  <br />
                  <br />
                  JudgementChecker is the unique service that lets both the
                  public and businesses instantly search the complete record.
                  Our services cover checking individuals and businesses against
                  official records across the UK and beyond.
                </div>
              </div>
            </div>
            <div className="cards-div card-4 judgement-checker-cards-top-margin">
              <div className="video-container">
                <iframe
                  width="100%"
                  height="255px"
                  src="https://www.youtube.com/embed/SY38csOjOo0?si=E6SMz-gcYjdMsjuJ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>

          <div>
            <div className="first-parallel-div">
              <div className="cards-div card-1 judgement-checker-cards-top-margin">
                <div className="card-headers button-text">
                  <img src={headerLine} /> My Reports
                </div>

                <div className="content-div">
                  <div className="body-text-normal">
                    Access and review your personalized reports in one
                    convenient place. Stay informed about your checked
                    individuals or businesses, ensuring you have all the
                    insights you need at your fingertips.
                  </div>

                  <div className="btn-div button-text">
                    <button onClick={moveToJudgmentCheckerReportsPage}>
                      View Reports
                    </button>
                  </div>
                </div>
              </div>
              <div className="cards-div card-4 judgement-checker-cards-top-margin">
                <div className="card-headers button-text">
                  <img src={headerLine} /> Interested in learning what you will
                </div>

                <div className="content-div">
                  <div className="body-text-normal">
                    When you want to know more, we provide a complete overview
                    of our services, including access to official registers and
                    detailed data. We equip you with the knowledge to make
                    informed decisions and enhance your experience.
                  </div>
                  <div className="btn-div button-text">
                    <button onClick={downloadSampleReport}>
                      Download Sample Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JudgementChecker;
