import "./FilterMidInput.css";
import "./SicCode/SicCodeDropTree.css";
import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AddIcon from "@material-ui/icons/Add";
import SicCodeDropTree from "./SicCode/SicCodeDropTree";
import CONSTANTS from "../../utils/constants";
import {
  reArrangetransformDateRange,
  debounce,
  flattenStructure,
  searchList,
} from "../../utils/helper";
import CorporateExplorerDateRangeFilter from "./CorporateExplorerDateRangeFilter/CorporateExplorerDateRangeFilter";
import { isArray } from "lodash";

function FilterMidInput({
  filteredItem,
  setFilterReadyToApply,
  filterReadyToApply,
  locationSearchInput,
  locationSearchResults,
}) {
  const [searchInput, setSearchInput] = useState(null);
  const [searchInputFromSicCodes, setSearchInputFromSicCodes] = useState(null);
  const [showCustomDateFilter, setShowCustomDateFilter] = useState(false);
  const [datePickerRange, setDatePickerRange] = useState([]);
  // const [filterReadyToApply, setFilterReadyToApply] = useState([
  //   { header: "Name", filter: ["+Raw", "-Raw"] },
  // ]);

  const datePickerHandler = (dates) => {
    let fromToDate = dates?.fromDate + " - " + dates?.toDate;
    handleStatusLabelInput(reArrangetransformDateRange(fromToDate));
  };

  const searchInputHandler = debounce((sub, searchInput) => {
    if (sub?.key === "SIC Code") {
      if (searchInput) {
        let searchedResults = searchList(
          searchInput,
          flattenStructure(CONSTANTS.SIC_CODES)
        );
        setSearchInputFromSicCodes(searchedResults);
      } else {
        setSearchInputFromSicCodes([]);
      }
    }

    if (sub?.key === "Name") {
      setSearchInput(searchInput);
    }

    if (sub?.key === "Registered Address") {
      locationSearchInput(searchInput);
    }
  }, 400);

  const actionHandler = (value) => {
    // Clone the state array to avoid directly mutating it
    const updatedFilterReadyToApply = [...filterReadyToApply];
    let key = filteredItem?.subValues?.map((item) => item.key)[0];
    // Check if an item with the same header already exists
    const existingItemIndex = updatedFilterReadyToApply.findIndex(
      (item) => item.header === key
    );

    if (existingItemIndex !== -1) {
      // If item with same header exists, check if value+searchInput already exists in the filter array
      if (
        !updatedFilterReadyToApply[existingItemIndex].filter.includes(
          value + searchInput
        )
      ) {
        // If value+searchInput doesn't exist, push it to the filter array
        updatedFilterReadyToApply[existingItemIndex].filter.push(
          value + searchInput
        );
      }
    } else {
      // If item with same header doesn't exist, append it to the array
      updatedFilterReadyToApply.push({
        header: key,
        filter: [value + searchInput],
      });
    }
    setFilterReadyToApply(updatedFilterReadyToApply);
  };

  const handleStatusLabelInput = (selectedStatus) => {
    let status = selectedStatus
      ?.toLowerCase()
      ?.replace(/\(.*?\)/g, "")
      ?.trim();
    // Clone the state array to avoid directly mutating it
    const updatedFilterReadyToApply = [...filterReadyToApply];
    let key = filteredItem?.subValues?.map((item) => item?.key)[0];

    // Check if an item with the same header already exists
    const existingItemIndex = updatedFilterReadyToApply?.findIndex(
      (item) => item.header === key
    );

    if (existingItemIndex !== -1) {
      // If item with same header exists, check if value+searchInput already exists in the filter array
      if (
        !updatedFilterReadyToApply[existingItemIndex]?.filter?.includes(status)
      ) {
        // If value+searchInput doesn't exist, push it to the filter array
        updatedFilterReadyToApply[existingItemIndex]?.filter?.push(status);
      }
    } else {
      // If item with same header doesn't exist, append it to the array
      updatedFilterReadyToApply?.push({
        header: key,
        filter: [status],
      });
    }
    setFilterReadyToApply(updatedFilterReadyToApply);
  };

  const handleTreeInput = (selectedValue) => {
    const updatedFilterReadyToApply = [...filterReadyToApply];
    let key = filteredItem?.subValues?.map((item) => item.key)[0];

    // Check if an item with the same header already exists
    const existingItemIndex = updatedFilterReadyToApply?.findIndex(
      (item) => item.header === key
    );

    if (existingItemIndex !== -1) {
      // If item with same header exists, check if value+searchInput already exists in the filter array
      if (
        !updatedFilterReadyToApply[existingItemIndex]?.filter?.includes(
          selectedValue?.name
        )
      ) {
        // If value+searchInput doesn't exist, push it to the filter array
        updatedFilterReadyToApply[existingItemIndex]?.filter?.push(
          selectedValue?.name
        );
      }
    } else {
      // If item with same header doesn't exist, append it to the array
      updatedFilterReadyToApply.push({
        header: key,
        filter: [selectedValue?.name],
      });
    }
    setFilterReadyToApply(updatedFilterReadyToApply);
  };

  const processMidUi = (sub, midValues) => {
    if (midValues?.type === "header") {
      return <span style={{ fontWeight: "600" }}>{midValues?.value}</span>;
    }
    if (midValues?.type === "sub_header") {
      return (
        <div
          style={{
            fontWeight: "600",
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "13px",
          }}
        >
          {midValues?.value}
        </div>
      );
    }

    if (midValues?.type === "search") {
      return (
        <div className="ce_filter_mid_input_main_search">
          <div>
            <SearchIcon />
          </div>
          <input
            className="ce_filter_mid_input_search"
            onChange={(e) => searchInputHandler(sub, e.target.value)}
            placeholder={midValues?.placeHolder}
          />
        </div>
      );
    }
    if (midValues?.type === "label" && !showCustomDateFilter) {
      return (
        <div className="ce_filter_mid_input_label_main">
          <span
            className="ce_filter_mid_input_label"
            onClick={() => handleStatusLabelInput(midValues?.value)}
          >
            {midValues?.value}
            <div>
              <NavigateNextIcon />
            </div>
          </span>
        </div>
      );
    }

    if (midValues?.type === "label-date-range" && !showCustomDateFilter) {
      return (
        <div className="ce_filter_mid_input_label_main">
          <span
            className="ce_filter_mid_input_label"
            onClick={() => setShowCustomDateFilter(true)}
          >
            {midValues?.value}
            <div>
              <NavigateNextIcon />
            </div>
          </span>
        </div>
      );
    }

    if (midValues?.type === "label-date-range" && showCustomDateFilter) {
      return (
        <div className="ce_filter_mid_input_label_main">
          <CorporateExplorerDateRangeFilter
            datePickerHandler={datePickerHandler}
          />
        </div>
      );
    }

    if (midValues?.type === "tree") {
      return (
        <div>
          {searchInputFromSicCodes?.map((value) => (
            <div
              className="filter-mid-input-search-on-filtered-data"
              style={{
                padding: "10px",
                cursor: "pointer",
                width: "100%",
                borderBottom: "1px solid #e4e4e4",
                color: "#003967",
              }}
              onClick={() => handleTreeInput({ name: value?.toLowerCase() })}
            >
              <div>{value}</div>
            </div>
          ))}
          <div>
            {midValues?.value?.map((node, index) => (
              <div
                style={{
                  padding: "3px",
                  backgroundColor: "white",
                  margin: "1px",
                  color: "#003967",
                }}
              >
                <SicCodeDropTree
                  key={index}
                  node={node}
                  handleTreeInput={handleTreeInput}
                />
              </div>
            ))}
          </div>
        </div>
      );
    }

    if (midValues?.type === "locationSearch") {
      return (
        <div style={{ marginTop: "5px" }}>
          {Array.isArray(locationSearchResults) &&
            locationSearchResults?.map((result) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="ce_filter_mid_input_label"
                onClick={() =>
                  handleStatusLabelInput(result?.value + "__" + result?.key)
                }
              >
                <div>{result?.value}</div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <div>{result?.key}</div>
                  <div>
                    <AddIcon fontSize="small" />
                  </div>
                </div>
              </div>
            ))}
        </div>
      );
    }
  };

  useEffect(() => {
    setShowCustomDateFilter(false);
    setSearchInput(null);
  }, [filteredItem]);

  return (
    <div className="ce_filter_mid_input_main">
      {filteredItem && (
        <div className="ce_filter_mid_input_main_mid_ui">
          {filteredItem.subValues?.map((sub) => (
            <div>
              {sub?.mid?.map((midValues) => (
                <div>{processMidUi(sub, midValues)}</div>
              ))}
            </div>
          ))}
        </div>
      )}

      {searchInput && (
        <div className="ce_filter_mid_input_main_contain_or_not">
          <div
            className="ce_filter_mid_input_main_contain_or_not_label"
            onClick={() => actionHandler("+")}
          >
            Company name must contain {searchInput}
            <div>
              <NavigateNextIcon />
            </div>
          </div>
          <div
            className="ce_filter_mid_input_main_contain_or_not_label"
            onClick={() => actionHandler("-")}
          >
            Company name must NOT contain {searchInput}
            <div>
              <NavigateNextIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterMidInput;
