import './HeaderDropdown.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ProfileAvatar from '../assets/images/icons/avatar-demo.png';
import MyProfileIcon from '../assets/images/icons/my-profile-icon.svg';
import CustomerAdminIcon from '../assets/images/icons/customer-admin-icon.svg';
import Settings from '../assets/images/icons/settings.png';
import ReportIcon from '../assets/images/report.png';
import SuggestionIcon from '../assets/images/icons/bx_chat.svg';
import HelpIcon from '../assets/images/icons/Help.svg';
import { AppContext } from '../context/Context';
import { useLocation } from 'react-router-dom';
import './HeaderDropdownNative.css';
import { useAuth } from '../context/AuthProvider';

function HeaderDropdown() {
  const { setToggleSidebar, toggleSidebar } = useContext(AppContext);
  const location = useLocation();
  const { user } = useContext(AppContext);
  const { logout, openLogin, openProfile } = useAuth();
  const navigate = useNavigate();

  const loginUser = () => {
    openLogin();
  };

  const logoutUser = () => {
    logout();
  };

  const userSettings = () => {
    openProfile();
  };

  const toggleSidebarHandler = () => {
    setToggleSidebar(!toggleSidebar);
  };

  const handleMenuClick = path => {
    navigate(path);
    setToggleSidebar(false);
  };

  return (
    <div className="name-div">
      {!location.pathname === '/' && location.pathname !== '/home' && (
        <div
          className="make-a-suggestion-div"
          data-tooltip-id="suggestion-tooltip"
        >
          <button
            onClick={() => {
              navigate('/feedbackForm');
            }}
            className="body-text-normal"
          >
            <img
              style={{ marginRight: '5px' }}
              src={SuggestionIcon}
              alt="icon here"
            />
            <span className="help-text-large">Make a Suggestion</span>
            <span className="help-text-small">Suggestion</span>
          </button>
        </div>
      )}
      <div
        className="make-a-suggestion-div"
        data-tooltip-id="help-request-tooltip"
      >
        <button>
          <img style={{ marginRight: '5px' }} src={HelpIcon} alt="icon here" />
          <span className="help-text-large">Submit help request</span>
          <span className="help-text-small">Help</span>
        </button>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="dropdown">
          <div
            className="btn dropdown-toggle"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {user && !user?.guest ? (
              <div className="initials" data-tooltip-id="profile-tooltip">
                <img
                  src={
                    user.ProfileImageS3Url
                      ? user.ProfileImageS3Url
                      : ProfileAvatar
                  }
                  alt={ProfileAvatar}
                />
              </div>
            ) : null}

            {user && !user?.guest ? (
              <div
                className="name-greetings body-text-bold dark-heading-color"
                data-tooltip-id="profile-tooltip"
              >
                Hi {user.FullName && <>, {user.FullName}</>}
              </div>
            ) : null}

            {!user || user?.guest ? (
              <div className="name-greetings body-text-bold dark-heading-color">
                Hi Guest User
              </div>
            ) : null}
          </div>

          {user && !user?.guest ? (
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <div className="dropdown-item" onClick={userSettings}>
                <li>
                  <div>
                    <img src={MyProfileIcon} alt="icon here" />
                  </div>
                  <div>Profile</div>
                </li>
              </div>

              {user?.FirebaseUser?.role === 'admin' && (
                <div
                  className="dropdown-item"
                  onClick={() => handleMenuClick('/CustomerAdmin')}
                >
                  <li>
                    <div>
                      <img src={CustomerAdminIcon} alt="icon here" />
                    </div>
                    <div>Customer Administration</div>
                  </li>
                </div>
              )}

              <div
                className="dropdown-item"
                onClick={() => handleMenuClick('/JudgementCheckerReports')}
              >
                <li>
                  <div>
                    <img src={ReportIcon} alt="report" />
                  </div>
                  <div>Reports</div>
                </li>
              </div>

              <div
                className="dropdown-item"
                onClick={() => handleMenuClick('/AccountsUsage')}
              >
                <li>
                  <div>
                    <img src={Settings} alt="icon here" />
                  </div>
                  <div>Usage</div>
                </li>
              </div>

              <div className="dropdown-item" onClick={logoutUser}>
                <li>
                  <div>
                    <img src={CustomerAdminIcon} alt="icon here" />
                  </div>
                  <div>Logout</div>
                </li>
              </div>
            </ul>
          ) : null}

          {!user || user?.guest ? (
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <div className="dropdown-item">
                <li onClick={loginUser}>
                  <div>
                    <img src={MyProfileIcon} alt="icon here" />
                  </div>
                  <div> Login </div>
                </li>
              </div>
            </ul>
          ) : null}
        </div>
        <div className="burger-main" onClick={toggleSidebarHandler}>
          &#9776;
        </div>
      </div>
    </div>
  );
}

export default HeaderDropdown;
