import React, { useState, useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from './context/Context';
import { getUserById } from './api/outseta/outseta';
import { useLoading } from './context/LoadingContext/LoadingContext';

// Protected Route component
const ProtectedRoute = ({ allowedRoles }) => {
  const { user } = useContext(AppContext);
  const [userRole, setUserRole] = useState('');
  const [isRoleChecked, setIsRoleChecked] = useState(false); // State to track if the role check is done

  useEffect(() => {
    let userId = localStorage.getItem('user_id');
    if (!user && userId) {
      const fetchUser = async () => {
        let firebaseUser = await getUserById(userId);
        if (firebaseUser) {
          setUserRole(firebaseUser?.data?.role);
        }
        setIsRoleChecked(true); // Set role check to true after fetching user
      };
      fetchUser();
    } else if (user?.FirebaseUser) {
      setUserRole(user.FirebaseUser.role);
      setIsRoleChecked(true); // Set role check to true if user is already in context
    } else {
      setIsRoleChecked(true); // Set role check to true if no user is found
    }
  }, [user]);

  if (!isRoleChecked) {
    // Render some loading screen or return null while checking
    return null;
  }

  return allowedRoles.includes(userRole) ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
