import React from "react";
import LineHeader from "../assets/images/icons/header-line.svg";
import EditIcon from "../assets/images/icons/edit-icon.svg";
import ListIcon from "../assets/images/icons/Licence.svg";
import YesIcon from "../assets/images/icons/yes-tag.svg";
import NoIcon from "../assets/images/icons/no-tag.svg";
import ChargesIcon from "../assets/images/icons/charges.svg";
import "./CheckLists.css";
function CheckLists() {
  return (
    <div className="checklists-info-section">
      <div className="header-section">
        <div className="body-text-normal light-text-color">
          <img src={LineHeader} alt="icon here" /> Check Lists
        </div>
        <div>
          <div className="dropdown">
            <button
              className="btn"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={EditIcon} alt="icon here" />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <table className="table table-custom">
        <thead>
          <tr>
            <td scope="col">
              <div className="thead-td-custom">
                <img className="img-thead" src={ListIcon} alt="icon here" />{" "}
                List<span style={{ color: "transparent" }}>.</span>Name{" "}
              </div>
            </td>
            <td scope="col">
              <div className="thead-td-custom">
                <img className="img-thead" src={ChargesIcon} alt="icon here" />{" "}
                Charges{" "}
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="body-text-bold">United Nations Sanction List</td>
            <td>
              <div className="no-tag">
                <img src={NoIcon} alt="icon here" /> No
              </div>
            </td>
          </tr>
          <tr>
            <td className="body-text-bold">Subject to Financial Sanctions</td>
            <td>
              <div className="no-tag">
                <img src={NoIcon} alt="icon here" /> No
              </div>
            </td>
          </tr>
          <tr>
            <td className="body-text-bold">Financial Services Resgister</td>
            <td>
              <div className="yes-tag">
                <img src={YesIcon} alt="icon here" /> Yes
              </div>
            </td>
          </tr>
          <tr>
            <td className="body-text-bold">Insolvency Register</td>
            <td>
              <div className="yes-tag">
                <img src={YesIcon} alt="icon here" /> Yes
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CheckLists;
