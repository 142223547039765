import React, { useEffect, useState } from 'react';
import ProbLabel from '../../../components/ProbLabel/ProbLabel';
import ProbeButton from '../../../components/ProbeButton/ProbeButton';
import AddIcon from '@mui/icons-material/Add';
import CESearchEntitiesSection from './CESearchEntitiesSearchSection';
import ExploreLinksOfficerUpdatedCard from '../../../components/ExploreLinksOfficerUpdatedCard/ExploreLinksOfficerUpdatedCard';
import '../../CorporateLinks/CESearchEntities/CESearchEntitiesFlowSection.css';
const CESearchEntitiesFlowSection = ({
  searchContainerRef,
  inputValue,
  handleInputFocus,
  handleInputBlur,
  handleChange,
  selectedSuggestions,
  handleGraphPage,
  isOpen,
  selectedType,
  handleButtonClick,
  isLoadingPage,
  loadingGif,
  suggestions,
  removeItem,
  itemClicked,
  setSelectedType,
  hideButtonsAndSearch,
  setHideButtonsAndSearch,
  setSuggestions,
}) => {
  const [selectEntityTypeButtonList, setSelectEntityTypeButtonList] = useState([
    'People',
    'Companies',
    '...',
  ]);
  const [addEntity, setAddEntity] = useState(false);

  const handleAddEntity = () => {
    setAddEntity(true);
    setHideButtonsAndSearch(false);
  };

  const handleSelectEntity = type => {
    handleButtonClick(type);
  };

  const selectEntityType = () => {
    return (
      <div className="corporate-links-flow-section-add-entity-button-holder">
        {selectEntityTypeButtonList.map(item => {
          const isSelected = item === selectedType;
          return (
            <>
              <ProbeButton
                width={'150px'}
                height={'40px'}
                name={item}
                backgroundColor={isSelected ? '#d5dbdb ' : '#fff'}
                borderColor={'#0466D1'}
                borderRadius={'20px'}
                iconSrc={<AddIcon />}
                onClick={() => handleSelectEntity(item)}
                disabled={item === '...' ? true : false}
              />
            </>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className="cesearch-entites-flow-section-probelabel">
          <ProbLabel text="Please select the entity or entities for which the relationships are to be discovered. Click the “add entity” button to start the selection of the organizations and/or people of interest" />
        </div>
        <div className="ec-search-entites-explore-link-officer-card-main">
          <div>
            {' '}
            <div className="ec-search-entites-explore-link-officer-card">
              {selectedSuggestions &&
                selectedSuggestions?.map(sugg => (
                  <div>
                    <ExploreLinksOfficerUpdatedCard
                      data={sugg}
                      removeItem={removeItem}
                      showTitle={false}
                    />
                  </div>
                ))}
              <div
                className="cesearch-entites-flow-section-addentity-card"
                onClick={
                  selectedSuggestions && selectedSuggestions?.length === 2
                    ? null
                    : handleAddEntity
                }
              >
                <div>
                  <AddIcon style={{ fontSize: '40px', color: '#0466D1' }} />
                </div>
                <div>Add Entity</div>
              </div>
            </div>
            <div className='cesearch-entites-flow-section-addentity-card-create-network'>
              {selectedSuggestions && selectedSuggestions?.length > 0 && (
                <ProbeButton
                  width={'200px'}
                  height={'40px'}
                  name="Create Network"
                  borderRadius={'20px'}
                  borderColor={'#0466D1'}
                  iconSrc={<AddIcon />}
                  onClick={handleGraphPage}
                />
              )}
            </div>
          </div>
        </div>

        <div className="corporate-links-flow-section-add-entity">
          {addEntity && !hideButtonsAndSearch && selectEntityType()}
        </div>
      </div>

      <div>
        {selectedType && !hideButtonsAndSearch && (
          <CESearchEntitiesSection
            searchContainerRef={searchContainerRef}
            inputValue={inputValue}
            handleInputFocus={handleInputFocus}
            handleInputBlur={handleInputBlur}
            handleChange={handleChange}
            selectedSuggestions={selectedSuggestions}
            handleGraphPage={handleGraphPage}
            isOpen={isOpen}
            selectedType={selectedType}
            handleButtonClick={handleButtonClick}
            isLoadingPage={isLoadingPage}
            loadingGif={loadingGif}
            suggestions={suggestions}
            removeItem={removeItem}
            itemClicked={itemClicked}
            showCards={false}
          />
        )}
      </div>
    </div>
  );
};

export default CESearchEntitiesFlowSection;
