import './Banner.css';
import React, { useState, useContext } from 'react';
import BannerImage from '../assets/images/pages/Home/banner-image.png';
import Procurement from '../assets/images/pages/Home/procurement.svg';
import LawEnforcement from '../assets/images/pages/Home/law-enforcement.svg';
import HiringOrganizations from '../assets/images/pages/Home/hiring-organizations.svg';
import AuditFirms from '../assets/images/pages/Home/audit-firms.svg';
import KYC from '../assets/images/pages/Home/kyc.svg';
import Security from '../assets/images/pages/Home/security.svg';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/Context';
import { useAuth } from '../context/AuthProvider';

function Banner() {
  const { openLogin } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const { user } = useContext(AppContext);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const navigate = useNavigate();

  const videoUrl =
    'https://firebasestorage.googleapis.com/v0/b/probe-digital.appspot.com/o/probe-digital-cdn%2FFirst%20Page%20Intro.mp4?alt=media&token=282f0aca-d9b2-4221-8050-e602f7ad8cc1';

  const checkUserLogin = () => {
    if (user) {
      navigate('/ccj-orders-fines');
    } else {
      navigate('/ccj-orders-fines', { replace: true });
      window.location.reload();
    }
  };

  return (
    <div className="banner-section">
      <div className="banner-outer-div light-text-color">
        <div className="banner-left">
          <p className="main-title mb-0">
            Check for Judgements, Orders and Fines
          </p>
          <div className="Judgements-fines-btn">
            <button
              className="button-text secondary-background-color dark-heading-color"
              onClick={checkUserLogin}
            >
              Try Judgments & Fines
            </button>

            {/* <div
              className="button-text"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onMouseEnter={(e) =>
                (e.target.style.textDecoration = "underline")
              }
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
              onClick={handleShow}
            >
              Watch video
              <img
                alt="Play Icon"
                src={PlayIcon}
                style={{ marginLeft: "5px", width: "30px", cursor: "pointer" }}
                onClick={handleShow}
              />
            </div> */}

            {/* Modal */}
            <Modal show={showModal} onHide={handleClose} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Introduction</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <video width="100%" height="auto" controls>
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="button-text secondary-background-color banner-modal-close-button"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="heading-2 mb-4">
            Trusted by Industries all over the world
          </div>
          <div className="features-outer-div body-text-normal">
            <div className="features-inner-div">
              <div>
                <img alt="icon" src={Procurement} />
              </div>
              <div>Procurement Departments</div>
            </div>

            <div className="features-inner-div">
              <div>
                <img alt="icon" src={LawEnforcement} />
              </div>
              <div>Law Enforcement</div>
            </div>

            <div className="features-inner-div">
              <div>
                <img alt="icon" src={HiringOrganizations} />
              </div>
              <div>Hiring Organizations</div>
            </div>
          </div>
          <div className="features-outer-div body-text-normal">
            <div className="features-inner-div">
              <div>
                <img alt="icon" src={AuditFirms} />
              </div>
              <div>Audit Firms</div>
            </div>

            <div className="features-inner-div">
              <div>
                <img alt="icon" src={KYC} />
              </div>
              <div>Banks for KYC Checks</div>
            </div>

            <div className="features-inner-div">
              <div>
                <img alt="icon" src={Security} />
              </div>
              <div>Security and Compliance Organizations</div>
            </div>
          </div>
        </div>
        <div className="banner-right">
          <img alt="icon" src={BannerImage} />
        </div>
      </div>
    </div>
  );
}

export default Banner;
