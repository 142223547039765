import React, { useEffect, useState } from "react";
import ProbeButton from "../../ProbeButton/ProbeButton";
import ProbeDatePicker from "../../ProbeDatePicker/ProbeDatePicker";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";

function CorporateExplorerDateRangeFilter({ datePickerHandler }) {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isErrorFromDate, setIsErrorFromDate] = useState(false);
  const [isErrorToDate, setErrorIsToDate] = useState(false);
  const [dateFromHelperText, setDateFromHelperText] = useState("");
  const [dateToHelperText, setDateToHelperText] = useState("");
  const [dateRangeFilled, setDateRangeFilled] = useState(false);

  useEffect(() => {
    const validateDates = () => {
      if (fromDate.length < 1 || toDate.length < 1) {
        setDateRangeFilled(true);
        return;
      }
      if (fromDate && toDate) {
        // Reverse order of date components for YYYY-MM-DD format
        const formattedFromDate = `${fromDate.split("-").reverse().join("-")}`;
        const formattedToDate = `${toDate.split("-").reverse().join("-")}`;

        try {
          // Attempt to create Date objects using parsed strings
          const parsedFromDate = new Date(formattedFromDate);
          const parsedToDate = new Date(formattedToDate);

          // Use dayjs for date comparison (ensures reliable validation)
          const isFromDateGreaterThanToDate = dayjs(parsedFromDate).isAfter(
            dayjs(parsedToDate)
          );

          setIsErrorFromDate(isFromDateGreaterThanToDate);
          setDateFromHelperText(
            isFromDateGreaterThanToDate
              ? "From date cannot be after To date"
              : ""
          );
          setErrorIsToDate(isFromDateGreaterThanToDate);
          setDateToHelperText(
            isFromDateGreaterThanToDate
              ? "To date cannot be before From date"
              : ""
          );
          setDateRangeFilled(false);
        } catch (error) {
          // Handle invalid date format errors
          console.error("Error parsing date format:", error.message);
          setIsErrorFromDate(true); // Set errors to indicate invalid format
          setDateFromHelperText("Invalid From date format (DD-MM-YYYY)");
          setErrorIsToDate(true);
          setDateToHelperText("Invalid To date format (DD-MM-YYYY)");
          setDateRangeFilled(true);
        }
      } else {
        // Reset errors if either date is empty
        setIsErrorFromDate(false);
        setDateFromHelperText("");
        setErrorIsToDate(false);
        setDateToHelperText("");
        setDateRangeFilled(false);
      }
    };

    validateDates();
  }, [fromDate, toDate]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <ProbeDatePicker
            placeHolder={"FROM"}
            setDate={setFromDate}
            isError={isErrorFromDate}
            helperText={dateFromHelperText}
          />
          <div>—</div>
          <ProbeDatePicker
            placeHolder={"TO"}
            setDate={setToDate}
            isError={isErrorToDate}
            helperText={dateToHelperText}
          />
        </div>
        <ProbeButton
          disabled={
            dateRangeFilled || isErrorFromDate || isErrorToDate === true
              ? true
              : false
          }
          width={"200px"}
          height={"40px"}
          name="APPLY FILTER"
          borderRadius={"20px"}
          borderColor={"gray"}
          iconSrc={<AddIcon />}
          iconType={"svg"}
          onClick={() => datePickerHandler({ fromDate, toDate })}
        />
      </div>
    </div>
  );
}

export default CorporateExplorerDateRangeFilter;
