import React, { useContext, useState } from 'react';
import './AccountsProfileDetails.css';
import { AppContext } from '../../context/Context';
function AccountsProfileDetails() {
  const { user } = useContext(AppContext);

  return (
    <div className="accounts-profile-details-section">
      {user && (
        <>
          <div className="initials">
            <img
              style={{ width: '200px' }}
              src={user.ProfileImageS3Url && user.ProfileImageS3Url}
            />
          </div>

          <div className="outer-div custom-margin">
            <div className="body-text-normal dark-text-color profile-div">
              <div className="label-text"> Name: </div>{' '}
              <div> {user.FullName ? user.FullName : 'N/A'} </div>
            </div>
            <div className="body-text-normal dark-text-color profile-div">
              <div className="label-text"> Country: </div>{' '}
              <div> {user.Timezone ? user.Timezone : 'N/A'} </div>
            </div>

            {/* <div className="body-text-normal dark-text-color profile-div">
              <div className="label-text"> Organization: </div> <div> N/A </div>
            </div> */}

            <div className="body-text-normal dark-text-color profile-div">
              <div className="label-text"> Email: </div>{' '}
              <div> {user.Email ? user.Email : 'N/A'} </div>
            </div>
            <div className="body-text-normal dark-text-color profile-div">
              <div className="label-text"> Phone: </div>{' '}
              <div> {user.PhoneMobile ? user.PhoneMobile : 'N/A'} </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AccountsProfileDetails;
