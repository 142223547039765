import React from 'react';
import TableLoadingScreen from '../../../LoadingScreen/TableLoadingScreen/TableLoadingScreen';

import '../CompanyInfoModal.css';
import PeopleAndContactItem from './PeopleAndContactItem';

function PeopleAndContacts({
  companyOfficersInfo,
  isLoading,
  showCard = true,
}) {
  return (
    <div>
      {Array.isArray(companyOfficersInfo) && (
        <div className={showCard && 'company-info-modal-people-contant-main'}>
          <div
            className="company-info-modal-overview-main"
            style={{ padding: !showCard && '0px', gap: !showCard && '2px' }}
          >
            <div
              className="company-info-modal-overview-title"
              style={{
                display: 'flex',
                gap: '5px',
                color: '#021B41',
              }}
            >
              People
              <div className="company-info-modal-people-officers-count">
                <div>
                  {companyOfficersInfo?.length > 0
                    ? companyOfficersInfo?.length
                    : 0}
                </div>
              </div>
            </div>
            {isLoading === false ? (
              <div>
                {companyOfficersInfo &&
                  companyOfficersInfo?.map(officer => (
                    <PeopleAndContactItem officer={officer} />
                  ))}
              </div>
            ) : (
              <TableLoadingScreen />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PeopleAndContacts;
