import React from "react";
import "./ProbeInput.css";

const ProbeInputUseForm = ({
  height,
  width,
  marginRight,
  placeholder,
  registerConfig,
  errors,
}) => {
  const inputStyle = {
    height: height,
    width: width,
    marginRight: marginRight,
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: width }}>
        <input
          type="text"
          className="probeInput" // Apply the class for base styles
          style={inputStyle} // Inline styles for height and width
          placeholder={placeholder}
          {...registerConfig}
        />
      </div>

      <div className="probe_input_use_form_error_main_div">
        {errors && errors[registerConfig?.name] ? (
          <div className="error" style={{ color: "red", width: "100%" }}>
            {errors[registerConfig.name]?.message}
          </div>
        ) : (
          <div className="probe_input_use_form_error_place_holder"></div>
        )}
      </div>
    </div>
  );
};

export default ProbeInputUseForm;
