import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ForegnUKOwnership.css";
import "../JudgementChecker/CheckReviewPerson/SearchOnPerson/SearchOnPerson.css";
import HeaderDropdown from "../../components/HeaderDropdownNative";
import foregnUkChecker from "../../assets/images/foregn-uk-check.png";
import headerLine from "../../assets/images/icons/header-line.svg";
import ProbeButton from "../../components/ProbeButton/ProbeButton";

function ForegnUKOwnership() {
  const [selectedCard, setSelectedCard] = useState(null);
  const [buttonText, setButtonText] = useState("Select a Card");

  const handleCardClick = (cardType) => {
    setSelectedCard(cardType);
    setButtonText(`Check ${cardType}`);
  };

  const handleError = () => {
    throw new Error("This is a test error!");
  };

  // Dynamically generate the link based on the selected card
  const linkTo =
    selectedCard === "Person"
      ? "/JudgementCheckerPerson"
      : "/JudgementCheckerBusiness";
  // Disable the button when neither card is selected
  const isButtonDisabled = !selectedCard;
  // Style for the disabled button
  const disabledButtonStyle = isButtonDisabled ? { opacity: 0.5 } : {};

  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className="header">
          <div className="header-heading dark-heading-color">
            Foreign UK Ownership Checker
          </div>
          <HeaderDropdown />
        </div>
        <div className="dashboard-content-div">
          <div className="quote-div">
            <div>
              <div className="main-quote-title light-text-color">
                {" "}
                "Check which foreign entity owns <br /> assets in the UK"
              </div>
            </div>
            <div className="quote-img-div" style={{ padding: "56px" }}>
              <img src={foregnUkChecker} />
            </div>
          </div>

          <div className="first-parallel-div">
            <div className="cards-div card-1">
              <div className="card-headers button-text">
                <img src={headerLine} /> What is Foreign UK Ownership Checker?{" "}
                <input
                  type="button"
                  onClick={handleError}
                  value="error check"
                  style={{
                    fontColor: "white",
                    backgroundColor: "white",
                    color: "white",
                    border: "none",
                  }}
                />
              </div>

              <div className="content-div">
                <div className="body-text-normal">
                  The Foreign UK Ownership Checker Service empowers clients to
                  scrutinize our extensive data sets, providing insights into
                  the beneficial ownership of UK assets. Given that these assets
                  often involve overseas entities, discerning crucial details
                  such as the company name, jurisdiction, and type is imperative
                  for investigators. This information serves as a foundational
                  resource, enabling seamless cross-referencing with other Probe
                  services, notably the Corporate Link Checker.
                </div>
              </div>
            </div>
            <div className="cards-div card-2 ForegnUKOwnership-card">
              <div className="card-headers button-text">
                <img src={headerLine} /> Interested in learning what you will
                get?
              </div>

              <div className="content-div">
                <div className="body-text-normal">
                  When you want to know more, we provide a complete overview of
                  our services, including access to our databases.
                </div>
                <div className="btn-div button-text">
                  <button> Download Sample - Coming soon </button>
                </div>
              </div>
            </div>
          </div>

          <div className="ForegnUKOwnership-coming-soon-btn">
            <ProbeButton
              name={"Coming Soon"}
              backgroundColor={"#c1d8f0"}
              borderRadius={"10px"}
              borderColor={"#blue"}
              height={"43px"}
              width={"237px"}
              fontColor="#FFF"
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForegnUKOwnership;
