import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
});

const PaginationButton = styled("button")({
  border: "1px solid #ddd",
  padding: "1px 10px",
  margin: "0 4px",
  borderRadius: "12px",
  background: "none",
  color: "#0466d1",
  "&:hover": {
    background: "#f0f0f0",
    cursor: "pointer",
  },
  "&:disabled": {
    color: "#999",
    cursor: "not-allowed",
  },
  "&.selected": {
    fontWeight: "bold",
    color: "white",
    background: "#007bff",
  },
});

export default function UsePagination({
  totalPages,
  currentPage,
  onPageChange,
}) {
  const { items } = usePagination({
    count: totalPages,
    page: currentPage + 1,
  });

  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <PaginationButton
                type="button"
                className={selected ? "selected" : ""}
                {...item}
                onClick={() => onPageChange(page - 1)}
              >
                {page}
              </PaginationButton>
            );
          } else if (type === "next") {
            children = (
              <PaginationButton
                type="button"
                disabled={currentPage >= totalPages - 1}
                onClick={() => onPageChange(currentPage + 1)}
              >
                next
              </PaginationButton>
            );
          } else if (type === "previous") {
            children = (
              <PaginationButton
                type="button"
                disabled={currentPage <= 0}
                onClick={() => onPageChange(currentPage - 1)}
              >
                previous
              </PaginationButton>
            );
          } else {
            children = (
              <PaginationButton type="button" {...item}>
                {type}
              </PaginationButton>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
}
