import React, { useEffect, useState } from "react";
import "./AppliedFilterGroup.css";
import NumberDisplay from "../NumberDisplay/NumberDisplay";
import { formatDateRange, getDateRange } from "../../utils/helper";

function AppliedFilterGroup({
  filterReadyToApply,
  setRemoveFIlterItem,
  totalCompaniesDocument,
  applyFilters,
}) {
  const [animatedValue, setAnimatedValue] = useState(totalCompaniesDocument);

  const truncateText = (text) => {
    const index = text.indexOf("__");
    const truncatedText = index !== -1 ? text.substring(0, index) : text;
    return truncatedText.toUpperCase();
  };

  function incop_includes(incop, value) {
    return incop.some((val) => val.toLowerCase() === value.toLowerCase());
  }

  const formateDate = (header, value) => {
    let dateHeader = [
      "Incorporation",
      "Accounts Year End",
      "Accounts Due By",
      "Accounts Last Made",
    ];

    let incop = [
      "Today",
      "Yesterday",
      "This Week",
      "Last 7 days",
      "Last week",
      "Next 7 Days",
      "Last 14 days",
      "Next 14 days",
      "This month",
      "Last 30 days",
      "Next 30 days",
      "Last month",
      "Tomorrow",
    ];
    if (dateHeader.includes(header) && !incop_includes(incop, value)) {
      const splitRange = value.split(" - ");

      // Check if the split resulted in two valid parts
      const firstPart = splitRange[0];
      const secondPart = splitRange[1];

      // You can optionally trim any leading or trailing whitespace
      const trimmedFirstPart = firstPart.trim();
      const trimmedSecondPart = secondPart.trim();

      return formatDateRange(trimmedFirstPart, trimmedSecondPart);
    }

    if (dateHeader.includes(header) && incop_includes(incop, value)) {
      return getDateRange(value);
    }
    return value;
  };

  useEffect(() => {
    const startValue = animatedValue;
    const endValue = totalCompaniesDocument;
    const duration = 300; // Duration of the animation in milliseconds
    const increment = (Math.abs(endValue - startValue) / duration) * 10;
    let currentValue = startValue;

    const interval = setInterval(() => {
      if (currentValue < endValue) {
        currentValue += increment;
        setAnimatedValue(Math.min(currentValue, endValue));
      } else if (currentValue > endValue) {
        currentValue -= increment;
        setAnimatedValue(Math.max(currentValue, endValue));
      } else {
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [totalCompaniesDocument]);

  return (
    <div className="ch_exp_applied_filter_group_main">
      <div className="ch_exp_applied_filter_group_main_filter_holder">
        {filterReadyToApply ? (
          filterReadyToApply.map((fitlerReady, index) => (
            <React.Fragment key={index}>
              {fitlerReady.filter.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "white",
                    padding: "5px",
                    margin: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "6px",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "5px",
                      textAlign: "start",
                      fontWeight: "500",
                      color: "gray",
                    }}
                  >
                    {fitlerReady.header}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      gap: "4px",
                    }}
                  >
                    {fitlerReady.filter.map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        style={{
                          display: "flex",
                          backgroundColor: "#305c8a",
                          color: "white",
                          position: "relative",
                          padding: "5px",
                          fontSize: "12px",
                          flexWrap: "wrap",
                          fontWeight: "500",
                          borderRadius: "10px",
                        }}
                        className="applied-filter-group-filter-item"
                      >
                        <div style={{ width: "100%" }}>
                          {truncateText(formateDate(fitlerReady.header, item))}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            color: "white",
                            cursor: "pointer",
                            backgroundColor: "black",
                            width: "20px",
                            height: "20px",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            top: "0",
                            right: "0",
                            visibility: "hidden",
                            position: "absolute",
                          }}
                          className="remove-button"
                          onClick={() =>
                            setRemoveFIlterItem({
                              key: fitlerReady?.header,
                              item: item,
                            })
                          }
                        >
                          x
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </React.Fragment>
          ))
        ) : (
          <div>NO FILTERS APPLIED</div>
        )}
      </div>
      <div className="corporate_explor_filter_modal_main_right_apply_filter">
        <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
          <NumberDisplay animatedValue={Math.round(animatedValue)} />{" "}
          <div style={{ fontWeight: "bold" }}>Companies</div>
        </div>
        <div
          className="corporate_explor_filter_modal_main_right_apply_filter_button"
          onClick={applyFilters}
        >
          Apply Filters
        </div>
      </div>
    </div>
  );
}

export default AppliedFilterGroup;
