import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./SearchName.css";

const marks = [
  {
    value: 0,
    label: "20",
  },
  {
    value: 15,
    label: "25",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 45,
    label: "35",
  },
  {
    value: 60,
    label: "40",
  },
  {
    value: 75,
    label: "45",
  },
  {
    value: 88,
    label: "50",
  },
  {
    value: 100,
    label: "55+",
  },
];
function SearchName() {
  const valuetext = React.useCallback((value) => {
    return `${value}°C`;
  });

  const valueLabelFormat = React.useCallback((value) => {
    return marks.findIndex((mark) => mark.value === value) + 1;
  });

  const [value, setValue] = React.useState([10, 80]);

  const handleChange = React.useCallback((event, newValue) => {
    setValue(newValue);
  });

  return (
    <div className="searchbar">
      <div className="search-name-div">
        <div className="label-text"> First Name :</div>{" "}
        <div className="input-div">
          <input placeholder="e.g. Adam" />
        </div>
      </div>
      <div className="search-name-div">
        <div className="label-text"> Surname : </div>{" "}
        <div className="input-div">
          <input placeholder="e.g. Adam" />
        </div>
      </div>
      <div className="search-name-div range-slider">
        <span className="age-label">Age:</span>{" "}
        <Box sx={{ width: 300 }}>
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={value}
            onChange={handleChange}
            getAriaValueText={valuetext}
            step={null}
            marks={marks}
          />
        </Box>
      </div>
    </div>
  );
}

export default SearchName;
