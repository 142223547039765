import React, { createContext, useContext, useState } from 'react';

const UserSetupContext = createContext();

export const useUserSetupContext = () => useContext(UserSetupContext);

export const UserSetupProvider = ({ children }) => {
  const [isUserSetup, setIsUserSetup] = useState(false);

  return (
    <UserSetupContext.Provider value={{ isUserSetup, setIsUserSetup }}>
      {children}
    </UserSetupContext.Provider>
  );
};
