import { uniqueViews } from '../api/outseta/outseta';
import { TrackGoogleAnalyticsEvent } from '../googleAnalytics/googleAnalytics';

export const triggerGoogleAnalyticsEvent = async (
  category,
  pageName,
  userId
) => {
  const res = await uniqueViews(pageName, userId);
  if (res.triggerEvent) {
    TrackGoogleAnalyticsEvent(category, pageName);
  }
};
