import React, { useEffect, useState } from 'react';
import './CELandBuilding.css';
import BusinessIcon from '@material-ui/icons/Business';
import { getCompanyProperty } from '../../../api/corporateExplorer/corporateExplorer';
import ProbeButton from '../../ProbeButton/ProbeButton';
import { openGoogleMaps } from '../../../utils/helper';

const CELandBuilding = ({ companyInfo }) => {
  const [properties, setProperties] = useState([]);
  const [totalProperties, setTotalProperties] = useState(0);

  useEffect(() => {
    console.log('companyInfo', companyInfo);
    const fetchTradeMarks = async () => {
      if (!companyInfo) return;
      try {
        const companyPropertyResponse = await getCompanyProperty(
          companyInfo?.company_name,
          200
        );
        setProperties(companyPropertyResponse?.hits);
        setTotalProperties(companyPropertyResponse?.total);
      } catch (error) {}
    };
    fetchTradeMarks();
  }, [companyInfo]);

  return (
    <div className="corporate-explorer-property-land-building-card">
      <div className="corporate-explorer-property-land-building-title">
        Land & Buildings
        <span className="corporate-explorer-property-land-building-count">
          {properties?.length}
        </span>
      </div>
      {properties && (
        <div className="corporate-explorer-property-land-building-striped-table">
          <table className="corporate-explorer-property-land-building-table">
            <tbody>
              <tr>
                <td>Address</td>
                <td>Tenure</td>
                <td>Date Added</td>
                <td></td>
              </tr>
              {Array.isArray(properties) &&
                properties.map((property, index) => (
                  <tr key={index}>
                    <td>
                      <BusinessIcon
                        fontSize="small"
                        className="corporate-explorer-property-land-building-building-icon"
                      />
                      {property?._source['Property Address']}
                    </td>
                    <td>{property?._source['Tenure']}</td>
                    <td>{property?._source['Date Proprietor Added']}</td>
                    <td>
                      <ProbeButton
                        width={'50px'}
                        fontSize={'10px'}
                        borderRadius={'20px'}
                        name={'Map'}
                        onClick={() =>
                          openGoogleMaps(property?._source['Property Address'])
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CELandBuilding;
