import React, { useEffect, useState } from "react";
import "./OptionsSideBar.css";
import OptionsSideBarItem from "./OptionsSideBarItem";

function OptionsSideBar({ filterData, setSelectedFilterData }) {
  const [open, setOpen] = useState(null);

  return (
    <div className="ch_options_sidebar">
      {filterData.map((item, index) => (
        <OptionsSideBarItem
          key={item?.title}
          item={item}
          open={open === item?.title}
          setOpen={setOpen}
          setSelectedFilterData={setSelectedFilterData}
        />
      ))}
    </div>
  );
}

export default OptionsSideBar;
