import React, { useEffect } from 'react';
import './CorporateExplorerProperty.css';
import CETradeMark from './CETradeMark/CETradeMark';
import CEDomainName from './CEDomainName/CEDomainName';
import CELandBuilding from './CELandBuilding/CELandBuilding';

const CorporateExplorerProperty = ({ companyInfo }) => {
  return (
    <div className="corporate-explorer-property-main-content">
      {/* CONTENT:Trade-Marks */}
      <CETradeMark companyInfo={companyInfo} />
      {/* CONTENT:Domain-Names */}
      <CEDomainName companyInfo={companyInfo} />
      {/* CONTENT:Land-Buildings */}
      <CELandBuilding companyInfo={companyInfo} />
    </div>
  );
};

export default CorporateExplorerProperty;
