import React from "react";

function Registers({ probe }) {
  return (
    <div className="mb-4">
      <div className="button-text mb-2">Register(s) to be searched</div>
      {probe?.selectedRegisters?.map((item, index) =>
        item?.data?.map((value, index) => {
          return (
            <div key={index} className="mb-2">
              <div className="body-text-heading">{value.title}</div>
              {/* <div>{value.content}</div> */}
              {Array.isArray(value?.content) ? (
                value?.content?.map((innerContent) => {
                  return <div>{innerContent}</div>;
                })
              ) : (
                <div>{value?.content}</div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
}

export default Registers;
