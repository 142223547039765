import axios from 'axios';
import { apiConfig, buildUrl } from '../apiConfig';

export const postJudgementCheckerInfo = async (data, showToast) => {
  let attempts = 0;
  const maxAttempts = 4;
  const retryDelay = 2000;

  // Array of different positive messages
  const positiveMessages = [
    'Fetching information from Judgement service...',
    'Processing Judgement service data...',
    'Requesting information from Judgement service...',
    'Awaiting response from Judgement service...',
  ];

  while (attempts < maxAttempts) {
    try {
      const response = await axios.post(
        buildUrl(
          apiConfig.baseDigital,
          apiConfig.judgementChecker.endpoints.postTrustonlineInfo
        ),
        { tonline: data, url: process.env.REACT_APP_JUDGMENT_CHECKER_ENDPOINT }
      );

      if (response.data.success) {
        return {
          success: true,
          data: response.data.data,
        };
      } else {
        return {
          success: false,
          response: 'Service is down',
          status: 408,
        };
      }
    } catch (error) {
      if (process.env.REACT_APP_ENV_NAME === 'testing') {
        let dummyPostJudCheckerInfoForTest =
          await dummyPostJudgementCheckerInfo();
        return {
          success: true,
          data: dummyPostJudCheckerInfoForTest?.data?.data?.data,
        };
      }

      attempts++;

      // Select a different positive message for each attempt
      const positiveMessage =
        positiveMessages[attempts % positiveMessages.length] ||
        'Service middleware call...';

      showToast('success', positiveMessage);

      if (attempts === maxAttempts) {
        return {
          success: false,
          response: error?.response?.data,
          status: error?.response?.status,
        };
      }

      await new Promise(resolve => setTimeout(resolve, retryDelay));
    }
  }
};

export const dummyPostJudgementCheckerInfo = async () => {
  try {
    const response = await axios.get(
      buildUrl(apiConfig.base, apiConfig.helper.endpoints.dummyTrustonline)
    );

    if (response.data) {
      return {
        success: true,
        data: response.data,
      };
    }
  } catch (error) {
    return {
      success: false,
      response: error?.response?.data,
      status: error?.response?.status,
    };
  }
};

export const getJudgementAddresses = async data => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.judgementChecker.endpoints.getAddresses,
        { postcode: data }
      )
    );
    return {
      success: true,
      data: response.data.addresses,
    };
  } catch (error) {
    return {
      success: false,
      response: error?.response?.data,
      status: error?.response?.status,
    };
  }
};

export const postJudgementCheckerPdfUpload = async (
  userId,
  pdfData,
  saveJC
) => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.judgementChecker.endpoints.saveJudgmentcheckerPdf
      ),
      { userId: userId, pdfData: pdfData, judgementDetail: saveJC }
    );
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      response: error?.response?.data,
      status: error?.response?.status,
    };
  }
};

export const getJudgementCheckerReports = async (
  userId,
  page = 1,
  Pagelimit = 5
) => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.judgementChecker.endpoints.getUserReports
      ),
      {
        params: {
          userId: userId,
          page: page,
          limit: Pagelimit,
        },
      }
    );

    // Destructuring the response data
    const { currentPage, limit, reports, totalPages, totalReports, success } =
      response.data;
    return {
      success: success,
      data: reports,
      currentPage: currentPage,
      limit: limit,
      totalPages: totalPages,
      totalReports: totalReports,
    };
  } catch (error) {
    if (error.status === 429 && error.isDuplicate) {
      return {
        success: false,
        response: error?.message,
        status: error.status,
      };
    }
    return {
      success: false,
      response: error?.response?.data,
      status: error?.response?.status,
    };
  }
};

export const downloadReport = async fileUrl => {
  try {
    // Make a GET request to the download_file endpoint with the fileUrl as a query parameter
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.judgementChecker.endpoints.downloadReportFile
      ),
      {
        params: { fileUrl: fileUrl },
        responseType: 'blob', // Set the responseType to 'blob' to handle binary data
      }
    );

    // Check if the response is successful (status code 200)
    if (response.status === 200) {
      // Create a Blob object from the response data
      const blob = new Blob([response.data], { type: 'application/pdf' }); // You can set the appropriate content type

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create an <a> element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'JudgmentReport.pdf'; // Set the desired filename

      // Trigger the download by simulating a click on the <a> element
      link.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(url);

      return { success: true };
    } else {
      return { success: false, error: 'Failed to download the file' };
    }
  } catch (error) {
    return { success: false, error: 'Error downloading the file' };
  }
};

export const createCheckoutSession = async (
  successUrl,
  cancelUrl,
  judgementChecker,
  userId
) => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.judgementChecker.endpoints.createCheckoutSession
      ),
      {
        success_url: successUrl,
        cancel_url: cancelUrl,
        user_id: userId,
        judgement_checker: judgementChecker,
      }
    );

    // Handle the response from the server here
    return {
      success: true,
      url: response.data.session.url,
      session: response.data.session,
    };
  } catch (error) {
    // Handle any errors here
    return {
      success: false,
      error: error.response?.data?.error || error.message,
      status: error.response?.status,
    };
  }
};

export const getPaymentSessionStatus = async (sessionId, userId) => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.judgementChecker.endpoints.paymentSessionStatus
      ),
      {
        params: {
          session_id: sessionId,
          user_id: userId,
        },
      }
    );

    return {
      success: true,
      data: response.data.judgement_checker,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const processRefund = async (sessionId, userId) => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.judgementChecker.endpoints.processRefund
      ),
      {
        session_id: sessionId,
        user_id: userId,
      }
    );

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const generatePdf = async data => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.judgementChecker.endpoints.JudgmentCheckerPdfGenerate
      ),
      data,
      {
        responseType: 'blob',
      }
    );

    // Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: 'application/pdf' });

    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    // Handle the response from the server here
    return {
      success: true,
      pdfUrl: fileURL,
      file: file,
    };
  } catch (error) {
    // Handle any errors here
    return {
      success: false,
      error: error.response?.data?.error || error.message,
      status: error.response?.status,
    };
  }
};

export const raiseNotification = async notificationData => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.judgementChecker.endpoints.raiseNotification
      ),
      notificationData
    );

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const sendEmail = async (
  guestUserInformation,
  pdfUrl,
  uniqueReferenceNumber
) => {
  try {
    let signedUrl = await getReportSignedUrl(pdfUrl);

    if (!signedUrl.success) {
      return {
        success: false,
        error: 'Not able to signed url',
      };
    }
    const emailData = {
      from: process.env.REACT_APP_FROM,
      to: guestUserInformation?.guestUserInfo.emailaddress,
      subject: process.env.REACT_APP_SUBJECT,
      host: process.env.REACT_APP_HOST,
      port: process.env.REACT_APP_PORT,
      secure: process.env.REACT_APP_SECURE,
      client_email: process.env.REACT_APP_CLIENT_EMAIL,
      client_password: process.env.REACT_APP_CLIENT_PASSWORD,
      user_name: guestUserInformation?.guestUserInfo?.surname,
      order_id: uniqueReferenceNumber,
      order_amount: process.env.REACT_APP_ORDER_AMOUNT,
      pdf_url: signedUrl?.url,
    };

    const response = await axios.post(
      buildUrl(apiConfig.base, apiConfig.helper.endpoints.sendEmail),
      emailData
    );

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const getReportSignedUrl = async pdfUrl => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.judgementChecker.endpoints.getReportSignedUrl
      ),
      { url: pdfUrl }
    );

    return {
      success: true,
      url: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response ? error.response.data : error.message,
    };
  }
};
