import React from "react";
import overViewIcon from "../../../assets/images/OverView.png";
import userPeopleAndContact from "../../../assets/images/userpeopleandContact.png";
import Financials from "../../../assets/images/Financials.png";
import control from "../../../assets/images/control.png";
import creditAndRisk from "../../../assets/images/creditAndRisk.png";
import Competition from "../../../assets/images/Competition.png";
import property from "../../../assets/images/property.png";
import document from "../../../assets/images/document.png";
import "./CorporateExploreInsightSidebar.css";

// Functional component
const CorporateExploreInsightSidebarHorizental = ({
  selectedCompany,
  onSelectMenuItem,
}) => {
  const handleMouseEnter = () => {};

  const handleMouseLeave = () => {};

  const handleTabClick = (tabLabel) => {
    onSelectMenuItem(tabLabel);
  };

  return (
    <div className="col-lg-12 corporate-explore-insight-sidebar corporate-explore-insight-sidebar-mobile">
     
      <div className="corporate-explore-insight-companyName">
        <a href="#" style={{ color: "#000" }}>
          {selectedCompany?.title}
        </a>
      </div>
      <div className="corporate-explore-insight-companyAddress">
        {selectedCompany?.address}
      </div>

      {/* Horizontal Tabs */}
      <ul className="nav nav-tabs">
        {menuItems.map((item) => (
          <li className="nav-item" key={item.label}>
            <a
              className="nav-link"
              onClick={() => handleTabClick(item.label)}
              style={{ cursor: "pointer" }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={item.icon}
                alt={`${item.label}-icon`}
                style={{
                  width: "15px",
                  height: "auto",
                  borderRadius: "5px",
                  marginRight: "8px",
                }}
              />
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

// Menu items configuration
const menuItems = [
  {
    key: "overview",
    icon: overViewIcon,
    label: "Overview",
  },
  {
    key: "people",
    icon: userPeopleAndContact,
    label: "People & Contacts",
  },
  {
    key: "financials",
    icon: Financials,
    label: "Financials",
  },
  {
    key: "control",
    icon: control,
    label: "Control & Ownership",
  },
  {
    key: "creditRisk",
    icon: creditAndRisk,
    label: "Credit Risk",
  },
  {
    key: "competition",
    icon: Competition,
    label: "Competition",
  },
  {
    key: "property",
    icon: property,
    label: "Property",
  },
  {
    key: "documents",
    icon: document,
    label: "Documents",
  },
  {
    key: "shareholders",
    icon: userPeopleAndContact,
    label: "Shareholders",
  },
];

export default CorporateExploreInsightSidebarHorizental;
