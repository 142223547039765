import React from "react";
import LineHeader from "../assets/images/icons/header-line.svg";
import EditIcon from "../assets/images/icons/edit-icon.svg";
import CompanyIcon from "../assets/images/icons/Organization.svg";
import ChargesIcon from "../assets/images/icons/charges.svg";
import YesIcon from "../assets/images/icons/yes-tag.svg";
import NoIcon from "../assets/images/icons/no-tag.svg";
import SuspectedIcon from "../assets/images/icons/suspectedIcon.svg";
import "./LegalInfo.css";

function LegalInfo() {
  return (
    <div className="legal-info-section">
      <div className="header-section">
        <div className="body-text-normal light-text-color">
          <img src={LineHeader} alt="icon here" /> Legal Info
        </div>
        <div>
          <div className="dropdown">
            <button
              className="btn"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={EditIcon} alt="icon here" />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-custom">
          <thead>
            <tr>
              <td scope="col">
                <div className="thead-td-custom">
                  <img
                    className="img-thead"
                    src={CompanyIcon}
                    alt="icon here"
                  />{" "}
                  Legal<span style={{ color: "transparent" }}>.</span>Orders{" "}
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  <img
                    className="img-thead"
                    src={ChargesIcon}
                    alt="icon here"
                  />{" "}
                  Charges
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="body-text-bold">County Court Judgements (CCJ)</td>

              <td>
                <div className="yes-tag">
                  <img src={YesIcon} alt="icon here" /> Clear
                </div>
              </td>
            </tr>
            <tr>
              <td className="body-text-bold">Consent Judgement</td>

              <td>
                <div className="yes-tag">
                  <img src={YesIcon} alt="icon here" /> Clear
                </div>
              </td>
            </tr>

            <tr>
              <td className="body-text-bold">Declaratory Judgement </td>

              <td>
                <div className="no-tag">
                  <img src={NoIcon} alt="icon here" /> Charged
                </div>
              </td>
            </tr>
            <tr>
              <td className="body-text-bold">Interlocutory Judgement</td>

              <td>
                <div className="suspected-tag">
                  <img src={SuspectedIcon} alt="icon here" /> Suspected
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LegalInfo;
