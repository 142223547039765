import React, { useState, useEffect } from "react";
import "../JudgementChecker/CheckReviewPerson/SearchOnPerson/SearchOnPerson.css";
import ServicesTable from "../../components/ServicesTable";
import CustomerTable from "../../components/CustomerTable";
import HeaderDropdown from "../../components/HeaderDropdownNative";
import { getAllUsers } from "../../api/outseta/outseta"; // Update this path
import { useLoading } from "../../context/LoadingContext/LoadingContext";
import Pagination from "../../components/Pagination/Pagination";
import TotalCustomer from "../../components/TotalCustomers/TotalCustomer";
import { debounce, getCurrentMonth } from "../../utils/helper";
import JudgementCheckerReports from "../JudgementChecker/JudgmentCheckerReports/JudgementCheckerReports";

function CustomerAdminScreen() {
  // State to store users
  const { setIsLoading } = useLoading();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState([]);
  const [customerDetail, setCustomerDetail] = useState(null);
  const [isSearchInProgress, setIsSearchInProgress] = useState(false); // You can use this to show a loading indicator
  const [apiCallsEver, setApiCallsEver] = useState();
  const [apiCallsCurrentMonth, setApiCallsCurrentMonth] = useState();

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const onSearchChange = async (value) => {
    await fetchUsers(currentPage, 5, value);
    setIsSearchInProgress(false);
  };

  const debouncedSearch = debounce((event) => {
    setIsSearchInProgress(true);
    onSearchChange(event);
  }, 500); // Waits for 500ms after typing stops

  const customersDetailClick = (customersDetail) => {
    setCustomerDetail(customersDetail);
  };

  const fetchUsers = async (currentPage, limit = 5, search = "") => {
    try {
      setIsLoading(true);
      const response = await getAllUsers(currentPage, limit, search);
      if (response.success) {
        setCustomers(response.data);
        setTotalPages(response.totalPages);
        setTotalCustomers(response.totalCustomers);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      await fetchUsers(currentPage);
    };

    apiCall();
  }, [currentPage]);

  useEffect(() => {
    let month = getCurrentMonth();
    const usage = customerDetail?.FirebaseUser?.usage;

    if (usage) {
      setApiCallsCurrentMonth(
        usage?.companiesHouse_api_usage?.monthly[month] +
          usage?.openCorporates_api_usage?.monthly[month]
      );
      setApiCallsEver(
        usage?.companiesHouse_api_usage?.total_usage +
          usage?.openCorporates_api_usage?.total_usage
      );
    } else {
      setApiCallsCurrentMonth(0);
      setApiCallsEver(0);
    }
  }, [customerDetail]);

  return (
    <>
      <div className="dashboard-section">
        <div className="main-div">
          <div className="header">
            <div className="header-heading dark-heading-color">
              Customer Admin Screen
            </div>
            <HeaderDropdown />
          </div>
          <div className="dashboard-content-div">
            {/* total customers */}
            <TotalCustomer customers={totalCustomers} />
            {/* Pass the users to your CustomerTable, or use it as needed */}
            <CustomerTable
              customers={customers}
              customersDetailClick={customersDetailClick}
              debouncedSearch={debouncedSearch}
              isSearchInProgress={isSearchInProgress}
            />

            <ServicesTable
              customerDetail={customerDetail}
              apiCallsEver={apiCallsEver}
              apiCallsCurrentMonth={apiCallsCurrentMonth}
            />

            {/* pagination component  */}
            <div style={{ marginTop: "25px" }}></div>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
            {customerDetail && (
              <JudgementCheckerReports
                showHeader={false}
                userId={customerDetail?.PrimaryContact?.Uid}
                removePadding={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerAdminScreen;
