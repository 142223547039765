import React, { useEffect, useState } from 'react';
import './CorporateExploreInsight.css';
import eyeIcon from '../../assets/images/password-icon.png';
import HeaderDropdown from '../../components/HeaderDropdownNative';
import CEIKeyData from '../../components/CorporateExploreInsights/CEIKeyData/CEIKeyData';
import CEIFinancial from '../../components/CorporateExploreInsights/CEIFinancials/CEIFinancials';
import SearchSuggestions from '../../components/SearchSuggestions/SearchSuggestions';
import CIEContact from '../../components/CorporateExploreInsights/CIEContact/CIEContact';
import CIEDocument from '../../components/CorporateExploreInsights/CIEDocument/CIEDocument';
import CEIPeople from '../../components/CorporateExploreInsights/CEIPeople/CEIPeople';
import CEILatestActivity from '../../components/CorporateExploreInsights/CEILatestActivity/CEILatestActivity';
import CEIDisableAndSignificantControl from '../../components/CorporateExploreInsights/CEIDisableAndSignificantControl/CEIDisableAndSignificantControl';
import WatchList from '../../components/CorporateExploreInsights/CEIWatchList/CEIWatchList';
import CEIShareholder from '../../components/CorporateExploreInsights/CEIShareholder/CEIShareholder';
import CorporateExploreInsightSidebar from '../../components/CorporateExploreInsights/CorporateExploreInsightSidebar/CorporateExploreInsightSidebar';
import CorporateExploreInsightSidebarHorizental from '../../components/CorporateExploreInsights/CorporateExploreInsightSidebar/CorporateExplorerInsightSideBarHorizental';
import CorporateExploreInsightOverview from './CorporateExploreInsightOverview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import CEIPersonInsightsLatestOverview from './CEIPersonInsightsLatest/CEIPersonInsightsLatestOverview';

export default function CorporateExploreInsight() {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [accountInfo, setAccountsInfo] = useState([]);
  const [selectedView, setSelectedView] = useState('Overview');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [staticHolder, setStaticHolder] = useState([
    'Overview',
    'People & Contacts',
    'Financials',
    'Control & Ownership',
    'Credit Risk',
    'Competition',
    'Property',
    'Documents',
    'Shareholders',
  ]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className="header">
          <div className="header-heading dark-heading-color">
            Corporate Explorer Insight
          </div>
          <HeaderDropdown />
        </div>

        <div className="corporate-explore-search-insight-main">
          <div className="Corporate-explore-search-insight-title">Insight</div>
          <SearchSuggestions
            setSelectedCompany={setSelectedCompany}
            setAccountsInfo={setAccountsInfo}
          />
          <div className="corporate-explore-insight-watch-list">
            <span>
              <FontAwesomeIcon
                icon={faEye}
                style={{ color: '#fff', width: '25px', borderRadius: '5px' }}
              />
            </span>
            <div className="corporate-explore-insight-watch-list-text">
              Watch List
            </div>
          </div>
          <button className="upgrade-button">UPGRADE</button>
        </div>

        <div className="Corporate-explore-insight-main">
          <div className="corporate-explorer-insights-main-row">
            {/* Desktop view */}
            {windowWidth >= 1200 && selectedCompany && (
              <div style={{ display: 'flex' }}>
                <CorporateExploreInsightSidebar
                  selectedCompany={selectedCompany}
                  onSelectMenuItem={setSelectedView}
                />
                <div className="corporate-explore-content">
                  {staticHolder.includes(selectedView) && (
                    <CorporateExploreInsightOverview
                      selectedCompany={selectedCompany}
                      accountInfo={accountInfo}
                    />
                  )}
                </div>
              </div>
            )}
            {/* Desktop view */}

            {/* mobile view */}
            {windowWidth < 1200 && selectedCompany && (
              <div style={{ backgroundColor: 'white' }}>
                <CorporateExploreInsightSidebarHorizental
                  selectedCompany={selectedCompany}
                  onSelectMenuItem={setSelectedView}
                />
                <div className="corporate-explore-content">
                  {staticHolder.includes(selectedView) && (
                    <CorporateExploreInsightOverview
                      selectedCompany={selectedCompany}
                      accountInfo={accountInfo}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
