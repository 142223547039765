import React from "react";
import LineHeader from "../../assets/images/icons/header-line.svg";
import "./TotalCustomer.css";

function TotalCustomer({ customers }) {
  return (
    <div className="totalCustomer-info-section">
      <div className="header-section">
        <div className="body-text-normal dark-text-color">
          <img src={LineHeader} alt="icon here" /> Total Customers
        </div>
        {customers}
      </div>
    </div>
  );
}

export default TotalCustomer;
