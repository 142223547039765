import React, { useState, useContext } from 'react';
import LogoIcon from '../../assets/images/icons/Logo-colored.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AppContext } from '../../context/Context';
import './additional.css';
import './FeedbackForm.css';
import ProbeButton from '../../components/ProbeButton/ProbeButton';
import HeaderDropdown from '../../components/HeaderDropdownNative';
import { submitFeedback } from '../../api/outseta/outseta';
import showToast from '../../utils/showToast';

function FeedbackForm() {
  const { user } = useContext(AppContext);

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    priority: 'High',
    email: '',
    phone: '',
  });

  const setValue = (prop, val) => {
    formData[prop] = val;
    setFormData({ ...formData });
  };

  const onSubmit = async () => {
    if (
      formData.title === '' ||
      formData.description === '' ||
      formData.email === ''
    ) {
      showToast('error', 'please fill all required fields');
      return;
    }

    if (!user.Uid) {
      showToast('error', 'You need to login before submitting suggestion');
      return;
    }

    formData.uid = user.Uid;
    const result = await submitFeedback(formData);
    if (result.error)
      console.error(`error submitting feedback : ${result.error}`);
    if (result.success) showToast('success', result.message);
  };

  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className="header">
          <div className="header-heading dark-heading-color">
            Request a Feature
          </div>
          <HeaderDropdown />
        </div>
        <div>
          <section
            className="login-section feedback-section"
            style={{
              backgroundColor: '	#f4f4f4',
            }}
          >
            <div
              className="login-form-div feedback-form-div"
              style={{ backgroundColor: 'white' }}
            >
              <Link to="/home">
                <Link to="/SelectEntity">{/* <div>Back</div> */}</Link>
                <div className="text-center">
                  <img className="logo-login" src={LogoIcon} alt="icon here" />
                </div>
              </Link>

              <div className="heading-1 dark-heading-color first-heading">
                Request a Feature
              </div>

              <div className="mb-4 FeedbackForm-text">
                Get your idea to our team. We view and approve each feature
                request. Approve idea make their way onto our community roadmap
                where other users can vote.
              </div>

              <div className="input-outer-div">
                <div className="label-text FeedbackForm-title">
                  Give your suggestion a title*
                </div>
                <div className="input-div">
                  <input
                    className="input-login"
                    type="text"
                    placeholder="Enter title here"
                    value={formData.title}
                    onChange={e => {
                      setValue('title', e.target.value);
                    }}
                  />
                </div>
                <div className="label-text">
                  Write some detailed information on what you need and how you
                  would like this feature visually presented*
                </div>
                <div className="input-div">
                  <textarea
                    className="input-login"
                    rows="5"
                    placeholder="Write your details here"
                    onChange={e => {
                      setValue('description', e.target.value);
                    }}
                  >
                    {' '}
                  </textarea>
                </div>
                <div className="label-text">
                  How important is this feature to you?
                </div>
                <div className="input-div">
                  <select
                    className="input-login"
                    type="text"
                    placeholder="Enter title here"
                    onChange={e => {
                      setValue('priority', e.target.value);
                    }}
                  >
                    <option>High</option>
                    <option>Medium</option>
                    <option>Low</option>
                  </select>
                </div>

                <div className="parallel-div mt-2">
                  <div className="flex-grow-1">
                    <div className="label-text FeedbackForm-email ">Email*</div>
                    <div className="input-div">
                      <input
                        className="input-login"
                        type="text"
                        placeholder="Enter email address here"
                        onChange={e => {
                          setValue('email', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="label-text">Phone</div>
                    <div className="input-div">
                      <input
                        className="input-login"
                        type="text"
                        placeholder="Enter phone number here"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="FeedbackForm-submit-info-btn">
                <ProbeButton
                  name={'Submit Information'}
                  backgroundColor={'#0466D1'}
                  borderRadius={'10px'}
                  borderColor={'#blue'}
                  height={'40px'}
                  width={'237px'}
                  onClick={onSubmit}
                  fontColor="#FFF"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default FeedbackForm;
