import { useEffect, useState, createContext, useContext, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getGuestUserInfo } from '../utils/helper';
import { useUserSetupContext } from './UserLoadingContext/UserLoadingContext';
import Home from '../pages/Home/Home';
import LocalStorageService from '../utils/LocalStorageServices';
import { AppContext } from './Context';
import { getUserById, postToOutsetaUser } from '../api/outseta/outseta';

const AuthContext = createContext({});

export function useAuth() {
  return useContext(AuthContext);
}

// Outseta is added to the window by the
// Outseta script added to the head in ../public/index.html
function getOutseta() {
  if (window['Outseta']) {
    return window['Outseta'];
  } else {
    throw new Error('Outseta is missing, have you added the script to head?');
  }
}

export default function AuthProvider({ children, unprotectedRoutes }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState('init');
  const { user, setUser } = useContext(AppContext);
  const [, setOpenProfileCheck] = useState(false);
  const navigate = useNavigate();
  const [childrenHolder, setChildrenHolder] = useState(null);
  // Save a reference to Outseta
  const outsetaRef = useRef(getOutseta());
  const { setIsUserSetup } = useUserSetupContext();

  const getCurrentOutSetaUser = async () => {
    try {
      let outsetaUser = await outsetaRef.current.getUser();
      return outsetaUser;
    } catch (error) {}
  };

  const updateUser = async () => {
    // Fetch the current user data from outseta
    const outsetaUser = await getCurrentOutSetaUser();
    // Update user state
    if (user) {
      if (user.FirebaseUser) {
        setUser({
          ...outsetaUser, // This spreads all properties from outsetaUser into the new object
          FirebaseUser: user.FirebaseUser, // This ensures the FirebaseUser property is preserved from the original user
        });
      } else {
        let userResponse = await getUserById(outsetaUser.Uid);
        if (userResponse && userResponse.success) {
          setUser({
            ...outsetaUser, // This spreads all properties from outsetaUser into the new object
            FirebaseUser: userResponse.data, // This ensures the FirebaseUser property is preserved from the original user
          });
        }
      }
    } else {
      let userResponse = await getUserById(outsetaUser.Uid);

      if (userResponse && userResponse.success) {
        setUser({
          ...outsetaUser, // This spreads all properties from outsetaUser into the new object
          FirebaseUser: userResponse.data, // This ensures the FirebaseUser property is preserved from the original user
        });
      } else {
        // while signup if no use found and outseta user exits create that user into database.

        if (
          userResponse &&
          userResponse.response?.status === 404 &&
          outsetaUser
        ) {
          const newUserData = {
            user_id: outsetaUser.Uid,
            user_email: outsetaUser.Email,
          };
          await postToOutsetaUser(newUserData);
          const userResponse = await getUserById(outsetaUser.Uid);
          let updatedUser = { ...outsetaUser, FirebaseUser: userResponse.data };
          setUser(updatedUser);
        }
      }
    }
    setStatus('ready');
  };

  const handleOutsetaUserEvents = onEvent => {
    // Subscribe to user related events
    // with onEvent function
    const outseta = outsetaRef.current;
    outseta.on('subscription.update', onEvent);
    outseta.on('profile.update', onEvent);
    outseta.on('account.update', onEvent);
  };

  const logout = () => {
    outsetaRef.current.setAccessToken('');
    setUser(null);
    LocalStorageService.clearLocalStorage();
    navigate('/');
  };

  const openLogin = options => {
    let nav_path = LocalStorageService.getItem('navigation-path');
    if (nav_path) {
      LocalStorageService.removeItem('navigation-path');
    }
    let path = nav_path ? nav_path : '/ccj-orders-fines';
    outsetaRef.current.auth.open({
      widgetMode: 'login|register',
      authenticationCallbackUrl: window.location.origin + path,
      ...options,
    });
  };

  const openSignup = options => {
    outsetaRef.current.auth.open({
      widgetMode: 'register',
      // authenticationCallbackUrl: window.location.href,
      ...options,
    });
  };

  const openProfile = options => {
    setOpenProfileCheck(true);
    outsetaRef.current.profile.open({ tab: 'profile', ...options });
  };

  useEffect(() => {
    handleOutsetaUserEvents(updateUser);

    const accessToken = searchParams.get('access_token');

    if (accessToken) {
      outsetaRef.current.setAccessToken(accessToken);
      setSearchParams({});
    }

    if (outsetaRef.current.getAccessToken()) {
      updateUser();
    } else {
      setStatus('ready');
    }

    return () => {
      handleOutsetaUserEvents(() => {});
    };
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (status === 'ready' && user && children) {
      setChildrenHolder(children);
    } else {
      if (status === 'ready' && !user) {
        if (unprotectedRoutes.includes(currentPath)) {
          setChildrenHolder(children);
          setUser(getGuestUserInfo());
        } else {
          setChildrenHolder(<Home />);
        }
      }
    }
    setIsUserSetup(status === 'init' ? true : false);
  }, [status, user]);

  return (
    <AuthContext.Provider
      value={{
        logout,
        openLogin,
        openSignup,
        openProfile,
        status,
      }}
    >
      {childrenHolder}
    </AuthContext.Provider>
  );
}
