import React, { useEffect, useState } from 'react';
import CEIPersonalInsightsCommon from '../../../components/CorporateExploreInsights/CEIPersonInsights/CEIPersonalInsightsCommon/CEIPersonalInsightsCommon';
import CEIPersonInsightsHeader from '../../../components/CorporateExploreInsights/CEIPersonInsights/CEIPersonInsightsHeader/CEIPersonInsightsHeader';
import './CEIPersonInsights.css';
const CEIPersonInsights = ({ selectedOfficer }) => {
  return (
    <div className="cororate-explore-insight-person-main">
      <div className="cororate-explore-insight-person-main-content ">
        {' '}
        <CEIPersonInsightsHeader personInsights={selectedOfficer} />
        <CEIPersonalInsightsCommon personInsights={selectedOfficer} />
      </div>
    </div>
  );
};

export default CEIPersonInsights;
