import "./ReportModal.css";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
const ReportModal = ({ showModal, setShowModal, pdfUrl }) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      contentClassName="modal-height"
    >
      <Modal.Header closeButton>
        <Modal.Title>PDF Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          src={pdfUrl}
          width="100%"
          height="100%"
          title="report-pdf"
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default ReportModal;
