import { useEffect, useState } from 'react';
import './CompanyInfoModal.css';
import Modal from 'react-bootstrap/Modal';
import BusinessIcon from '@material-ui/icons/Business';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PeopleIcon from '@material-ui/icons/People';
import EventIcon from '@material-ui/icons/Event';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import TodayIcon from '@material-ui/icons/Today';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import WorkIcon from '@material-ui/icons/Work';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import TableLoadingScreen from '../../LoadingScreen/TableLoadingScreen/TableLoadingScreen';
import RoomIcon from '@material-ui/icons/Room';
import PublicIcon from '@material-ui/icons/Public';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';

import {
  corporateExplorerOfficerInfo,
  corporateExplorerShareholders,
} from '../../../api/corporateExplorer/corporateExplorer';
import {
  debounce,
  processCompanyName,
  processRegisteredOfficeAddress,
  statusDiv,
} from '../../../utils/helper';
import PeopleAndContacts from './PeopleAndContacts/PeopleAndContacts';
import Shareholders from './Shareholders/Shareholders';
import CorporateExplorerProperty from '../../CorporateExplorerProperty/CorporateExplorerProperty';

function CompanyInfoModal({
  showCompanyInfoModal,
  setShowCompanyInfoModal,
  companyInfo,
  selectedCompanyInfo,
}) {
  const [key, setKey] = useState('');
  const [shareholders, setShareholders] = useState([]);
  const [
    shareholdersBackeupForSearchFilter,
    setShareholdersBackeupForSearchFilter,
  ] = useState([]);
  const [companyOfficersInfo, setCompanyOfficersInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formatDate = dateStr => {
    if (!dateStr) return;

    try {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(
        dateStr?.split('/')?.reverse()?.join('-')
      ).toLocaleDateString('en-GB', options);
    } catch (error) {
      return null;
    }
  };

  const generateStatementDivs = data => {
    if (data) {
      try {
        const {
          last_made_up_to = null,
          next_due = null,
          next_made_up_to = null,
        } = data;

        const formattedNextMadeUpTo = next_made_up_to
          ? formatDate(next_made_up_to)
          : null;
        const formattedNextDue = next_due ? formatDate(next_due) : null;
        const formattedLastMadeUpTo = last_made_up_to
          ? formatDate(last_made_up_to)
          : null;

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              Next statement date {formattedNextMadeUpTo} due by
              <span style={{ marginLeft: '4px' }}>{formattedNextDue}</span>
            </div>
            <div>Last statement dated {formattedLastMadeUpTo}</div>
          </div>
        );
      } catch (error) {
        console.error('Error formatting date:', error);

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              Next statement date {data?.next_made_up_to} due by
              <span style={{ marginLeft: '4px' }}>{data?.next_due}</span>
            </div>
            <div>Last statement dated {data?.last_made_up_to}</div>
          </div>
        );
      }
    }
  };

  const generateAccountDivs = data => {
    if (data) {
      try {
        const {
          accounting_reference_date = null,
          last_accounts = null,
          next_accounts = null,
        } = data;

        const formattedLastMadeUpTo = last_accounts
          ? formatDate(last_accounts?.made_up_to)
          : null;
        const formattedNextDue = next_accounts
          ? formatDate(next_accounts?.due_on)
          : null;

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {accounting_reference_date && (
              <div>
                Accounting reference date: {accounting_reference_date.day}{' '}
                {new Date(
                  accounting_reference_date.month + ' 1, 2024'
                ).toLocaleString('default', { month: 'long' })}{' '}
                {new Date().getFullYear()}
              </div>
            )}
            {formattedNextDue && (
              <div>
                Next accounts made up to {formattedNextDue} due by{' '}
                {accounting_reference_date?.day}{' '}
                {new Date(
                  accounting_reference_date?.month + ' 1, 2024'
                ).toLocaleString('default', { month: 'long' })}{' '}
                {new Date().getFullYear()}
              </div>
            )}
            {formattedLastMadeUpTo && (
              <div>
                Last accounts made up to {formattedLastMadeUpTo} (
                {last_accounts?.type})
              </div>
            )}
          </div>
        );
      } catch (error) {
        console.error('Error generating account divs:', error);
      }
    }
  };

  const classification = data => {
    if (!data || !data['Sic Codes']?.length > 0) return <div></div>;

    return (
      <div style={{ color: '#5c5c5c' }}>
        {data['Sic Codes']?.map((item, index) => (
          <div key={index}>
            {item?.charAt(0)?.toUpperCase() + item?.slice(1)}
          </div>
        ))}
      </div>
    );
  };

  const formatPeopleList = people => {
    if (!Array.isArray(people) || people.length === 0) {
      return 'UnReported';
    }

    // Get the first two people
    const firstTwoPeople = people.slice(0, 2).map(person => person?.name);

    // Check if there are more people beyond the first two
    const additionalPeopleCount = people.length - 2;

    // Construct the formatted string
    let formattedString = firstTwoPeople.join(', ');
    if (additionalPeopleCount > 0) {
      formattedString += ` & ${additionalPeopleCount} more`;
    }

    return formattedString;
  };

  const formatStatusCode = rowData => {
    const status = rowData?.company_status?.toLowerCase();

    return statusDiv(status);
  };

  const debouncedSearch = debounce(event => {
    if (event === '') {
      setShareholders(shareholdersBackeupForSearchFilter);
      return;
    }

    let filteredShareHolders = filterPeopleByQuery(
      event,
      shareholdersBackeupForSearchFilter
    );
    setShareholders(filteredShareHolders);
  }, 500);

  const renderContent = () => {
    switch (key) {
      case 'overview':
        return (
          <div className="company-info-modal-overview-main company-info-body-scroller">
            <div className="company-info-modal-overview-title">Overview</div>
            <div className="company-info-modal-overview-contant">
              <div className="company-info-modal-overview-contant-one">
                <div className="company-info-modal-overview-key">
                  <BusinessIcon fontSize="small" />
                  <span>Company Name</span>
                </div>
              </div>
              <div className="company-info-modal-overview-value">
                <span className="company-name">
                  {' '}
                  {processCompanyName(companyInfo?.company_name)}
                </span>
                <div>
                  <a
                    href={`https://www.linkedin.com/search/results/companies/?keywords=${companyInfo?.company_name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                </div>
                <div>
                  <a
                    href={`https://www.google.com/search?q=${companyInfo?.company_name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LanguageIcon />
                  </a>
                </div>
              </div>
              <div className="company-info-modal-overview-contant-one">
                <div className="company-info-modal-overview-key ">
                  <CheckCircleOutlineIcon fontSize="small" />
                  Status
                </div>
                <div className="company-info-modal-overview-active-btn">
                  {formatStatusCode(companyInfo)}
                </div>
              </div>
              <div className="company-info-modal-overview-contant-one">
                <div className="company-info-modal-overview-key">
                  <EventIcon fontSize="small" />
                  Incorporated
                </div>
                <div className="company-info-modal-overview-value">
                  {companyInfo?.date_of_creation}
                </div>
              </div>
              <div className="company-info-modal-overview-contant-one">
                <div className="company-info-modal-overview-key">
                  <PeopleIcon fontSize="small" />
                  People
                </div>
                <div className="company-info-modal-overview-value">
                  {formatPeopleList(companyOfficersInfo)}
                </div>
              </div>
              <div className="company-info-modal-overview-contant-one">
                <div className="company-info-modal-overview-key">
                  <EventAvailableIcon fontSize="small" />
                  Confirmation
                </div>
                <div className="company-info-modal-overview-value">
                  {generateStatementDivs(companyInfo?.confirmation_statement)}
                </div>
              </div>
              <div className="company-info-modal-overview-contant-one">
                <div className="company-info-modal-overview-key">
                  {' '}
                  <TodayIcon fontSize="small" />
                  Accounts
                </div>
                <div className="company-info-modal-overview-value">
                  {generateAccountDivs(companyInfo?.accounts)}
                </div>
              </div>
              <div className="company-info-modal-overview-contant-one">
                <div className="company-info-modal-overview-key">
                  <FolderOpenIcon fontSize="small" />
                  Classification
                </div>
                <div className="company-info-modal-overview-value">
                  {classification(selectedCompanyInfo)}
                </div>
              </div>
              <div className="company-info-modal-overview-contant-one">
                <div className="company-info-modal-overview-key">
                  {' '}
                  <WorkIcon fontSize="small" />
                  Activity
                </div>
                <div className="company-info-modal-overview-value">Phase 2</div>
              </div>
              <div className="company-info-modal-overview-contant-one">
                <div className="company-info-modal-overview-key">
                  <EmojiTransportationIcon fontSize="small" />
                  SME Size
                </div>
                <div className="company-info-modal-overview-value">Phase 2</div>
              </div>
            </div>
          </div>
        );
      case 'people':
        return (
          <div className="company-info-body-scroller">
            <div className="company-info-modal-people-contant-main">
              <div className="company-info-modal-overview-main">
                <div className="company-info-modal-overview-title">Contact</div>
                <div className="company-info-body-people-contact">
                  <div className="company-info-body-people-contact-contant">
                    <div className="company-info-modal-overview-key">
                      <EventIcon fontSize="small" />
                      Company Name
                    </div>
                    <div className="company-info-modal-overview-value1">
                      {processCompanyName(companyInfo?.company_name)}
                    </div>
                    <div className="company-info-modal-overview-key">
                      <RoomIcon fontSize="small" />
                      Registered Address
                    </div>
                    <div className="company-info-modal-overview-value1 company-address">
                      {processRegisteredOfficeAddress(companyInfo)}
                    </div>
                    <div className="company-info-modal-overview-key">
                      <PublicIcon fontSize="small" />
                      Country Origin
                    </div>
                    <div className="company-info-modal-overview-value1">
                      {companyInfo?.registered_office_address?.country}
                    </div>
                  </div>
                  <div className="company-info-body-people-contact-contant">
                    <div className="company-info-modal-overview-key">
                      <PhoneIcon fontSize="small" />
                      Telephone
                    </div>
                    <div className="company-info-modal-overview-value1">
                      Phase 2
                    </div>
                    <div className="company-info-modal-overview-key">
                      <EmailIcon fontSize="small" />
                      Email Address
                    </div>
                    <div className="company-info-modal-overview-value1">
                      Phase 2
                    </div>
                    <div className="company-info-modal-overview-key">
                      <PublicIcon fontSize="small" />
                      Website
                    </div>
                    <div className="company-info-modal-overview-value1">
                      Phase 2
                    </div>
                    <div className="company-info-modal-overview-key">
                      <PersonIcon fontSize="small" />
                      Social
                    </div>
                    <div className="company-info-modal-overview-value1">
                      Phase 2
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PeopleAndContacts
              companyOfficersInfo={companyOfficersInfo}
              isLoading={isLoading}
            />
            <div className="company-info-modal-people-contant-main">
              <div className="company-info-modal-overview-main">
                <div className="company-info-modal-overview-title">
                  Trading Addresses
                </div>
                <div>No trading addresses recorded.</div>
              </div>
            </div>
          </div>
        );
      case 'financials':
        return <div>Financials Content</div>;
      case 'property':
        return (
          <div>
            <CorporateExplorerProperty companyInfo={companyInfo} />
          </div>
        );
      case 'shareholders':
        return (
          <Shareholders
            debouncedSearch={debouncedSearch}
            isLoading={isLoading}
            shareholders={shareholders}
          />
        );
      default:
        return <div>Overview Content</div>;
    }
  };

  const filterPeopleByQuery = (queryString, dataList) => {
    // Convert the query string to lower case for case-insensitive matching
    const lowerCaseQuery = queryString.toLowerCase();

    // Function to filter people in a given list
    const filterPeople = peopleList => {
      return peopleList.filter(person =>
        person.name.toLowerCase().includes(lowerCaseQuery)
      );
    };

    // Create a new filtered list for both Ordinary and Preferred types
    const filteredDataList = dataList.map(data => {
      return {
        ...data,
        people: filterPeople(data.people),
      };
    });

    return filteredDataList;
  };

  useEffect(() => {
    if (key === 'shareholders') {
      setIsLoading(true);
      const getShareholders = async () => {
        let companyNumber = companyInfo?.company_number;
        try {
          if (companyNumber && shareholders.length < 1) {
            const shareholdersRes =
              await corporateExplorerShareholders(companyNumber);
            // Handle the shareholders data here
            setShareholders(shareholdersRes);
            setShareholdersBackeupForSearchFilter(shareholdersRes);
            setIsLoading(false);
          } else {
            setShareholders(shareholders);
            setShareholdersBackeupForSearchFilter(shareholders);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      };
      getShareholders();
    }

    if (key === 'people') {
      setIsLoading(true);
      const getCompanyOfficers = async () => {
        let companyNumber = companyInfo?.company_number;
        try {
          if (companyNumber && companyOfficersInfo.length < 1) {
            let officersInfo =
              await corporateExplorerOfficerInfo(companyNumber);
            setCompanyOfficersInfo(officersInfo);
            setIsLoading(false);
          } else {
            setCompanyOfficersInfo(companyOfficersInfo);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      };
      getCompanyOfficers();
    }
  }, [key]);

  useEffect(() => {
    setShareholders([]);
    setShareholdersBackeupForSearchFilter([]);
    setCompanyOfficersInfo([]);
    setKey('overview');
  }, [companyInfo]);

  return (
    <div>
      <Modal
        show={showCompanyInfoModal}
        onHide={() => setShowCompanyInfoModal(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {processCompanyName(companyInfo?.company_name)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0px' }}>
          {companyInfo ? (
            <div className="corporate-explorer-company-info-main">
              <div className="corporate-explorer-company-info-header">
                <div className="corporate-explorer-company-info-navs">
                  <div
                    className={key === 'overview' ? 'active' : ''}
                    onClick={() => setKey('overview')}
                  >
                    Overview&nbsp;
                  </div>
                  <div
                    id="corporate-explorer-company-info-people-and-contacts-div"
                    className={`${key === 'people' ? 'active' : ''}`}
                    onClick={() => setKey('people')}
                  >
                    <div className="hide-people-contacts-for-small-screen">
                      People&nbsp;&amp;&nbsp;Contacts
                    </div>
                  </div>
                  <div
                    className={key === 'financials' ? 'active' : ''}
                    onClick={() => setKey('financials')}
                  >
                    Financials&nbsp;
                  </div>
                  <div
                    className={key === 'property' ? 'active' : ''}
                    onClick={() => setKey('property')}
                  >
                    Property&nbsp;
                  </div>
                  <div
                    className={key === 'shareholders' ? 'active' : ''}
                    onClick={() => setKey('shareholders')}
                  >
                    Shareholders
                  </div>
                </div>
              </div>
              <div className="corporate-explorer-company-info-body">
                {renderContent()}
              </div>
            </div>
          ) : (
            <div>
              <TableLoadingScreen />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CompanyInfoModal;
