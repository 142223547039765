import './Home.css';
import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Header from '../../components/Header';
import Banner from '../../components/Banner';
import Footer from '../../components/Footer';
import CopywriteLiine from '../../components/CopywriteLiine';
import Individuals from '../../assets/images/pages/Home/individuals.png';
import Investors from '../../assets/images/pages/Home/investors.png';
import LegalFirms from '../../assets/images/pages/Home/legal-firms.png';
import Businesses from '../../assets/images/pages/Home/businesses.png';
import WhoAreWe from '../../assets/images/pages/Home/who-are-we.png';
import OurServices from '../../assets/images/pages/Home/our-services.png';
import OurApproach from '../../assets/images/pages/Home/our-approach.png';
import OurGoal from '../../assets/images/pages/Home/our-goal.png';
import GlobeProperty from '../../assets/images/icons/Globe_Property_Icon.png';
import SearchIcon from '../../assets/images/what/company.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../context/Context';
import CONSTANTS from '../../utils/constants';
import PostAuthFlow from './PostAuthFlow/PostAuthFlow';
import { useAuth } from '../../context/AuthProvider';

const PostSignup = () => {
  const navigate = useNavigate();
  const { openSignup } = useAuth();
  const { user, setUser } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [testHomeComponent, setTestHomeComponent] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setSearchParams({});
  };

  useEffect(() => {
    const probeToken = searchParams.get('probe_token');
    if (probeToken === '01eb26ec-9fbb-11ee-8c90-0242ac120002') {
      setShowModal(true);
    }
  }, [searchParams]);
  return (
    <div>
      <div>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Check Your Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please check your email for further instructions.
          </Modal.Body>
        </Modal>
        <Header />
        <div>
          <PostAuthFlow
            headerMessage={'All Signed Up'}
            subMessage={
              "Congratulations on taking this exciting step by subscribing to our plans! Now, let's explore the array of solutions we have in store for you below."
            }
          />
          <Footer />
          <CopywriteLiine />
        </div>
      </div>
    </div>
  );
};

export default PostSignup;
