import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { triggerGoogleAnalyticsEvent } from '../../../utils/googleAnalyticsEvent';

function ViewBlogs(props) {
  const [blogList] = useState([]);
  const navigate = useNavigate();

  const handleBlogs = () => {
    navigate('/blogs/view');
  };

  const handleCreate = () => {
    navigate('/blogs/post');
  };

  useEffect(() => {
    triggerGoogleAnalyticsEvent('page_visits', 'blogs');
  }, []);

  return (
    <Col lg={12} xs={12}>
      <Row style={Styles.heading}>
        <Button style={Styles.button} onClick={handleCreate}>
          {' '}
          Create{' '}
        </Button>
        <Form.Text style={Styles.navbarText} onClick={handleBlogs}>
          Blogs
        </Form.Text>
      </Row>

      <Col lg={12} xs={12} md={12}>
        <Row lg={12} xs={12} md={12} style={Styles.cardsWrapper}>
          {blogList.map(blog => {
            return (
              <Col
                lg={4}
                xs={12}
                md={12}
                style={Styles.card}
                onClick={() => {
                  navigate(`/blogs/${blog.blogId}`, { state: blog });
                }}
              >
                <div style={Styles.blogTitle}>{blog.title}</div>

                <div>
                  <Image
                    style={Styles.blogThumbnail}
                    src={blog.coverDownloadUrl}
                  />
                </div>

                <div>
                  <p style={Styles.blogDescription}>{blog.intro}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Col>
  );
}

export default ViewBlogs;

const Styles = {
  button: {
    width: '10%',
    height: '50px',
    borderRadius: '50px',
  },
  heading: {
    width: '101%',
    padding: '25px',
    height: 'auto',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0px 0px 8px 1px lightgray',
  },
  navbarText: {
    width: '100px',
    font: '18px ',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  cardsWrapper: {
    padding: '25px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: 'auto',
  },
  card: {
    height: 'auto',
    padding: '10px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0px 0px 8px 1px lightgray',
    marginRight: '15px',
    marginBottom: '15px',
    cursor: 'pointer',
  },
  blogThumbnail: {
    width: '100%',
    height: '200px',
    padding: '0px',
    marginBottom: '15px',
  },
  blogDescription: {
    font: '14px ',
  },
  blogTitle: {
    font: '20px ',
    marginBottom: '15px',
  },
};
