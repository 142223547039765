export const JUDGMENT_CHECKER = {
  DUMMY_REPORT_URL:
    'https://firebasestorage.googleapis.com/v0/b/probe-digital.appspot.com/o/probe-digital-cdn%2FSampleReport.pdf?alt=media&token=420fbdd7-1f5f-4e15-9b4a-e58357a6d31a',
  SELECT_SERVICE: {
    TITLE: 'Select Service?',
    DESCRIPTION:
      "Select the service that suits you best. Whether you need to check an individual or a business against UK Official Registers, we've got you covered. Choose one of the two options below to start your check",
  },
  PERSON: {
    TITLE: 'Check a Person',
    DESCRIPTION:
      "Click here to search for an individual, whether it's for yourself or another person.",
  },
  BUSINESS: {
    TITLE: 'Check a Business',
    DESCRIPTION:
      'Click here to search for either a Non-Corporate or Corporate business.',
  },
  INTERESTED_IN: {
    TITLE: 'Interested in learning what you will get?',
    DESCRIPTION:
      'When you want to know more, we provide a complete overview of our services, including access to official registers and detailed data. We equip you with the knowledge to make informed decisions and enhance your experience.',
  },
  MY_REPORTS: {
    TITLE: 'MY REPORTS',
    DESCRIPTION:
      'Access and review your personalized reports in one convenient place. Stay informed about your checked individuals or businesses, ensuring you have all the insights you need at your fingertips.',
  },
  WHAT_IS_JUDGMENT_CHECKER: {
    TITLE: 'What is Judgments & Fines?',
    DESCRIPTION:
      'Your Key to Official Records Across the UK and Beyond via Judgement & Finding. We offer quick access to official records for various regions, including England, Wales, Scotland, Northern Ireland, Isle of Man, Jersey, and the Republic of Ireland.',
    DESCRIPTION_SECOND:
      'JudgementChecker is the unique service that lets both the public and businesses instantly search the complete record. Our services cover checking individuals and businesses against official records across the UK and beyond.',
  },
};

const SIC_CODES = [
  {
    parent: 'Agriculture, forestry and fishing',
    child: [
      {
        parent:
          'Crop and animal production, hunting and related service activities',
        child: [
          {
            parent: 'Growing of non-perennial crops',
            child: [
              {
                parent:
                  'Growing of cereals (except rice), leguminous crops and oil seeds',
              },
              {
                parent: 'Growing of rice',
              },
              {
                parent: 'Growing of vegetables and melons, roots and tubers',
              },
              {
                parent: 'Growing of sugar cane',
              },
              {
                parent: 'Growing of tobacco',
              },
              {
                parent: 'Growing of fibre crops',
              },
              {
                parent: 'Growing of other non-perennial crops',
              },
            ],
          },
          {
            parent: 'Growing of perennial crops',
            child: [
              {
                parent: 'Growing of grapes',
              },
              {
                parent: 'Growing of tropical and subtropical fruits',
              },
              {
                parent: 'Growing of citrus fruits',
              },
              {
                parent: 'Growing of pome fruits and stone fruits',
              },
              {
                parent: 'Growing of other tree and bush fruits and nuts',
              },
              {
                parent: 'Growing of oleaginous fruits',
              },
              {
                parent: 'Growing of beverage crops',
              },
              {
                parent:
                  'Growing of spices, aromatic, drug and pharmaceutical crops',
              },
              {
                parent: 'Growing of other perennial crops',
              },
            ],
          },
          {
            parent: 'Plant propagation',
            child: [
              {
                parent: 'Plant propagation',
              },
            ],
          },
          {
            parent: 'Animal production',
            child: [
              {
                parent: 'Raising of dairy cattle',
              },
              {
                parent: 'Raising of other cattle and buffaloes',
              },
              {
                parent: 'Raising of horses and other equines',
              },
              {
                parent: 'Raising of camels and camelids',
              },
              {
                parent: 'Raising of sheep and goats',
              },
              {
                parent: 'Raising of swine/pigs',
              },
              {
                parent: 'Raising of poultry',
              },
              {
                parent: 'Raising of other animals',
              },
            ],
          },
          {
            parent: 'Mixed farming',
            child: [
              {
                parent: 'Mixed farming',
              },
            ],
          },
          {
            parent:
              'Support activities to agriculture and post-harvest crop activities',
            child: [
              {
                parent: 'Support activities for crop production',
              },
              {
                parent: 'Support activities for animal production',
                child: [
                  {
                    parent: 'Farm animal boarding and care',
                    child: [],
                  },
                  {
                    parent:
                      'Support activities for animal production (other than farm animal boarding and care) n.e.c.',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Post-harvest crop activities',
              },
              {
                parent: 'Seed processing for propagation',
              },
            ],
          },
          {
            parent: 'Hunting, trapping and related service activities',
            child: [
              {
                parent: 'Hunting, trapping and related service activities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Forestry and logging',
        child: [
          {
            parent: 'Silviculture and other forestry activities',
            child: [
              {
                parent: 'Silviculture and other forestry activities',
              },
            ],
          },
          {
            parent: 'Logging',
            child: [],
          },
          {
            parent: 'Gathering of wild growing non-wood products',
            child: [
              {
                parent: 'Gathering of wild growing non-wood products',
              },
            ],
          },
          {
            parent: 'Support services to forestry',
            child: [
              {
                parent: 'Support services to forestry',
              },
            ],
          },
        ],
      },
      {
        parent: 'Fishing and aquaculture',
        child: [
          {
            parent: 'Fishing',
            child: [
              {
                parent: 'Marine fishing',
              },
              {
                parent: 'Freshwater fishing',
              },
            ],
          },
          {
            parent: 'Aquaculture',
            child: [
              {
                parent: 'Marine aquaculture',
              },
              {
                parent: 'Freshwater aquaculture',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Mining and quarrying',
    child: [
      {
        parent: 'Mining of coal and lignite',
        child: [
          {
            parent: 'Mining of hard coal',
            child: [
              {
                parent: 'Mining of hard coal',
                child: [
                  {
                    parent:
                      'Mining of hard coal from deep coal mines (underground mining)',
                    child: [],
                  },
                  {
                    parent:
                      'Mining of hard coal from open cast coal working (surface mining)',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Mining of lignite',
            child: [
              {
                parent: 'Mining of lignite',
              },
            ],
          },
        ],
      },
      {
        parent: 'Extraction of crude petroleum and natural gas',
        child: [
          {
            parent: 'Extraction of crude petroleum',
            child: [
              {
                parent: 'Extraction of crude petroleum',
              },
            ],
          },
          {
            parent: 'Extraction of natural gas',
            child: [
              {
                parent: 'Extraction of natural gas',
              },
            ],
          },
        ],
      },
      {
        parent: 'Mining of metal ores',
        child: [
          {
            parent: 'Mining of iron ores',
            child: [
              {
                parent: 'Mining of iron ores',
              },
            ],
          },
          {
            parent: 'Mining of non-ferrous metal ores',
            child: [
              {
                parent: 'Mining of uranium and thorium ores',
              },
              {
                parent: 'Mining of other non-ferrous metal ores',
              },
            ],
          },
        ],
      },
      {
        parent: 'Other mining and quarrying',
        child: [
          {
            parent: 'Quarrying of stone, sand and clay',
            child: [
              {
                parent:
                  'Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate',
              },
              {
                parent:
                  'Operation of gravel and sand pits; mining of clays and kaolin',
              },
            ],
          },
          {
            parent: 'Mining and quarrying n.e.c.',
            child: [
              {
                parent: 'Mining of chemical and fertiliser minerals',
              },
              {
                parent: 'Extraction of peat',
              },
              {
                parent: 'Extraction of salt',
              },
              {
                parent: 'Other mining and quarrying n.e.c.',
              },
            ],
          },
        ],
      },
      {
        parent: 'Mining support service activities',
        child: [
          {
            parent:
              'Support activities for petroleum and natural gas extraction',
            child: [
              {
                parent:
                  'Support activities for petroleum and natural gas extraction',
              },
            ],
          },
          {
            parent: 'Support activities for other mining and quarrying',
            child: [
              {
                parent: 'Support activities for other mining and quarrying',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Manufacturing',
    child: [
      {
        parent: 'Manufacture of food products',
        child: [
          {
            parent:
              'Processing and preserving of meat and production of meat products',
            child: [
              {
                parent: 'Processing and preserving of meat',
              },
              {
                parent: 'Processing and preserving of poultry meat',
              },
              {
                parent: 'Production of meat and poultry meat products',
              },
            ],
          },
          {
            parent:
              'Processing and preserving of fish, crustaceans and molluscs',
            child: [
              {
                parent:
                  'Processing and preserving of fish, crustaceans and molluscs',
              },
            ],
          },
          {
            parent: 'Processing and preserving of fruit and vegetables',
            child: [
              {
                parent: 'Processing and preserving of potatoes',
              },
              {
                parent: 'Manufacture of fruit and vegetable juice',
              },
              {
                parent:
                  'Other processing and preserving of fruit and vegetables',
              },
            ],
          },
          {
            parent: 'Manufacture of vegetable and animal oils and fats',
            child: [
              {
                parent: 'Manufacture of oils and fats',
              },
              {
                parent: 'Manufacture of margarine and similar edible fats',
              },
            ],
          },
          {
            parent: 'Manufacture of dairy products',
            child: [
              {
                parent: 'Operation of dairies and cheese making',
                child: [
                  {
                    parent: 'Liquid milk and cream production',
                    child: [],
                  },
                  {
                    parent: 'Butter and cheese production',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of milk products (other than liquid milk and cream, butter, cheese) n.e.c.',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Manufacture of ice cream',
              },
            ],
          },
          {
            parent:
              'Manufacture of grain mill products, starches and starch products',
            child: [
              {
                parent: 'Manufacture of grain mill products',
                child: [
                  { parent: 'Grain milling', child: [] },
                  {
                    parent:
                      'Manufacture of breakfast cereals and cereals-based foods',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Manufacture of starches and starch products',
              },
            ],
          },
          {
            parent: 'Manufacture of bakery and farinaceous products',
            child: [
              {
                parent:
                  'Manufacture of bread; manufacture of fresh pastry goods and cakes',
              },
              {
                parent:
                  'Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes',
              },
              {
                parent:
                  'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
              },
            ],
          },
          {
            parent: 'Manufacture of other food products',
            child: [
              {
                parent: 'Manufacture of sugar',
              },
              {
                parent: 'Manufacture of cocoa, chocolate and confectionery',
                child: [
                  {
                    parent: 'Manufacture of cocoa, and chocolate confectionery',
                    child: [],
                  },
                  {
                    parent: 'Manufacture of sugar confectionery',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Processing of tea and coffee',
                child: [
                  { parent: 'Tea processing', child: [] },
                  {
                    parent: 'Production of coffee and coffee substitutes',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Manufacture of condiments and seasonings',
              },
              {
                parent: 'Manufacture of prepared meals and dishes',
              },
              {
                parent:
                  'Manufacture of homogenised food preparations and dietetic food',
              },
              {
                parent: 'Manufacture of other food products n.e.c.',
              },
            ],
          },
          {
            parent: 'Manufacture of prepared animal feeds',
            child: [
              {
                parent: 'Manufacture of prepared feeds for farm animals',
              },
              {
                parent: 'Manufacture of prepared pet foods',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of beverages',
        child: [
          {
            parent: 'Manufacture of beverages',
            child: [
              {
                parent: 'Distilling, rectifying and blending of spirits',
              },
              {
                parent: 'Manufacture of wine from grape',
              },
              {
                parent: 'Manufacture of cider and other fruit wines',
              },
              {
                parent:
                  'Manufacture of other non-distilled fermented beverages',
              },
              {
                parent: 'Manufacture of beer',
              },
              {
                parent: 'Manufacture of malt',
              },
              {
                parent:
                  'Manufacture of soft drinks; production of mineral waters and other bottled waters',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of tobacco products',
        child: [
          {
            parent: 'Manufacture of tobacco products',
            child: [
              {
                parent: 'Manufacture of tobacco products',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of textiles',
        child: [
          {
            parent: 'Preparation and spinning of textile fibres',
            child: [
              {
                parent: 'Preparation and spinning of textile fibres',
              },
            ],
          },
          {
            parent: 'Weaving of textiles',
            child: [
              {
                parent: 'Weaving of textiles',
              },
            ],
          },
          {
            parent: 'Finishing of textiles',
            child: [
              {
                parent: 'Finishing of textiles',
              },
            ],
          },
          {
            parent: 'Manufacture of other textiles',
            child: [
              {
                parent: 'Manufacture of knitted and crocheted fabrics',
              },
              {
                parent:
                  'Manufacture of made-up textile articles, except apparel',
                child: [
                  {
                    parent: 'Manufacture of soft furnishings',
                    child: [],
                  },
                  {
                    parent: 'Manufacture of canvas goods, sacks etc.',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of household textiles (other than soft furnishings of 13.92/1)',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Manufacture of carpets and rugs',
                child: [
                  {
                    parent: 'Manufacture of woven or tufted carpets and rugs',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of carpets and rugs (other than woven or tufted) n.e.c.',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Manufacture of cordage, rope, twine and netting',
              },
              {
                parent:
                  'Manufacture of non-wovens and articles made from non-wovens, except apparel',
              },
              {
                parent:
                  'Manufacture of other technical and industrial textiles',
              },
              {
                parent: 'Manufacture of other textiles n.e.c.',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of wearing apparel',
        child: [
          {
            parent: 'Manufacture of wearing apparel, except fur apparel',
            child: [
              {
                parent: 'Manufacture of leather clothes',
              },
              {
                parent: 'Manufacture of workwear',
              },
              {
                parent: 'Manufacture of other outerwear',
                child: [
                  {
                    parent:
                      "Manufacture of men's outerwear, other than leather clothes and workwear",
                    child: [],
                  },
                  {
                    parent:
                      "Manufacture of women's outerwear, other than leather clothes and workwear",
                    child: [],
                  },
                ],
              },
              {
                parent: 'Manufacture of underwear',
                child: [
                  {
                    parent: "Manufacture of men's underwear",
                    child: [],
                  },
                  {
                    parent: "Manufacture of women's underwear",
                    child: [],
                  },
                ],
              },
              {
                parent: 'Manufacture of other wearing apparel and accessories',
              },
            ],
          },
          {
            parent: 'Manufacture of articles of fur',
            child: [
              {
                parent: 'Manufacture of articles of fur',
              },
            ],
          },
          {
            parent: 'Manufacture of knitted and crocheted apparel',
            child: [
              {
                parent: 'Manufacture of knitted and crocheted hosiery',
              },
              {
                parent: 'Manufacture of other knitted and crocheted apparel',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of leather and related products',
        child: [
          {
            parent:
              'Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur',
            child: [
              {
                parent:
                  'Tanning and dressing of leather; dressing and dyeing of fur',
              },
              {
                parent:
                  'Manufacture of luggage, handbags and the like, saddlery and harness',
              },
            ],
          },
          {
            parent: 'Manufacture of footwear',
            child: [
              {
                parent: 'Manufacture of footwear',
              },
            ],
          },
        ],
      },
      {
        parent:
          'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
        child: [
          {
            parent: 'Sawmilling and planing of wood',
            child: [
              {
                parent: 'Sawmilling and planing of wood',
              },
            ],
          },
          {
            parent:
              'Manufacture of products of wood, cork, straw and plaiting materials',
            child: [
              {
                parent: 'Manufacture of veneer sheets and wood-based panels',
              },
              {
                parent: 'Manufacture of assembled parquet floors',
              },
              {
                parent: "Manufacture of other builders' carpentry and joinery",
              },
              {
                parent: 'Manufacture of wooden containers',
              },
              {
                parent:
                  'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of paper and paper products',
        child: [
          {
            parent: 'Manufacture of pulp, paper and paperboard',
            child: [
              {
                parent: 'Manufacture of pulp',
              },
              {
                parent: 'Manufacture of paper and paperboard',
              },
            ],
          },
          {
            parent: 'Manufacture of articles of paper and paperboard',
            child: [
              {
                parent:
                  'Manufacture of corrugated paper and paperboard and of containers of paper and paperboard',
                child: [
                  {
                    parent:
                      'Manufacture of corrugated paper and paperboard; manufacture of sacks and bags of paper',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of paper and paperboard containers other than sacks and bags',
                    child: [],
                  },
                ],
              },
              {
                parent:
                  'Manufacture of household and sanitary goods and of toilet requisites',
              },
              {
                parent: 'Manufacture of paper stationery',
              },
              {
                parent: 'Manufacture of wallpaper',
              },
              {
                parent: 'Manufacture of other articles of paper and paperboard',
              },
            ],
          },
        ],
      },
      {
        parent: 'Printing and reproduction of recorded media',
        child: [
          {
            parent: 'Printing and service activities related to printing',
            child: [
              {
                parent: 'Printing of newspapers',
              },
              {
                parent: 'Other printing',
                child: [
                  {
                    parent: 'Manufacture of printed labels',
                    child: [],
                  },
                  {
                    parent:
                      'Printing (other than printing of newspapers and printing on labels and tags) n.e.c.',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Pre-press and pre-media services',
              },
              {
                parent: 'Binding and related services',
              },
            ],
          },
          {
            parent: 'Reproduction of recorded media',
            child: [
              {
                parent: 'Reproduction of recorded media',
                child: [
                  {
                    parent: 'Reproduction of sound recording',
                    child: [],
                  },
                  {
                    parent: 'Reproduction of video recording',
                    child: [],
                  },
                  {
                    parent: 'Reproduction of computer media',
                    child: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of coke and refined petroleum products',
        child: [
          {
            parent: 'Manufacture of coke oven products',
            child: [
              {
                parent: 'Manufacture of coke oven products',
              },
            ],
          },
          {
            parent: 'Manufacture of refined petroleum products',
            child: [
              {
                parent: 'Manufacture of refined petroleum products',
                child: [
                  { parent: 'Mineral oil refining', child: [] },
                  {
                    parent:
                      'Other treatment of petroleum products (excluding mineral oil refining/petrochemicals manufacture)',
                    child: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of chemicals and chemical products',
        child: [
          {
            parent:
              'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
            child: [
              {
                parent: 'Manufacture of industrial gases',
              },
              {
                parent: 'Manufacture of dyes and pigments',
              },
              {
                parent: 'Manufacture of other inorganic basic chemicals',
              },
              {
                parent: 'Manufacture of other organic basic chemicals',
              },
              {
                parent: 'Manufacture of fertilisers and nitrogen compounds',
              },
              {
                parent: 'Manufacture of plastics in primary forms',
              },
              {
                parent: 'Manufacture of synthetic rubber in primary forms',
              },
            ],
          },
          {
            parent: 'Manufacture of pesticides and other agrochemical products',
            child: [
              {
                parent:
                  'Manufacture of pesticides and other agrochemical products',
              },
            ],
          },
          {
            parent:
              'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
            child: [
              {
                parent:
                  'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
                child: [
                  {
                    parent:
                      'Manufacture of paints, varnishes and similar coatings, mastics and sealants',
                    child: [],
                  },
                  {
                    parent: 'Manufacture of printing ink',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent:
              'Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations',
            child: [
              {
                parent:
                  'Manufacture of soap and detergents, cleaning and polishing preparations',
                child: [
                  {
                    parent: 'Manufacture of soap and detergents',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of cleaning and polishing preparations',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Manufacture of perfumes and toilet preparations',
              },
            ],
          },
          {
            parent: 'Manufacture of other chemical products',
            child: [
              {
                parent: 'Manufacture of explosives',
              },
              {
                parent: 'Manufacture of glues',
              },
              {
                parent: 'Manufacture of essential oils',
              },
              {
                parent: 'Manufacture of other chemical products n.e.c.',
              },
            ],
          },
          {
            parent: 'Manufacture of man-made fibres',
            child: [
              {
                parent: 'Manufacture of man-made fibres',
              },
            ],
          },
        ],
      },
      {
        parent:
          'Manufacture of basic pharmaceutical products and pharmaceutical preparations',
        child: [
          {
            parent: 'Manufacture of basic pharmaceutical products',
            child: [
              {
                parent: 'Manufacture of basic pharmaceutical products',
              },
            ],
          },
          {
            parent: 'Manufacture of pharmaceutical preparations',
            child: [
              {
                parent: 'Manufacture of pharmaceutical preparations',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of rubber and plastic products',
        child: [
          {
            parent: 'Manufacture of rubber products',
            child: [
              {
                parent:
                  'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
              },
              {
                parent: 'Manufacture of other rubber products',
              },
            ],
          },
          {
            parent: 'Manufacture of plastics products',
            child: [
              {
                parent:
                  'Manufacture of plastic plates, sheets, tubes and profiles',
              },
              {
                parent: 'Manufacture of plastic packing goods',
              },
              {
                parent: 'Manufacture of buildersâ€™ ware of plastic',
              },
              {
                parent: 'Manufacture of other plastic products',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of other non-metallic mineral products',
        child: [
          {
            parent: 'Manufacture of glass and glass products',
            child: [
              {
                parent: 'Manufacture of flat glass',
              },
              {
                parent: 'Shaping and processing of flat glass',
              },
              {
                parent: 'Manufacture of hollow glass',
              },
              {
                parent: 'Manufacture of glass fibres',
              },
              {
                parent:
                  'Manufacture and processing of other glass, including technical glassware',
              },
            ],
          },
          {
            parent: 'Manufacture of refractory products',
            child: [
              {
                parent: 'Manufacture of refractory products',
              },
            ],
          },
          {
            parent: 'Manufacture of clay building materials',
            child: [
              {
                parent: 'Manufacture of ceramic tiles and flags',
              },
              {
                parent:
                  'Manufacture of bricks, tiles and construction products, in baked clay',
              },
            ],
          },
          {
            parent: 'Manufacture of other porcelain and ceramic products',
            child: [
              {
                parent:
                  'Manufacture of ceramic household and ornamental articles',
              },
              {
                parent: 'Manufacture of ceramic sanitary fixtures',
              },
              {
                parent:
                  'Manufacture of ceramic insulators and insulating fittings',
              },
              {
                parent: 'Manufacture of other technical ceramic products',
              },
              {
                parent: 'Manufacture of other ceramic products',
              },
            ],
          },
          {
            parent: 'Manufacture of cement, lime and plaster',
            child: [
              {
                parent: 'Manufacture of cement',
              },
              {
                parent: 'Manufacture of lime and plaster',
              },
            ],
          },
          {
            parent: 'Manufacture of articles of concrete, cement and plaster',
            child: [
              {
                parent:
                  'Manufacture of concrete products for construction purposes',
              },
              {
                parent:
                  'Manufacture of plaster products for construction purposes',
              },
              {
                parent: 'Manufacture of ready-mixed concrete',
              },
              {
                parent: 'Manufacture of mortars',
              },
              {
                parent: 'Manufacture of fibre cement',
              },
              {
                parent:
                  'Manufacture of other articles of concrete, plaster and cement',
              },
            ],
          },
          {
            parent: 'Cutting, shaping and finishing of stone',
            child: [
              {
                parent: 'Cutting, shaping and finishing of stone',
              },
            ],
          },
          {
            parent:
              'Manufacture of abrasive products and non-metallic mineral products n.e.c.',
            child: [
              {
                parent: 'Production of abrasive products',
              },
              {
                parent:
                  'Manufacture of other non-metallic mineral products n.e.c.',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of basic metals',
        child: [
          {
            parent: 'Manufacture of basic iron and steel and of ferro-alloys',
            child: [
              {
                parent:
                  'Manufacture of basic iron and steel and of ferro-alloys',
              },
            ],
          },
          {
            parent:
              'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
            child: [
              {
                parent:
                  'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
              },
            ],
          },
          {
            parent:
              'Manufacture of other products of first processing of steel',
            child: [
              {
                parent: 'Cold drawing of bars',
              },
              {
                parent: 'Cold rolling of narrow strip',
              },
              {
                parent: 'Cold forming or folding',
              },
              {
                parent: 'Cold drawing of wire',
              },
            ],
          },
          {
            parent:
              'Manufacture of basic precious and other non-ferrous metals',
            child: [
              {
                parent: 'Precious metals production',
              },
              {
                parent: 'Aluminium production',
              },
              {
                parent: 'Lead, zinc and tin production',
              },
              {
                parent: 'Copper production',
              },
              {
                parent: 'Other non-ferrous metal production',
              },
              {
                parent: 'Processing of nuclear fuel',
              },
            ],
          },
          {
            parent: 'Casting of metals',
            child: [
              {
                parent: 'Casting of iron',
              },
              {
                parent: 'Casting of steel',
              },
              {
                parent: 'Casting of light metals',
              },
              {
                parent: 'Casting of other non-ferrous metals',
              },
            ],
          },
        ],
      },
      {
        parent:
          'Manufacture of fabricated metal products, except machinery and equipment',
        child: [
          {
            parent: 'Manufacture of structural metal products',
            child: [
              {
                parent:
                  'Manufacture of metal structures and parts of structures',
              },
              {
                parent: 'Manufacture of doors and windows of metal',
              },
            ],
          },
          {
            parent: 'Manufacture of tanks, reservoirs and containers of metal',
            child: [
              {
                parent: 'Manufacture of central heating radiators and boilers',
              },
              {
                parent:
                  'Manufacture of other tanks, reservoirs and containers of metal',
              },
            ],
          },
          {
            parent:
              'Manufacture of steam generators, except central heating hot water boilers',
            child: [
              {
                parent:
                  'Manufacture of steam generators, except central heating hot water boilers',
              },
            ],
          },
          {
            parent: 'Manufacture of weapons and ammunition',
            child: [
              {
                parent: 'Manufacture of weapons and ammunition',
              },
            ],
          },
          {
            parent:
              'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
            child: [
              {
                parent:
                  'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
              },
            ],
          },
          {
            parent: 'Treatment and coating of metals; machining',
            child: [
              {
                parent: 'Treatment and coating of metals',
              },
              {
                parent: 'Machining',
              },
            ],
          },
          {
            parent: 'Manufacture of cutlery, tools and general hardware',
            child: [
              {
                parent: 'Manufacture of cutlery',
              },
              {
                parent: 'Manufacture of locks and hinges',
              },
              {
                parent: 'Manufacture of tools',
              },
            ],
          },
          {
            parent: 'Manufacture of other fabricated metal products',
            child: [
              {
                parent: 'Manufacture of steel drums and similar containers',
              },
              {
                parent: 'Manufacture of light metal packaging',
              },
              {
                parent: 'Manufacture of wire products, chain and springs',
              },
              {
                parent: 'Manufacture of fasteners and screw machine products',
              },
              {
                parent: 'Manufacture of other fabricated metal products n.e.c.',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of computer, electronic and optical products',
        child: [
          {
            parent: 'Manufacture of electronic components and boards',
            child: [
              {
                parent: 'Manufacture of electronic components',
              },
              {
                parent: 'Manufacture of loaded electronic boards',
              },
            ],
          },
          {
            parent: 'Manufacture of computers and peripheral equipment',
            child: [
              {
                parent: 'Manufacture of computers and peripheral equipment',
              },
            ],
          },
          {
            parent: 'Manufacture of communication equipment',
            child: [
              {
                parent: 'Manufacture of communication equipment',
                child: [
                  {
                    parent:
                      'Manufacture of telegraph and telephone apparatus and equipment',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of communication equipment (other than telegraph and telephone apparatus and equipment)',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Manufacture of consumer electronics',
            child: [
              {
                parent: 'Manufacture of consumer electronics',
              },
            ],
          },
          {
            parent:
              'Manufacture of instruments and appliances for measuring, testing and navigation; watches and clocks',
            child: [
              {
                parent:
                  'Manufacture of instruments and appliances for measuring, testing and navigation',
                child: [
                  {
                    parent:
                      'Manufacture of electronic instruments and appliances for measuring, testing, and navigation, except industrial process control equipment',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of electronic industrial process control equipment',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of non-electronic instruments and appliances for measuring, testing and navigation, except industrial process control equipment',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of non-electronic industrial process control equipment',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Manufacture of watches and clocks',
              },
            ],
          },
          {
            parent:
              'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
            child: [
              {
                parent:
                  'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
              },
            ],
          },
          {
            parent:
              'Manufacture of optical instruments and photographic equipment',
            child: [
              {
                parent:
                  'Manufacture of optical instruments and photographic equipment',
                child: [
                  {
                    parent: 'Manufacture of optical precision instruments',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of photographic and cinematographic equipment',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Manufacture of magnetic and optical media',
            child: [
              {
                parent: 'Manufacture of magnetic and optical media',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of electrical equipment',
        child: [
          {
            parent:
              'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
            child: [
              {
                parent:
                  'Manufacture of electric motors, generators and transformers',
              },
              {
                parent:
                  'Manufacture of electricity distribution and control apparatus',
              },
            ],
          },
          {
            parent: 'Manufacture of batteries and accumulators',
            child: [
              {
                parent: 'Manufacture of batteries and accumulators',
              },
            ],
          },
          {
            parent: 'Manufacture of wiring and wiring devices',
            child: [
              {
                parent: 'Manufacture of fibre optic cables',
              },
              {
                parent:
                  'Manufacture of other electronic and electric wires and cables',
              },
              {
                parent: 'Manufacture of wiring devices',
              },
            ],
          },
          {
            parent: 'Manufacture of electric lighting equipment',
            child: [
              {
                parent: 'Manufacture of electric lighting equipment',
              },
            ],
          },
          {
            parent: 'Manufacture of domestic appliances',
            child: [
              {
                parent: 'Manufacture of electric domestic appliances',
              },
              {
                parent: 'Manufacture of non-electric domestic appliances',
              },
            ],
          },
          {
            parent: 'Manufacture of other electrical equipment',
            child: [
              {
                parent: 'Manufacture of other electrical equipment',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of machinery and equipment n.e.c.',
        child: [
          {
            parent: 'Manufacture of general-purpose machinery',
            child: [
              {
                parent:
                  'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
              },
              {
                parent: 'Manufacture of fluid power equipment',
              },
              {
                parent: 'Manufacture of other pumps and compressors',
                child: [
                  { parent: 'Manufacture of pumps', child: [] },
                  { parent: 'Manufacture of compressors', child: [] },
                ],
              },
              {
                parent: 'Manufacture of other taps and valves',
              },
              {
                parent:
                  'Manufacture of bearings, gears, gearing and driving elements',
              },
            ],
          },
          {
            parent: 'Manufacture of other general-purpose machinery',
            child: [
              {
                parent: 'Manufacture of ovens, furnaces and furnace burners',
              },
              {
                parent: 'Manufacture of lifting and handling equipment',
              },
              {
                parent:
                  'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
              },
              {
                parent: 'Manufacture of power-driven hand tools',
              },
              {
                parent:
                  'Manufacture of non-domestic cooling and ventilation equipment',
              },
              {
                parent: 'Manufacture of other general-purpose machinery n.e.c.',
              },
            ],
          },
          {
            parent: 'Manufacture of agricultural and forestry machinery',
            child: [
              {
                parent: 'Manufacture of agricultural and forestry machinery',
                child: [
                  {
                    parent: 'Manufacture of agricultural tractors',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of agricultural and forestry machinery (other than agricultural tractors)',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Manufacture of metal forming machinery and machine tools',
            child: [
              {
                parent: 'Manufacture of metal forming machinery',
              },
              {
                parent: 'Manufacture of other machine tools',
              },
            ],
          },
          {
            parent: 'Manufacture of other special-purpose machinery',
            child: [
              {
                parent: 'Manufacture of machinery for metallurgy',
              },
              {
                parent:
                  'Manufacture of machinery for mining, quarrying and construction',
                child: [
                  {
                    parent: 'Manufacture of machinery for mining',
                    child: [],
                  },
                  {
                    parent: 'Manufacture of earthmoving equipment',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of equipment for concrete crushing and screening roadworks',
                    child: [],
                  },
                ],
              },
              {
                parent:
                  'Manufacture of machinery for food, beverage and tobacco processing',
              },
              {
                parent:
                  'Manufacture of machinery for textile, apparel and leather production',
              },
              {
                parent:
                  'Manufacture of machinery for paper and paperboard production',
              },
              {
                parent: 'Manufacture of plastics and rubber machinery',
              },
              {
                parent: 'Manufacture of other special-purpose machinery n.e.c.',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of motor vehicles, trailers and semi-trailers',
        child: [
          {
            parent: 'Manufacture of motor vehicles',
            child: [
              {
                parent: 'Manufacture of motor vehicles',
              },
            ],
          },
          {
            parent:
              'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
            child: [
              {
                parent:
                  'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
                child: [
                  {
                    parent:
                      'Manufacture of bodies (coachwork) for motor vehicles (except caravans)',
                    child: [],
                  },
                  {
                    parent: 'Manufacture of trailers and semi-trailers',
                    child: [],
                  },
                  { parent: 'Manufacture of caravans', child: [] },
                ],
              },
            ],
          },
          {
            parent: 'Manufacture of parts and accessories for motor vehicles',
            child: [
              {
                parent:
                  'Manufacture of electrical and electronic equipment for motor vehicles',
              },
              {
                parent:
                  'Manufacture of other parts and accessories for motor vehicles',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of other transport equipment',
        child: [
          {
            parent: 'Building of ships and boats',
            child: [
              {
                parent: 'Building of ships and floating structures',
              },
              {
                parent: 'Building of pleasure and sporting boats',
              },
            ],
          },
          {
            parent: 'Manufacture of railway locomotives and rolling stock',
            child: [
              {
                parent: 'Manufacture of railway locomotives and rolling stock',
              },
            ],
          },
          {
            parent: 'Manufacture of air and spacecraft and related machinery',
            child: [
              {
                parent:
                  'Manufacture of air and spacecraft and related machinery',
              },
            ],
          },
          {
            parent: 'Manufacture of military fighting vehicles',
            child: [
              {
                parent: 'Manufacture of military fighting vehicles',
              },
            ],
          },
          {
            parent: 'Manufacture of transport equipment n.e.c.',
            child: [
              {
                parent: 'Manufacture of motorcycles',
              },
              {
                parent: 'Manufacture of bicycles and invalid carriages',
              },
              {
                parent: 'Manufacture of other transport equipment n.e.c.',
              },
            ],
          },
        ],
      },
      {
        parent: 'Manufacture of furniture',
        child: [
          {
            parent: 'Manufacture of furniture',
            child: [
              {
                parent: 'Manufacture of office and shop furniture',
              },
              {
                parent: 'Manufacture of kitchen furniture',
              },
              {
                parent: 'Manufacture of mattresses',
              },
              {
                parent: 'Manufacture of other furniture',
              },
            ],
          },
        ],
      },
      {
        parent: 'Other manufacturing',
        child: [
          {
            parent: 'Manufacture of jewellery, bijouterie and related articles',
            child: [
              {
                parent: 'Striking of coins',
              },
              {
                parent: 'Manufacture of jewellery and related articles',
              },
              {
                parent:
                  'Manufacture of imitation jewellery and related articles',
              },
            ],
          },
          {
            parent: 'Manufacture of musical instruments',
            child: [
              {
                parent: 'Manufacture of musical instruments',
              },
            ],
          },
          {
            parent: 'Manufacture of sports goods',
            child: [
              {
                parent: 'Manufacture of sports goods',
              },
            ],
          },
          {
            parent: 'Manufacture of games and toys',
            child: [
              {
                parent: 'Manufacture of games and toys',
                child: [
                  {
                    parent:
                      'Manufacture of professional and arcade games and toys',
                    child: [],
                  },
                  {
                    parent:
                      'Manufacture of games and toys (other than professional and arcade games and toys) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent:
              'Manufacture of medical and dental instruments and supplies',
            child: [
              {
                parent:
                  'Manufacture of medical and dental instruments and supplies',
              },
            ],
          },
          {
            parent: 'Other manufacturing n.e.c.',
            child: [
              {
                parent: 'Manufacture of brooms and brushes',
              },
              {
                parent: 'Other manufacturing n.e.c.',
              },
            ],
          },
        ],
      },
      {
        parent: 'Repair and installation of machinery and equipment',
        child: [
          {
            parent:
              'Repair of fabricated metal products, machinery and equipment',
            child: [
              {
                parent: 'Repair of fabricated metal products',
              },
              {
                parent: 'Repair of machinery',
              },
              {
                parent: 'Repair of electronic and optical equipment',
              },
              {
                parent: 'Repair of electrical equipment',
              },
              {
                parent: 'Repair and maintenance of ships and boats',
              },
              {
                parent: 'Repair and maintenance of aircraft and spacecraft',
              },
              {
                parent: 'Repair and maintenance of other transport equipment',
              },
              {
                parent: 'Repair of other equipment',
              },
            ],
          },
          {
            parent: 'Installation of industrial machinery and equipment',
            child: [
              {
                parent: 'Installation of industrial machinery and equipment',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Electricity, gas, steam and air conditioning supply',
    child: [
      {
        parent: 'Electricity, gas, steam and air conditioning supply',
        child: [
          {
            parent: 'Electric power generation, transmission and distribution',
            child: [
              {
                parent: 'Production of electricity',
              },
              {
                parent: 'Transmission of electricity',
              },
              {
                parent: 'Distribution of electricity',
              },
              {
                parent: 'Trade of electricity',
              },
            ],
          },
          {
            parent:
              'Manufacture of gas; distribution of gaseous fuels through mains',
            child: [
              {
                parent: 'Manufacture of gas',
              },
              {
                parent: 'Distribution of gaseous fuels through mains',
              },
              {
                parent: 'Trade of gas through mains',
              },
            ],
          },
          {
            parent: 'Steam and air conditioning supply',
            child: [
              {
                parent: 'Steam and air conditioning supply',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent:
      'Water supply; sewerage, waste management and remediation activities',
    child: [
      {
        parent: 'Water collection, treatment and supply',
        child: [
          {
            parent: 'Water collection, treatment and supply',
            child: [
              {
                parent: 'Water collection, treatment and supply',
              },
            ],
          },
        ],
      },
      {
        parent: 'Sewerage',
        child: [
          {
            parent: 'Sewerage',
            child: [
              {
                parent: 'Sewerage',
              },
            ],
          },
        ],
      },
      {
        parent:
          'Waste collection, treatment and disposal activities; materials recovery',
        child: [
          {
            parent: 'Waste collection',
            child: [
              {
                parent: 'Collection of non-hazardous waste',
              },
              {
                parent: 'Collection of hazardous waste',
              },
            ],
          },
          {
            parent: 'Waste treatment and disposal',
            child: [
              {
                parent: 'Treatment and disposal of non-hazardous waste',
              },
              {
                parent: 'Treatment and disposal of hazardous waste',
              },
            ],
          },
          {
            parent: 'Materials recovery',
            child: [
              {
                parent: 'Dismantling of wrecks',
              },
              {
                parent: 'Recovery of sorted materials',
              },
            ],
          },
        ],
      },
      {
        parent:
          'This division includes the provision of remediation services, i.e. the cleanup of contaminated buildings and sites, soil, surface or ground water.',
        child: [
          {
            parent:
              'Remediation activities and other waste management services',
            child: [
              {
                parent:
                  'Remediation activities and other waste management services',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Construction',
    child: [
      {
        parent: 'Construction of buildings',
        child: [
          {
            parent: 'Development of building projects',
            child: [
              {
                parent: 'Development of building projects',
              },
            ],
          },
          {
            parent: 'Construction of residential and non-residential buildings',
            child: [
              {
                parent:
                  'Construction of residential and non-residential buildings',
                child: [
                  {
                    parent: 'Construction of commercial buildings',
                    child: [],
                  },
                  {
                    parent: 'Construction of domestic buildings',
                    child: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        parent: 'Civil engineering',
        child: [
          {
            parent: 'Construction of roads and railways',
            child: [
              {
                parent: 'Construction of roads and motorways',
              },
              {
                parent: 'Construction of railways and underground railways',
              },
              {
                parent: 'Construction of bridges and tunnels',
              },
            ],
          },
          {
            parent: 'Construction of utility projects',
            child: [
              {
                parent: 'Construction of utility projects for fluids',
              },
              {
                parent:
                  'Construction of utility projects for electricity and telecommunications',
              },
            ],
          },
          {
            parent: 'Construction of other civil engineering projects',
            child: [
              {
                parent: 'Construction of water projects',
              },
              {
                parent:
                  'Construction of other civil engineering projects n.e.c.',
              },
            ],
          },
        ],
      },
      {
        parent: 'Specialised construction activities',
        child: [
          {
            parent: 'Demolition and site preparation',
            child: [
              {
                parent: 'Demolition',
              },
              {
                parent: 'Site preparation',
              },
              {
                parent: 'Test drilling and boring',
              },
            ],
          },
          {
            parent:
              'Electrical, plumbing and other construction installation activities',
            child: [
              {
                parent: 'Electrical installation',
              },
              {
                parent: 'Plumbing, heat and air-conditioning installation',
              },
              {
                parent: 'Other construction installation',
              },
            ],
          },
          {
            parent: 'Building completion and finishing',
            child: [
              {
                parent: 'Plastering',
              },
              {
                parent: 'Joinery installation',
              },
              {
                parent: 'Floor and wall covering',
              },
              {
                parent: 'Painting and glazing',
                child: [
                  { parent: 'Painting', child: [] },
                  { parent: 'Glazing', child: [] },
                ],
              },
              {
                parent: 'Other building completion and finishing',
              },
            ],
          },
          {
            parent: 'Other specialised construction activities',
            child: [
              {
                parent: 'Roofing activities',
              },
              {
                parent: 'Other specialised construction activities n.e.c.',
                child: [
                  { parent: 'Scaffold erection', child: [] },
                  {
                    parent:
                      'Specialised construction activities (other than scaffold erection) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent:
      'Wholesale and retail trade; repair of motor vehicles and motorcycles',
    child: [
      {
        parent:
          'Wholesale and retail trade and repair of motor vehicles and motorcycles',
        child: [
          {
            parent: 'Sale of motor vehicles',
            child: [
              {
                parent: 'Sale of cars and light motor vehicles',
                child: [
                  {
                    parent: 'Sale of new cars and light motor vehicles',
                    child: [],
                  },
                  {
                    parent: 'Sale of used cars and light motor vehicles',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Sale of other motor vehicles',
              },
            ],
          },
          {
            parent: 'Maintenance and repair of motor vehicles',
            child: [
              {
                parent: 'Maintenance and repair of motor vehicles',
              },
            ],
          },
          {
            parent: 'Sale of motor vehicle parts and accessories',
            child: [
              {
                parent:
                  'Wholesale trade of motor vehicle parts and accessories',
              },
              {
                parent: 'Retail trade of motor vehicle parts and accessories',
              },
            ],
          },
          {
            parent:
              'Sale, maintenance and repair of motorcycles and related parts and accessories',
            child: [
              {
                parent:
                  'Sale, maintenance and repair of motorcycles and related parts and accessories',
              },
            ],
          },
        ],
      },
      {
        parent: 'Wholesale trade, except of motor vehicles and motorcycles',
        child: [
          {
            parent: 'Wholesale on a fee or contract basis',
            child: [
              {
                parent:
                  'Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods',
              },
              {
                parent:
                  'Agents involved in the sale of fuels, ores, metals and industrial chemicals',
              },
              {
                parent:
                  'Agents involved in the sale of timber and building materials',
              },
              {
                parent:
                  'Agents involved in the sale of machinery, industrial equipment, ships and aircraft',
              },
              {
                parent:
                  'Agents involved in the sale of furniture, household goods, hardware and ironmongery',
              },
              {
                parent:
                  'Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
              },
              {
                parent:
                  'Agents involved in the sale of food, beverages and tobacco',
              },
              {
                parent:
                  'Agents specialised in the sale of other particular products',
              },
              {
                parent: 'Agents involved in the sale of a variety of goods',
              },
            ],
          },
          {
            parent: 'Wholesale of agricultural raw materials and live animals',
            child: [
              {
                parent:
                  'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
              },
              {
                parent: 'Wholesale of flowers and plants',
              },
              {
                parent: 'Wholesale of live animals',
              },
              {
                parent: 'Wholesale of hides, skins and leather',
              },
            ],
          },
          {
            parent: 'Wholesale of food, beverages and tobacco',
            child: [
              {
                parent: 'Wholesale of fruit and vegetables',
              },
              {
                parent: 'Wholesale of meat and meat products',
              },
              {
                parent:
                  'Wholesale of dairy products, eggs and edible oils and fats',
              },
              {
                parent: 'Wholesale of beverages',
                child: [
                  {
                    parent:
                      'Wholesale of fruit and vegetable juices, mineral waters and soft drinks',
                    child: [],
                  },
                  {
                    parent:
                      'Wholesale of wine, beer, spirits and other alcoholic beverages',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Wholesale of tobacco products',
              },
              {
                parent:
                  'Wholesale of sugar and chocolate and sugar confectionery',
              },
              {
                parent: 'Wholesale of coffee, tea, cocoa and spices',
              },
              {
                parent:
                  'Wholesale of other food, including fish, crustaceans and molluscs',
              },
              {
                parent:
                  'Non-specialised wholesale of food, beverages and tobacco',
              },
            ],
          },
          {
            parent: 'Wholesale of household goods',
            child: [
              {
                parent: 'Wholesale of textiles',
              },
              {
                parent: 'Wholesale of clothing and footwear',
              },
              {
                parent: 'Wholesale of electrical household appliances',
                child: [
                  {
                    parent:
                      'Wholesale of gramophone records, audio tapes, compact discs and video tapes and of the equipment on which these are played',
                    child: [],
                  },
                  {
                    parent:
                      'Wholesale of radio and television goods and of electrical household appliances (other than of gramophone records, audio tapes, compact discs and video tapes and the equipment on which these are played) n.e.c.',
                    child: [],
                  },
                ],
              },
              {
                parent:
                  'Wholesale of china and glassware and cleaning materials',
              },
              {
                parent: 'Wholesale of perfume and cosmetics',
              },
              {
                parent: 'Wholesale of pharmaceutical goods',
              },
              {
                parent:
                  'Wholesale of furniture, carpets and lighting equipment',
              },
              {
                parent: 'Wholesale of watches and jewellery',
              },
              {
                parent: 'Wholesale of other household goods',
                child: [
                  {
                    parent: 'Wholesale of musical instruments',
                    child: [],
                  },
                  {
                    parent:
                      'Wholesale of household goods (other than musical instruments) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Wholesale of information and communication equipment',
            child: [
              {
                parent:
                  'Wholesale of computers, computer peripheral equipment and software',
              },
              {
                parent:
                  'Wholesale of electronic and telecommunications equipment and parts',
              },
            ],
          },
          {
            parent: 'Wholesale of other machinery, equipment and supplies',
            child: [
              {
                parent:
                  'Wholesale of agricultural machinery, equipment and supplies',
              },
              {
                parent: 'Wholesale of machine tools',
              },
              {
                parent:
                  'Wholesale of mining, construction and civil engineering machinery',
              },
              {
                parent:
                  'Wholesale of machinery for the textile industry and of sewing and knitting machines',
              },
              {
                parent: 'Wholesale of office furniture',
              },
              {
                parent: 'Wholesale of other office machinery and equipment',
              },
              {
                parent: 'Wholesale of other machinery and equipment',
              },
            ],
          },
          {
            parent: 'Other specialised wholesale',
            child: [
              {
                parent:
                  'Wholesale of solid, liquid and gaseous fuels and related products',
                child: [
                  {
                    parent: 'Wholesale of petroleum and petroleum products',
                    child: [],
                  },
                  {
                    parent:
                      'Wholesale of fuels and related products (other than petroleum and petroleum products)',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Wholesale of metals and metal ores',
              },
              {
                parent:
                  'Wholesale of wood, construction materials and sanitary equipment',
              },
              {
                parent:
                  'Wholesale of hardware, plumbing and heating equipment and supplies',
              },
              {
                parent: 'Wholesale of chemical products',
              },
              {
                parent: 'Wholesale of other intermediate products',
              },
              {
                parent: 'Wholesale of waste and scrap',
              },
            ],
          },
          {
            parent: 'Non-specialised wholesale trade',
            child: [
              {
                parent: 'Non-specialised wholesale trade',
              },
            ],
          },
        ],
      },
      {
        parent: 'Retail trade, except of motor vehicles and motorcycles',
        child: [
          {
            parent: 'Retail sale in non-specialised stores',
            child: [
              {
                parent:
                  'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
              },
              {
                parent: 'Other retail sale in non-specialised stores',
              },
            ],
          },
          {
            parent:
              'Retail sale of food, beverages and tobacco in specialised stores',
            child: [
              {
                parent:
                  'Retail sale of fruit and vegetables in specialised stores',
              },
              {
                parent:
                  'Retail sale of meat and meat products in specialised stores',
              },
              {
                parent:
                  'Retail sale of fish, crustaceans and molluscs in specialised stores',
              },
              {
                parent:
                  'Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores',
              },
              {
                parent: 'Retail sale of beverages in specialised stores',
              },
              {
                parent: 'Retail sale of tobacco products in specialised stores',
              },
              {
                parent: 'Other retail sale of food in specialised stores',
              },
            ],
          },
          {
            parent: 'Retail sale of automotive fuel in specialised stores',
            child: [
              {
                parent: 'Retail sale of automotive fuel in specialised stores',
              },
            ],
          },
          {
            parent:
              'Retail sale of information and communication equipment in specialised stores',
            child: [
              {
                parent:
                  'Retail sale of computers, peripheral units and software in specialised stores',
              },
              {
                parent:
                  'Retail sale of telecommunications equipment in specialised stores',
                child: [
                  {
                    parent:
                      'Retail sale of mobile telephones in specialised stores',
                    child: [],
                  },
                  {
                    parent:
                      'Retail sale of telecommunications equipment (other than mobile telephones) n.e.c., in specialised stores',
                    child: [],
                  },
                ],
              },
              {
                parent:
                  'Retail sale of audio and video equipment in specialised stores',
              },
            ],
          },
          {
            parent:
              'Retail sale of other household equipment in specialised stores',
            child: [
              {
                parent: 'Retail sale of textiles in specialised stores',
              },
              {
                parent:
                  'Retail sale of hardware, paints and glass in specialised stores',
              },
              {
                parent:
                  'Retail sale of carpets, rugs, wall and floor coverings in specialised stores',
              },
              {
                parent:
                  'Retail sale of electrical household appliances in specialised stores',
              },
              {
                parent:
                  'Retail sale of furniture, lighting equipment and other household articles in specialised stores',
                child: [
                  {
                    parent:
                      'Retail sale of musical instruments and scores in specialised stores',
                    child: [],
                  },
                  {
                    parent:
                      'Retail sale of furniture, lighting equipment and other household articles (other than musical instruments) n.e.c., in specialised stores',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent:
              'Retail sale of cultural and recreation goods in specialised stores',
            child: [
              {
                parent: 'Retail sale of books in specialised stores',
              },
              {
                parent:
                  'Retail sale of newspapers and stationery in specialised stores',
              },
              {
                parent:
                  'Retail sale of music and video recordings in specialised stores',
              },
              {
                parent:
                  'Retail sale of sporting equipment in specialised stores',
              },
              {
                parent: 'Retail sale of games and toys in specialised stores',
              },
            ],
          },
          {
            parent: 'Retail sale of other goods in specialised stores',
            child: [
              {
                parent: 'Retail sale of clothing in specialised stores',
              },
              {
                parent:
                  'Retail sale of footwear and leather goods in specialised stores',
                child: [
                  {
                    parent: 'Retail sale of footwear in specialised stores',
                    child: [],
                  },
                  {
                    parent:
                      'Retail sale of leather goods in specialised stores',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Dispensing chemist in specialised stores',
              },
              {
                parent:
                  'Retail sale of medical and orthopaedic goods in specialised stores',
                child: [
                  {
                    parent: 'Retail sale of hearing aids in specialised stores',
                    child: [],
                  },
                  {
                    parent:
                      'Retail sale of medical and orthopaedic goods (other than hearing aids) n.e.c., in specialised stores',
                    child: [],
                  },
                ],
              },
              {
                parent:
                  'Retail sale of cosmetic and toilet articles in specialised stores',
              },
              {
                parent:
                  'Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores',
              },
              {
                parent:
                  'Retail sale of watches and jewellery in specialised stores',
              },
              {
                parent: 'Other retail sale of new goods in specialised stores',
                child: [
                  {
                    parent: 'Retail sale in commercial art galleries',
                    child: [],
                  },
                  { parent: 'Retail sale by opticians', child: [] },
                  {
                    parent:
                      'Other retail sale of new goods in specialised stores (other than by opticians or commercial art galleries), n.e.c',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Retail sale of second-hand goods in stores',
                child: [
                  {
                    parent:
                      'Retail sale of antiques including antique books, in stores',
                    child: [],
                  },
                  {
                    parent:
                      'Retail sale of second-hand goods (other than antiques and antique books) in stores',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Retail sale via stalls and markets',
            child: [
              {
                parent:
                  'Retail sale via stalls and markets of food, beverages and tobacco products',
              },
              {
                parent:
                  'Retail sale via stalls and markets of textiles, clothing and footwear',
              },
              {
                parent: 'Retail sale via stalls and markets of other goods',
              },
            ],
          },
          {
            parent: 'Retail trade not in stores, stalls or markets',
            child: [
              {
                parent: 'Retail sale via mail order houses or via Internet',
              },
              {
                parent: 'Other retail sale not in stores, stalls or markets',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Transportation and storage',
    child: [
      {
        parent: 'Land transport and transport via pipelines',
        child: [
          {
            parent: 'Passenger rail transport, interurban',
            child: [
              {
                parent: 'Passenger rail transport, interurban',
              },
            ],
          },
          {
            parent: 'Freight rail transport',
            child: [
              {
                parent: 'Freight rail transport',
              },
            ],
          },
          {
            parent: 'Other passenger land transport',
            child: [
              {
                parent: 'Urban and suburban passenger land transport',
                child: [
                  {
                    parent:
                      'Urban, suburban or metropolitan area passenger railway transportation by underground, metro and similar systems',
                    child: [],
                  },
                  {
                    parent:
                      'Urban, suburban or metropolitan area passenger land transport other than railway transportation by underground, metro and similar systems',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Taxi operation',
              },
              {
                parent: 'Other passenger land transport n.e.c.',
              },
            ],
          },
          {
            parent: 'Freight transport by road and removal services',
            child: [
              {
                parent: 'Freight transport by road',
              },
              {
                parent: 'Removal services',
              },
            ],
          },
          {
            parent: 'Transport via pipeline',
            child: [
              {
                parent: 'Transport via pipeline',
              },
            ],
          },
        ],
      },
      {
        parent: 'Water transport',
        child: [
          {
            parent: 'Sea and coastal passenger water transport',
            child: [
              {
                parent: 'Sea and coastal passenger water transport',
              },
            ],
          },
          {
            parent: 'Sea and coastal freight water transport',
            child: [
              {
                parent: 'Sea and coastal freight water transport',
              },
            ],
          },
          {
            parent: 'Inland passenger water transport',
            child: [
              {
                parent: 'Inland passenger water transport',
              },
            ],
          },
          {
            parent: 'Inland freight water transport',
            child: [
              {
                parent: 'Inland freight water transport',
              },
            ],
          },
        ],
      },
      {
        parent: 'Air transport',
        child: [
          {
            parent: 'Passenger air transport',
            child: [
              {
                parent: 'Passenger air transport',
                child: [
                  {
                    parent: 'Scheduled passenger air transport',
                    child: [],
                  },
                  {
                    parent: 'Non-scheduled passenger air transport',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Freight air transport and space transport',
            child: [
              {
                parent: 'Freight air transport',
              },
              {
                parent: 'Space transport',
              },
            ],
          },
        ],
      },
      {
        parent: 'Warehousing and support activities for transportation',
        child: [
          {
            parent: 'Warehousing and storage',
            child: [
              {
                parent: 'Warehousing and storage',
                child: [
                  {
                    parent:
                      'Operation of warehousing and storage facilities for water transport activities of division 50',
                    child: [],
                  },
                  {
                    parent:
                      'Operation of warehousing and storage facilities for air transport activities of division 51',
                    child: [],
                  },
                  {
                    parent:
                      'Operation of warehousing and storage facilities for land transport activities of division 49',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Support activities for transportation',
            child: [
              {
                parent: 'Service activities incidental to land transportation',
                child: [
                  {
                    parent: 'Operation of rail freight terminals',
                    child: [],
                  },
                  {
                    parent:
                      'Operation of rail passenger facilities at railway stations',
                    child: [],
                  },
                  {
                    parent:
                      'Operation of bus and coach passenger facilities at bus and coach stations',
                    child: [],
                  },
                  {
                    parent:
                      'Other service activities incidental to land transportation, n.e.c. (not including operation of rail freight terminals, passenger facilities at railway stations or passenger facilities at bus and coach stations)',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Service activities incidental to water transportation',
              },
              {
                parent: 'Service activities incidental to air transportation',
              },
              {
                parent: 'Cargo handling',
                child: [
                  {
                    parent:
                      'Cargo handling for water transport activities of division 50',
                    child: [],
                  },
                  {
                    parent:
                      'Cargo handling for air transport activities of division 51',
                    child: [],
                  },
                  {
                    parent:
                      'Cargo handling for land transport activities of division 49',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Other transportation support activities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Postal and courier activities',
        child: [
          {
            parent: 'Postal activities under universal service obligation',
            child: [
              {
                parent: 'Postal activities under universal service obligation',
              },
            ],
          },
          {
            parent: 'Other postal and courier activities',
            child: [
              {
                parent: 'Other postal and courier activities',
                child: [
                  { parent: 'Licensed Carriers', child: [] },
                  { parent: 'Unlicensed Carriers', child: [] },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Accommodation and food service activities',
    child: [
      {
        parent: 'Accommodation',
        child: [
          {
            parent: 'Hotels and similar accommodation',
            child: [
              {
                parent: 'Hotels and similar accommodation',
              },
            ],
          },
          {
            parent: 'Holiday and other short-stay accommodation',
            child: [
              {
                parent: 'Holiday and other short-stay accommodation',
                child: [
                  {
                    parent: 'Holiday centres and villages',
                    child: [],
                  },
                  { parent: 'Youth hostels', child: [] },
                  {
                    parent:
                      'Other holiday and other short-stay accommodation (not including holiday centres and villages or youth hostels) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent:
              'Camping grounds, recreational vehicle parks and trailer parks',
            child: [
              {
                parent:
                  'Camping grounds, recreational vehicle parks and trailer parks',
              },
            ],
          },
          {
            parent: 'Other accommodation',
            child: [
              {
                parent: 'Other accommodation',
              },
            ],
          },
        ],
      },
      {
        parent: 'Food and beverage service activities',
        child: [
          {
            parent: 'Restaurants and mobile food service activities',
            child: [
              {
                parent: 'Restaurants and mobile food service activities',
                child: [
                  { parent: 'Licensed restaurants', child: [] },
                  {
                    parent: 'Unlicensed restaurants and cafes',
                    child: [],
                  },
                  {
                    parent: 'Take away food shops and mobile food stands',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Event catering and other food service activities',
            child: [
              {
                parent: 'Event catering activities',
              },
              {
                parent: 'Other food service activities',
              },
            ],
          },
          {
            parent: 'Beverage serving activities',
            child: [
              {
                parent: 'Beverage serving activities',
                child: [
                  { parent: 'Licensed clubs', child: [] },
                  { parent: 'Public houses and bars', child: [] },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Information and communication',
    child: [
      {
        parent: 'Publishing activities',
        child: [
          {
            parent:
              'Publishing of books, periodicals and other publishing activities',
            child: [
              {
                parent: 'Book publishing',
              },
              {
                parent: 'Publishing of directories and mailing lists',
              },
              {
                parent: 'Publishing of newspapers',
              },
              {
                parent: 'Publishing of journals and periodicals',
                child: [
                  {
                    parent: 'Publishing of learned journals',
                    child: [],
                  },
                  {
                    parent:
                      'Publishing of consumer, business and professional journals and periodicals',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Other publishing activities',
              },
            ],
          },
          {
            parent: 'Software publishing',
            child: [
              {
                parent: 'Publishing of computer games',
              },
              {
                parent: 'Other software publishing',
              },
            ],
          },
        ],
      },
      {
        parent:
          'Motion picture, video and television programme production, sound recording and music publishing activities',
        child: [
          {
            parent: 'Motion picture, video and television programme activities',
            child: [
              {
                parent:
                  'Motion picture, video and television programme production activities',
                child: [
                  {
                    parent: 'Motion picture production activities',
                    child: [],
                  },
                  {
                    parent: 'Video production activities',
                    child: [],
                  },
                  {
                    parent: 'Television programme production activities',
                    child: [],
                  },
                ],
              },
              {
                parent:
                  'Motion picture, video and television programme post-production activities',
              },
              {
                parent:
                  'Motion picture, video and television programme distribution activities',
                child: [
                  {
                    parent: 'Motion picture distribution activities',
                    child: [],
                  },
                  {
                    parent: 'Video distribution activities',
                    child: [],
                  },
                  {
                    parent: 'Television programme distribution activities',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Motion picture projection activities',
              },
            ],
          },
          {
            parent: 'Sound recording and music publishing activities',
            child: [
              {
                parent: 'Sound recording and music publishing activities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Programming and broadcasting activities',
        child: [
          {
            parent: 'Radio broadcasting',
            child: [
              {
                parent: 'Radio broadcasting',
              },
            ],
          },
          {
            parent: 'Television programming and broadcasting activities',
            child: [
              {
                parent: 'Television programming and broadcasting activities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Telecommunications',
        child: [
          {
            parent: 'Wired telecommunications activities',
            child: [
              {
                parent: 'Wired telecommunications activities',
              },
            ],
          },
          {
            parent: 'Wireless telecommunications activities',
            child: [
              {
                parent: 'Wireless telecommunications activities',
              },
            ],
          },
          {
            parent: 'Satellite telecommunications activities',
            child: [
              {
                parent: 'Satellite telecommunications activities',
              },
            ],
          },
          {
            parent: 'Other telecommunications activities',
            child: [
              {
                parent: 'Other telecommunications activities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Computer programming, consultancy and related activities',
        child: [
          {
            parent: 'Computer programming, consultancy and related activities',
            child: [
              {
                parent: 'Computer programming activities',
                child: [
                  {
                    parent:
                      'Ready-made interactive leisure and entertainment software development',
                    child: [],
                  },
                  {
                    parent: 'Business and domestic software development',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Computer consultancy activities',
              },
              {
                parent: 'Computer facilities management activities',
              },
              {
                parent:
                  'Other information technology and computer service activities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Information service activities',
        child: [
          {
            parent:
              'Data processing, hosting and related activities; web portals',
            child: [
              {
                parent: 'Data processing, hosting and related activities',
              },
              {
                parent: 'Web portals',
              },
            ],
          },
          {
            parent: 'Other information service activities',
            child: [
              {
                parent: 'News agency activities',
              },
              {
                parent: 'Other information service activities n.e.c.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Financial and insurance activities',
    child: [
      {
        parent:
          'Financial service activities, except insurance and pension funding',
        child: [
          {
            parent: 'Monetary intermediation',
            child: [
              {
                parent: 'Central banking',
              },
              {
                parent: 'Other monetary intermediation',
                child: [
                  { parent: 'Banks', child: [] },
                  { parent: 'Building societies', child: [] },
                ],
              },
            ],
          },
          {
            parent: 'Activities of holding companies',
            child: [
              {
                parent: 'Activities of holding companies',
                child: [
                  {
                    parent: 'Activities of agricultural holding companies',
                    child: [],
                  },
                  {
                    parent: 'Activities of production holding companies',
                    child: [],
                  },
                  {
                    parent: 'Activities of construction holding companies',
                    child: [],
                  },
                  {
                    parent: 'Activities of distribution holding companies',
                    child: [],
                  },
                  {
                    parent:
                      'Activities of financial services holding companies',
                    child: [],
                  },
                  {
                    parent:
                      'Activities of other holding companies (not including agricultural, production, construction, distribution and financial services holding companies) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Trusts, funds and similar financial entities',
            child: [
              {
                parent: 'Trusts, funds and similar financial entities',
                child: [
                  {
                    parent: 'Activities of investment trusts',
                    child: [],
                  },
                  { parent: 'Activities of unit trusts', child: [] },
                  {
                    parent:
                      'Activities of venture and development capital companies',
                    child: [],
                  },
                  {
                    parent: 'Activities of open-ended investment companies',
                    child: [],
                  },
                  {
                    parent: 'Activities of property unit trusts',
                    child: [],
                  },
                  {
                    parent: 'Activities of real estate investment trusts',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent:
              'Other financial service activities, except insurance and pension funding',
            child: [
              {
                parent: 'Financial leasing',
              },
              {
                parent: 'Other credit granting',
                child: [
                  {
                    parent:
                      'Credit granting by non-deposit taking finance houses and other specialist consumer credit grantors',
                    child: [],
                  },
                  {
                    parent: 'Activities of mortgage finance companies',
                    child: [],
                  },
                  {
                    parent:
                      'Other credit granting (not including credit granting by non-deposit taking finance houses and other specialist consumer credit grantors and activities of mortgage finance companies) n.e.c.',
                    child: [],
                  },
                ],
              },
              {
                parent:
                  'Other financial service activities, except insurance and pension funding, n.e.c.',
                child: [
                  {
                    parent: 'Security dealing on own account',
                    child: [],
                  },
                  { parent: 'Factoring', child: [] },
                  {
                    parent:
                      'Other financial service activities, except insurance and pension funding, (not including security dealing on own account and factoring) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        parent:
          'Insurance, reinsurance and pension funding, except compulsory social security',
        child: [
          {
            parent: 'Insurance',
            child: [
              {
                parent: 'Life insurance',
              },
              {
                parent: 'Non-life insurance',
              },
            ],
          },
          {
            parent: 'Reinsurance',
            child: [
              {
                parent: 'Reinsurance',
                child: [
                  { parent: 'Life reinsurance', child: [] },
                  { parent: 'Non-life reinsurance', child: [] },
                ],
              },
            ],
          },
          {
            parent: 'Pension funding',
            child: [
              {
                parent: 'Pension funding',
              },
            ],
          },
        ],
      },
      {
        parent:
          'Activities auxiliary to financial services and insurance activities',
        child: [
          {
            parent:
              'Activities auxiliary to financial services, except insurance and pension funding',
            child: [
              {
                parent: 'Administration of financial markets',
              },
              {
                parent: 'Security and commodity contracts brokerage',
              },
              {
                parent:
                  'Other activities auxiliary to financial services, except insurance and pension funding',
              },
            ],
          },
          {
            parent: 'Activities auxiliary to insurance and pension funding',
            child: [
              {
                parent: 'Risk and damage evaluation',
              },
              {
                parent: 'Activities of insurance agents and brokers',
              },
              {
                parent:
                  'Other activities auxiliary to insurance and pension funding',
              },
            ],
          },
          {
            parent: 'Fund management activities',
            child: [
              {
                parent: 'Fund management activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Real estate activities',
    child: [
      {
        parent: 'Real estate activities',
        child: [
          {
            parent: 'Buying and selling of own real estate',
            child: [
              {
                parent: 'Buying and selling of own real estate',
              },
            ],
          },
          {
            parent: 'Renting and operating of own or leased real estate',
            child: [
              {
                parent: 'Renting and operating of own or leased real estate',
                child: [
                  {
                    parent:
                      'Renting and operating of Housing Association real estate',
                    child: [],
                  },
                  {
                    parent:
                      'Letting and operating of conference and exhibition centres',
                    child: [],
                  },
                  {
                    parent:
                      'Letting and operating of own or leased real estate (other than Housing Association real estate and conference and exhibition services) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Real estate activities on a fee or contract basis',
            child: [
              {
                parent: 'Real estate agencies',
              },
              {
                parent: 'Management of real estate on a fee or contract basis',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Professional, scientific and technical activities',
    child: [
      {
        parent: 'Legal and accounting activities',
        child: [
          {
            parent: 'Legal activities',
            child: [
              {
                parent: 'Legal activities',
                child: [
                  { parent: 'Barristers at law', child: [] },
                  { parent: 'Solicitors', child: [] },
                  {
                    parent:
                      'Activities of patent and copyright agents; other legal activities (other than those of barristers and solicitors) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent:
              'Accounting, bookkeeping and auditing activities; tax consultancy',
            child: [
              {
                parent:
                  'Accounting, bookkeeping and auditing activities; tax consultancy',
                child: [
                  {
                    parent: 'Accounting, and auditing activities',
                    child: [],
                  },
                  { parent: 'Bookkeeping activities', child: [] },
                  { parent: 'Tax consultancy', child: [] },
                ],
              },
            ],
          },
        ],
      },
      {
        parent: 'Activities of head offices; management consultancy activities',
        child: [
          {
            parent: 'Activities of head offices',
            child: [
              {
                parent: 'Activities of head offices',
              },
            ],
          },
          {
            parent: 'Management consultancy activities',
            child: [
              {
                parent: 'Public relations and communication activities',
              },
              {
                parent: 'Business and other management consultancy activities',
                child: [
                  { parent: 'Financial management', child: [] },
                  {
                    parent:
                      'Management consultancy activities (other than financial management)',
                    child: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        parent:
          'Architectural and engineering activities; technical testing and analysis',
        child: [
          {
            parent:
              'Architectural and engineering activities and related technical consultancy',
            child: [
              {
                parent: 'Architectural activities',
                child: [
                  { parent: 'Architectural activities', child: [] },
                  {
                    parent:
                      'Urban planning and landscape architectural activities',
                    child: [],
                  },
                ],
              },
              {
                parent:
                  'Engineering activities and related technical consultancy',
                child: [
                  {
                    parent:
                      'Engineering design activities for industrial process and production',
                    child: [],
                  },
                  {
                    parent:
                      'Engineering related scientific and technical consulting activities',
                    child: [],
                  },
                  {
                    parent:
                      'Other engineering activities (not including engineering design for industrial process and production or engineering related scientific and technical consulting activities)',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Technical testing and analysis',
            child: [
              {
                parent: 'Technical testing and analysis',
              },
            ],
          },
        ],
      },
      {
        parent: 'Scientific research and development',
        child: [
          {
            parent:
              'Research and experimental development on natural sciences and engineering',
            child: [
              {
                parent:
                  'Research and experimental development on biotechnology',
              },
              {
                parent:
                  'Other research and experimental development on natural sciences and engineering',
              },
            ],
          },
          {
            parent:
              'Research and experimental development on social sciences and humanities',
            child: [
              {
                parent:
                  'Research and experimental development on social sciences and humanities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Advertising and market research',
        child: [
          {
            parent: 'Advertising',
            child: [
              {
                parent: 'Advertising agencies',
              },
              {
                parent: 'Media representation',
              },
            ],
          },
          {
            parent: 'Market research and public opinion polling',
            child: [
              {
                parent: 'Market research and public opinion polling',
              },
            ],
          },
        ],
      },
      {
        parent: 'Other professional, scientific and technical activities',
        child: [
          {
            parent: 'Specialised design activities',
            child: [
              {
                parent: 'Specialised design activities',
              },
            ],
          },
          {
            parent: 'Photographic activities',
            child: [
              {
                parent: 'Photographic activities',
                child: [
                  {
                    parent: 'Portrait photographic activities',
                    child: [],
                  },
                  {
                    parent:
                      'Other specialist photography (not including portrait photography)',
                    child: [],
                  },
                  { parent: 'Film processing', child: [] },
                  {
                    parent:
                      'Other photographic activities (not including portrait and other specialist photography and film processing) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Translation and interpretation activities',
            child: [
              {
                parent: 'Translation and interpretation activities',
              },
            ],
          },
          {
            parent:
              'Other professional, scientific and technical activities n.e.c.',
            child: [
              {
                parent:
                  'Other professional, scientific and technical activities n.e.c.',
                child: [
                  {
                    parent: 'Environmental consulting activities',
                    child: [],
                  },
                  {
                    parent: 'Quantity surveying activities',
                    child: [],
                  },
                  {
                    parent:
                      'Other professional, scientific and technical activities (not including environmental consultancy or quantity surveying) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        parent: 'Veterinary activities',
        child: [
          {
            parent: 'Veterinary activities',
            child: [
              {
                parent: 'Veterinary activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Administrative and support service activities',
    child: [
      {
        parent: 'Rental and leasing activities',
        child: [
          {
            parent: 'Renting and leasing of motor vehicles',
            child: [
              {
                parent: 'Renting and leasing of cars and light motor vehicles',
              },
              {
                parent: 'Renting and leasing of trucks',
              },
            ],
          },
          {
            parent: 'Renting and leasing of personal and household goods',
            child: [
              {
                parent: 'Renting and leasing of recreational and sports goods',
              },
              {
                parent: 'Renting of video tapes and disks',
              },
              {
                parent:
                  'Renting and leasing of other personal and household goods',
                child: [
                  {
                    parent:
                      'Renting and leasing of media entertainment equipment',
                    child: [],
                  },
                  {
                    parent:
                      'Renting and leasing of other personal and household goods (other than media entertainment equipment)',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent:
              'Renting and leasing of other machinery, equipment and tangible goods',
            child: [
              {
                parent:
                  'Renting and leasing of agricultural machinery and equipment',
              },
              {
                parent:
                  'Renting and leasing of construction and civil engineering machinery and equipment',
              },
              {
                parent:
                  'Renting and leasing of office machinery and equipment (including computers)',
              },
              {
                parent: 'Renting and leasing of water transport equipment',
                child: [
                  {
                    parent:
                      'Renting and leasing of passenger water transport equipment',
                    child: [],
                  },
                  {
                    parent:
                      'Renting and leasing of freight water transport equipment',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Renting and leasing of air transport equipment',
                child: [
                  {
                    parent:
                      'Renting and leasing of passenger air transport equipment',
                    child: [],
                  },
                  {
                    parent:
                      'Renting and leasing of freight air transport equipment',
                    child: [],
                  },
                ],
              },
              {
                parent:
                  'Renting and leasing of other machinery, equipment and tangible goods n.e.c.',
              },
            ],
          },
          {
            parent:
              'Leasing of intellectual property and similar products, except copyrighted works',
            child: [
              {
                parent:
                  'Leasing of intellectual property and similar products, except copyrighted works',
              },
            ],
          },
        ],
      },
      {
        parent: 'Employment activities',
        child: [
          {
            parent: 'Activities of employment placement agencies',
            child: [
              {
                parent: 'Activities of employment placement agencies',
                child: [
                  {
                    parent:
                      'Motion picture, television and other theatrical casting',
                    child: [],
                  },
                  {
                    parent:
                      'Activities of employment placement agencies (other than motion picture, television and other theatrical casting) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Temporary employment agency activities',
            child: [
              {
                parent: 'Temporary employment agency activities',
              },
            ],
          },
          {
            parent: 'Other human resources provision',
            child: [
              {
                parent: 'Other human resources provision',
              },
            ],
          },
        ],
      },
      {
        parent:
          'Travel agency, tour operator and other reservation service and related activities',
        child: [
          {
            parent: 'Travel agency and tour operator activities',
            child: [
              {
                parent: 'Travel agency activities',
              },
              {
                parent: 'Tour operator activities',
              },
            ],
          },
          {
            parent: 'Other reservation service and related activities',
            child: [
              {
                parent: 'Other reservation service and related activities',
                child: [
                  {
                    parent: 'Activities of tourist guides',
                    child: [],
                  },
                  {
                    parent:
                      'Other reservation service and related activities (not including activities of tourist guides)',
                    child: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        parent: 'Security and investigation activities',
        child: [
          {
            parent: 'Private security activities',
            child: [
              {
                parent: 'Private security activities',
              },
            ],
          },
          {
            parent: 'Security systems service activities',
            child: [
              {
                parent: 'Security systems service activities',
              },
            ],
          },
          {
            parent: 'Investigation activities',
            child: [
              {
                parent: 'Investigation activities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Services to buildings and landscape activities',
        child: [
          {
            parent: 'Combined facilities support activities',
            child: [
              {
                parent: 'Combined facilities support activities',
              },
            ],
          },
          {
            parent: 'Cleaning activities',
            child: [
              {
                parent: 'General cleaning of buildings',
              },
              {
                parent: 'Other building and industrial cleaning activities',
                child: [
                  { parent: 'Window cleaning services', child: [] },
                  {
                    parent: 'Specialised cleaning services',
                    child: [],
                  },
                  {
                    parent: 'Furnace and chimney cleaning services',
                    child: [],
                  },
                  {
                    parent:
                      'Building and industrial cleaning activities (other than window cleaning, specialised cleaning and furnace and chimney cleaning services) n.e.c.',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Other cleaning activities',
                child: [
                  {
                    parent: 'Disinfecting and extermination services',
                    child: [],
                  },
                  {
                    parent:
                      'Cleaning services (other than disinfecting and extermination services) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Landscape service activities',
            child: [
              {
                parent: 'Landscape service activities',
              },
            ],
          },
        ],
      },
      {
        parent:
          'Office administrative, office support and other business support activities',
        child: [
          {
            parent: 'Office administrative and support activities',
            child: [
              {
                parent: 'Combined office administrative service activities',
              },
              {
                parent:
                  'Photocopying, document preparation and other specialised office support activities',
              },
            ],
          },
          {
            parent: 'Activities of call centres',
            child: [
              {
                parent: 'Activities of call centres',
              },
            ],
          },
          {
            parent: 'Organisation of conventions and trade shows',
            child: [
              {
                parent: 'Organisation of conventions and trade shows',
                child: [
                  {
                    parent: 'Activities of exhibition and fair organizers',
                    child: [],
                  },
                  {
                    parent: 'Activities of conference organizers',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Business support service activities n.e.c.',
            child: [
              {
                parent: 'Activities of collection agencies and credit bureaus',
                child: [
                  {
                    parent: 'Activities of collection agencies',
                    child: [],
                  },
                  {
                    parent: 'Activities of credit bureaus',
                    child: [],
                  },
                ],
              },
              {
                parent: 'Packaging activities',
              },
              {
                parent: 'Other business support service activities n.e.c.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Public administration and defence; compulsory social security',
    child: [
      {
        parent: 'Public administration and defence; compulsory social security',
        child: [
          {
            parent:
              'Administration of the State and the economic and social policy of the community',
            child: [
              {
                parent: 'General public administration activities',
              },
              {
                parent:
                  'Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security',
              },
              {
                parent:
                  'Regulation of and contribution to more efficient operation of businesses',
              },
            ],
          },
          {
            parent: 'Provision of services to the community as a whole',
            child: [
              {
                parent: 'Foreign affairs',
              },
              {
                parent: 'Defence activities',
              },
              {
                parent: 'Justice and judicial activities',
              },
              {
                parent: 'Public order and safety activities',
              },
              {
                parent: 'Fire service activities',
              },
            ],
          },
          {
            parent: 'Compulsory social security activities',
            child: [
              {
                parent: 'Compulsory social security activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Education',
    child: [
      {
        parent: 'Education',
        child: [
          {
            parent: 'Pre-primary education',
            child: [
              {
                parent: 'Pre-primary education',
              },
            ],
          },
          {
            parent: 'Primary education',
            child: [
              {
                parent: 'Primary education',
              },
            ],
          },
          {
            parent: 'Secondary education',
            child: [
              {
                parent: 'General secondary education',
              },
              {
                parent: 'Technical and vocational secondary education',
              },
            ],
          },
          {
            parent: 'Higher education',
            child: [
              {
                parent: 'Post-secondary non-tertiary education',
              },
              {
                parent: 'Tertiary education',
                child: [
                  {
                    parent: 'First-degree level higher education',
                    child: [],
                  },
                  {
                    parent: 'Post-graduate level higher education',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Other education',
            child: [
              {
                parent: 'Sports and recreation education',
              },
              {
                parent: 'Cultural education',
              },
              {
                parent: 'Driving school activities',
              },
              {
                parent: 'Other education n.e.c.',
              },
            ],
          },
          {
            parent: 'Educational support activities',
            child: [
              {
                parent: 'Educational support activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Human health and social work activities',
    child: [
      {
        parent: 'Human health activities',
        child: [
          {
            parent: 'Hospital activities',
            child: [
              {
                parent: 'Hospital activities',
                child: [
                  { parent: 'Hospital activities', child: [] },
                  {
                    parent: 'Medical nursing home activities',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Medical and dental practice activities',
            child: [
              {
                parent: 'General medical practice activities',
              },
              {
                parent: 'Specialist medical practice activities',
              },
              {
                parent: 'Dental practice activities',
              },
            ],
          },
          {
            parent: 'Other human health activities',
            child: [
              {
                parent: 'Other human health activities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Residential care activities',
        child: [
          {
            parent: 'Residential nursing care activities',
            child: [
              {
                parent: 'Residential nursing care activities',
              },
            ],
          },
          {
            parent:
              'Residential care activities for learning disabilities, mental health and substance abuse',
            child: [
              {
                parent:
                  'Residential care activities for learning disabilities, mental health and substance abuse',
              },
            ],
          },
          {
            parent: 'Residential care activities for the elderly and disabled',
            child: [
              {
                parent:
                  'Residential care activities for the elderly and disabled',
              },
            ],
          },
          {
            parent: 'Other residential care activities',
            child: [
              {
                parent: 'Other residential care activities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Social work activities without accommodation',
        child: [
          {
            parent:
              'Social work activities without accommodation for the elderly and disabled',
            child: [
              {
                parent:
                  'Social work activities without accommodation for the elderly and disabled',
              },
            ],
          },
          {
            parent: 'Other social work activities without accommodation',
            child: [
              {
                parent: 'Child day-care activities',
              },
              {
                parent:
                  'Other social work activities without accommodation n.e.c.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Arts, entertainment and recreation',
    child: [
      {
        parent: 'Creative, arts and entertainment activities',
        child: [
          {
            parent: 'Creative, arts and entertainment activities',
            child: [
              {
                parent: 'Performing arts',
              },
              {
                parent: 'Support activities to performing arts',
              },
              {
                parent: 'Artistic creation',
              },
              {
                parent: 'Operation of arts facilities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Libraries, archives, museums and other cultural activities',
        child: [
          {
            parent:
              'Libraries, archives, museums and other cultural activities',
            child: [
              {
                parent: 'Library and archive activities',
                child: [
                  { parent: 'Library activities', child: [] },
                  { parent: 'Archive activities', child: [] },
                ],
              },
              {
                parent: 'Museum activities',
              },
              {
                parent:
                  'Operation of historical sites and buildings and similar visitor attractions',
              },
              {
                parent:
                  'Botanical and zoological gardens and nature reserve activities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Gambling and betting activities',
        child: [
          {
            parent: 'Gambling and betting activities',
            child: [
              {
                parent: 'Gambling and betting activities',
              },
            ],
          },
        ],
      },
      {
        parent: 'Sports activities and amusement and recreation activities',
        child: [
          {
            parent: 'Sports activities',
            child: [
              {
                parent: 'Operation of sports facilities',
              },
              {
                parent: 'Activities of sport clubs',
              },
              {
                parent: 'Fitness facilities',
              },
              {
                parent: 'Other sports activities',
                child: [
                  {
                    parent: 'Activities of racehorse owners',
                    child: [],
                  },
                  {
                    parent:
                      'Other sports activities (not including activities of racehorse owners) n.e.c.',
                    child: [],
                  },
                ],
              },
            ],
          },
          {
            parent: 'Amusement and recreation activities',
            child: [
              {
                parent: 'Activities of amusement parks and theme parks',
              },
              {
                parent: 'Other amusement and recreation activities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Other service activities',
    child: [
      {
        parent: 'Activities of membership organisations',
        child: [
          {
            parent:
              'Activities of business, employers and professional membership organisations',
            child: [
              {
                parent:
                  'Activities of business and employers membership organisations',
              },
              {
                parent: 'Activities of professional membership organisations',
              },
            ],
          },
          {
            parent: 'Activities of trade unions',
            child: [
              {
                parent: 'Activities of trade unions',
              },
            ],
          },
          {
            parent: 'Activities of other membership organisations',
            child: [
              {
                parent: 'Activities of religious organisations',
              },
              {
                parent: 'Activities of political organisations',
              },
              {
                parent: 'Activities of other membership organisations n.e.c.',
              },
            ],
          },
        ],
      },
      {
        parent: 'Repair of computers and personal and household goods',
        child: [
          {
            parent: 'Repair of computers and communication equipment',
            child: [
              {
                parent: 'Repair of computers and peripheral equipment',
              },
              {
                parent: 'Repair of communication equipment',
              },
            ],
          },
          {
            parent: 'Repair of personal and household goods',
            child: [
              {
                parent: 'Repair of consumer electronics',
              },
              {
                parent:
                  'Repair of household appliances and home and garden equipment',
              },
              {
                parent: 'Repair of footwear and leather goods',
              },
              {
                parent: 'Repair of furniture and home furnishings',
              },
              {
                parent: 'Repair of watches, clocks and jewellery',
              },
              {
                parent: 'Repair of other personal and household goods',
              },
            ],
          },
        ],
      },
      {
        parent: 'Other personal service activities',
        child: [
          {
            parent: 'Other personal service activities',
            child: [
              {
                parent:
                  'Washing and (dry-)cleaning of textile and fur products',
              },
              {
                parent: 'Hairdressing and other beauty treatment',
              },
              {
                parent: 'Funeral and related activities',
              },
              {
                parent: 'Physical well-being activities',
              },
              {
                parent: 'Other personal service activities n.e.c.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent:
      'Activities of households as employers; undifferentiated goods-and services-producing activities of households for own use',
    child: [
      {
        parent: 'Activities of households as employers of domestic personnel',
        child: [
          {
            parent:
              'Activities of households as employers of domestic personnel',
            child: [
              {
                parent:
                  'Activities of households as employers of domestic personnel',
              },
            ],
          },
        ],
      },
      {
        parent:
          'Undifferentiated goods- and services-producing activities of private households for own use',
        child: [
          {
            parent:
              'Undifferentiated goods-producing activities of private households for own use',
            child: [
              {
                parent:
                  'Undifferentiated goods-producing activities of private households for own use',
              },
            ],
          },
          {
            parent:
              'Undifferentiated service-producing activities of private households for own use',
            child: [
              {
                parent:
                  'Undifferentiated service-producing activities of private households for own use',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    parent: 'Activities of extraterritorial organisations and bodies',
    child: [
      {
        parent: 'Activities of extraterritorial organisations and bodies',
        child: [
          {
            parent: 'Activities of extraterritorial organisations and bodies',
            child: [
              {
                parent:
                  'Activities of extraterritorial organisations and bodies',
              },
            ],
          },
        ],
      },
    ],
  },
];

const SIC_CODE_DESCRIPTION = {
  'manufacture of milk products (other than liquid milk and cream, butter, cheese) n.e.c.':
    '10519',
  'mining of hard coal from open cast coal working (surface mining)': '05102',
  'mining of hard coal from deep coal mines (underground mining)': '05101',
  'mining of hard coal': '0510',
  'growing of cereals (except rice), leguminous crops and oil seeds': '01110',
  'growing of rice': '01120',
  'growing of vegetables and melons, roots and tubers': '01130',
  'growing of sugar cane': '01140',
  'growing of tobacco': '01150',
  'growing of fibre crops': '01160',
  'growing of other non-perennial crops': '01190',
  'growing of grapes': '01210',
  'growing of tropical and subtropical fruits': '01220',
  'growing of citrus fruits': '01230',
  'growing of pome fruits and stone fruits': '01240',
  'growing of other tree and bush fruits and nuts': '01250',
  'growing of oleaginous fruits': '01260',
  'growing of beverage crops': '01270',
  'growing of spices, aromatic, drug and pharmaceutical crops': '01280',
  'growing of other perennial crops': '01290',
  'plant propagation': '01300',
  'raising of dairy cattle': '01410',
  'raising of other cattle and buffaloes': '01420',
  'raising of horses and other equines': '01430',
  'raising of camels and camelids': '01440',
  'raising of sheep and goats': '01450',
  'raising of swine/pigs': '01460',
  'raising of poultry': '01470',
  'raising of other animals': '01490',
  'mixed farming': '01500',
  'support activities for crop production': '01610',
  'farm animal boarding and care': '01621',
  'support activities for animal production (other than farm animal boarding and care) n.e.c.':
    '01629',
  'post-harvest crop activities': '01630',
  'seed processing for propagation': '01640',
  'hunting, trapping and related service activities': '01700',
  'silviculture and other forestry activities': '02100',
  logging: '02200',
  'gathering of wild growing non-wood products': '02300',
  'support services to forestry': '02400',
  'marine fishing': '03110',
  'freshwater fishing': '03120',
  'marine aquaculture': '03210',
  'freshwater aquaculture': '03220',
  'deep coal mines': '05101',
  'open cast coal working': '05102',
  'mining of lignite': '05200',
  'extraction of crude petroleum': '06100',
  'extraction of natural gas': '06200',
  'mining of iron ores': '07100',
  'mining of uranium and thorium ores': '07210',
  'mining of other non-ferrous metal ores': '07290',
  'quarrying of ornamental and building stone, limestone, gypsum, chalk and slate':
    '08110',
  'operation of gravel and sand pits; mining of clays and kaolin': '08120',
  'mining of chemical and fertiliser minerals': '08910',
  'extraction of peat': '08920',
  'extraction of salt': '08930',
  'other mining and quarrying n.e.c.': '08990',
  'support activities for petroleum and natural gas extraction': '09100',
  'support activities for other mining and quarrying': '09900',
  'processing and preserving of meat': '10110',
  'processing and preserving of poultry meat': '10120',
  'production of meat and poultry meat products': '10130',
  'processing and preserving of fish, crustaceans and molluscs': '10200',
  'processing and preserving of potatoes': '10310',
  'manufacture of fruit and vegetable juice': '10320',
  'other processing and preserving of fruit and vegetables': '10390',
  'manufacture of oils and fats': '10410',
  'manufacture of margarine and similar edible fats': '10420',
  'liquid milk and cream production': '10511',
  'butter and cheese production': '10512',
  'manufacture of ice cream': '10520',
  'grain milling': '10611',
  'manufacture of breakfast cereals and cereals-based foods': '10612',
  'manufacture of starches and starch products': '10620',
  'manufacture of bread; manufacture of fresh pastry goods and cakes': '10710',
  'manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes':
    '10720',
  'manufacture of macaroni, noodles, couscous and similar farinaceous products':
    '10730',
  'manufacture of sugar': '10810',
  'manufacture of cocoa, and chocolate confectionery': '10821',
  'manufacture of sugar confectionery': '10822',
  'tea processing': '10831',
  'production of coffee and coffee substitutes': '10832',
  'manufacture of condiments and seasonings': '10840',
  'manufacture of prepared meals and dishes': '10850',
  'manufacture of homogenised food preparations and dietetic food': '10860',
  'manufacture of other food products n.e.c.': '10890',
  'manufacture of prepared feeds for farm animals': '10910',
  'manufacture of prepared pet foods': '10920',
  'distilling, rectifying and blending of spirits': '11010',
  'manufacture of wine from grape': '11020',
  'manufacture of cider and other fruit wines': '11030',
  'manufacture of other non-distilled fermented beverages': '11040',
  'manufacture of beer': '11050',
  'manufacture of malt': '11060',
  'manufacture of soft drinks; production of mineral waters and other bottled waters':
    '11070',
  'manufacture of tobacco products': '12000',
  'preparation and spinning of textile fibres': '13100',
  'weaving of textiles': '13200',
  'finishing of textiles': '13300',
  'manufacture of knitted and crocheted fabrics': '13910',
  'manufacture of soft furnishings': '13921',
  'manufacture of canvas goods, sacks etc.': '13922',
  'manufacture of household textiles (other than soft furnishings of 13.92/1)':
    '13923',
  'manufacture of woven or tufted carpets and rugs': '13931',
  'manufacture of carpets and rugs (other than woven or tufted) n.e.c.':
    '13939',
  'manufacture of cordage, rope, twine and netting': '13940',
  'manufacture of non-wovens and articles made from non-wovens, except apparel':
    '13950',
  'manufacture of other technical and industrial textiles': '13960',
  'manufacture of other textiles n.e.c.': '13990',
  'manufacture of leather clothes': '14110',
  'manufacture of workwear': '14120',
  "manufacture of other men's outerwear": '14131',
  "manufacture of other women's outerwear": '14132',
  "manufacture of men's underwear": '14141',
  "manufacture of women's underwear": '14142',
  'manufacture of other wearing apparel and accessories n.e.c.': '14190',
  'manufacture of articles of fur': '14200',
  'manufacture of knitted and crocheted hosiery': '14310',
  'manufacture of other knitted and crocheted apparel': '14390',
  'tanning and dressing of leather; dressing and dyeing of fur': '15110',
  'manufacture of luggage, handbags and the like, saddlery and harness':
    '15120',
  'manufacture of footwear': '15200',
  'sawmilling and planing of wood': '16100',
  'manufacture of veneer sheets and wood-based panels': '16210',
  'manufacture of assembled parquet floors': '16220',
  "manufacture of other builders' carpentry and joinery": '16230',
  'manufacture of wooden containers': '16240',
  'manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials':
    '16290',
  'manufacture of pulp': '17110',
  'manufacture of paper and paperboard': '17120',
  'manufacture of corrugated paper and paperboard, sacks and bags': '17211',
  'manufacture of other paper and paperboard containers': '17219',
  'manufacture of household and sanitary goods and of toilet requisites':
    '17220',
  'manufacture of paper stationery': '17230',
  'manufacture of wallpaper': '17240',
  'manufacture of other articles of paper and paperboard n.e.c.': '17290',
  'printing of newspapers': '18110',
  'manufacture of printed labels': '18121',
  'printing n.e.c.': '18129',
  'pre-press and pre-media services': '18130',
  'binding and related services': '18140',
  'reproduction of sound recording': '18201',
  'reproduction of video recording': '18202',
  'reproduction of computer media': '18203',
  'manufacture of coke oven products': '19100',
  'mineral oil refining': '19201',
  'other treatment of petroleum products (excluding petrochemicals manufacture)':
    '19209',
  'manufacture of industrial gases': '20110',
  'manufacture of dyes and pigments': '20120',
  'manufacture of other inorganic basic chemicals': '20130',
  'manufacture of other organic basic chemicals': '20140',
  'manufacture of fertilisers and nitrogen compounds': '20150',
  'manufacture of plastics in primary forms': '20160',
  'manufacture of synthetic rubber in primary forms': '20170',
  'manufacture of pesticides and other agrochemical products': '20200',
  'manufacture of paints, varnishes and similar coatings, mastics and sealants':
    '20301',
  'manufacture of printing ink': '20302',
  'manufacture of soap and detergents': '20411',
  'manufacture of cleaning and polishing preparations': '20412',
  'manufacture of perfumes and toilet preparations': '20420',
  'manufacture of explosives': '20510',
  'manufacture of glues': '20520',
  'manufacture of essential oils': '20530',
  'manufacture of other chemical products n.e.c.': '20590',
  'manufacture of man-made fibres': '20600',
  'manufacture of basic pharmaceutical products': '21100',
  'manufacture of pharmaceutical preparations': '21200',
  'manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres':
    '22110',
  'manufacture of other rubber products': '22190',
  'manufacture of plastic plates, sheets, tubes and profiles': '22210',
  'manufacture of plastic packing goods': '22220',
  'manufacture of builders  ware of plastic': '22230',
  'manufacture of other plastic products': '22290',
  'manufacture of flat glass': '23110',
  'shaping and processing of flat glass': '23120',
  'manufacture of hollow glass': '23130',
  'manufacture of glass fibres': '23140',
  'manufacture and processing of other glass, including technical glassware':
    '23190',
  'manufacture of refractory products': '23200',
  'manufacture of ceramic tiles and flags': '23310',
  'manufacture of bricks, tiles and construction products, in baked clay':
    '23320',
  'manufacture of ceramic household and ornamental articles': '23410',
  'manufacture of ceramic sanitary fixtures': '23420',
  'manufacture of ceramic insulators and insulating fittings': '23430',
  'manufacture of other technical ceramic products': '23440',
  'manufacture of other ceramic products n.e.c.': '23490',
  'manufacture of cement': '23510',
  'manufacture of lime and plaster': '23520',
  'manufacture of concrete products for construction purposes': '23610',
  'manufacture of plaster products for construction purposes': '23620',
  'manufacture of ready-mixed concrete': '23630',
  'manufacture of mortars': '23640',
  'manufacture of fibre cement': '23650',
  'manufacture of other articles of concrete, plaster and cement': '23690',
  'cutting, shaping and finishing of stone': '23700',
  'production of abrasive products': '23910',
  'manufacture of other non-metallic mineral products n.e.c.': '23990',
  'manufacture of basic iron and steel and of ferro-alloys': '24100',
  'manufacture of tubes, pipes, hollow profiles and related fittings, of steel':
    '24200',
  'cold drawing of bars': '24310',
  'cold rolling of narrow strip': '24320',
  'cold forming or folding': '24330',
  'cold drawing of wire': '24340',
  'precious metals production': '24410',
  'aluminium production': '24420',
  'lead, zinc and tin production': '24430',
  'copper production': '24440',
  'other non-ferrous metal production': '24450',
  'processing of nuclear fuel': '24460',
  'casting of iron': '24510',
  'casting of steel': '24520',
  'casting of light metals': '24530',
  'casting of other non-ferrous metals': '24540',
  'manufacture of metal structures and parts of structures': '25110',
  'manufacture of doors and windows of metal': '25120',
  'manufacture of central heating radiators and boilers': '25210',
  'manufacture of other tanks, reservoirs and containers of metal': '25290',
  'manufacture of steam generators, except central heating hot water boilers':
    '25300',
  'manufacture of weapons and ammunition': '25400',
  'forging, pressing, stamping and roll-forming of metal; powder metallurgy':
    '25500',
  'treatment and coating of metals': '25610',
  machining: '25620',
  'manufacture of cutlery': '25710',
  'manufacture of locks and hinges': '25720',
  'manufacture of tools': '25730',
  'manufacture of steel drums and similar containers': '25910',
  'manufacture of light metal packaging': '25920',
  'manufacture of wire products, chain and springs': '25930',
  'manufacture of fasteners and screw machine products': '25940',
  'manufacture of other fabricated metal products n.e.c.': '25990',
  'manufacture of electronic components': '26110',
  'manufacture of loaded electronic boards': '26120',
  'manufacture of computers and peripheral equipment': '26200',
  'manufacture of telegraph and telephone apparatus and equipment': '26301',
  'manufacture of communication equipment other than telegraph, and telephone apparatus and equipment':
    '26309',
  'manufacture of consumer electronics': '26400',
  'manufacture of electronic measuring, testing etc. equipment, not for industrial process control':
    '26511',
  'manufacture of electronic industrial process control equipment': '26512',
  'manufacture of non-electronic measuring, testing etc. equipment, not for industrial process control':
    '26513',
  'manufacture of non-electronic industrial process control equipment': '26514',
  'manufacture of watches and clocks': '26520',
  'manufacture of irradiation, electromedical and electrotherapeutic equipment':
    '26600',
  'manufacture of optical precision instruments': '26701',
  'manufacture of photographic and cinematographic equipment': '26702',
  'manufacture of magnetic and optical media': '26800',
  'manufacture of electric motors, generators and transformers': '27110',
  'manufacture of electricity distribution and control apparatus': '27120',
  'manufacture of batteries and accumulators': '27200',
  'manufacture of fibre optic cables': '27310',
  'manufacture of other electronic and electric wires and cables': '27320',
  'manufacture of wiring devices': '27330',
  'manufacture of electric lighting equipment': '27400',
  'manufacture of electric domestic appliances': '27510',
  'manufacture of non-electric domestic appliances': '27520',
  'manufacture of other electrical equipment': '27900',
  'manufacture of engines and turbines, except aircraft, vehicle and cycle engines':
    '28110',
  'manufacture of fluid power equipment': '28120',
  'manufacture of pumps': '28131',
  'manufacture of compressors': '28132',
  'manufacture of taps and valves': '28140',
  'manufacture of bearings, gears, gearing and driving elements': '28150',
  'manufacture of ovens, furnaces and furnace burners': '28210',
  'manufacture of lifting and handling equipment': '28220',
  'manufacture of office machinery and equipment (except computers and peripheral equipment)':
    '28230',
  'manufacture of power-driven hand tools': '28240',
  'manufacture of non-domestic cooling and ventilation equipment': '28250',
  'manufacture of other general-purpose machinery n.e.c.': '28290',
  'manufacture of agricultural tractors': '28301',
  'manufacture of agricultural and forestry machinery other than tractors':
    '28302',
  'manufacture of metal forming machinery': '28410',
  'manufacture of other machine tools': '28490',
  'manufacture of machinery for metallurgy': '28910',
  'manufacture of machinery for mining': '28921',
  'manufacture of earthmoving equipment': '28922',
  'manufacture of equipment for concrete crushing and screening and roadworks':
    '28923',
  'manufacture of machinery for food, beverage and tobacco processing': '28930',
  'manufacture of machinery for textile, apparel and leather production':
    '28940',
  'manufacture of machinery for paper and paperboard production': '28950',
  'manufacture of plastics and rubber machinery': '28960',
  'manufacture of other special-purpose machinery n.e.c.': '28990',
  'manufacture of motor vehicles': '29100',
  'manufacture of bodies (coachwork) for motor vehicles (except caravans)':
    '29201',
  'manufacture of trailers and semi-trailers': '29202',
  'manufacture of caravans': '29203',
  'manufacture of electrical and electronic equipment for motor vehicles and their engines':
    '29310',
  'manufacture of other parts and accessories for motor vehicles': '29320',
  'building of ships and floating structures': '30110',
  'building of pleasure and sporting boats': '30120',
  'manufacture of railway locomotives and rolling stock': '30200',
  'manufacture of air and spacecraft and related machinery': '30300',
  'manufacture of military fighting vehicles': '30400',
  'manufacture of motorcycles': '30910',
  'manufacture of bicycles and invalid carriages': '30920',
  'manufacture of other transport equipment n.e.c.': '30990',
  'manufacture of office and shop furniture': '31010',
  'manufacture of kitchen furniture': '31020',
  'manufacture of mattresses': '31030',
  'manufacture of other furniture': '31090',
  'striking of coins': '32110',
  'manufacture of jewellery and related articles': '32120',
  'manufacture of imitation jewellery and related articles': '32130',
  'manufacture of musical instruments': '32200',
  'manufacture of sports goods': '32300',
  'manufacture of professional and arcade games and toys': '32401',
  'manufacture of other games and toys, n.e.c.': '32409',
  'manufacture of medical and dental instruments and supplies': '32500',
  'manufacture of brooms and brushes': '32910',
  'other manufacturing n.e.c.': '32990',
  'repair of fabricated metal products': '33110',
  'repair of machinery': '33120',
  'repair of electronic and optical equipment': '33130',
  'repair of electrical equipment': '33140',
  'repair and maintenance of ships and boats': '33150',
  'repair and maintenance of aircraft and spacecraft': '33160',
  'repair and maintenance of other transport equipment n.e.c.': '33170',
  'repair of other equipment': '33190',
  'installation of industrial machinery and equipment': '33200',
  'production of electricity': '35110',
  'transmission of electricity': '35120',
  'distribution of electricity': '35130',
  'trade of electricity': '35140',
  'manufacture of gas': '35210',
  'distribution of gaseous fuels through mains': '35220',
  'trade of gas through mains': '35230',
  'steam and air conditioning supply': '35300',
  'water collection, treatment and supply': '36000',
  sewerage: '37000',
  'collection of non-hazardous waste': '38110',
  'collection of hazardous waste': '38120',
  'treatment and disposal of non-hazardous waste': '38210',
  'treatment and disposal of hazardous waste': '38220',
  'dismantling of wrecks': '38310',
  'recovery of sorted materials': '38320',
  'remediation activities and other waste management services': '39000',
  'development of building projects': '41100',
  'construction of commercial buildings': '41201',
  'construction of domestic buildings': '41202',
  'construction of roads and motorways': '42110',
  'construction of railways and underground railways': '42120',
  'construction of bridges and tunnels': '42130',
  'construction of utility projects for fluids': '42210',
  'construction of utility projects for electricity and telecommunications':
    '42220',
  'construction of water projects': '42910',
  'construction of other civil engineering projects n.e.c.': '42990',
  demolition: '43110',
  'site preparation': '43120',
  'test drilling and boring': '43130',
  'electrical installation': '43210',
  'plumbing, heat and air-conditioning installation': '43220',
  'other construction installation': '43290',
  plastering: '43310',
  'joinery installation': '43320',
  'floor and wall covering': '43330',
  painting: '43341',
  glazing: '43342',
  'other building completion and finishing': '43390',
  'roofing activities': '43910',
  'scaffold erection': '43991',
  'other specialised construction activities n.e.c.': '43999',
  'sale of new cars and light motor vehicles': '45111',
  'sale of used cars and light motor vehicles': '45112',
  'sale of other motor vehicles': '45190',
  'maintenance and repair of motor vehicles': '45200',
  'wholesale trade of motor vehicle parts and accessories': '45310',
  'retail trade of motor vehicle parts and accessories': '45320',
  'sale, maintenance and repair of motorcycles and related parts and accessories':
    '45400',
  'agents selling agricultural raw materials, livestock, textile raw materials and semi-finished goods':
    '46110',
  'agents involved in the sale of fuels, ores, metals and industrial chemicals':
    '46120',
  'agents involved in the sale of timber and building materials': '46130',
  'agents involved in the sale of machinery, industrial equipment, ships and aircraft':
    '46140',
  'agents involved in the sale of furniture, household goods, hardware and ironmongery':
    '46150',
  'agents involved in the sale of textiles, clothing, fur, footwear and leather goods':
    '46160',
  'agents involved in the sale of food, beverages and tobacco': '46170',
  'agents specialised in the sale of other particular products': '46180',
  'agents involved in the sale of a variety of goods': '46190',
  'wholesale of grain, unmanufactured tobacco, seeds and animal feeds': '46210',
  'wholesale of flowers and plants': '46220',
  'wholesale of live animals': '46230',
  'wholesale of hides, skins and leather': '46240',
  'wholesale of fruit and vegetables': '46310',
  'wholesale of meat and meat products': '46320',
  'wholesale of dairy products, eggs and edible oils and fats': '46330',
  'wholesale of fruit and vegetable juices, mineral water and soft drinks':
    '46341',
  'wholesale of wine, beer, spirits and other alcoholic beverages': '46342',
  'wholesale of tobacco products': '46350',
  'wholesale of sugar and chocolate and sugar confectionery': '46360',
  'wholesale of coffee, tea, cocoa and spices': '46370',
  'wholesale of other food, including fish, crustaceans and molluscs': '46380',
  'non-specialised wholesale of food, beverages and tobacco': '46390',
  'wholesale of textiles': '46410',
  'wholesale of clothing and footwear': '46420',
  'wholesale of audio tapes, records, cds and video tapes and the equipment on which these are played':
    '46431',
  "wholesale of radio, television goods & electrical household appliances (other than records, tapes, cd's & video tapes and the equipment used for playing them)":
    '46439',
  'wholesale of china and glassware and cleaning materials': '46440',
  'wholesale of perfume and cosmetics': '46450',
  'wholesale of pharmaceutical goods': '46460',
  'wholesale of furniture, carpets and lighting equipment': '46470',
  'wholesale of watches and jewellery': '46480',
  'wholesale of musical instruments': '46491',
  'wholesale of household goods (other than musical instruments) n.e.c.':
    '46499',
  'wholesale of computers, computer peripheral equipment and software': '46510',
  'wholesale of electronic and telecommunications equipment and parts': '46520',
  'wholesale of agricultural machinery, equipment and supplies': '46610',
  'wholesale of machine tools': '46620',
  'wholesale of mining, construction and civil engineering machinery': '46630',
  'wholesale of machinery for the textile industry and of sewing and knitting machines':
    '46640',
  'wholesale of office furniture': '46650',
  'wholesale of other office machinery and equipment': '46660',
  'wholesale of other machinery and equipment': '46690',
  'wholesale of petroleum and petroleum products': '46711',
  'wholesale of other fuels and related products': '46719',
  'wholesale of metals and metal ores': '46720',
  'wholesale of wood, construction materials and sanitary equipment': '46730',
  'wholesale of hardware, plumbing and heating equipment and supplies': '46740',
  'wholesale of chemical products': '46750',
  'wholesale of other intermediate products': '46760',
  'wholesale of waste and scrap': '46770',
  'non-specialised wholesale trade': '46900',
  'retail sale in non-specialised stores with food, beverages or tobacco predominating':
    '47110',
  'other retail sale in non-specialised stores': '47190',
  'retail sale of fruit and vegetables in specialised stores': '47210',
  'retail sale of meat and meat products in specialised stores': '47220',
  'retail sale of fish, crustaceans and molluscs in specialised stores':
    '47230',
  'retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores':
    '47240',
  'retail sale of beverages in specialised stores': '47250',
  'retail sale of tobacco products in specialised stores': '47260',
  'other retail sale of food in specialised stores': '47290',
  'retail sale of automotive fuel in specialised stores': '47300',
  'retail sale of computers, peripheral units and software in specialised stores':
    '47410',
  'retail sale of mobile telephones': '47421',
  'retail sale of telecommunications equipment other than mobile telephones':
    '47429',
  'retail sale of audio and video equipment in specialised stores': '47430',
  'retail sale of textiles in specialised stores': '47510',
  'retail sale of hardware, paints and glass in specialised stores': '47520',
  'retail sale of carpets, rugs, wall and floor coverings in specialised stores':
    '47530',
  'retail sale of electrical household appliances in specialised stores':
    '47540',
  'retail sale of musical instruments and scores': '47591',
  'retail of furniture, lighting, and similar (not musical instruments or scores) in specialised store':
    '47599',
  'retail sale of books in specialised stores': '47610',
  'retail sale of newspapers and stationery in specialised stores': '47620',
  'retail sale of music and video recordings in specialised stores': '47630',
  'retail sale of sports goods, fishing gear, camping goods, boats and bicycles':
    '47640',
  'retail sale of games and toys in specialised stores': '47650',
  'retail sale of clothing in specialised stores': '47710',
  'retail sale of footwear in specialised stores': '47721',
  'retail sale of leather goods in specialised stores': '47722',
  'dispensing chemist in specialised stores': '47730',
  'retail sale of hearing aids': '47741',
  'retail sale of medical and orthopaedic goods in specialised stores (not incl. hearing aids) n.e.c.':
    '47749',
  'retail sale of cosmetic and toilet articles in specialised stores': '47750',
  'retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores':
    '47760',
  'retail sale of watches and jewellery in specialised stores': '47770',
  'retail sale in commercial art galleries': '47781',
  'retail sale by opticians': '47782',
  'other retail sale of new goods in specialised stores (not commercial art galleries and opticians)':
    '47789',
  'retail sale of antiques including antique books in stores': '47791',
  'retail sale of other second-hand goods in stores (not incl. antiques)':
    '47799',
  'retail sale via stalls and markets of food, beverages and tobacco products':
    '47810',
  'retail sale via stalls and markets of textiles, clothing and footwear':
    '47820',
  'retail sale via stalls and markets of other goods': '47890',
  'retail sale via mail order houses or via internet': '47910',
  'other retail sale not in stores, stalls or markets': '47990',
  'passenger rail transport, interurban': '49100',
  'freight rail transport': '49200',
  'urban and suburban passenger railway transportation by underground, metro and similar systems':
    '49311',
  'other urban, suburban or metropolitan passenger land transport (not underground, metro or similar)':
    '49319',
  'taxi operation': '49320',
  'other passenger land transport': '49390',
  'freight transport by road': '49410',
  'removal services': '49420',
  'transport via pipeline': '49500',
  'sea and coastal passenger water transport': '50100',
  'sea and coastal freight water transport': '50200',
  'inland passenger water transport': '50300',
  'inland freight water transport': '50400',
  'scheduled passenger air transport': '51101',
  'non-scheduled passenger air transport': '51102',
  'freight air transport': '51210',
  'space transport': '51220',
  'operation of warehousing and storage facilities for water transport activities':
    '52101',
  'operation of warehousing and storage facilities for air transport activities':
    '52102',
  'operation of warehousing and storage facilities for land transport activities':
    '52103',
  'operation of rail freight terminals': '52211',
  'operation of rail passenger facilities at railway stations': '52212',
  'operation of bus and coach passenger facilities at bus and coach stations':
    '52213',
  'other service activities incidental to land transportation, n.e.c.': '52219',
  'service activities incidental to water transportation': '52220',
  'service activities incidental to air transportation': '52230',
  'cargo handling for water transport activities': '52241',
  'cargo handling for air transport activities': '52242',
  'cargo handling for land transport activities': '52243',
  'other transportation support activities': '52290',
  'postal activities under universal service obligation': '53100',
  'licensed carriers': '53201',
  'unlicensed carrier': '53202',
  'hotels and similar accommodation': '55100',
  'holiday centres and villages': '55201',
  'youth hostels': '55202',
  'other holiday and other collective accommodation': '55209',
  'recreational vehicle parks, trailer parks and camping grounds': '55300',
  'other accommodation': '55900',
  'licensed restaurants': '56101',
  'unlicensed restaurants and cafes': '56102',
  'take-away food shops and mobile food stands': '56103',
  'event catering activities': '56210',
  'other food services': '56290',
  'licensed clubs': '56301',
  'public houses and bars': '56302',
  'book publishing': '58110',
  'publishing of directories and mailing lists': '58120',
  'publishing of newspapers': '58130',
  'publishing of learned journals': '58141',
  'publishing of  consumer and business journals and periodicals': '58142',
  'other publishing activities': '58190',
  'publishing of computer games': '58210',
  'other software publishing': '58290',
  'motion picture production activities': '59111',
  'video production activities': '59112',
  'television programme production activities': '59113',
  'motion picture, video and television programme post-production activities':
    '59120',
  'motion picture distribution activities': '59131',
  'video distribution activities': '59132',
  'television programme distribution activities': '59133',
  'motion picture projection activities': '59140',
  'sound recording and music publishing activities': '59200',
  'radio broadcasting': '60100',
  'television programming and broadcasting activities': '60200',
  'wired telecommunications activities': '61100',
  'wireless telecommunications activities': '61200',
  'satellite telecommunications activities': '61300',
  'other telecommunications activities': '61900',
  'ready-made interactive leisure and entertainment software development':
    '62011',
  'business and domestic software development': '62012',
  'information technology consultancy activities': '62020',
  'computer facilities management activities': '62030',
  'other information technology service activities': '62090',
  'data processing, hosting and related activities': '63110',
  'web portals': '63120',
  'news agency activities': '63910',
  'other information service activities n.e.c.': '63990',
  'central banking': '64110',
  banks: '64191',
  'building societies': '64192',
  'activities of agricultural holding companies': '64201',
  'activities of production holding companies': '64202',
  'activities of construction holding companies': '64203',
  'activities of distribution holding companies': '64204',
  'activities of financial services holding companies': '64205',
  'activities of other holding companies n.e.c.': '64209',
  'activities of investment trusts': '64301',
  'activities of unit trusts': '64302',
  'activities of venture and development capital companies': '64303',
  'activities of open-ended investment companies': '64304',
  'activities of property unit trusts': '64305',
  'activities of real estate investment trusts': '64306',
  'financial leasing': '64910',
  'credit granting by non-deposit taking finance houses and other specialist consumer credit grantors':
    '64921',
  'activities of mortgage finance companies': '64922',
  'other credit granting n.e.c.': '64929',
  'security dealing on own account': '64991',
  factoring: '64992',
  'financial intermediation not elsewhere classified': '64999',
  'life insurance': '65110',
  'non-life insurance': '65120',
  'life reinsurance': '65201',
  'non-life reinsurance': '65202',
  'pension funding': '65300',
  'administration of financial markets': '66110',
  'security and commodity contracts dealing activities': '66120',
  'activities auxiliary to financial intermediation n.e.c.': '66190',
  'risk and damage evaluation': '66210',
  'activities of insurance agents and brokers': '66220',
  'other activities auxiliary to insurance and pension funding': '66290',
  'fund management activities': '66300',
  'buying and selling of own real estate': '68100',
  'renting and operating of housing association real estate': '68201',
  'letting and operating of conference and exhibition centres': '68202',
  'other letting and operating of own or leased real estate': '68209',
  'real estate agencies': '68310',
  'management of real estate on a fee or contract basis': '68320',
  'barristers at law': '69101',
  solicitors: '69102',
  'activities of patent and copyright agents; other legal activities n.e.c.':
    '69109',
  'accounting and auditing activities': '69201',
  'bookkeeping activities': '69202',
  'tax consultancy': '69203',
  'activities of head offices': '70100',
  'public relations and communications activities': '70210',
  'financial management': '70221',
  'management consultancy activities other than financial management': '70229',
  'architectural activities': '71111',
  'urban planning and landscape architectural activities': '71112',
  'engineering design activities for industrial process and production':
    '71121',
  'engineering related scientific and technical consulting activities': '71122',
  'other engineering activities': '71129',
  'technical testing and analysis': '71200',
  'research and experimental development on biotechnology': '72110',
  'other research and experimental development on natural sciences and engineering':
    '72190',
  'research and experimental development on social sciences and humanities':
    '72200',
  'advertising agencies': '73110',
  'media representation services': '73120',
  'market research and public opinion polling': '73200',
  'specialised design activities': '74100',
  'portrait photographic activities': '74201',
  'other specialist photography': '74202',
  'film processing': '74203',
  'photographic activities not elsewhere classified': '74209',
  'translation and interpretation activities': '74300',
  'environmental consulting activities': '74901',
  'quantity surveying activities': '74902',
  'other professional, scientific and technical activities n.e.c.': '74909',
  'non-trading company': '74990',
  'veterinary activities': '75000',
  'renting and leasing of cars and light motor vehicles': '77110',
  'renting and leasing of trucks and other heavy vehicles': '77120',
  'renting and leasing of recreational and sports goods': '77210',
  'renting of video tapes and disks': '77220',
  'renting and leasing of media entertainment equipment': '77291',
  'renting and leasing of other personal and household goods': '77299',
  'renting and leasing of agricultural machinery and equipment': '77310',
  'renting and leasing of construction and civil engineering machinery and equipment':
    '77320',
  'renting and leasing of office machinery and equipment (including computers)':
    '77330',
  'renting and leasing of passenger water transport equipment': '77341',
  'renting and leasing of freight water transport equipment': '77342',
  'renting and leasing of air passenger transport equipment': '77351',
  'renting and leasing of freight air transport equipment': '77352',
  'renting and leasing of other machinery, equipment and tangible goods n.e.c.':
    '77390',
  'leasing of intellectual property and similar products, except copyright works':
    '77400',
  'motion picture, television and other theatrical casting activities': '78101',
  'other activities of employment placement agencies': '78109',
  'temporary employment agency activities': '78200',
  'human resources provision and management of human resources functions':
    '78300',
  'travel agency activities': '79110',
  'tour operator activities': '79120',
  'activities of tourist guides': '79901',
  'other reservation service activities n.e.c.': '79909',
  'private security activities': '80100',
  'security systems service activities': '80200',
  'investigation activities': '80300',
  'combined facilities support activities': '81100',
  'general cleaning of buildings': '81210',
  'window cleaning services': '81221',
  'specialised cleaning services': '81222',
  'furnace and chimney cleaning services': '81223',
  'other building and industrial cleaning activities': '81229',
  'disinfecting and exterminating services': '81291',
  'other cleaning services': '81299',
  'landscape service activities': '81300',
  'combined office administrative service activities': '82110',
  'photocopying, document preparation and other specialised office support activities':
    '82190',
  'activities of call centres': '82200',
  'activities of exhibition and fair organisers': '82301',
  'activities of conference organisers': '82302',
  'activities of collection agencies': '82911',
  'activities of credit bureaus': '82912',
  'packaging activities': '82920',
  'other business support service activities n.e.c.': '82990',
  'general public administration activities': '84110',
  'regulation of health care, education, cultural and other social services, not incl. social security':
    '84120',
  'regulation of and contribution to more efficient operation of businesses':
    '84130',
  'foreign affairs': '84210',
  'defence activities': '84220',
  'justice and judicial activities': '84230',
  'public order and safety activities': '84240',
  'fire service activities': '84250',
  'compulsory social security activities': '84300',
  'pre-primary education': '85100',
  'primary education': '85200',
  'general secondary education': '85310',
  'technical and vocational secondary education': '85320',
  'post-secondary non-tertiary education': '85410',
  'first-degree level higher education': '85421',
  'post-graduate level higher education': '85422',
  'sports and recreation education': '85510',
  'cultural education': '85520',
  'driving school activities': '85530',
  'other education n.e.c.': '85590',
  'educational support services': '85600',
  'hospital activities': '86101',
  'medical nursing home activities': '86102',
  'general medical practice activities': '86210',
  'specialists medical practice activities': '86220',
  'dental practice activities': '86230',
  'other human health activities': '86900',
  'residential nursing care facilities': '87100',
  'residential care activities for learning difficulties, mental health and substance abuse':
    '87200',
  'residential care activities for the elderly and disabled': '87300',
  'other residential care activities n.e.c.': '87900',
  'social work activities without accommodation for the elderly and disabled':
    '88100',
  'child day-care activities': '88910',
  'other social work activities without accommodation n.e.c.': '88990',
  'performing arts': '90010',
  'support activities to performing arts': '90020',
  'artistic creation': '90030',
  'operation of arts facilities': '90040',
  'library activities': '91011',
  'archives activities': '91012',
  'museums activities': '91020',
  'operation of historical sites and buildings and similar visitor attractions':
    '91030',
  'botanical and zoological gardens and nature reserves activities': '91040',
  'gambling and betting activities': '92000',
  'operation of sports facilities': '93110',
  'activities of sport clubs': '93120',
  'fitness facilities': '93130',
  'activities of racehorse owners': '93191',
  'other sports activities': '93199',
  'activities of amusement parks and theme parks': '93210',
  'other amusement and recreation activities n.e.c.': '93290',
  'activities of business and employers membership organisations': '94110',
  'activities of professional membership organisations': '94120',
  'activities of trade unions': '94200',
  'activities of religious organisations': '94910',
  'activities of political organisations': '94920',
  'activities of other membership organisations n.e.c.': '94990',
  'repair of computers and peripheral equipment': '95110',
  'repair of communication equipment': '95120',
  'repair of consumer electronics': '95210',
  'repair of household appliances and home and garden equipment': '95220',
  'repair of footwear and leather goods': '95230',
  'repair of furniture and home furnishings': '95240',
  'repair of watches, clocks and jewellery': '95250',
  'repair of personal and household goods n.e.c.': '95290',
  'washing and (dry-)cleaning of textile and fur products': '96010',
  'hairdressing and other beauty treatment': '96020',
  'funeral and related activities': '96030',
  'physical well-being activities': '96040',
  'other service activities n.e.c.': '96090',
  'activities of households as employers of domestic personnel': '97000',
  'residents property management': '98000',
  'undifferentiated goods-producing activities of private households for own use':
    '98100',
  'undifferentiated service-producing activities of private households for own use':
    '98200',
  'activities of extraterritorial organisations and bodies': '99000',
  'dormant company': '99999',
};

const HUB_SPOT_URL =
  'https://www.probedigital.co.uk/uk-company-information-for-corporate-risk-b2b-marketing-sales-valuation-research-compliance-probedigital?hs_preview=JovhDUiG-108698539504&hsLang=en-gb';

// remove this gra.ph data after test
export const GRAPH_DATA = {
  nodes: [
    {
      id: '174348410001',
      entityType: 'person',
      entityName: 'MRS NORAH TATIANA LIDDERDALE',
      entityData: {},
    },
    {
      id: '08569628',
      entityType: 'company',
      entityName: 'SIMULUS INTERACTIVE LEARNING LTD',
      entityData: {},
    },
    {
      id: '10941201',
      entityType: 'company',
      entityName: 'BARLEY CROFT HOLDINGS LIMITED',
      entityData: {},
    },
    {
      id: '089663500001',
      entityType: 'person',
      entityName: 'MR RICHARD JAMES MARSHALL',
      entityData: {},
    },
    {
      id: '10969882',
      entityType: 'company',
      entityName: 'NAVARINOU HOLDINGS LIMITED',
      entityData: {},
    },
    {
      id: '14588944',
      entityType: 'company',
      entityName: 'SHOPTIVITYLABS LTD',
      entityData: {},
    },
    {
      id: '09577759',
      entityType: 'company',
      entityName: 'TIMELY SIMULATIONS LTD',
      entityData: {},
    },
    {
      id: '05222135',
      entityType: 'company',
      entityName: 'PSP UK GROUP LTD',
      entityData: {},
    },
    {
      id: '04738463',
      entityType: 'company',
      entityName: 'SIGN SCENE (UK) LIMITED',
      entityData: {},
    },
    {
      id: '08329259',
      entityType: 'company',
      entityName: 'ILIAD5 LIMITED',
      entityData: {},
    },
    {
      id: '217439770001',
      entityType: 'person',
      entityName: 'MR IAN HARRY ETCHELLS',
      entityData: {},
    },
    {
      id: '13480798',
      entityType: 'company',
      entityName: 'IP SOFTWARE HOLDINGS LIMITED',
      entityData: {},
    },
    {
      id: '304175960001',
      entityType: 'person',
      entityName: 'MR STANIMIR ANGELOV',
      entityData: {},
    },
    {
      id: '239081870001',
      entityType: 'person',
      entityName: 'MR NEIL JAMES RICHMOND',
      entityData: {},
    },
    {
      id: '11016733',
      entityType: 'company',
      entityName: 'NORTHERN MONKEY CREATIVE THINKING LIMITED',
      entityData: {},
    },
    {
      id: '217439780001',
      entityType: 'person',
      entityName: 'MRS SUSAN ANNE ETCHELLS',
      entityData: {},
    },
    {
      id: 'OC320277',
      entityType: 'company',
      entityName: 'PQ NETWORKING LLP',
      entityData: {},
    },
    {
      id: '08696172',
      entityType: 'company',
      entityName: 'LEARNING TUBE LIMITED',
      entityData: {},
    },
    {
      id: '061200680001',
      entityType: 'person',
      entityName: 'MR MALCOLM BLAND',
      entityData: {},
    },
    {
      id: '09190860',
      entityType: 'company',
      entityName: 'TQA LIMITED',
      entityData: {},
    },
    {
      id: '06215909',
      entityType: 'company',
      entityName: 'NEBMUSIC LIMITED',
      entityData: {},
    },
    {
      id: '06062955',
      entityType: 'company',
      entityName: 'TIMELY BUSINESS SOLUTIONS LIMITED',
      entityData: {},
    },
    {
      id: '03637193',
      entityType: 'company',
      entityName: 'TIMELYINTERVENTION LIMITED',
      entityData: {},
    },
    {
      id: '07099496',
      entityType: 'company',
      entityName: 'PSP HOLDINGS LIMITED',
      entityData: {},
    },
    {
      id: 'OC364827',
      entityType: 'company',
      entityName: 'OXPHO LLP',
      entityData: {},
    },
    {
      id: '06665239',
      entityType: 'company',
      entityName: 'ETCHELLS PHOTOGRAPHY AND PUBLISHING LIMITED',
      entityData: {},
    },
    {
      id: '09926751',
      entityType: 'company',
      entityName: 'IHE LTD',
      entityData: {},
    },
    {
      id: '10028945',
      entityType: 'company',
      entityName: 'ROOMS THAT ROCK LTD',
      entityData: {},
    },
    {
      id: '06223976',
      entityType: 'company',
      entityName: 'B-PAID LIMITED',
      entityData: {},
    },
    {
      id: '06229114',
      entityType: 'company',
      entityName: 'HOUSEMATES LTD',
      entityData: {},
    },
    {
      id: '061200750001',
      entityType: 'person',
      entityName: 'MRS JEAN BLAND',
      entityData: {
        dob_year: 1952,
        dob_month: 2,
      },
    },
    {
      id: '06062955',
      entityType: 'company',
      entityName: 'TIMELY BUSINESS SOLUTIONS LIMITED',
      entityData: {},
    },
    {
      id: '061200680001',
      entityType: 'person',
      entityName: 'MR MALCOLM BLAND',
      entityData: {
        dob_year: 1958,
        dob_month: 5,
      },
    },
    {
      id: '09577759',
      entityType: 'company',
      entityName: 'TIMELY SIMULATIONS LTD',
      entityData: {},
    },
    {
      id: '09190860',
      entityType: 'company',
      entityName: 'TQA LIMITED',
      entityData: {},
    },
    {
      id: '06215909',
      entityType: 'company',
      entityName: 'NEBMUSIC LIMITED',
      entityData: {},
    },
    {
      id: '03637193',
      entityType: 'company',
      entityName: 'TIMELYINTERVENTION LIMITED',
      entityData: {},
    },
    {
      id: '163718420001',
      entityType: 'person',
      entityName: 'MR NICHOLAS EDWARD BLAND',
      entityData: {
        dob_year: 1986,
        dob_month: 11,
      },
    },
  ],
  edges: [
    {
      edgeId: 0,
      source: '174348410001',
      target: '08569628',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company SIMULUS INTERACTIVE LEARNING LTD',
      },
    },
    {
      edgeId: 1,
      source: '174348410001',
      target: '10941201',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company BARLEY CROFT HOLDINGS LIMITED',
      },
    },
    {
      edgeId: 2,
      source: '089663500001',
      target: '10941201',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company BARLEY CROFT HOLDINGS LIMITED',
      },
    },
    {
      edgeId: 3,
      source: '089663500001',
      target: '10969882',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company NAVARINOU HOLDINGS LIMITED',
      },
    },
    {
      edgeId: 4,
      source: '089663500001',
      target: '14588944',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company SHOPTIVITYLABS LTD',
      },
    },
    {
      edgeId: 5,
      source: '089663500001',
      target: '09577759',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company TIMELY SIMULATIONS LTD',
      },
    },
    {
      edgeId: 6,
      source: '089663500001',
      target: '05222135',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company PSP UK GROUP LTD',
      },
    },
    {
      edgeId: 7,
      source: '089663500001',
      target: '04738463',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company SIGN SCENE (UK) LIMITED',
      },
    },
    {
      edgeId: 8,
      source: '174348410001',
      target: '08329259',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company ILIAD5 LIMITED',
      },
    },
    {
      edgeId: 9,
      source: '089663500001',
      target: '08569628',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company SIMULUS INTERACTIVE LEARNING LTD',
      },
    },
    {
      edgeId: 10,
      source: '174348410001',
      target: '10969882',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company NAVARINOU HOLDINGS LIMITED',
      },
    },
    {
      edgeId: 11,
      source: '217439770001',
      target: '14588944',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company SHOPTIVITYLABS LTD',
      },
    },
    {
      edgeId: 12,
      source: '217439770001',
      target: '13480798',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company IP SOFTWARE HOLDINGS LIMITED',
      },
    },
    {
      edgeId: 13,
      source: '304175960001',
      target: '14588944',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company SHOPTIVITYLABS LTD',
      },
    },
    {
      edgeId: 14,
      source: '239081870001',
      target: '14588944',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company SHOPTIVITYLABS LTD',
      },
    },
    {
      edgeId: 15,
      source: '239081870001',
      target: '11016733',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company NORTHERN MONKEY CREATIVE THINKING LIMITED',
      },
    },
    {
      edgeId: 16,
      source: '217439780001',
      target: '14588944',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company SHOPTIVITYLABS LTD',
      },
    },
    {
      edgeId: 17,
      source: '217439780001',
      target: 'OC320277',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company PQ NETWORKING LLP',
      },
    },
    {
      edgeId: 18,
      source: '217439780001',
      target: '13480798',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company IP SOFTWARE HOLDINGS LIMITED',
      },
    },
    {
      edgeId: 19,
      source: '217439780001',
      target: '08696172',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company LEARNING TUBE LIMITED',
      },
    },
    {
      edgeId: 20,
      source: '061200680001',
      target: '09577759',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company TIMELY SIMULATIONS LTD',
      },
    },
    {
      edgeId: 21,
      source: '061200680001',
      target: '09190860',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company TQA LIMITED',
      },
    },
    {
      edgeId: 22,
      source: '061200680001',
      target: '06215909',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company NEBMUSIC LIMITED',
      },
    },
    {
      edgeId: 23,
      source: '061200680001',
      target: '06062955',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company TIMELY BUSINESS SOLUTIONS LIMITED',
      },
    },
    {
      edgeId: 24,
      source: '061200680001',
      target: '03637193',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company TIMELYINTERVENTION LIMITED',
      },
    },
    {
      edgeId: 25,
      source: '217439770001',
      target: '05222135',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company PSP UK GROUP LTD',
      },
    },
    {
      edgeId: 26,
      source: '217439770001',
      target: '07099496',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company PSP HOLDINGS LIMITED',
      },
    },
    {
      edgeId: 27,
      source: '217439770001',
      target: 'OC364827',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company OXPHO LLP',
      },
    },
    {
      edgeId: 28,
      source: '217439770001',
      target: '06665239',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company ETCHELLS PHOTOGRAPHY AND PUBLISHING LIMITED',
      },
    },
    {
      edgeId: 29,
      source: '217439780001',
      target: '05222135',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company PSP UK GROUP LTD',
      },
    },
    {
      edgeId: 30,
      source: '217439780001',
      target: '09926751',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company IHE LTD',
      },
    },
    {
      edgeId: 31,
      source: '217439780001',
      target: '10028945',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company ROOMS THAT ROCK LTD',
      },
    },
    {
      edgeId: 32,
      source: '217439780001',
      target: 'OC364827',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company OXPHO LLP',
      },
    },
    {
      edgeId: 33,
      source: '217439780001',
      target: '07099496',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company PSP HOLDINGS LIMITED',
      },
    },
    {
      edgeId: 34,
      source: '217439780001',
      target: '06223976',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company B-PAID LIMITED',
      },
    },
    {
      edgeId: 35,
      source: '217439780001',
      target: '06229114',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company HOUSEMATES LTD',
      },
    },
    {
      edgeId: 36,
      source: '217439770001',
      target: '04738463',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company SIGN SCENE (UK) LIMITED',
      },
    },
    {
      edgeId: 37,
      source: '061200750001',
      target: '06062955',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company TIMELY BUSINESS SOLUTIONS LIMITED',
      },
    },
    {
      edgeId: 38,
      source: '061200680001',
      target: '06062955',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company 06062955',
      },
    },
    {
      edgeId: 39,
      source: '061200680001',
      target: '09577759',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company 09577759',
      },
    },
    {
      edgeId: 40,
      source: '061200680001',
      target: '09190860',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company 09190860',
      },
    },
    {
      edgeId: 41,
      source: '061200680001',
      target: '06215909',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company 06215909',
      },
    },
    {
      edgeId: 42,
      source: '061200680001',
      target: '03637193',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company 03637193',
      },
    },
    {
      edgeId: 43,
      source: '163718420001',
      target: '06062955',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company 06062955',
      },
    },
    {
      edgeId: 44,
      source: '163718420001',
      target: '03637193',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description: 'Edge between officer and company 03637193',
      },
    },
    {
      edgeId: 45,
      source: '061200750001',
      target: '03637193',
      probability: '1',
      edgeInfo: {
        type: 'Director',
        description:
          'Edge between officer and company TIMELYINTERVENTION LIMITED',
      },
    },
  ],
  meta: {
    relationshipEdgesIds: [37, 23, 20, 5, 9],
  },
};

const CONSTANTS = {
  JUDGMENT_CHECKER,
  SIC_CODES,
  SIC_CODE_DESCRIPTION,
  GRAPH_DATA,
  HUB_SPOT_URL,
};

export default CONSTANTS;
