import React from "react";
import "./ProfileDetails.css";

function ProfileDetails() {
  return (
    <div className="profile-details-section">
      <div className="outer-div round-img-div"></div>

      <div className="outer-div custom-margin">
        <div className="body-text-normal dark-text-color">
          <span className="label-text"> First Name: </span> James
        </div>
        <div className="body-text-normal dark-text-color">
          <span className="label-text"> Surname: </span> Smithson
        </div>
      </div>
      <div className="outer-div">
        <div className="body-text-normal dark-text-color">
          <span className="label-text"> D.O.B.: </span> 08/04/1973
        </div>
        <div className="body-text-normal dark-text-color">
          <span className="label-text"> Age </span> 49
        </div>
      </div>
      <div className="outer-div">
        <div className="body-text-normal dark-text-color">
          <span className="label-text"> ID Card: </span> 5843 2166 4567 8901
        </div>
        <div className="body-text-normal dark-text-color">
          <span className="label-text"> Contact Number: </span> +44 1632 960034
        </div>
      </div>
      <div className="outer-div">
        <div className="body-text-normal dark-text-color">
          <span className="label-text"> Current Address: </span> 52 Guild
          Street, London
        </div>
      </div>
    </div>
  );
}

export default ProfileDetails;
