import './CESearchEntities.css';
import React, { useEffect } from 'react';
import ProbeButton from '../../../components/ProbeButton/ProbeButton';
import ExploreLinksOfficerCard from '../../../components/ExploreLinksOfficerCard/ExploreLinksOfficerCard';
import CompanyItem from '../../../components/CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/CompanyItem';
import PeopleAndContactItem from '../../../components/CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/PeopleAndContactItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons';
import ExploreLinksOfficerUpdatedCard from '../../../components/ExploreLinksOfficerUpdatedCard/ExploreLinksOfficerUpdatedCard';
const CESearchEntitiesSection = ({
  searchContainerRef,
  inputValue,
  handleInputFocus,
  handleInputBlur,
  handleChange,
  selectedSuggestions,
  handleGraphPage,
  isOpen,
  selectedType,
  handleButtonClick,
  isLoadingPage,
  loadingGif,
  suggestions,
  removeItem,
  itemClicked,
  showCards = true,
  isSectionVisible,
}) => {
  return (
    <>
      {
        <section className="cesearch-entites-explorer-link-section">
          <div
            className="cesearch-entites-search-container"
            ref={searchContainerRef}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              <input
                type="text"
                className="cesearch-entites-search-input"
                value={inputValue}
                placeholder={selectedType}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onChange={handleChange}
              />
              {/* {selectedSuggestions && selectedSuggestions.length > 0 ? (
                <ProbeButton
                  name={'Search'}
                  width={110}
                  borderRadius={'10px'}
                  backgroundColor={'white'}
                  borderColor={'blue'}
                  onClick={handleGraphPage}
                />
              ) : null} */}
            </div>
            {isOpen && (
              <div className="cesearch-entites-dropdown-main">
                <span className="cesearch-entites-heading">
                  I'm Searching for ...
                </span>
                {showCards && (
                  <div className="cesearch-entites-buttons-container">
                    <button
                      className={`cesearch-entites-dropdown-button  ${
                        selectedType === 'People' ? 'active' : ''
                      }`}
                      onClick={() => handleButtonClick('People')}
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: '5px' }}
                      />
                      People
                    </button>
                    <button
                      className={`cesearch-entites-dropdown-button  ${
                        selectedType === 'Companies' ? 'active' : ''
                      }`}
                      onClick={() => handleButtonClick('Companies')}
                    >
                      <FontAwesomeIcon
                        icon={faBuilding}
                        style={{ marginRight: '5px' }}
                      />
                      Companies
                    </button>
                  </div>
                )}
                <div className="cesearch-entites-main-cards">
                  <div className="cesearch-entites-main-cards-header">
                    <span className="cesearch-entites-main-cards-heading">
                      {selectedType}
                    </span>
                    {isLoadingPage && (
                      <div>
                        <img src={loadingGif} alt="Loading..." width={20} />
                      </div>
                    )}
                  </div>

                  {!isLoadingPage && (
                    <div className="cesearch-entites-main-All-cards">
                      {selectedType === 'People'
                        ? suggestions &&
                          suggestions?.map(person => (
                            <div
                              onClick={() => itemClicked(selectedType, person)}
                            >
                              <PeopleAndContactItem
                                officer={person}
                                isCardView={true}
                              />
                            </div>
                          ))
                        : suggestions &&
                          suggestions?.map(comp => (
                            <div
                              onClick={() => itemClicked(selectedType, comp)}
                            >
                              <CompanyItem company={comp} isCardView={true} />
                            </div>
                          ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      }
    </>
  );
};

export default CESearchEntitiesSection;
