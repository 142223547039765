import { useState } from "react";
import CONSTANTS from "../../../utils/constants";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import "./SicCodeDropTree.css";

const SicCodeDropTree = ({ node, handleTreeInput }) => {
  const [openNodes, setOpenNodes] = useState({});

  const toggleNode = (parent) => {
    setOpenNodes((prevOpenNodes) => ({
      ...prevOpenNodes,
      [parent]: !prevOpenNodes[parent],
    }));
  };

  const addToFilter = (parent) => {
    handleTreeInput({
      name: parent.toLowerCase(),
    });
  };

  const processNodeUi = (node) => {
    if (!node) return null;

    const isOpen = openNodes[node.parent];
    const iconClassName = isOpen ? "icon-open" : "icon-closed";
    const contentClassName = isOpen ? "expanded" : "collapsed";

    return (
      <div
        style={{
          display: "flex",
          gap: "5px",
          cursor: "pointer",
        }}
      >
        <div
          onClick={() => toggleNode(node.parent)}
          style={{
            backgroundColor: "#F5F5F5",
            width: "12px",
            height: "12px",
            display: "flex",
            color: "gray",
            border: "1px solid #d2d2d2",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
          }}
          className="icon-container"
        >
          {node?.child?.length > 0 && (
            <KeyboardArrowRightIcon
              fontSize="inherit"
              className={`sic_code_drop_tree_icon ${iconClassName}`}
            />
          )}
        </div>
        <div className="sic_code_max_div">
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              borderBottom: "1px solid #e4e4e4",
            }}
            onClick={() => addToFilter(node.parent)}
            className="sic_code_drop_tree_parent_name"
          >
            <div>{node.parent}</div>
          </div>
          {node.child && node.child.length > 0 && (
            <div
              className={`sic_code_max_div ${contentClassName}`}
              style={{ width: "100%" }}
            >
              {isOpen &&
                node.child.map((childNode, index) => (
                  <div
                    key={index}
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    {processNodeUi(childNode)}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return <div>{processNodeUi(node)}</div>;
};

export default SicCodeDropTree;
