import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function ProbeDatePicker({
  placeHolder,
  isError,
  setDate,
  helperText,
}) {
  const handleDateChange = (newDate) => {
    const formattedDate = dayjs(newDate).format("DD-MM-YYYY");
    setDate(formattedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <DatePicker
          format="YYYY-MM-DD"
          onChange={handleDateChange}
          label={placeHolder}
          slotProps={{
            textField: {
              error: isError,
              helperText: isError ? helperText : "",
              size: "small",
              sx: {
                "& .MuiOutlinedInput-root": {
                  borderRadius: "16px",
                },
              },
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
}
