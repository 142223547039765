import React from 'react';
import overview from '../../../assets/images/company1.png';
import overViewIcon from '../../../assets/images/OverView.png';
import userPeopleAndContact from '../../../assets/images/userpeopleandContact.png';
import Financials from '../../../assets/images/Financials.png';
import control from '../../../assets/images/control.png';
import creditAndRisk from '../../../assets/images/creditAndRisk.png';
import Competition from '../../../assets/images/Competition.png';
import property from '../../../assets/images/property.png';
import document from '../../../assets/images/document.png';
import './CorporateExploreInsightSidebar.css';

// Update the CorporateExploreInsightSidebar component
const CorporateExploreInsightSidebar = ({
  selectedCompany,
  onSelectMenuItem,
}) => {
  const handleMouseEnter = () => {};

  const handleMouseLeave = () => {};

  return (
    <div className="col-lg-2 corporate-explore-insight-sidebar corporate-explore-insight-sidebar-desktop">
      <div className="corporate-explore-insight-companyLogo">
        <img
          src={overview}
          alt="Company Logo"
          style={{
            width: '75px',
            height: 'auto',
            marginRight: '1.5rem',
            borderRadius: '5px',
          }}
        />
      </div>
      <div className="corporate-explore-insight-companyName">
        <a href="#" style={{ color: '#021B41' }}>
          {selectedCompany?.title}
        </a>
      </div>
      <div className="corporate-explore-insight-companyAddress">
        {selectedCompany?.address}
      </div>
      {menuItems.map(item => (
        <MenuItem
          key={item.label}
          icon={item.icon}
          label={item.label}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          onClick={() => onSelectMenuItem(item.label)}
        />
      ))}
    </div>
  );
};

const MenuItem = ({
  icon,
  label,
  handleMouseEnter,
  handleMouseLeave,
  onClick,
}) => {
  return (
    <div
      className="corporate-explore-insight-menuItem"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <span className="fas fa-users corporate-explore-insight-menuItemIcon">
        <img
          src={icon}
          alt={`${label}-icon`}
          style={{
            width: '15px',
            height: 'auto',
            borderRadius: '5px',
          }}
        />
      </span>
      <span style={{ fontWeight: '700', fontSize: '16px' }}>{label}</span>
    </div>
  );
};

const menuItems = [
  { icon: overViewIcon, label: 'Overview' },
  { icon: userPeopleAndContact, label: 'People & Contacts' },
  { icon: Financials, label: 'Financials' },
  { icon: control, label: 'Control & Ownership' },
  { icon: creditAndRisk, label: 'Credit Risk' },
  { icon: Competition, label: 'Competition' },
  { icon: property, label: 'Property' },
  { icon: document, label: 'Documents' },
  { icon: userPeopleAndContact, label: 'Shareholders' },
];

export default CorporateExploreInsightSidebar;
