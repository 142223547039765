import React from 'react';
import "./CorporateLinksGraphSidebar.css"
const CorporateLinksGraphLeftSidebar = ({ sidebarOpen, toggleSidebar }) => {
  return (
    <div className="corporate-links-graph-sidebar">
      <div
        className={`corporate-links-graph-sidebar ${sidebarOpen ? 'open' : ''}`}
      >
        <div
          className="corporate-links-graph-sidebar-close-btn"
          onClick={toggleSidebar}
        >
          &times;
        </div>

        <div className="corporate-links-graph-data-filters">
          <ul className="sidebar-menu">
            <li className="sidebar-menu-item">
              <i className="icon-temperature">X</i> Temperature
            </li>
            <li className="sidebar-menu-item active">
              <i className="icon-feels-like">X</i> Feels like temperature
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-precipitation">X</i> Precipitation
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-radar">X</i> Radar
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-satellite">X</i> Satellite
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-clouds">X</i> Clouds
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-wind-speed">X</i> Wind speed
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-wind-gusts">X</i> Wind gusts
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-air-pressure">X</i> Air pressure
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-thunderstorms">X</i> Thunderstorms
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-humidity">X</i> Humidity
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-sea">X</i> Sea
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-snow-cover">X</i> Snow cover
            </li>
            <li className="sidebar-menu-item">
              <i className="icon-air-quality">X</i> Air quality
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CorporateLinksGraphLeftSidebar;
