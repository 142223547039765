import React from "react";
import loadingGif from "../../../assets/images/loading.gif"; // Adjust the path to where your GIF is located

const TableLoadingScreen = () => (
  <div style={{ display: "flex", width: "100%" }}>
    <div>
      <img style={{ width: "20px" }} src={loadingGif} alt="Loading..." />
    </div>
  </div>
);

export default TableLoadingScreen;
