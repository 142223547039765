export let routeConfig = {
  'ccj-orders-fines': [
    'JudgementCheckerPerson',
    'JudgementCheckerBusiness',
    'CheckReviewBusiness',
    'CheckReviewPerson',
    'JudgementCheckerYourDetail',
    'Report',
    'ccj-orders-fines',
  ],
  SelectEntity: ['entityDisambiguation', 'EntityResult', 'SelectEntity'],
  CorporateExplorer: ['CorporateExplorer'],
  ForegnUKOwnership: ['ForegnUKOwnership'],
  BackgroundInvestigation: ['BackgroundInvestigation'],
  feedbackForm: ['feedbackForm'],
  CorporateExploreInsight: ['CorporateExploreInsight'],
  CorporateLinks: ['CorporateLinks'],
  CEIPersonInsights: ['CEIPersonInsights'],
};
