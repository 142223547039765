import React, { useState, useEffect } from "react";
import { routeConfig } from "../../utils/routeConfig";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [errorUrl, setErrorUrl] = useState(null);
  const [redirectPath, setRedirectPath] = useState("/");

  // Function to find the key by last segment
  const findKeyBySegment = (segment, config) => {
    try {
      for (const [key, values] of Object.entries(config)) {
        if (values.includes(segment)) {
          return key;
        }
      }
      return "";
    } catch (error) {
      return "";
    }
  };

  const getSegments = (url) => {
    const { pathname } = new URL(url);
    const segments = pathname.split("/").filter((segment) => segment !== "");
    return {
      lastSegment: segments[segments.length - 1],
      precedingSegment: segments[segments.length - 2] || "",
    };
  };

  useEffect(() => {
    const errorHandler = (error, errorInfo) => {
      setHasError(true);
      const { lastSegment, precedingSegment } = getSegments(
        window.location.href
      );
      if (lastSegment) {
        let nevPath = findKeyBySegment(lastSegment, routeConfig);
        setRedirectPath(nevPath);
        setTimeout(() => {
          window.location.href = "/" + nevPath;
        }, 400);
      }
    };

    // Listen to errors globally
    window.addEventListener("error", errorHandler);
    window.addEventListener("unhandledrejection", errorHandler);

    return () => {
      // Clean up listeners
      window.removeEventListener("error", errorHandler);
      window.removeEventListener("unhandledrejection", errorHandler);
    };
  }, []);

  if (hasError) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center vh-100">
        <h1>Something went wrong.</h1>
        <p className="text-center">
          Navigating to <em>/{redirectPath}</em>{" "}
        </p>
      </div>
    );
  }

  return children;
};

export default ErrorBoundary;
