import React from "react";
import RegistrySvg from "../../assets/images/RegistryRT/RegistryTrustRT.svg";
import "./ProbeRegistryTrustLtd.css";

function ProbeRegistryTrustLtd({ poweredByColor, width }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        gap: "10px",
        padding: "10px",
        marginLeft: "10px",
      }}
    >
      <div
        style={{
          color: poweredByColor,
          fontWeight: "bold",
          letterSpacing: "1px",
        }}
      >
        Powered by
      </div>
      <img className="probe_registry_image_ltd" src={RegistrySvg} alt="Registry" width={width} />
    </div>
  );
}

export default ProbeRegistryTrustLtd;
