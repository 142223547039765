import axios from "axios";
import { apiConfig, buildUrl } from "../apiConfig";

export const uploadFile = async (payload) => {
  try {
    const response = await axios.post(
      buildUrl(apiConfig.base, apiConfig.blogs.endpoints.uploadFile),
      payload
    );

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const submitFeedback = async (payload) => {
  try {
    const response = await axios.post(
      buildUrl(apiConfig.base, apiConfig.blogs.endpoints.submitFeedback),
      payload
    );

    return response.data;
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const addBlogToFirestore = async (payload) => {
  try {
    const response = await axios.post(
      buildUrl(apiConfig.base, apiConfig.blogs.endpoints.addBlogToFirestore),
      payload
    );
    return response.data;
  } catch (error) {
    return { success: false, error: error.message };
  }
};


export const postStarRating = async (payload) => {
  try {
    const response = await axios.post(
      buildUrl(apiConfig.base, apiConfig.blogs.endpoints.postStarRating),
      payload
    );
    return response.data;
  } catch (error) {
    return { success: false, error: error.message };
  }
};
