import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { StyledEngineProvider } from '@mui/material/styles';
import { LoadingProvider } from './context/LoadingContext/LoadingContext';
import { UserSetupProvider } from './context/UserLoadingContext/UserLoadingContext';
import './index.css';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
    <UserSetupProvider>
      <LoadingProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </LoadingProvider>
    </UserSetupProvider>
  </StyledEngineProvider>
);

reportWebVitals();
