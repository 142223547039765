import './CorporateExplorerFilter.css';
import Modal from 'react-bootstrap/Modal';
import OptionsSideBar from './OptionsSideBar';
import FilterMidInput from './FilterMidInput';
import { AppContext } from '../../context/Context';
import { useContext, useEffect, useState } from 'react';
import AppliedFilterGroup from './AppliedFilterGroup';
import CONSTANTS from '../../utils/constants';

function FilterModal({
  showModal,
  setShowModal,
  filterReadyToApply,
  setFilterReadyToApply,
  totalCompaniesDocument,
  applyFilters,
  locationSearchInput,
  locationSearchResults,
}) {
  const SICData = CONSTANTS.SIC_CODES;

  const [selectedFilterData, setSelectedFilterData] = useState({});
  const [filteredItem, setFilteredItem] = useState({});
  const [removeFilterItem, setRemoveFIlterItem] = useState({});
  const filterData = [
    {
      title: 'Key Data',
      iconName: 'arrow',
      subValues: [
        {
          key: 'Name',
          mid: [
            { type: 'header', value: 'Filter by Company Name' },
            {
              type: 'search',
              value: 'search-component',
              placeHolder: 'Include or exclude one or more words',
            },
          ],
        },
        {
          key: 'Status',
          mid: [
            { type: 'header', value: 'Filter by Company Status' },
            { type: 'label', value: 'Active' },
            { type: 'label', value: 'Dormant' },
            { type: 'label', value: 'Dissolved' },
            { type: 'label', value: 'In Liquidation' },
            { type: 'label', value: 'In Administration' },
            { type: 'label', value: 'In Receivership' },
          ],
        },
        {
          key: 'Type',
          mid: [
            { type: 'header', value: 'Filter by Company Type' },
            { type: 'sub_header', value: 'PRIVATE' },
            { type: 'label', value: 'Private (All)' },
            { type: 'label', value: 'Private Unlimited with Share Capital' },
            { type: 'label', value: 'Private Unlimited without Share Capital' },
            { type: 'label', value: 'Private Limited with Share Capital' },
            {
              type: 'label',
              value:
                'Private Limited by Guarantee without Share Capital (Exempt from using Capital)',
            },
            {
              type: 'label',
              value: 'Private Limited (Exempt from using Limited)',
            },
            { type: 'sub_header', value: 'PUBLIC' },
            { type: 'label', value: 'Public (All)' },
            { type: 'label', value: 'Public Limited with Share Capital' },
            {
              type: 'label',
              value: 'Old Public Limited',
            },
            { type: 'sub_header', value: 'OTHER' },
            { type: 'label', value: 'Societas Europaea (SE)' },
            { type: 'label', value: 'Limited Partnership' },
            {
              type: 'label',
              value: 'Other',
            },
          ],
        },
        { key: 'Size', isDisabled: true },
        {
          key: 'Accounts Category',
          mid: [
            { type: 'header', value: 'Filter by Accounts Category' },
            { type: 'label', value: 'Micro Entity' },
            { type: 'label', value: 'Small' },
            { type: 'label', value: 'Medium' },
            { type: 'label', value: 'Full' },
            { type: 'label', value: 'Group' },
            { type: 'label', value: 'Dormant' },
            { type: 'label', value: 'Interim' },
            { type: 'label', value: 'Initial' },
            { type: 'label', value: 'Total Exemption Full' },
            { type: 'label', value: 'Total Exemption Small' },
            { type: 'label', value: 'Partial Exemption' },
            { type: 'label', value: 'Audit Exemption Subsidiary' },
            { type: 'label', value: 'Filing Exemption Subsidiary' },
            { type: 'label', value: 'Audited Abridged' },
            { type: 'label', value: 'unaudited Abridged' },
          ],
        },
        { key: 'Employees', isDisabled: true },
        { key: 'Adversity', isDisabled: true },
      ],
    },
    {
      title: 'Dates',
      iconName: 'date',
      isDisabled: false,
      subValues: [
        {
          key: 'Incorporation',
          mid: [
            { type: 'header', value: 'Filter by Incorporation Date' },
            { type: 'label', value: 'Today' },
            { type: 'label', value: 'Yesterday' },
            { type: 'label', value: 'This Week (Mon - Today)' },
            { type: 'label', value: 'Last 7 days' },
            { type: 'label', value: 'Last week (Mon - Sun)' },
            { type: 'label', value: 'Last 14 days' },
            { type: 'label', value: 'This month' },
            { type: 'label', value: 'Last 30 days' },
            { type: 'label', value: 'Last month' },
            { type: 'label-date-range', value: 'Select custom date' },
          ],
        },
        {
          key: 'Accounts Year End',
          mid: [
            { type: 'header', value: 'Filter by Accounts Year End Date' },
            { type: 'label', value: 'Today' },
            { type: 'label', value: 'Yesterday' },
            { type: 'label', value: 'Tomorrow' },
            { type: 'label', value: 'This Week (Mon - Today)' },
            { type: 'label', value: 'Last 7 days' },
            { type: 'label', value: 'Next 7 days' },
            { type: 'label', value: 'Last 14 days' },
            { type: 'label', value: 'Next 14 days' },
            { type: 'label', value: 'This month (1st - Today)' },
            { type: 'label', value: 'Last 30 days' },
            { type: 'label', value: 'Next 30 days' },
            { type: 'label-date-range', value: 'Select custom date' },
          ],
        },
        {
          key: 'Accounts Due By',
          mid: [
            { type: 'header', value: 'Filter by Accounts Due By Date' },
            { type: 'label', value: 'Today' },
            { type: 'label', value: 'Yesterday' },
            { type: 'label', value: 'Tomorrow' },
            { type: 'label', value: 'This Week (Mon - Today)' },
            { type: 'label', value: 'Last 7 days' },
            { type: 'label', value: 'Next 7 days' },
            { type: 'label', value: 'Last 14 days' },
            { type: 'label', value: 'Next 14 days' },
            { type: 'label', value: 'This month (1st - Today)' },
            { type: 'label', value: 'Last 30 days' },
            { type: 'label', value: 'Next 30 days' },
            { type: 'label-date-range', value: 'Select custom date' },
          ],
        },
        {
          key: 'Accounts Last Made',
          mid: [
            { type: 'header', value: 'Filter by Accounts Last Made Up To' },
            { type: 'label', value: 'Today' },
            { type: 'label', value: 'Yesterday' },
            { type: 'label', value: 'Tomorrow' },
            { type: 'label', value: 'This Week (Mon - Today)' },
            { type: 'label', value: 'Last 7 days' },
            { type: 'label', value: 'Next 7 days' },
            { type: 'label', value: 'Last 14 days' },
            { type: 'label', value: 'Next 14 days' },
            { type: 'label', value: 'This month (1st - Today)' },
            { type: 'label', value: 'Last 30 days' },
            { type: 'label', value: 'Next 30 days' },
            { type: 'label-date-range', value: 'Select custom date' },
          ],
        },
      ],
    },
    {
      title: 'Location',
      iconName: 'location',
      isDisabled: false,
      subValues: [
        {
          key: 'Registered Address',
          mid: [
            { type: 'header', value: 'Filter by Registered Address' },
            {
              type: 'search',
              value: 'search-component',
              placeHolder: 'Search for a town, city or postcode',
            },
            { type: 'locationSearch' },
          ],
        },
      ],
    },
    {
      title: 'Industry',
      iconName: 'industry',
      subValues: [
        {
          key: 'SIC Code',
          mid: [
            { type: 'header', value: 'Filter by SIC Code' },
            {
              type: 'search',
              value: 'search-component',
              placeHolder: 'Search for a code or classification',
            },
            { type: 'tree', value: SICData },
          ],
        },
      ],
    },
    {
      title: 'Financials',
      iconName: 'financials',
      isDisabled: true,
      subValues: [{ key: 'Figure' }, { key: 'Growth' }],
    },
    {
      title: 'Preferences',
      iconName: 'preferences',
      isDisabled: true,
      subValues: [
        { key: 'Contact' },
        { key: 'Website' },
        { key: 'Gazette' },
        { key: 'Charges' },
        { key: 'Property' },
      ],
    },
  ];

  useEffect(() => {
    let filteredResult = filterData.filter(
      item =>
        item.title === Object.keys(selectedFilterData)[0]?.replace('_', ' ')
    )[0];
    let subValues = filteredResult?.subValues?.filter(
      item =>
        item.key === selectedFilterData[Object.keys(selectedFilterData)[0]]
    );
    setFilteredItem({
      subValues,
    });
  }, [selectedFilterData]);

  useEffect(() => {
    // Filter out the item to be removed
    const updatedFilterReadyToApply = filterReadyToApply.map(item => {
      if (item.header === removeFilterItem.key) {
        // Remove the specified item from the filter array
        return {
          ...item,
          filter: item.filter.filter(
            filterItem => filterItem !== removeFilterItem.item
          ),
        };
      }
      return item;
    });

    // Update the state with the updated array
    setFilterReadyToApply(updatedFilterReadyToApply);
  }, [removeFilterItem]);

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="xl"
        contentClassName="modal-height"
      >
        <Modal.Header closeButton>
          <Modal.Title>FILTERS</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0px' }}>
          <div className="corporate_explor_filter_modal_main">
            <div className="corporate_explor_filter_modal_main_options">
              <OptionsSideBar
                filterData={filterData}
                setSelectedFilterData={setSelectedFilterData}
              />
            </div>
            <div className="corporate_explor_filter_modal_main_mid">
              <FilterMidInput
                filteredItem={filteredItem}
                setFilterReadyToApply={setFilterReadyToApply}
                filterReadyToApply={filterReadyToApply}
                locationSearchInput={locationSearchInput}
                locationSearchResults={locationSearchResults}
              />
            </div>
            <div className="corporate_explor_filter_modal_main_right">
              <AppliedFilterGroup
                filterReadyToApply={filterReadyToApply}
                setRemoveFIlterItem={setRemoveFIlterItem}
                totalCompaniesDocument={totalCompaniesDocument}
                applyFilters={applyFilters}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FilterModal;
