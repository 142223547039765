import React, { useState } from 'react';
import '../../CheckReviewPerson/SearchOnPerson/SearchOnPerson.css';
import '../../JudgementChecker.css';
import './JudgementCheckerBusiness.css';
import '../../CheckReviewPerson/JudgementCheckerPerson/JudgementCheckerPerson';
import HeaderDropdown from '../../../../components/HeaderDropdownNative.js';
import trustPower from '../../../../assets/images/icons/powered-trust-online.svg';
import checkBusiness from '../../../../assets/images/Documents-bro.svg';
import { AppContext } from '../../../../context/Context.js';
import { useEffect, useContext } from 'react';
import { getCardsByIndex, getGuestUserInfo } from '../../../../utils/helper.js';
import {
  fontSizeForJudgmentCheckerInput,
  staticCardData,
} from '../../../../utils/common.js';
import { useNavigate } from 'react-router-dom';
import PoweredByTrustOnline from '../../common/PoweredByTrustOnline/PoweredByTrustOnline.jsx';
import ProbeButton from '../../../../components/ProbeButton/ProbeButton.jsx';
import ProbeModal from '../../../../components/ProbeModal/ProbeModal.js';
import { entitySimilarity } from '../../../../api/corporateLinks/corporateLinks.js';
import StimulusGrid from '../../../../components/StimulusGrid/StimulusGrid.jsx';
import { useLoading } from '../../../../context/LoadingContext/LoadingContext.js';
import showToast from '../../../../utils/showToast.js';
import ProbeInput from '../../../../components/ProbeInput/ProbeInput.jsx';
import TableLoadingScreen from '../../../../components/LoadingScreen/TableLoadingScreen/TableLoadingScreen.js';
import { explorerFilter } from '../../../../api/corporateExplorer/corporateExplorer.js';

function JudgementCheckerBusiness() {
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoading();
  const [options, setOptions] = useState(null);
  const [selectedCards, setSelectedCards] = useState([0, 1, 2, 3]);
  const [companyNameError, setCompanyNameError] = useState('');
  const [onError, setOnError] = useState('');

  const [closeCompanySearchDialog, setCloseCompanySearchDialog] =
    useState(false);

  const [companyHouseSearchName, setCompanyHouseSearchName] = useState('');

  const { judgementChecker, setJudgementChecker, user } =
    useContext(AppContext);

  const handleCardClick = index => {
    // Toggle the selection state of the card at the given index
    if (selectedCards.includes(index)) {
      setSelectedCards(selectedCards.filter(item => item !== index));
    } else {
      setSelectedCards([...selectedCards, index]);
    }
  };

  const handleCompanyNameChange = e => {
    const companyName = e.target.value.toUpperCase();
    if (!companyName.trim()) {
      setCompanyNameError('Company name is required');
    } else {
      setCompanyNameError('');
    }
    companyNameSetup(companyName);
  };

  const companyNameSetup = companyName => {
    setJudgementChecker(prev => ({
      ...prev,
      businessJudgementChecker: {
        ...prev.businessJudgementChecker,
        companyName: companyName,
        selectedRegisters: getCardsByIndex(staticCardData, selectedCards),
        type: 'BusinessSearch',
      },
    }));
  };

  const validateInput = () => {
    // Ensure companyName exists and is not just empty spaces
    const companyName = judgementChecker.businessJudgementChecker.companyName;
    if (!companyName || !companyName.trim()) {
      setCompanyNameError('Company name is required');
      return false; // Stop further execution
    }
    return true;
  };

  const handleContinue = () => {
    if (!validateInput()) return;

    // No errors, continue with navigation
    navigate('/CheckReviewBusiness');
  };

  const openCompanyFromCorporateLinksApi = async () => {
    setOptions(null);
    setOnError('');
    setCloseCompanySearchDialog(true);
  };

  const searchCompanyFromCorporateLinksApi = async () => {
    try {
      setIsLoading(true);
      setOptions(null);

      if (!companyHouseSearchName?.length > 0) {
        setOnError('Company name is required');
        setIsLoading(false);
        return;
      }

      const entitiesWithOptions = await explorerFilter([
        { header: 'company_name', filter: ['+' + companyHouseSearchName] },
      ]);
      if (
        !entitiesWithOptions?.result &&
        !entitiesWithOptions?.result?.length > 0
      ) {
        showToast(
          'error',
          'Companies house service is down please proceed with manual search'
        );
        setIsLoading(false);
        return;
      } else {
        let mapResults = [];
        entitiesWithOptions?.result?.map(company => {
          mapResults.push({
            title: company?._source?.company_name,
            address: {
              locality: company?._source?.registered_office_address?.locality,
            },
            date_of_creation: company?._source?.date_of_creation,
            company_status: company?._source?.company_status,
          });
        });
        setOptions(mapResults);
      }
      setCloseCompanySearchDialog(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setJudgementChecker(prev => ({
      ...prev,
      businessJudgementChecker: {
        ...prev.businessJudgementChecker,
        selectedRegisters: getCardsByIndex(staticCardData, selectedCards),
      },
    }));
  }, [selectedCards]);

  useEffect(() => {
    const targetDiv = document.getElementById(
      'judgment-checker-business-input-div'
    );
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className="header">
          <div className="header-heading dark-heading-color">
            Judgments & Fines - Business
          </div>
          <HeaderDropdown />
        </div>
        <div className="dashboard-content-div">
          {/* powered by Trust online component */}
          <PoweredByTrustOnline
            staticCardData={staticCardData}
            trustPower={trustPower}
          />

          <div className="person-detail-card">
            <div className="contennt-div">
              <div className="heading-2-light">Enter Business Details</div>
              <div className="d-flex align-items-start gap-2">
                <div className="small-text-bold">Note:</div>
                <div>
                  <div className="small-text-bold">
                    We can only search using the name and address you provide.
                  </div>
                  <div className="small-text-normal">
                    Please ensure that the details you enter match those when
                    the record was registered.
                  </div>
                  <div className="small-text-normal">
                    If the business you wish to search is a registered company
                    please enter the full name including Limited, Ltd or PLC
                  </div>
                </div>
              </div>

              <div className="input-outer-div">
                <div className="button-text mb-1">Company Name</div>
                <div className="small-text-normal">
                  Please enter the full name of the business you wish to search
                  and click continue, or search Companies House database.
                </div>
                <div className="probe-jc-input-search-button">
                  <div className="parallel-input-div check-business">
                    <div id="judgment-checker-business-input-div ">
                      <input
                        className="judgement-checker-business-company-name-input"
                        placeholder="Enter Company Name"
                        onChange={handleCompanyNameChange}
                        style={fontSizeForJudgmentCheckerInput()}
                        value={
                          judgementChecker?.businessJudgementChecker
                            ?.companyName
                        }
                      />
                    </div>
                    <div style={{ minHeight: '30px', margin: '2px' }}>
                      {companyNameError ? (
                        <div className="error" style={{ color: 'red' }}>
                          {companyNameError}
                        </div>
                      ) : (
                        <div
                          style={{
                            height: '30px',
                            visibility: 'hidden',
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                  <h6 style={{ marginLeft: '-20px' }}>Or</h6>
                  <ProbeButton
                    id="jc-business-probe-button-search-ch-api"
                    name={'Search Companies house'}
                    width={'310px'}
                    fontSize={'10'}
                    height={'40px'}
                    fontColor={'white'}
                    backgroundColor={'#0466D1'}
                    borderRadius={'10px'}
                    borderColor={'#blue'}
                    onClick={openCompanyFromCorporateLinksApi}
                  />
                </div>
              </div>

              {/* address */}
              <div className="input-outer-div">
                <div className="small-text-bold">
                  Note:You will still be able to review your searches before
                  payment
                </div>
              </div>
            </div>
            <div>
              <img
                className="judgment_checker_responsive_image"
                src={checkBusiness}
                alt="business"
              />
            </div>
          </div>

          <div className="JudgementCheckerBusiness-continue">
            <ProbeButton
              name={'Continue'}
              backgroundColor={'#0466D1'}
              borderRadius={'10px'}
              borderColor={'#blue'}
              height={'40px'}
              width={'237px'}
              onClick={handleContinue}
              fontColor="#FFF"
            />
          </div>
        </div>
      </div>
      <ProbeModal
        className={'jc-business-probe-modal'}
        title={'Select Company'}
        showModal={closeCompanySearchDialog}
        onClose={() => {
          setCloseCompanySearchDialog(false);
        }}
        children={
          <div style={{ width: '100%' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-evenly',
                marginBottom: '10px',
              }}
            >
              <ProbeInput
                height="50px"
                width="100%"
                placeholder="Search at Companies house."
                onChange={setCompanyHouseSearchName}
                onError={onError}
                setOnError={setOnError}
              />
              <div style={{ marginLeft: '10px' }}>
                <ProbeButton
                  name={'Search'}
                  backgroundColor={'#0466D1'}
                  borderRadius={'10px'}
                  borderColor={'#blue'}
                  height={'50px'}
                  width={'110px'}
                  fontColor="#FFF"
                  onClick={searchCompanyFromCorporateLinksApi}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div>{isLoading && <TableLoadingScreen />}</div>
            {options && (
              <StimulusGrid
                showCheckBox={false}
                hoverRowSelect={true}
                options={options}
                entitySelectedHandler={option => {
                  companyNameSetup(option?.title);
                  setCloseCompanySearchDialog(false);
                  setCompanyNameError('');
                }}
              />
            )}
          </div>
        }
        maxWidth={'58%'}
      />
    </div>
  );
}

export default JudgementCheckerBusiness;
