import React, { useEffect, useState } from "react";
import "./CEIPeople.css";
import { corporateExplorerOfficerInfo } from "../../../api/corporateExplorer/corporateExplorer";
import PeopleAndContacts from "../../CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/PeopleAndContacts";

const CEIPeople = ({ selectedCompany }) => {
  const [companyOfficersInfo, setCompanyOfficersInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (selectedCompany) {
      setIsLoading(true);

      const getCompanyOfficers = async () => {
        let companyNumber = selectedCompany?.company_number;
        let officersInfo = await corporateExplorerOfficerInfo(companyNumber);
        setCompanyOfficersInfo(officersInfo);
        setIsLoading(false);
      };
      getCompanyOfficers();
    }
  }, [selectedCompany]);
  return (
    <PeopleAndContacts
      companyOfficersInfo={companyOfficersInfo}
      isLoading={isLoading}
      showCard={false}
    />
  );
};

export default CEIPeople;
