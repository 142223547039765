import React from "react";
import "./PoweredByTrustOnline.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ProbeRegistryTrustLtd from "../../../../components/ProbeRegistryTrustLtd/ProbeRegistryTrustLtd";

const PoweredByTrustOnline = ({ staticCardData, trustPower }) => {
  return (
    <div className="bg-white p-3 powered-by-trust-online-rounded-card">
      <h3 className="poweredByTrustOnline-Register">Registers</h3>
      <p className="poweredByTrustOnline-text">
        Following are the registers that will be used to search
      </p>
      <div className="row  poweredByTrustOnline-cards">
        <div className="col-md-10">
          <div className="row">
            {staticCardData.map((card, index) => (
              <div
                key={index}
                className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
                style={{ padding: "5px" }}>
                <div className="card text-white powered-by-trust-online-rounded-card-body">
                  <div className="card-body PoweredByTrustOnline-card">
                    {card.data.map((data, insideIndex) => (
                      <div key={insideIndex} className="mb-3">
                        <h5 className="powered-by-trust-online-rounded-card-title">
                          {data.title}
                        </h5>
                        <p className="powered-by-trust-online-rounded-card-content">
                          {data.content}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ProbeRegistryTrustLtd poweredByColor="black" width={120} />
      </div>
    </div>
  );
};

export default PoweredByTrustOnline;
