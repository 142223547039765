import React, { useContext, useEffect, useState } from 'react';
import './OptionsSideBar.css';
import { AppContext } from '../../context/Context';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

function OptionsSideBarItem({ item, open, setOpen, setSelectedFilterData }) {
  const [selectedSubItem, setSelectedSubItem] = useState(null);

  const toggleButton = (isSub, subItem, e) => {
    if (!isSub) {
      setOpen(open ? null : item?.title);
      setSelectedSubItem(null);
    } else {
      setSelectedSubItem(subItem);
    }
    if (e) {
      e.stopPropagation();
    }
    let selectedFilter = {};
    let selectedKey = item?.title?.replace(/ /g, '_');
    selectedFilter[selectedKey] = subItem;
    setSelectedFilterData(selectedFilter);
  };

  return (
    <div
      className={
        open ? 'ch_filter_sidebar_item open' : 'ch_filter_sidebar_item '
      }
      onClick={() => toggleButton(false)}
    >
      <div
        className={
          !item?.isDisabled
            ? 'ch_filter_sidebar_title'
            : 'ch_filter_sidebar_title ch_filter_sidebar_title_disabled'
        }
      >
        <div style={{ fontWeight: '550' }}>{item?.title}</div>
        <div>
          {!open && (
            <NavigateNextIcon className="ch-filter-sidebar-item-icon-rotate" />
          )}
        </div>
      </div>
      {!item?.isDisabled && (
        <div className="ch_filter_sidebar_content">
          {item?.subValues?.map((value, index) => (
            <div
              key={index}
              className={`ch_filter_sidebar_content_sub_item ${
                selectedSubItem === value?.key
                  ? 'ch_filter_sidebar_content_sub_item_blue_text'
                  : ''
              } ${value?.isDisabled ? 'ch_filter_sidebar_title_disabled' : ''}`}
              onClick={e => toggleButton(true, value?.key, e)}
            >
              <div>{value?.key}</div>
              <NavigateNextIcon fontSize="small" color="disabled" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default OptionsSideBarItem;
