import React from "react";
import LineHeader from "../assets/images/icons/header-line.svg";
import EditIcon from "../assets/images/icons/edit-icon.svg";
import AssetsIcon from "../assets/images/icons/Bust.svg";
import DollarIcon from "../assets/images/icons/DollarCoin.svg";
import "./AssetsInfo.css";

function AssetsInfo() {
  return (
    <div className="assets-info-section">
      <div className="header-section">
        <div className="body-text-normal light-text-color">
          <img src={LineHeader} alt="icon here" /> Assets Info
        </div>
        <div>
          <div className="dropdown">
            <button
              className="btn"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={EditIcon} alt="icon here" />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-custom">
          <thead>
            <tr>
              <td scope="col">
                <div className="thead-td-custom">
                  <img className="img-thead" src={AssetsIcon} alt="icon here" />{" "}
                  Assets<span style={{ color: "transparent" }}>.</span>Name{" "}
                </div>
              </td>
              <td scope="col">
                <div className="thead-td-custom">
                  <img className="img-thead" src={DollarIcon} alt="icon here" />{" "}
                  Total<span style={{ color: "transparent" }}>.</span>Worth
                  <span style={{ color: "transparent" }}>.</span>(USD){" "}
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="body-text-bold">Real Estate</td>

              <td className="body-text-bold">$ 5 Million</td>
            </tr>
            <tr>
              <td className="body-text-bold">Cars/Vehicles</td>

              <td className="body-text-bold">$ 2 Million</td>
            </tr>
            <tr>
              <td className="body-text-bold">Collectibles</td>

              <td className="body-text-bold">$ 0.8 Million</td>
            </tr>
            <tr>
              <td className="body-text-bold">Cars/Vehicles</td>

              <td className="body-text-bold">$ 2 Million</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AssetsInfo;
