import axios from "axios";
import { apiConfig, buildUrl } from "../apiConfig";
import axiosInstance from "../axiosInstance";

// This is the function you will call to make a POST request to your OutsetaUser endpoint
export const postToOutsetaUser = async (userData) => {
  try {
    const response = await axios.post(
      buildUrl(apiConfig.base, apiConfig.outseta.endpoints.outsetaUser), // Replace with your actual cloud function URL
      userData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// This function sends a GET request to fetch a user by user ID
export const getUserById = async (userId) => {
  try {
    const response = await axios.get(
      buildUrl(apiConfig.base, apiConfig.outseta.endpoints.getUserById), // Replace with your actual cloud function URL
      {
        params: { user_id: userId },
      }
    );
    // Assuming the response data is the user object you want
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error fetching user:", error); // Log the error for debugging purposes
    // Return an object with success as false and an error message
    return {
      success: false,
      response: error?.response,
      status: error?.response?.status,
    };
  }
};

// Function to get all users
export const getAllUsers = async (page = 1, limit = 5, search = "") => {
  try {
    const response = await axios.get(
      buildUrl(apiConfig.base, apiConfig.outseta.endpoints.getOutsetaUsers), // Replace with your actual cloud function URL
      {
        params: { page: page, limit: limit, search: search },
      }
    );

    return {
      success: true,
      data: response.data.data,
      totalPages: Math.ceil(response.data.total / limit), // Calculate total pages based on total items and limit
      currentPage: page,
      limit,
      totalCustomers: response.data.total,
    };
  } catch (error) {
    console.error("Error fetching users:", error);
    return {
      success: false,
      response: error?.response,
      status: error?.response?.status,
    };
  }
};

export const userGaveFeedback = async (uid) => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.outseta.endpoints.userGaveFeedback,
        {},
        { uid: uid }
      ) // Replace with your actual cloud function URL
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    return {
      success: false,
      response: error?.response,
      status: error?.response?.status,
    };
  }
};

export const submitFeedback = async (data) => {
  try {
    const response = await axios.post(
      buildUrl(apiConfig.base, apiConfig.outseta.endpoints.submitFeedback), // Replace with your actual cloud function URL
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getIpAddress = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://api.ipify.org/?format=json",
    headers: {},
  };

  const response = await axios.request(config);
  return response.data.ip;
};

export const uniqueViews = async (pageName, userId) => {
  var ip;
  if (!pageName) console.error(`unique views :: page name is required`);
  if (!userId) ip = await getIpAddress();

  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.outseta.endpoints.uniqueViews,
        {},
        { userId: userId ? userId : ip, pageName: pageName }
      )
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    return {
      success: false,
      response: error?.response,
      status: error?.response?.status,
    };
  }
};
