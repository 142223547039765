import React from "react";
import ProbeButton from "../ProbeButton/ProbeButton";

const ProbePricing = ({ styles, data, isHaveButton, onClick, isDisabled }) => {
  return (
    <div
      className={styles?.mainDivStyle}
      style={{ backgroundColor: isDisabled && "#F6F4EB" }}
    >
      <div
        className={styles?.headerDivStyle}
        style={{
          textDecoration: "underline",
          textDecorationColor: isDisabled ? "gray" : "#00D1A9",
          textDecorationThickness: "2px",
          textUnderlineOffset: "4px",
          color: isDisabled && "gray",
        }}
      >
        {data?.header}
      </div>

      <div className={styles?.contentDivStyle}>
        {data?.content && (
          <div
            style={{
              width: "80%",
              color: isDisabled && "gray",
            }}
          >
            {data?.content}
          </div>
        )}
        {styles?.priceTag && (
          <div className={styles?.priceTag} style={{ marginLeft: "15px" }}>
            <div className={styles?.originalPrice}>£10.00</div>
            <div className={styles?.salePrice}>£8.50</div>
          </div>
        )}
      </div>
      {data?.subType?.header && (
        <>
          <div
            className={styles?.headerDivStyle + " " + styles?.marginTop}
            style={{ color: isDisabled && "gray" }}
          >
            {data?.subType?.header}
          </div>
          <div className={styles?.contentDivStyle}>
            {data?.subType?.content && (
              <div style={{ color: isDisabled && "gray" }}>
                {data?.subType?.content}
              </div>
            )}
          </div>
        </>
      )}
      {isHaveButton && (
        <div className={styles?.marginTop}>
          <ProbeButton
            name={data?.buttonContent}
            width={"100%"}
            height={"50px"}
            onClick={onClick}
            disabled={isDisabled}
            borderRadius={"10px"}
          />
        </div>
      )}

      {data?.features && (
        <div className={styles?.marginTop}>
          <div
            className={styles?.headerDivStyle}
            style={{ color: isDisabled && "gray" }}
          >
            {data?.features.header}
          </div>
          <ul>
            <li>
              <div
                className={styles?.featuresDivStyle}
                style={{ color: isDisabled && "gray" }}
              >
                {data?.features.contentHeader}
              </div>
              <ul>
                <li style={{ color: isDisabled && "gray" }}>
                  {data?.features?.innerContent}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProbePricing;
