import React, { useState } from 'react';
import './ExploreLinksOfficerUpdatedCard.css';
import PeopleIcon from '@material-ui/icons/People';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExploreLinksOfficerUpdatedCard = ({ data, removeItem, showTitle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="explore-links-officerCard">
      <button
        className="explore-links-officer-close-button"
        onClick={() => removeItem(data?.title)}
      >
        ×
      </button>
      {showTitle && (
        <>
          <h2 className="explore-links-officer-card-title">{data.type}</h2>
          <p className="accordion-item-description">{data?.subType}</p>
          <p className="explore-links-officer-card-text">More Information</p>
        </>
      )}

      <div className={`accordion ${isOpen ? 'open' : ''}`}>
        <div
          className="explore-links-officer-accordion-header"
          onClick={toggleAccordion}
        >
          <div
            className="explore-links-officer-accordion-logo"
            style={{
              backgroundColor: data.type === 'Officer' ? '#ffde4d' : '#BBE9FF',
            }}
          >
            {data.type === 'Officer' ? (
              <PeopleIcon />
            ) : (
              <FontAwesomeIcon
                icon={faBuilding}
                fontSize={'20px'}
                style={{ marginRight: '5px' }}
              />
            )}
          </div>
          <div className="explore-links-officer-accordion-title">
            <p className="explore-links-officer-accordion-main-title">
              {data?.title}
            </p>
            <p className="explore-links-officer-accordion-subtitle">
              {data?.address}
            </p>
          </div>
          <div className="explore-links-officer-accordion-arrow">
            {isOpen ? '▲' : '▼'}
          </div>
        </div>
        {isOpen && (
          <div className="explore-links-officer-accordion-content">
            {data?.item &&
              Object.entries(data.item).map(([key, value]) => (
                <div key={key}>
                  <div className="explore-links-officer-accordion-item">
                    <div className="accordion-item-description-container">
                      <span className="accordion-item-label">{key}</span>
                    </div>
                    <div className="accordion-item-value-container">
                      <span className="accordion-item-value">
                        {typeof value === 'object' && value !== null
                          ? value.main
                          : value}
                      </span>
                      {typeof value === 'object' && value !== null && (
                        <span className="accordion-item-description">
                          {value.sub}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="accordion-separator"></div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExploreLinksOfficerUpdatedCard;
