import React from 'react';
import './CEIPersonInsightsHeader.css';

const CEIPersonInsightsHeader = ({ personInsights }) => {
  return (
    <div className="corporate-explore-insight-person-header-card">
      <img
        src={personInsights.bio.url}
        alt="Profile"
        className="corporate-explore-insight-person-profile-image"
      />
      <div className="corporate-explore-insight-person-info">
        <h2 className="corporate-explore-insight-person-name">
          {personInsights.bio.name}
        </h2>
        <p className="corporate-explore-insight-person-title">
          {personInsights.bio.occupation}
        </p>
        <p className="corporate-explore-insight-person-location">
          {personInsights.bio.location}
        </p>
      </div>
    </div>
  );
};

export default CEIPersonInsightsHeader;
