import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import overview from '../../../../assets/images/domain.png';
import { getMonthName } from '../../../../utils/common';
import { nationalityToFlag, statusDiv } from '../../../../utils/helper';
const CompanyItem = ({ company, isCardView = false }) => {
  return (
    <div
      className={` ${
        isCardView
          ? 'company-info-modal-person-list-is-card-view'
          : 'company-info-modal-person-list'
      }`}
    >
      <div className="company-info-modal-person-list-info">
        <img
          src={overview}
          alt="Company Logo"
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: '#fff',
          }}
        />
        <div>
          <div className="company-info-modal-overview-key">{company.title}</div>
          <div className="company-info-modal-overview-value company-info-modal-overview-value-people-list company-info-modal-overview-value-with-flex">
            {nationalityToFlag[company?.nationality?.toUpperCase()] && (
              <img
                src={nationalityToFlag[company?.nationality?.toUpperCase()]}
                alt={`${company.nationality} flag`}
                style={{
                  width: '15px',
                  height: '10px',
                  marginRight: '4px',
                }}
              />
            )}
            {company.address && <span>{company?.address}</span>}
          </div>
        </div>
      </div>
      <div className="company-info-modal-person-list-info company-info-modal-person-list-info-link-buttons">
        <div>
          {company.resigned_on ? statusDiv('resigned') : statusDiv('active')}
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            <a
              href={`https://www.linkedin.com/search/results/all/?keywords=${company?.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon style={{ width: '20px' }} />
            </a>
          </div>
          <div>
            <a
              href={`https://www.google.com/search?q=${company?.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LanguageIcon style={{ width: '20px' }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyItem;
