import ReactGA4 from "react-ga4";

const InitializeGoogleAnalytics = () => {
  const tracking_id = process.env.REACT_APP_MEASUREMENT_ID;
  ReactGA4.initialize(tracking_id);
};

const TrackGoogleAnalyticsEvent = (category, action) => {
  ReactGA4.event({
    category: category,
    action: action,
  });
};

export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };
