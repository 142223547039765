import React, { useContext, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './CLGraph.css';
import CLSearchInput from '../CLSearchInput/CLSearchInput';
import { AppContext } from '../../../context/Context';
import CLGrapgLeftSidebarItemHolder from '../CLGraphLeftSidebar/CLGrapgLeftSidebarItemHolder/CLGrapgLeftSidebarItemHolder';
import CLGraphTotalDropdown from '../CLGraphTotalDropdown/CLGraphTotalDropdown';
import ProbeButton from '../../ProbeButton/ProbeButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import RightSidebarMenu from '../../GraphRightSideBar/GraphRightSideBar';
import { convertGraphToCSV } from '../../../utils/helper';
const CLGraph = ({ graph }) => {
  const navigate = useNavigate();
  const svgRef = useRef();
  const zoomRef = useRef();
  const { clGraph, setClGraph } = useContext(AppContext);
  const [searchProbe, setSearchProbe] = useState('');
  const [selectedGraph, setSelectedGraph] = useState(null);

  const exportSelectedGraph = () => {
    try {
      let graphcsvfile = convertGraphToCSV(selectedGraph);

      // Create a Blob from the CSV content
      const blob = new Blob([graphcsvfile], {
        type: 'text/csv;charset=utf-8;',
      });

      // Create a download link
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'graph.csv');

      // Append link to the body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link after the download starts
      document.body.removeChild(link);
    } catch (error) {}
  };

  const onSuggestionSelect = node => {
    let searchQuery = node?.id ? node?.id : node?.entityName;
    setSearchProbe(searchQuery);
  };

  const goBackToSearchEntitiesPage = () => {
    navigate('/CorporateLinks');
  };

  const zoomIn = () => {
    d3.select(svgRef.current).transition().call(zoomRef.current.scaleBy, 1.2);
  };

  const zoomOut = () => {
    d3.select(svgRef.current).transition().call(zoomRef.current.scaleBy, 0.8);
  };

  useEffect(() => {
    if (!selectedGraph) return;

    const width = window.innerWidth;
    const height = window.innerHeight;
    const spaceBetweenNodes = 50; // Adjust this value to increase or decrease spacing

    const zoom = d3.zoom().on('zoom', event => {
      svgGroup.attr('transform', event.transform);
    });

    zoomRef.current = zoom;

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .call(zoom);

    const svgGroup = svg.append('g');

    const graphData = structuredClone(selectedGraph);

    const relationshipEdgesIds = graphData.meta?.relationshipEdgesIds || [];

    const simulation = d3
      .forceSimulation(graphData.nodes)
      .force(
        'link',
        d3
          .forceLink(graphData.edges)
          .id(d => d.id)
          .distance(150)
      )
      .force('charge', d3.forceManyBody().strength(-300))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force(
        'collision',
        d3.forceCollide().radius(d => spaceBetweenNodes)
      );

    const link = svgGroup
      .append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(graphData.edges)
      .enter()
      .append('line')
      .attr('stroke-width', 2)
      .attr('stroke', d =>
        relationshipEdgesIds.includes(d.edgeId) ? 'red' : '#999'
      );

    const node = svgGroup
      .append('g')
      .attr('class', 'nodes')
      .selectAll('circle')
      .data(graphData.nodes)
      .enter()
      .append('circle')
      .attr('r', 10)
      .attr('fill', d => (d.entityType === 'person' ? '#69b3a2' : '#ffab00'))
      .on('mouseover', handleMouseOver)
      .on('mouseout', handleMouseOut)
      .on('click', nodeClicked)
      .call(
        d3
          .drag()
          .on('start', dragStarted)
          .on('drag', dragged)
          .on('end', dragEnded)
      );

    const label = svgGroup
      .append('g')
      .attr('class', 'labels')
      .selectAll('text')
      .data(graphData.nodes)
      .enter()
      .append('text')
      .attr('dx', 12)
      .attr('dy', '.35em')
      .text(d => {
        if (d.entityType === 'company' && d.entityData && d.entityData.name) {
          return d.entityData.name;
        }
        return d.entityName;
      });

    simulation.on('tick', () => {
      link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      node.attr('cx', d => d.x).attr('cy', d => d.y);

      label.attr('x', d => d.x).attr('y', d => d.y);
    });

    function dragStarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragEnded(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    // Handle node click event
    function nodeClicked(event, d) {
      // Stop event propagation so the SVG click handler doesn't fire
      event.stopPropagation();

      // Highlight the clicked node and its connections
      const connectedNodes = new Set();
      const connectedEdges = new Set();

      function findDirectConnections(nodeId) {
        link.each(l => {
          if (l.source.id === nodeId || l.target.id === nodeId) {
            connectedEdges.add(l.edgeId);
            connectedNodes.add(l.source.id);
            connectedNodes.add(l.target.id);
          }
        });
      }

      function findConnectedNodesThroughRelationship(nodeId) {
        link.each(l => {
          if (l.source.id === nodeId || l.target.id === nodeId) {
            if (relationshipEdgesIds.includes(l.edgeId)) {
              connectedEdges.add(l.edgeId);
              if (!connectedNodes.has(l.source.id)) {
                connectedNodes.add(l.source.id);
                findConnectedNodesThroughRelationship(l.source.id);
              }
              if (!connectedNodes.has(l.target.id)) {
                connectedNodes.add(l.target.id);
                findConnectedNodesThroughRelationship(l.target.id);
              }
            }
          }
        });
      }

      connectedNodes.add(d.id);

      let isConnectedWithRelationship = false;
      if (relationshipEdgesIds.length > 0) {
        link.each(l => {
          if (
            (l.source.id === d.id || l.target.id === d.id) &&
            relationshipEdgesIds.includes(l.edgeId)
          ) {
            isConnectedWithRelationship = true;
          }
        });
      }

      if (isConnectedWithRelationship) {
        findConnectedNodesThroughRelationship(d.id);
      } else {
        findDirectConnections(d.id);
      }

      link
        .style('stroke-opacity', l => (connectedEdges.has(l.edgeId) ? 1 : 0.1))
        .style('stroke-width', l =>
          connectedEdges.has(l.edgeId) ? '3px' : '1px'
        );

      node
        .selectAll('circle')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));

      node
        .selectAll('text')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));

      // Update the state with the clicked node details
      setClGraph(previousState => ({
        ...previousState,
        clickedNode: d,
        isOpenRightSidebar: true,
      }));
    }

    // Handle mouse over event to highlight connections
    function handleMouseOver(event, d) {
      const connectedNodes = new Set();
      const connectedEdges = new Set();

      function findDirectConnections(nodeId) {
        link.each(l => {
          if (l.source.id === nodeId || l.target.id === nodeId) {
            connectedEdges.add(l.edgeId);
            connectedNodes.add(l.source.id);
            connectedNodes.add(l.target.id);
          }
        });
      }

      function findConnectedNodesThroughRelationship(nodeId) {
        link.each(l => {
          if (l.source.id === nodeId || l.target.id === nodeId) {
            if (relationshipEdgesIds.includes(l.edgeId)) {
              connectedEdges.add(l.edgeId);
              if (!connectedNodes.has(l.source.id)) {
                connectedNodes.add(l.source.id);
                findConnectedNodesThroughRelationship(l.source.id);
              }
              if (!connectedNodes.has(l.target.id)) {
                connectedNodes.add(l.target.id);
                findConnectedNodesThroughRelationship(l.target.id);
              }
            }
          }
        });
      }

      connectedNodes.add(d.id);

      let isConnectedWithRelationship = false;
      if (relationshipEdgesIds.length > 0) {
        link.each(l => {
          if (
            (l.source.id === d.id || l.target.id === d.id) &&
            relationshipEdgesIds.includes(l.edgeId)
          ) {
            isConnectedWithRelationship = true;
          }
        });
      }

      if (isConnectedWithRelationship) {
        findConnectedNodesThroughRelationship(d.id);
      } else {
        findDirectConnections(d.id);
      }

      link
        .style('stroke-opacity', l => (connectedEdges.has(l.edgeId) ? 1 : 0.1))
        .style('stroke-width', l =>
          connectedEdges.has(l.edgeId) ? '3px' : '1px'
        );

      node
        .selectAll('circle')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));

      node
        .selectAll('text')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));
    }

    // Handle mouse out event to reset the graph
    function handleMouseOut() {
      link.style('stroke-opacity', 1).style('stroke-width', '1px');
      node.selectAll('circle').style('opacity', 1);
      node.selectAll('text').style('opacity', 1);
    }

    // Reset the graph to its default state
    const resetGraph = () => {
      // Reset all nodes, labels, and links to default opacity and size
      d3.selectAll('circle').style('opacity', 1);
      d3.selectAll('text').style('opacity', 1);
      d3.selectAll('line').style('opacity', 1).style('stroke-width', '2px');

      // Clear the searchProbe state to prevent refocusing on the searched node
      setSearchProbe('');

      // Reset zoom and pan (optional if you want to reset the zoom as well)
      svg.transition().duration(750).call(zoom.transform, d3.zoomIdentity);
      simulation.alpha(1).restart();
      simulation.tick();
    };

    // Focus on node if searchProbe matches id or entityName
    if (searchProbe) {
      const matchedNode = graphData.nodes.find(
        node =>
          node.id === searchProbe ||
          (node.entityName &&
            node.entityName.toLowerCase() === searchProbe.toLowerCase())
      );

      if (matchedNode) {
        // Trigger a tick to ensure the node positions are up to date
        simulation.alpha(1).restart();
        simulation.tick();

        // Highlight the matched node
        node.style('opacity', n => (n.id === matchedNode.id ? 1 : 0.1));
        label.style('opacity', n => (n.id === matchedNode.id ? 1 : 0.1));
        link.style('opacity', 0.1);

        // Zoom in and center the matched node
        const [x, y] = [matchedNode.x, matchedNode.y];
        // Assuming you want to zoom to level 2 and center the node
        const zoomLevel = 2;

        // Calculate the new translation values based on zoom level and node's coordinates
        const translateX = width / 2 - x * zoomLevel;
        const translateY = height / 2 - y * zoomLevel;

        const transform = d3.zoomIdentity
          .translate(translateX, translateY) // Adjust translation based on node's position
          .scale(zoomLevel); // Adjust zoom level
        svg.transition().duration(750).call(zoom.transform, transform);

        // Reset the simulation alpha target
        simulation.alphaTarget(0);
      }
    }

    // Handle click outside the SVG to reset the graph
    function handleClickOutside(event) {
      if (svgRef.current && !svgRef.current.contains(event.target)) {
        resetGraph(); // Call the reset function when clicking outside
      }
    }

    // Add a click listener to the SVG to reset the graph when clicking on empty space
    svg.on('click', () => {
      resetGraph(); // Reset graph when clicking on the SVG (empty space)
    });

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      svg.on('click', null); // Remove SVG click listener
      svg.selectAll('*').remove();
    };
  }, [selectedGraph, searchProbe]);

  return (
    <div>
      <svg ref={svgRef} className="cl-graph-d3-svg"></svg>
      {graph && (
        <div>
          <div className="cl-graph-searchinput-main">
            <div className="cl-graph-desktop-view">
              <ProbeButton
                width={50}
                height={50}
                iconSrc={<ArrowBackIcon />}
                iconType={'svg'}
                borderRadius={50}
                onClick={goBackToSearchEntitiesPage}
              />
            </div>
            <CLSearchInput
              nodes={selectedGraph && selectedGraph?.nodes}
              onSuggestionSelect={onSuggestionSelect}
            />
            <div className="cl-graph-desktop-view">
              <CLGraphTotalDropdown
                graphList={graph}
                setSelectedGraph={setSelectedGraph}
              />
            </div>
          </div>

          <div className="cl-graph-rightsidebarmenu-main">
            <RightSidebarMenu
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              exportSelectedGraph={exportSelectedGraph}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 80,
              left: 10,
            }}
          >
            <CLGrapgLeftSidebarItemHolder />
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 170,
              left: 10,
            }}
            className="cl-graph-mobile-view"
          >
            <CLGraphTotalDropdown
              graphList={graph}
              setSelectedGraph={setSelectedGraph}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 80,
              right: 10,
            }}
          >
            <div className="cl-graph-mobile-view">
              <ProbeButton
                width={50}
                height={50}
                iconSrc={<ArrowBackIcon />}
                iconType={'svg'}
                borderRadius={50}
                onClick={goBackToSearchEntitiesPage}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CLGraph;
