import React from "react";
import TableLoadingScreen from "../../../LoadingScreen/TableLoadingScreen/TableLoadingScreen";
import NumberDisplay from "../../../NumberDisplay/NumberDisplay";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LanguageIcon from "@material-ui/icons/Language";
import "../CompanyInfoModal.css";

function Shareholders({
  debouncedSearch,
  isLoading,
  shareholders,
  smallIcons = false,
}) {
  return (
    <div>
      <div
        className={`company-info-body-scroller ${
          smallIcons ? "company-info-body-scroller-insight" : ""
        }`}
      >
        <div className="company-info-modal-people-contant-main">
          <div className="company-info-modal-overview-main">
            <div
              className="company-info-modal-overview-title"
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Shareholders
              <div>
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search Shareholder ..."
                  onChange={(e) => {
                    const value = e.target.value;
                    debouncedSearch(value);
                  }}
                />
              </div>
            </div>
            {isLoading === false ? (
              <div className="company-info-modal-Table-container">
                {shareholders && Array.isArray(shareholders) && (
                  <>
                    {shareholders
                      ?.filter((shareType) => shareType.type === "Preferred")
                      .map((shareType, index) => (
                        <div
                          key={index}
                          className="company-info-modal-share-type"
                        >
                          <div className="company-info-modal-header">
                            <div className="company-info-modal-shares-type">
                              {shareType?.type}{" "}
                              <span style={{ marginLeft: "5px" }}>Shares</span>
                            </div>
                            <div className="company-info-modal-total-shares">
                              <span
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "600px",
                                }}
                              >
                                Total Shares:
                              </span>
                              <NumberDisplay
                                animatedValue={shareType?.total_shares}
                              />
                            </div>
                          </div>
                          <table className="company-info-modal-shareholders-table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th className="preferred-column">Shares</th>
                                <th className="percentage-column">
                                  Percentage
                                </th>
                                <th className="company-info-modal-shareholder-socialIcon-main"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {shareType.people.map((person, idx) => (
                                <tr
                                  key={idx}
                                  className="company-info-modal-shareholder"
                                >
                                  <td className="company-info-modal-shareholders-name">
                                    {person.name}
                                    <div className="sub-text">
                                      {person.type.toUpperCase()}
                                    </div>
                                  </td>
                                  <td className="preferred-column">
                                    <NumberDisplay
                                      animatedValue={person.number_of_shares}
                                    />
                                  </td>
                                  <td className="percentage-column">
                                    {person.shares_percentage}
                                  </td>
                                  <td className="company-info-modal-shareholder-socialIcon-main">
                                    <div
                                      className={`company-info-modal-shareholder-socialIcons ${
                                        smallIcons
                                          ? "company-info-modal-shareholder-socialIcons-insights"
                                          : ""
                                      }`}
                                    >
                                      <a
                                        href={`https://www.linkedin.com/search/results/all/?keywords=${person?.name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <LinkedInIcon
                                          className={
                                            smallIcons &&
                                            "company-info-modal-shareholder-socialIcons-size"
                                          }
                                        />
                                      </a>
                                      <a
                                        href={`https://www.google.com/search?q=${person?.name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <LanguageIcon
                                          className={
                                            smallIcons &&
                                            "company-info-modal-shareholder-socialIcons-size"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}

                    {shareholders
                      .filter((shareType) => shareType.type !== "Preferred")
                      .map((shareType, index) => (
                        <div
                          key={index}
                          className="company-info-modal-share-type"
                        >
                          <div className="company-info-modal-header">
                            <div className="company-info-modal-shares-type">
                              {shareType?.type}{" "}
                              <span style={{ marginLeft: "5px" }}>Shares</span>
                            </div>
                            <div className="company-info-modal-total-shares">
                              <span
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "600px",
                                }}
                              >
                                Total Shares:
                              </span>
                              <NumberDisplay
                                animatedValue={shareType?.total_shares}
                              />
                            </div>
                          </div>
                          <table className="company-info-modal-shareholders-table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th className="preferred-column">Shares</th>
                                <th className="percentage-column">
                                  Percentage
                                </th>
                                <th className="company-info-modal-shareholder-socialIcon-main"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {shareType.people.map((person, idx) => (
                                <tr
                                  key={idx}
                                  className="company-info-modal-shareholder"
                                >
                                  <td className="company-info-modal-shareholders-name">
                                    {person.name}
                                    <div className="sub-text">
                                      {person.type.toUpperCase()}
                                    </div>
                                  </td>
                                  <td className="preferred-column">
                                    <NumberDisplay
                                      animatedValue={person.number_of_shares}
                                    />
                                  </td>
                                  <td className="percentage-column">
                                    {person.shares_percentage}
                                  </td>
                                  <td className="company-info-modal-shareholder-socialIcon-main">
                                    <div
                                      className={`company-info-modal-shareholder-socialIcons ${
                                        smallIcons
                                          ? "company-info-modal-shareholder-socialIcons-insights"
                                          : ""
                                      }`}
                                    >
                                      <a
                                        href={`https://www.linkedin.com/search/results/all/?keywords=${person?.name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <LinkedInIcon
                                          className={
                                            smallIcons &&
                                            "company-info-modal-shareholder-socialIcons-size"
                                          }
                                        />
                                      </a>
                                      <a
                                        href={`https://www.google.com/search?q=${person?.name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <LanguageIcon
                                          className={
                                            smallIcons &&
                                            "company-info-modal-shareholder-socialIcons-size"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}
                  </>
                )}
              </div>
            ) : (
              <TableLoadingScreen />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shareholders;
