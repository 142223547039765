import axios from "axios";
import { stringify } from "qs"; // qs is a querystring parsing library

const axiosInstance = axios.create();

// Define a cache structure that stores both timestamp and response
const requestCache = new Map();

axiosInstance.interceptors.request.use(
  (config) => {
    const { url, method, params, data, setIsLoading } = config;
    // Serialize URL parameters and request body to ensure uniqueness
    const serializedParams = params ? `?${stringify(params)}` : "";
    const serializedData = data ? JSON.stringify(data) : "";
    const key = `${method} ${url}${serializedParams} ${serializedData}`;
    const now = Date.now();

    // Check if the request is cached and validate the cache expiration
    if (requestCache.has(key)) {
      const { lastCall, response } = requestCache.get(key);
      const timeSinceLastCall = now - lastCall;

      // If within the cache timeframe, return the cached response
      if (timeSinceLastCall < 1000) {
        // If setIsLoading is provided, ensure to handle loading state
        if (setIsLoading) {
          setIsLoading(false);
        }

        // Return cached response instead of making a new request
        return Promise.resolve({
          ...response,
          config,
          request: { ...config, fromCache: true },
        });
      }
    }

    // Proceed with the request and cache it
    requestCache.set(key, { lastCall: now });

    // Show loading if the function is provided
    if (setIsLoading) setIsLoading(true);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Modify the response interceptor to cache responses and hide loading
axiosInstance.interceptors.response.use(
  (response) => {
    const { config } = response;
    const { url, method, setIsLoading, params, data } = config;

    // Serialize URL parameters and request body to ensure uniqueness
    const serializedParams = params ? `?${stringify(params)}` : "";
    const serializedData = data ? JSON.stringify(data) : "";
    const key = `${method} ${url}${serializedParams} ${serializedData}`;

    // Cache the successful response with timestamp
    requestCache.set(key, { lastCall: Date.now(), response });

    // Hide loading if the function is provided
    if (setIsLoading) setIsLoading(false);
    return response;
  },
  (error) => {
    if (error.config && error.config.setIsLoading)
      error.config.setIsLoading(false);
    return Promise.reject(error);
  }
);

export default axiosInstance;
