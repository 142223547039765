import React from "react";
import HeaderDropdown from "../../../../components/HeaderDropdownNative";
import "../../JudgementChecker.css";
import "./TermsAndConditions.css";

function TermsAndConditions() {
  return (
    <div
      className="dashboard-section dashboard-content-div-overflow"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="main-div">
        <div className="header">
          <div className="header-heading dark-heading-color probe-header-info">
            Terms & Conditions
          </div>
          <div className="judgement_check_terms_conditions_policy_headerDropDOwn">
            <HeaderDropdown />
          </div>
        </div>
      </div>
      <div className="judgement_check_terms_conditions_policy">
        <div className="judgement_check_terms_conditions_policy_bold_text">
          Please read these terms and conditions carefully.
        </div>
        <div className="judgement_check_terms_conditions_policy_main_heading">
          Terms & Conditions
        </div>
        <div className="judgement_check_terms_conditions_policy_bold_text">
          By viewing and using our website, you are agreeing to the terms that
          appear below and those contained in the separate Privacy and Cookies
          notice. These terms & conditions apply to both personal and business
          users of the website.
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          The site is owned and run Probe Digital Limited, a for profit company
          limited by guarantee (registered in England No 15518675) and based in
          England. Our address is 167-169 5th Floor, Great Portland Street,
          London, United Kingdom, W1W 5PF and we can be contacted by post at
          this address or by email at
          <span className="judgement_check_terms_conditions_policy_hyper_links">
            <a href="mailto:contact@probedigital.co.uk">
              contact@probedigital.co.uk
            </a>
          </span>
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          We may make changes to any of the web pages or content (including
          these terms & conditions) at any time. Changes to the terms and
          conditions will be indicated by displaying the Date of Last Issue at
          the end of these terms & conditions. By continued use of the website
          after we make the changes, you are agreeing to the changes.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          The ProbeDigital website may from time to time be unavailable to allow
          for essential maintenance and updates and Registry Trust Limited
          cannot guarantee the availability of the site. Please return to the
          site the following day if it is after 1900 or any time on a Sunday and
          after 2 hours if it between 0800 and 1900 Monday to Saturday.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_bold_text">
          Should you have a complaint
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          If you are dissatisfied with the service you have received from
          Registry Trust we would like to hear from you. To read our full
          Complaints Policy and Procedure which explains how to contact us and
          how we handle complaints please click
          <a
            style={{ marginLeft: "5px" }}
            href={process.env.REACT_APP_COMPLAINT_PDF}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
          <span style={{ marginLeft: "5px" }}>
            Please note to download this document you must have Adobe Reader
            installed on your computer or you can download the free Adobe reader
            software by using the following link :
          </span>
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          If you wish to make a complaint please download the complaint form
          <span className="judgement_check_terms_conditions_policy_hyper_links">
            <a
              href={process.env.REACT_APP_COMPLAINT_PDF}
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </span>
          which will help you supply the information we need to investigate and
          to respond to you. All complaints are monitored internally and will be
          treated in priority order. If you are not satisfied with the response
          given to you our Complaints Policy and Procedure also explains how
          your complaint may be escalated.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_bold_text">
          Alternative Dispute Resolution{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          In accordance with The Alternative Dispute Resolution for Consumer
          Disputes (Competent Authorities and Information) Regulations 2015 (as
          amended from time to time) Registry Trust has selected The Ombudsman
          Service Limited{" "}
          <span className="judgement_check_terms_conditions_policy_hyper_links">
            <a
              href="https://www.ombudsman-services.org"
              target="_blank"
              rel="noreferrer"
            >
              ( ombudsman-services )
            </a>
          </span>{" "}
          as its nominated alternative dispute resolution (ADR) service
          provider.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_bold_text">
          Alternative Dispute Resolution
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          <div className="judgement_check_terms_conditions_policy_bold_text">
            Please be aware that businesses are not obliged to use an ADR
            service and Registry Trust has chosen not to
          </div>
          . Registry Trust has an established complaints procedure outlined in
          the document outlined in the above section. This includes escalation
          to an independent consumer rights professional who fulfils the
          function of ombudsman for complaints about our actions or service.
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          There is an online dispute resolution platform maintained by the
          European Commission. This can be found at: 
          <span className="judgement_check_terms_conditions_policy_hyper_links">
            <a
              href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage"
              target="_blank"
              rel="noreferrer"
            >
              webgate.ec.europa.eu
            </a>
          </span>
           It is intended to assist with the resolution of domestic and
          cross-border disputes concerning on-line purchases by channelling
          disputes to national ADR bodies. However, your attention is drawn to
          the text in bold above explaining that Registry Trust has chosen not
          to use an ADR service.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_bold_text">
          Content of this website{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          We or our licensors own all the rights to all the materials contained
          in the website (we call this ‘the content’) this includes patents,
          copyright, database rights and trademarks.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          As a user you can:{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          <ul>
            <li className="judgement_check_terms_conditions_policy_normal_text">
              View and display the content on a computer screen
            </li>
            <li className="judgement_check_terms_conditions_policy_normal_text">
              Print individual pages on paper and make a reasonable number of
              photocopies
            </li>
            <li className="judgement_check_terms_conditions_policy_normal_text">
              Store the content in electronic form on your computer for your
              personal use or for account customers their own business use
            </li>
          </ul>
          We do not give you the right and you must not pass on, sell, publish
          or make profit from any of the content without first getting written
          agreement from Registry Trust Limited. By making trademarks and logos
          available on the web site, we are not giving you permission to use
          them.
        </div>
        <div className="judgement_check_terms_conditions_policy_bold_text">
          Liability of users of the website{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          The content of this website contains personal data held as part of the
          registers containing judgments, orders and fines. We are obliged to
          make this information available for a fee to anyone who wishes to
          search the register.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          We update the content from data that is supplied to Registry Trust
          from a number of sources and we take reasonable care to check that the
          content is accurate and complete before we add it to the website. We
          will not be responsible for any mistake, internet access or for the
          accuracy of the content in any way and you enter data, download, print
          or use the content entirely at your own risk.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          You are advised that any content downloaded onto your computer or
          printed should be used solely for your own personal or business use
          and is subject to the Data Protection Acts. If you use this content
          for any business purpose and store the data in any form, you will need
          to comply with the Data Protection Act of 2018, which covers the
          usage, storage and deletion of such data. You can obtain more details
          from the Information Commissioner’s Office on their official website
          at 
          <span className="judgement_check_terms_conditions_policy_hyper_links">
            <a href="https://ico.org.uk/" target="_blank" rel="noreferrer">
              ico.org.uk
            </a>
          </span>
        </div>
        <div className="judgement_check_terms_conditions_policy_bold_text">
          Paying for searches of the registers{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          Access to the register by way of a search will incur a fee payable
          prior to each search made by you. If you opt to pay on-line by our
          secure payment facility, Registry Trust Limited will not store any
          information in respect of your credit or debit card as these are
          processed by a reputable third party.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          If you are a business user your account must be in credit before you
          can make a search and the fee will be deducted from your credit
          balance each time a search is made.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          It is your responsibility to check that the details of the search have
          been entered correctly when submitting the search request. Fees cannot
          be refunded once a search has been made even if the user has
          incorrectly entered the data used in the search.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          By agreeing to these terms and conditions you are giving consent to
          waive the 14-day cooling-off period and will therefore not obtain a
          refund for the search.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_bold_text">
          Links to other websites{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          The website contains links or references to other websites or services
          provided by organisations that are independent from Registry Trust
          Limited. Although we include these links we do not in any way endorse,
          recommend or promote the products or services contained in these
          websites. If you have any complaints about the material on these sites
          you should contact the party providing the website or service.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          You must not link to this site without first getting the written
          permission of Registry Trust Limited.{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_bold_text">
          Legal Jurisdiction{" "}
        </div>
        <div className="judgement_check_terms_conditions_policy_normal_text">
          The terms will be governed by, and interpreted under English Law. You
          agree by using this site that the English courts will have full
          authority to settle any dispute that may directly arise or are in
          connection with these Terms & Conditions.{" "}
        </div>
        <div>
          <span className="judgement_check_terms_conditions_policy_bold_text">
            Date of Last Issue
          </span>
           – 16th March 2020
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
